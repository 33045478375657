import { FiAlertCircle } from "react-icons/fi";
import { useRef, useEffect, useState } from 'react'
import "./Significance.css";
import { useParams } from "react-router-dom";
import * as translator from "../../../../utils/translator";

export default function Significance({
  level,
  text = "Significance",
  description = "",
  width = "100%",
  fontWeight,
  showIcon = true,
  dangerSignificancesRefsDispatch,
  parentID,
  id="",
  fontSize = '15px',
  margin = '25px 0',
  fontFamily = "Poppins, sans-serif",
}) {

  const { country } = useParams();
  const ref = useRef();
  const [expanded, setExpanded] = useState(false);
  const replacePattern = /\{([^:]+):([^}]+)\}/g;

  //Replacing the text to be a link incase it matches that replacePattern
  if (text && typeof text === "string"){
    text = text.replace(replacePattern, (match, anytext, anyattributes) => {
      return `<a ${anyattributes}>${anytext}</a>`;
    });
  }

  const getSignificanceStyling = () => {
    switch (level) {
      case "danger":
        return {
          display:"block",
          width: width,
          backgroundColor: "var(--sub-danger)",
          color: "var(--primary-danger)",
        };

      case "warning":
        return {
          display:"block",
          width: width,
          backgroundColor: "var(--sub-warning)",
          color: "var(--primary-warning)",
        };

      case "success":
        return {
          display:"block",
          width: width,
          backgroundColor: "var(--sub-success)",
          color: "var(--primary-success)",
        };

      case "info":
        return {
          display:"block",
          width: width,
          backgroundColor: "var(--primary-info)",
          color: "var(--sub-info)",
        };

      default:
        return  {
          display:null,
          width: 0,
          backgroundColor: "transparent",
          color: "white",
        };    
    }
  };

  useEffect(() => {
    // add to the array of significances refs
    if(level === "danger" && dangerSignificancesRefsDispatch && parentID){
      dangerSignificancesRefsDispatch("add",{current:ref.current,id:parentID});
    }
  
    return  () => {
    // remove to the array of significances refs
    if(level === "danger" && dangerSignificancesRefsDispatch && parentID ){
      dangerSignificancesRefsDispatch("remove",{current:ref.current,id:parentID});
    }}
  }, [])

  
  const toggleDescription = () => {
    setExpanded(!expanded);
  };

  if (!getSignificanceStyling().display) {
    return null;

  } else {
    return (
      <div  
        id={id}
        ref={ref}
        className="significance-container"
        style={{backgroundColor: getSignificanceStyling().backgroundColor, margin:margin }}
      >
        <div id="significance">
          <div id="significance-icon-and-text">

            {/* ICON */}
            {showIcon ? (
              <div>
                <FiAlertCircle
                  className="significance-alert-icon"
                  color={getSignificanceStyling().color}
                />
              </div>
            ) : null}

            {/* TEXT  */}
            <span
              id="significance-text"
              dangerouslySetInnerHTML={{ __html: text }}
              style={{
                whiteSpace: "pre-wrap",
                fontWeight: fontWeight,
                fontSize: fontSize,
                color: getSignificanceStyling().color,
                fontFamily: fontFamily
              }}
            />
          </div>

          {/* Show more/less clickable*/}
          {description && (
            <>
              <span id="significance-show-clickable">
                {expanded ? (
                    <span id="show-less-clickable"
                      onClick={toggleDescription}
                    >
                      {translator.translate(translator.getLanguageByCountry(country), "my_account", 'MyOrders/showLess')} ▲
                    </span>
                ) : (
                  <span id="show-more-clickable"
                    onClick={toggleDescription}
                  >
                    {translator.translate(translator.getLanguageByCountry(country), "my_account", 'MyOrders/showMore')} ▼


                  </span>
                )}
              </span>

              {/* Expanded description */}
              {
                expanded ?
                  <p 
                    id="significance-expanded-description" 
                    dangerouslySetInnerHTML={{ __html: description }}
                    style={{color:getSignificanceStyling().color}}
                  />
                  :
                  null
              }

            </>

          ) 

        }
        </div>
      </div>
    );
  }

}
