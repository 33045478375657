import React, { useState, useEffect } from 'react'
import "./DeactivateAccountConfirmationPage.css" 
import { useParams } from "react-router-dom"
import Footer from '../../components/Footer/Footer';
import * as translator from "../../utils/translator";
import * as http from "../../utils/http"
import { clearCustomerFromSession } from '../../reducers/CustomerReducer';
import goodbyeImage from "../../images/goodbye.png"
import HeaderCMS from '../../components/Headers/HeaderCMS/HeaderCMS';
import StickyNotification from '../../components/StickyNotification/StickyNotification';

const DeactivateAccountConfirmationPage = () => {

  const { country } = useParams();
  const { token } = useParams();
  const [stickyNotificationDetails, setStickyNotificationDetails] = useState({ // global state for sticky handling
    show: false,
    title: "",
    content: "",
    level : "",
    showInfoIcon:true,
    refresh:false,
  });

  useEffect(() => {
    http
    .postToServer(
    `deactivate-account`,
    {
      token:token,
    },
    []
    )
    .then((response) => {
        clearCustomerFromSession()
    })
    .catch(error => {
      setStickyNotificationDetails({
        show: true,
        title: "",
        content: translator.translate(translator.getLanguageByCountry(country), "my_account", `stickyNotifications/error/error`),
        level: "error",
        showInfoIcon: false,
        refresh: false
      });
    });
  }, [])

  return (
    <>
      <StickyNotification
        showNotification={stickyNotificationDetails.show}
        title={stickyNotificationDetails.title}
        onClick={() => setStickyNotificationDetails({ ...stickyNotificationDetails, show: false })}
        content={stickyNotificationDetails.content}
      />
      {country && (
        <div className="goodbye-page">
          <HeaderCMS country={country} />
          <div className="goodbye-image-container">
          <img className="goodbye-image" src={goodbyeImage} alt="Goodbye" />
          <h1 className="goodbye-title">Goodbye and Stay Healthy!</h1>
          </div>
          <div>
            <Footer page="CheckoutRegister" />
          </div>
        </div>
      )}
    </>
  );
}

export default DeactivateAccountConfirmationPage;