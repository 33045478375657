import React from "react";
import RadioButton from "../RadioButton/RadioButton";
import { Fragment } from "react";

const RadioButtons = ({ children, rootQuestion = null, onAnswerClick, errorMode, answersSelectedMap }) => {
  return (
    <div className={"questionnaire-answers-container"}>
      {children.map((childNode, idx) => (
        <Fragment key={childNode.recipe.id}>
          {/* <div
            className="questionnaire-question-item-text-container questionnaire-subquestion-text "
            dangerouslySetInnerHTML={{
              __html:
                childNode.recipe.label !== ""
                  ? childNode.recipe.label
                  : childNode.recipe.long_label,
            }}
          /> */}
          <RadioButton
            selected={answersSelectedMap[childNode.recipe.id]}
            onClick={() => {
              onAnswerClick(childNode);
            }}
            label={childNode.recipe.value}
            groupName={childNode.recipe.parent_id}
            value={childNode.recipe.value}
            id={"answer_" + childNode.recipe.id}
            questionId={childNode.recipe.recipe_question_id}
            errorMode={errorMode}
          />
        </Fragment>
      ))}
    </div>
  );
};

export default RadioButtons;
