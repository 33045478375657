import "./NumberInput.css";

export default function NumberInput({
  placeholder = "A Number Input",
  minValue = 0,
  maxValue = 220,
  id,
  onChange,
  errorMode,
  value = ""
}) {

  return (
    <input
      className={
        errorMode ? "numberInput-error-container" : "numberInput-container"
      }
      type="number"
      onChange={(event) => {
        onChange(event.target.value);
      }}
      placeholder={placeholder}
      id={id}
      value={value}
    />
  );
}
