import React, { useEffect } from 'react'
import "./Trustedshops.css";
import { clientConfig } from "../../config";
import { MainTracker } from '../../PixelTrackers/MainTracker';

let TrustedshopsKey

const Trustedshops = ({isThankyouPage = false, response='', language}) => {

    if(language === 'de'){
    TrustedshopsKey = clientConfig.TRUSTED_SHOPS_KEY_DE;
    }else{
    TrustedshopsKey = clientConfig.TRUSTED_SHOPS_KEY_FR;
    }

    useEffect(() => {
        if (window.innerWidth > 649) {
            const body = document.querySelector('body');

            let searchChat = new MutationObserver(mutations => {
                let shop = document.getElementById("trustbadge-container");
                let chatAgent
                if (window.liveAgentChatButton) {
                    chatAgent = window.liveAgentChatButton
                } else if (window.HubSpotConversations) {
                    chatAgent = window.HubSpotConversations
                }

                let liveAgentBtn = chatAgent;

                if (liveAgentBtn && shop && shop.length > 0) {
                    shop.addClass('bottom');
                    searchChat.disconnect();
                }
            });

            searchChat.observe(body, { attributes: true, childList: true, subtree: true });
        }

        const trustedShopInit = (code) => {
            var _tsid = code;
            var _ts = document.createElement('script');
            _ts.type = 'text/javascript';
            _ts.charset = 'utf-8';
            _ts.async = true;
            _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js';
            var __ts = document.getElementsByTagName('script')[0];
            __ts.parentNode.insertBefore(_ts, __ts);
        };

        trustedShopInit(TrustedshopsKey)

        window.scroll(function () {
            const trusted = document.getElementById("minimized-trustbadge");
            if (trusted && window.innerWidth < 649) {
                if (trusted.scrollTop() <= 10) {
                    trusted.attr('style', 'visibility: visible !important; opacity: 1 !important;');
                } else {
                    trusted.attr('style', ' ');
                }
            }
        });
    }, [response])


    return (
        <>
        <div onClick={MainTracker.track('click', 'Clicked Trusted Shops Badge')}>
            <a href={`https://www.trustedshops.de/shop/certificate.php?shop_id=${TrustedshopsKey}`} rel="noreferrer nofollow" target="_blank">
                <div className="sprite sprite-main-trusted-logo"></div>
            </a>
            {
                isThankyouPage && (
                    <div id="trustedShopsCheckout" style={{display: 'none'}}>
                        <span id="tsCheckoutOrderNr">{response.order?.hash_id}</span>
                        <span id="tsCheckoutBuyerEmail">{response.tagManager?.CustomerEmail}</span>
                        <span id="tsCheckoutOrderAmount">{response.order?.price}</span>
                        <span id="tsCheckoutOrderCurrency">{response.tagManager?.OrderCurrency}</span>
                        <span id="tsCheckoutOrderPaymentType">PREPAYMENT</span>
                        <span id="tsCheckoutOrderEstDeliveryDate">{response.createdAt}</span>
                    </div>
                )
            }
        </div>
        </>
    )
}

export default Trustedshops
