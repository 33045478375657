import "./StepsList.css";
import { TbChevronRight } from "react-icons/tb";
import { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import * as translator from "../../utils/translator";

export default function StepsList({ stage, language, isMobile = false, flow }) {
  const [currentStage, setCurrentStage] = useState(stage);

  const getCurrentStage = (stage) => {
    if (stage === "Questionnaire") setCurrentStage(1);
    if (stage === "Treatment") setCurrentStage(2);
    if (stage === "Payment") setCurrentStage(3);
  };

  useEffect(() => {
    getCurrentStage(stage);
  }, [currentStage,stage]);


  const handleProgressBar = (stageNumber) => {
    if (stageNumber === 1) {
        return {width:'30%'}
    }
  
    if (stageNumber === 2) {
        return {width:'55%'}
    }

    if (stageNumber === 3) {
      return {width:'85%'}
    }
  }


  const handleFontStyles = (stageNumber) => {
    if (stageNumber === 1) {
      return currentStage == 1
        ? { fontWeight: 700 }
        : { color: "var(--primary-lightgreen)" };
    }

    if (stageNumber === 2) {
      if (currentStage === 2) return { fontWeight: 700 };
      if (currentStage > 2) {
        return { color: "var(--primary-lightgreen)" };
      }
    }

    if (stageNumber === 3) {
      return currentStage == 3 ? { fontWeight: 700 } : null;
    }
  };

  return (
    <>
    <div
      className={
        isMobile ? "stepslist-container-mobile" : "stepslist-container"
      }
    >
      <div className={isMobile ? "step-container-mobile" : "step-container"}>
        <span className="step-title notranslate" style={handleFontStyles(1)}>
          {currentStage > 1 && !isMobile ?
            <FaCheckCircle
              style={{ 
                marginRight: 5 ,
                }}
              color="var(--primary-lightgreen)"
              size={16}
            />
           : 
            "1. "
          }
          {translator.translate(language, "default", `StepsList/${flow}/questionnaire`)}
        </span>
        {isMobile ? null : <TbChevronRight size={22} className="step-logo" />}
      </div>

      <div className={isMobile ? "step-container-mobile" : "step-container"}>
        <span className="step-title notranslate" style={handleFontStyles(2)}>
          {currentStage > 2 && !isMobile ? 
            <FaCheckCircle
              style={{
                marginRight: 5,
              }}
              color="var(--primary-lightgreen)"
              size={16}
            />
           : 
            "2. "
          }
          {translator.translate(language, "default", `StepsList/${flow}/treatment`)}
        </span>
        {isMobile ? null : <TbChevronRight size={22} className="step-logo" />} 
      </div>

      <div className={isMobile ? "step-container-mobile" : "step-container"}>
        <span className="step-title notranslate" style={handleFontStyles(3)}>
          3. {translator.translate(language, "default", `StepsList/${flow}/payment`)}
        </span>
      </div>


    </div>
      {isMobile ? <div style={handleProgressBar(currentStage)} className="stepslist-progress-bar"></div> : null }
    </>
  );
}
