import { useState } from 'react';

type ConversationMention = {
  mention_type: string;
  mention_id: number;
  mention_text: string;
  mention_link?: string;
};

const useProductsAutoCompletion = (
  searchProducts: ConversationMention[],
  searchOrders: ConversationMention[],
  onSelect: (mention: ConversationMention) => void
) => {
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<ConversationMention[]>([]);
  const [prefixInputState, setPrefixInputState] = useState<string>('');

  const filterProductsByPrefix = (prefixInput: string) => {
    const regex = new RegExp(`^${prefixInput}`, 'i');
    return searchProducts.filter((product: ConversationMention) => regex.test(product.mention_text));
  };

  const filterOrdersByPrefix = (prefixInput: string) => {
    const regex = new RegExp(`^${prefixInput}`, 'i');
    return searchOrders.filter((order: ConversationMention) => regex.test(order.mention_text));
  };

  const onTextChange = (inputText: string) => {
    const words = inputText.split(/\s+/); // Split by whitespace
    const lastWord = words[words.length - 1];
  
    if (inputText.length < 1) {
      setShowAutoComplete(false);
      setAutoCompleteOptions([]);
      return;
    }
  
    if (lastWord.startsWith('@')) {
      setShowAutoComplete(true);
      const prefixInput = lastWord.slice(1); // The rest of the word without the @
      setPrefixInputState(prefixInput);
      setAutoCompleteOptions(filterProductsByPrefix(prefixInput));
  
    } else if (lastWord.startsWith('#')) {
      setShowAutoComplete(true);
      const prefixInput = lastWord.slice(1); // The rest of the word without the #
      setPrefixInputState(prefixInput);
      setAutoCompleteOptions(filterOrdersByPrefix(prefixInput));
  
    } else {
      setShowAutoComplete(false);
      setAutoCompleteOptions([]);
    }
  };

  const onAutoCompletionSelect = (mention: ConversationMention) => {
    setShowAutoComplete(false);
    onSelect(mention);
  };

  return {
    onAutoCompletionSelect,
    onTextChange,
    autoCompleteOptions,
    showAutoComplete,
    prefixInputState,
  };
};

export default useProductsAutoCompletion;
