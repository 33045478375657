import HeaderController from "../../components/Headers/HeaderController";
import "./CheckoutEvaluation.css";
import hasanEvaluation from "../../images/hasanEvaluation.png";
import recipeEvaluation from "../../images/recipeEvaluation.png";
import { clientConfig } from "../../config";
import { useParams } from "react-router-dom";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import * as translator from "../../utils/translator";

export default function CheckoutEvaluation() {
  const { language } = useParams();
  const relocationString = clientConfig.MAIN_WEBSITE_URL + `/${language}`;

  return (
    <>
      <HeaderController
        language={language}
        stage="Treatment"
        backgroundColor="white"
      />

      <div className="checkout-evaluation-body">
        <div className="checkout-evaluation-container">
          <div className="checkout-evaluation-content-container">
            <p className="checkout-evaluation-mainTitle">
              {translator.translate(
                language,
                "default",
                "CheckoutEvaluation/main_title"
              )}
            </p>

            <div className="checkout-evaluation-alert-container">
              <img src={recipeEvaluation} alt="recipe-evaluation" />
              <p className="checkout-evaluation-content-text">
                {translator.translate(
                  language,
                  "default",
                  "CheckoutEvaluation/content_text"
                )}
              </p>
            </div>
          </div>

          <img
            src={hasanEvaluation}
            className="checkout-evaluation-doctor-img"
            alt="doktor-img"
          />
        </div>
        <SubmitButton
          language={language}
          showArrow
          placeholder={"Zurück zum Kategoriebereich"}
          onSubmit={() => {
            window.location.replace(relocationString);
          }}
        />
      </div>
    </>
  );
}
