import React, { useEffect, useRef } from "react";
import "./TrustpilotBanner.css";

interface TrustpilotBannerProps {
  locale: string;
}

const TrustpilotBanner: React.FC<TrustpilotBannerProps> = ({ locale }) => {
  const ref = useRef<HTMLDivElement>(null);

  const getTrustpilotLinkByLocale = () => {
    if (locale.includes("de")) {
      return "https://de.trustpilot.com/review/doktorabc.com?utm_medium=trustbox&utm_source=MicroCombo";
    } else if (locale.includes("fr")) {
      return "https://fr.trustpilot.com/review/doktorabc.com?utm_medium=trustbox&utm_source=MicroCombo";
    } else {
      return "https://uk.trustpilot.com/review/doktorabc.com?utm_medium=trustbox&utm_source=MicroCombo";
    }
  };

  const trustpilotLink = getTrustpilotLinkByLocale();

  useEffect(() => {
    if ((window as any).Trustpilot) {
      (window as any).Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={locale}
      data-template-id="5419b6ffb0d04a076446a9af" //Couldn't find it in our TrustPilot admin account, used production's id
      data-businessunit-id="591302b60000ff0005a23087" //Couldn't find it in our TrustPilot admin account, used production's id
      data-style-height="24px"
      data-style-width="100%"
      data-theme="light"
      data-min-review-count="0"
      data-without-reviews-preferred-string-id="1"
      data-style-alignment="center"
    >
      <a href={trustpilotLink} target="_blank" rel="noreferrer">
        Trustpilot
      </a>
    </div>
  );
};

export default TrustpilotBanner;
