import React from 'react'

const HeaderCMSHamburger = () => {
  return (
    <div className="cms-header-hamburger">
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
    </div>
  )
}

export default HeaderCMSHamburger