import React from "react";
import "./CategoriesHamburgerMenu.css";
import CategoriesHamburgerMenuCategoryAccordion from "./CategoriesHamburgerMenuCategoryAccordion";


interface CategoriesHamburgerMenuProps{
  show:boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  headerData:any;
  setSelectedMention: React.Dispatch<React.SetStateAction<any>>;
}

const CategoriesHamburgerMenu:React.FC<CategoriesHamburgerMenuProps> = ({show, setShow, headerData, setSelectedMention}) => {
  return (
    <div onClick={(e)=> e.stopPropagation()} className={`hamburger-menu${show ? "-show" : ""}`}>
      <div className="hamburger-menu-head">
        Please Choose
      </div>

      <div className="hamburger-menu-treatments">
        {
          headerData.categoriesByRoot.map((rootCategory: any, index: number) => {
            return (
              <CategoriesHamburgerMenuCategoryAccordion key={index} rootCategory={rootCategory} setShow={setShow} setSelectedMention={setSelectedMention} />
            )
          })
        }
      </div>
    </div>
  );
};

export default CategoriesHamburgerMenu;
