import React from 'react'
import { Link } from "react-router-dom";
import BadgeNew from './BadgeNew/BadgeNew';
import BadgeNotification from './BadgeNotification/BadgeNotification';

interface AsideBarLinkProps {
    id:string;
    activeMenuLinkId:string;
    linkText:string;
    link?:string; //For fast routing inside the funnel
    href?:string; // For external routes like in cms for example
    iconSrc?:string;
    alt? : string;
    show?:boolean;
    onClick?: () => void;
    notificationsAmount?: number;
    showBadgeNew?: boolean;
}


const AsideBarLink: React.FC<AsideBarLinkProps> = ({id,
  iconSrc,
  activeMenuLinkId,
  linkText,
  link,
  href,
  alt,
  show=true,
  onClick,
  notificationsAmount=0,
  showBadgeNew=false,
}) => {
  return (
      show ? 
      <li className={activeMenuLinkId === id ? "asidebar-link-active" : "asidebar-link" } id={id}>
          { iconSrc ? 
           <img className={activeMenuLinkId === id ? "asidebar-link-icon-active" : "asidebar-link-icon" } src={iconSrc} alt={alt} />
            :
           null
          }

          {
            href ? 
            <a href={href} onClick={onClick}>{linkText}</a> 
            : 
            <Link onClick={onClick} to={link!}> {linkText} </Link>
          }

          <BadgeNotification amount={notificationsAmount}/>
          <BadgeNew show={showBadgeNew}/>
      </li>
      :
      null
  )
}

export default AsideBarLink