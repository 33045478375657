import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai'; // Import the "X" icon
import { cn } from '../../utils/helpers';

type Props = {
	trigger: React.ReactNode | string;
	content: string | React.ReactNode;
	title: string | React.ReactNode;
	image?: React.ReactNode;
	centerTitle?: boolean;
	className?: string;
};
const CMSModal: React.FC<Props> = ({ trigger, content, title, image, centerTitle, className }) => {
	const [isOpen, setIsOpen] = useState(false);
	const modalRef = useRef<HTMLDivElement>(null); // Reference to the modal content

	// Close the modal if clicked outside of it or if Esc key is pressed
	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			setIsOpen(false); // Close modal if Esc is pressed
		}
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
			setIsOpen(false); // Close modal if click is outside
		}
	};

	useEffect(() => {
		// Disable scrolling when the modal is open
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}

		// Add event listeners
		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside);
			document.addEventListener('keydown', handleKeyDown);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleKeyDown);
		}

		// Cleanup the event listeners on component unmount or when modal is closed
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen]);

	if (!isOpen) {
		return <div onClick={() => setIsOpen(true)}>{trigger}</div>;
	}

	return (
		<>
			{/* Backdrop */}
			<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-[#0d2c54] bg-opacity-90">
				{/* Modal */}
				<div
					ref={modalRef}
					className={cn(
						'bg-white relative rounded-[38px_7.5px] flex flex-col justify-between mx-8 transform transition-all duration-300 ease-in-out max-w-[400px] w-full max-h-[500px]',
						className
					)}
				>
					{/* Modal Header */}
					<div id="header" className="flex flex-row w-full justify-center items-center">
						{/* Close Button */}
						<button
							onClick={() => setIsOpen(false)}
							className="absolute top-4 right-4 w-6 h-6 p-0 bg-transparent border-none flex justify-center items-center"
						>
							<AiOutlineClose className="w-4 h-4 text-black cursor-pointer" style={{ strokeWidth: 2 }} />
						</button>

						{/* Modal Title */}
						<div className={`w-full flex flex-col mt-6 mx-6 justify-start ${centerTitle ? 'items-center text-center' : 'items-start'}`}>
							{image}
							<p className="mr-[10px] text-[#0d2c54] text-[20px] md:text-[24px] font-bold">{title}</p>
						</div>
					</div>

					{/* Modal Content */}
					<div
						id="modal-content-container"
						className="mx-5 h-full overflow-x-hidden mb-6 justify-center items-center text-center py-[15px] pl-[30px] pr-[10px]"
					>
						{content}
					</div>

					{/* Modal Footer */}
					<div className="w-full bg-[#E7FCF7] flex justify-center items-center border-t border-blue-200 rounded-b-2xl py-8 text-[12px]">
						<a onClick={() => setIsOpen(false)} className="text-[#5AB48A] cursor-pointer group no-underline relative">
							Close
							<span
								id="animated-underline"
								className="absolute left-0 bottom-0 h-[1px] w-0 bg-[#5AB48A] transition-all duration-500 ease-in-out group-hover:w-full"
							/>
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default CMSModal;
