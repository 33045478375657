import * as React from "react";
import './LoadingModal.css'


export default function LoadingModal() {
  return (
    <>
    <div className="loading-container">
        <div className="loading loading-circle"></div>
    </div>
    </>
  )
}