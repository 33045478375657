import React from "react";
import "./PharmacyRequestForm.css";
import * as translator from "../../utils/translator";
import SubmitButton from "../SubmitButton/SubmitButton";
import InputField from "../CredentialsInput/InputField";
import useForm from "../../customHooks/useForm";
import * as http from "../../utils/http";
import CloseIcon from "../../../src/icons/X.svg";

interface PharmacyInfo {
  name: string;
  city: string;
  email: string;
}

type PharmacyRequestFormProps = {
  closeModal: () => void;
  language: string | undefined;
  showSuccessMessage: ({name, city, email}: PharmacyInfo, disclaimerType?: string) => void;
  setStickyNotificationDetails: any;
  orderHashID: string;
};


const PharmacyRequestForm: React.FC<PharmacyRequestFormProps> = ({ closeModal, language, showSuccessMessage, setStickyNotificationDetails, orderHashID }) => {
  let newPharmacyFormAttributes = useForm({
    name: "",
    city: "",
    email: "",
  }, language)

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault();
    if (newPharmacyFormAttributes.validateForm()) {
      let pharmacyInfo = {
        name: newPharmacyFormAttributes.form.name,
        city: newPharmacyFormAttributes.form.city,
        email: newPharmacyFormAttributes.form.email
      }
      
      const route = "account/create-pharmacy-request";
      http.postToServer(route, {
        orderHashID,
        pharmacyName: pharmacyInfo.name,
        pharmacyCity: pharmacyInfo.city,
        pharmacyEmail: pharmacyInfo.email
      }, [http.MIDDLEWARES.CUSTOMER_ID])
        .then((res) => {
          showSuccessMessage(pharmacyInfo)
        })
        .catch((error) => {
          switch (error.response.data.status) {
            case "pharmacy_is_already_active":
              pharmacyInfo = error.response.data.pharmacyInfo;
              showSuccessMessage(pharmacyInfo, error.response.data.status)
              break;
            case "pharmacy_is_already_disabled":
            case "request_already_submitted_by_customer":
              showSuccessMessage(pharmacyInfo, error.response.data.status)
              break;
            default:
              window.clientLogger.error("error submit pharmacy request",error);
              setStickyNotificationDetails((currentDetails: any) => ({
                show: true,
                title: "Error",
                content: translator.translate(language, "my_account", 'PrescriptionRedemptoin/popup_submit_request/error'),
                level: "error",
                showInfoIcon: false,
                refresh: !currentDetails.refresh
              }))
          }

        });
    }

  };


  return (

    <div onClick={closeModal} className="pharmacy-request-modal-background">
      <div
        className="pharmacy-request-modal-container"
        onClick={(event) => event.stopPropagation()}
      >
        <img onClick={closeModal} className="close-btn" src={CloseIcon} alt="Close" />
        <h6>
          {translator.translate(language, "my_account", 'PrescriptionRedemptoin/popup_submit_request/title')}
        </h6>

        <p className='pharmacy-by-customer-disclaimer'>
          {translator.translate(language, "my_account", 'PrescriptionRedemptoin/popup_submit_request/note')}
        </p>

        <div>
          <InputField
            id="pharmacy-name"
            value={newPharmacyFormAttributes.form.name}
            errorMsg={newPharmacyFormAttributes.errorMessagePerField.name}
            type="text"
            placeholder={translator.translate(language, "my_account", 'PrescriptionRedemptoin/popup_submit_request/pharmacy_name')}
            mandatory
            onChange={(inputValue: string) =>
              newPharmacyFormAttributes.updateForm("name", inputValue)
            }
            language={language}
            onBlur={() => newPharmacyFormAttributes.onFieldBlur("name")}
            touched={newPharmacyFormAttributes.touched.name}
          />

          <InputField
            id="pharmacy-city"
            value={newPharmacyFormAttributes.form.city}
            errorMsg={newPharmacyFormAttributes.errorMessagePerField.city}
            type="text"
            placeholder={translator.translate(language, "my_account", 'PrescriptionRedemptoin/popup_submit_request/pharmacy_city')}
            mandatory
            onChange={(inputValue: string) =>
              newPharmacyFormAttributes.updateForm("city", inputValue)
            }
            language={language}
            onBlur={() => newPharmacyFormAttributes.onFieldBlur("city")}
            touched={newPharmacyFormAttributes.touched.city}
          />

          <InputField
            id="pharmacy-email"
            value={newPharmacyFormAttributes.form.email}
            errorMsg={newPharmacyFormAttributes.errorMessagePerField.email}
            type="email"
            placeholder={translator.translate(language, "my_account", 'PrescriptionRedemptoin/popup_submit_request/pharmacy_email')}
            mandatory
            onChange={(inputValue: string) =>
              newPharmacyFormAttributes.updateForm("email", inputValue)
            }
            language={language}
            onBlur={() => newPharmacyFormAttributes.onFieldBlur("email")}
            touched={newPharmacyFormAttributes.touched.email}
          />
        </div>

        <SubmitButton
          id={"submit-button"}
          isEnabledState={true}
          language={language}
          onSubmit={onSubmit}
          width={"100%"}
          placeholder={translator.translate(
            language,
            "my_account",
            "PrescriptionRedemptoin/popup_submit_request/submit"
          )}
        />

      </div>
    </div>
  );
}
export default PharmacyRequestForm
