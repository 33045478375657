import "./PaymentOptionCard.css";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import InputField from "../../../../components/CredentialsInput/InputField";
import { ShippingDataContext } from "../../Shipping";
import { useContext, useState } from "react";
import * as translator from "../../../../utils/translator";
import ToggleRadioButton from "../../../../components/ToggleRadioButton/ToggleRadioButton";

const PaymentOptionCard = ({
  onClick,
  selected,
  title,
  description,
  onSubmit,
  icons = [],
  disabled = false,
  phoneInput = false,
  language = 'de',
  longIconList = false,
}) => {


  const submitLoading = useContext(ShippingDataContext).submitButtonLoading;
  const shippingFormAttributes = useContext(ShippingDataContext).personalShippingFormAttributes;

  let forceShippingIdentityCheck = false;
  const catalog = useContext(ShippingDataContext).catalog;

  if(catalog.settings){
    const settings = JSON.parse(catalog.settings);
    forceShippingIdentityCheck = Boolean(settings.force_shipping_identity_check);
  }


  const [isPrecautionToggleEnabled, setIsPrecautionToggleEnabled] = useState(false);
  const [precautionLevel, setPrecautionLevel] = useState("info");


  const handleOnSubmit = () => {
    if(forceShippingIdentityCheck && !isPrecautionToggleEnabled){
      setPrecautionLevel("error");
    } else {
      onSubmit();
    }
  }


  return (
    // .delivery-panel-outer-container
    <>


      {selected ? (
        <>

          <div className="phoneInput-container">
            {phoneInput ? (
              <InputField
                placeholder={translator.translate(
                  language,
                  "shipping",
                  "ShippingForm/telephone"
                )}
                mandatory
                onChange={(value) =>
                  shippingFormAttributes.updateForm("phone", value)
                }

                value={shippingFormAttributes.form.phone}
                errorMsg={shippingFormAttributes.errorMessagePerField.phone}
                onBlur={() =>
                  shippingFormAttributes.onFieldBlur("phone")
                }
                touched={shippingFormAttributes.touched.phone}
              />
            ) : null}
          </div>
            <SubmitButton
              language={language}
              loading={submitLoading}
              onSubmit={handleOnSubmit}
              showArrow={false}
              placeholder={translator.translate(
                language,
                "shipping",
                "ShippingForm/button_title"
              )}
              width={"100%"}
              margin={"0"}
            />

        {
        forceShippingIdentityCheck ?
            <TogglePrecaution  
            isToggleEnabled={isPrecautionToggleEnabled}
            precautionLevel={precautionLevel}
            updateToggle={() => {
              setPrecautionLevel("info");
              setIsPrecautionToggleEnabled(!isPrecautionToggleEnabled)
            }}
          /> 
          : 
          null
        }

        </>

      ) : null}
    </>
  );
};

export default PaymentOptionCard;


const TogglePrecaution = ({isToggleEnabled,updateToggle,precautionLevel}) => {

  const selfPickupPharmacy = useContext(ShippingDataContext).selfPickupPharmacy;

  return (
    <div className="toggle-precaution-container">
      <ToggleRadioButton
        id={precautionLevel === "error" ? "toggle-precaution-error" : "toggle-precaution"}
        isToggleEnabled={isToggleEnabled}
        updateToggle={updateToggle}
      />
      <span className="toggle-precaution-text" style={{ color: `${precautionLevel === "error" ? "var(--primary-danger)" : "var(--radiobutton-text-color)"}` }}>
        Ich werde das Paket selbst abholen, meine persönlichen Daten sind korrekt, <b> und ich werde dem 
          {selfPickupPharmacy ? " Apotheker "  : " Kurier "}
          meinen Ausweis zeigen.
          </b>
      </span>
    </div>
  )
}



