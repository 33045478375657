import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LoadingModal from '../../components/Modals/LoadingModal/LoadingModal';
import HeaderController from '../../components/Headers/HeaderController';
import ServerError from '../../components/ServerError/ServerError';
import StickyNotification from '../../components/StickyNotification/StickyNotification';
import Footer from '../../components/Footer/Footer';
import "./ErecipeUpload.css"
import PhotoDiagnose from '../Questionnaire/components/PhotoDiagnose/PhotoDiagnose';
import uploadIcon from '../../icons/upload.png'
import checkMarkIcon from '../../icons/checkMark.png'
import eRecipeImage from '../../images/e-recipe.png'
import * as http from "../../utils/http";
import CategoriesHamburgerMenu from './components/CategoriesHamburgerMenu';
import LoginQuestion from '../Questionnaire/components/LoginQuestion/LoginQuestion';
import { MainTracker } from "../../PixelTrackers/MainTracker";
import ForgotPasswordModal from '../../components/Modals/ForgotPasswordModal/ForgotPasswordModal';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import * as translator from "../../utils/translator";
import { clientConfig } from '../../config';
import Accordion from '../../components/Accordion/Accordion';
import InnerHTMLRenderer from '../../components/InnerHTMLRenderer/InnerHTMLRenderer';
import { useCartContext } from '../../customHooks/useCart';


type MenuLink = {
    text: string;
    url: string;
}

type HeaderData = {
    menuLinks: Array<MenuLink>;
    rootCategoriesPairs: any;
    categoriesByRoot: any[];
    unreadNotifications: number;
}

type SelectedMention = { [key: string]: string };

let dontHaveErecipe: boolean


const ErecipeUpload = () => {
    const { country, language } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [showCategoriesModal, setShowCategoriesModal] = useState(false)
    const [selectedMention, setSelectedMention] = useState<SelectedMention>({})
    const [error] = useState<any>(false);
    const [files, setFiles] = useState<any[]>([])
    const [headerData, setHeaderData] = useState<HeaderData | null>(null);
    const [isMobile] = useState(window.innerWidth <= 768);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [qrValid, setQrValid] = useState(false)
    const [showStickyNotification, setShowStickyNotification] = useState({
        show: false,
        title: "",
        content: "",
    });

    const JSQR_SCRIPT_PATH = "https://cdn.jsdelivr.net/npm/jsqr@1.4.0/dist/jsQR.min.js"

    const [searchParams] = useSearchParams();
    const { saveCart } = useCartContext();
    const navigate = useNavigate();

    const urlMention = searchParams.get('cat');


    const loadScript = (src: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            // Check if the script is already in the document
            if (document.getElementById("jsqr_library")) {
                resolve();
                return;
            }

            const script = document.createElement('script');
            script.id = "jsqr_library";
            script.src = src;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
            document.head.appendChild(script);
        });
    };



    const setItemAndClose = (child: any) => {
        setSelectedMention({ [child.mention]: child.title });
        setShowCategoriesModal(false)
        if (dontHaveErecipe) {
            setLoading(true)
        }
    }

    useEffect(() => {
        if (dontHaveErecipe) {
            setLoading(true)
            reRouteToQuestionnaire()
        }
    }, [selectedMention])

    useEffect(() => {
        if (files.length) {
            setQrValid(false);
            const loadAndValidate = async ()=>{
                await loadScript(JSQR_SCRIPT_PATH);
                validateQr();
            }
            loadAndValidate();
            MainTracker.track('custom', 'E-Rezept uploaded', { 'File size': (Number(files[0].binary_data.byteLength) / 1000).toFixed(2) + 'kb', 'File format': files[0].type })
        }
    }, [files])



    const findTitleByMention = (data: any, mention: any): string | null => {
        if (Array.isArray(data)) {
            for (let item of data) {
                const result = findTitleByMention(item, mention);
                if (result) return result;
            }
        } else if (typeof data === 'object' && data !== null) {
            if (data.mention === mention) {
                return data.title;
            }
            for (let key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    const result = findTitleByMention(data[key], mention);
                    if (result) return result;
                }
            }
        }
        return null;
    };

    const reRouteToQuestionnaire = () => {
        MainTracker.track('click', "I do NOT have E-Rezept on 'E-Rezept upload step'")
        const mention = Object.keys(selectedMention)[0]
        if (mention) { //send to questionnaire with this mention
            window.location.href = `${clientConfig.FUNNEL_URL}/questionnaire/${country}/${language}/${mention}`
        } else { //open modal to choose treatment first
            dontHaveErecipe = true
            setShowCategoriesModal(true)
        }
    }

    useEffect(() => {
        http
            .getFromServer(`header/${language}/${country}`)
            .then((response) => {
                setHeaderData(response.data);
                if (urlMention) {
                    const title = findTitleByMention(response.data.rootCategoriesPairs, urlMention)
                    if (title) {
                        setSelectedMention({ [urlMention]: title })
                    }
                }
            })
            .catch((error) => {
                window.clientLogger.error("Error in ErecipeUpload: ", error);
            })
            .finally(() => {
                setLoading(false)
            });
    }, []);

    const validateQr = () => {
        const fileArrayBuffer = files[0].binary_data;
        const fileBlob = new Blob([fileArrayBuffer])

        const reader = new FileReader();
        reader.onload = (event:ProgressEvent<FileReader>) => {
            const result = event.target?.result as string;
      
            const img = new Image();
            img.src = result;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d') as CanvasRenderingContext2D;
                canvas.width = img.width;
                canvas.height = img.height;
                context?.drawImage(img, 0, 0);

                const imageData = context?.getImageData(0, 0, canvas.width, canvas.height);
                const qrCodeData = (window as any).jsQR(imageData.data, imageData.width, imageData.height);

                if (qrCodeData) {
                    setQrValid(true)
                }else{
                    MainTracker.track('custom', 'Bad E-Rezept (no QR)', {})
                }
            };
        };
        
        reader.readAsDataURL(fileBlob);
    }


    const onSubmit = () => {
        const mention = Object.keys(selectedMention)[0]
        //validate fields
        setLoading(true)
        if (files.length == 1 && mention && qrValid) {
            http
                .postMultiPartsToServer(
                    `questionnaire/${country}/${language}/${mention}`,
                    {
                        country: country,
                        language: language,
                        mention,
                        flow: "e-recipe"
                    },
                    files,
                    [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.CART_ORDER]
                )
                .then((res) => {
                    saveCart({
                        id: res.data.cartID,
                        token: res.data.cartToken,
                        mention,
                        mixpanel_title: res.data.catalog.mixpanel_title,
                        is_e_recipe: true,
                        products: []
                    });

                    const path = `/select_treatment/${country}/${language}/${mention}`;
                    navigate({ pathname: path });

                })
                .catch((err) => {
                    let stickyContent = err.response?.data?.code || 'Server Failed';
                    const errorTranslation = translator.translate(language, "default", `StickyNotification/${err.response?.data?.code}`)
                    if (errorTranslation) {
                        stickyContent = errorTranslation
                    }
                    setShowStickyNotification({
                        show: true,
                        title: translator.translate(language, "default", "StickyNotification/danger_title"),
                        content: stickyContent
                    })
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false)
            setShowStickyNotification({
                show: true,
                title: "Some fields are not filled or image does not contain QR",
                content: "Please upload a valid E-rezept"
            })
        }
    }

    return (
        <>
            {loading && <LoadingModal />}

            {error && !loading && (
                <>
                    <HeaderController showSteps={false} stage={"Questionnaire"} />
                    <ServerError status={500} />
                </>
            )}

            {!loading && !error && (
                <>
                    {showForgotPasswordModal && (
                        <ForgotPasswordModal
                            closeModal={() => setShowForgotPasswordModal(false)}
                            navigationCallback={() => { }} //TODO:
                            language={language!}
                        />
                    )}

                    {showCategoriesModal &&
                        <div onClick={() => setShowCategoriesModal(false)} className={`header-cms-modal-background-${showCategoriesModal ? 'show' : ''}`}>
                            <img className="cancel-icon" src="/static/media/X.7c9c13020bfb1deaf817d43cf5da46e3.svg" alt="X" onClick={() => setShowCategoriesModal(false)} />
                            {isMobile ?
                                <CategoriesHamburgerMenu show={showCategoriesModal} setShow={setShowCategoriesModal} headerData={headerData} setSelectedMention={setSelectedMention} />
                                :
                                <div className="treatments-modal">
                                    <div
                                        className="treatments-container"
                                        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
                                    >
                                        {headerData?.rootCategoriesPairs && headerData.rootCategoriesPairs.map((rootCategoryPair: any, index: number) => {
                                            return (
                                                <div className="root-category-pair" key={index}>
                                                    {rootCategoryPair.map((rootCategory: any) => (
                                                        <div key={rootCategory.title} className={`root-category`}>
                                                            <h4>{rootCategory.title}</h4>
                                                            <ul>
                                                                {rootCategory.children.map((childCategory: any, childIndex: number) => (
                                                                    <li key={childCategory.id + childIndex}>
                                                                        <a className="treatment-category-link" onClick={() => setItemAndClose(childCategory)}>
                                                                            {childCategory.title}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <StickyNotification
                        showNotification={showStickyNotification.show}
                        title={showStickyNotification.title}
                        onClick={(falseValue: any) => setShowStickyNotification(falseValue)}
                        content={showStickyNotification.content}
                    />

                    <HeaderController
                        language={language}
                        stage="Questionnaire"
                        flow={'e-recipe'}
                    />

                    <div className="erecipe-body-container">
                        <div className='margin-container'>
                            <h1 className="questionnaire-title">{translator.translate(language, "default", "ErecipeUploadPage/title")}</h1>
                            <p>{translator.translate(language, "default", "ErecipeUploadPage/subtitle")}</p>
                            <div className='card'>
                                <div className='description-and-uploader'>
                                    <div className='description'>
                                        <h3>{translator.translate(language, "default", "ErecipeUploadPage/uploadTitle")}</h3>
                                        <p>
                                            {translator.translate(language, "default", "ErecipeUploadPage/uploadText")}
                                        </p>
                                    </div>
                                    <PhotoDiagnose language={language} setFilesCallback={setFiles} files={files} imageUrl={files.length == 1 ? checkMarkIcon : uploadIcon} necessaryFileCount={[1, 1]} />
                                </div>
                            </div>
                            <div className='card'>
                                <div className='description-and-uploader'>
                                    <div className='description'>
                                        <h3>{translator.translate(language, "default", "ErecipeUploadPage/categoryTitle")}</h3>
                                        <p>
                                            {translator.translate(language, "default", "ErecipeUploadPage/categoryText")}
                                        </p>
                                    </div>
                                    <div className='category-select'>
                                        <button className={`${Object.values(selectedMention)[0] ? 'selected' : ''}`} onClick={() => setShowCategoriesModal(true)}>
                                            {Object.values(selectedMention)[0] ?? translator.translate(language, "default", "ErecipeUploadPage/pleaseChoose")}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <LoginQuestion
                                showForgotPasswordModal={() => { setShowForgotPasswordModal(true) }}
                                language={language}
                                onChange={(isFirstTime: Boolean) => {
                                    if (isFirstTime) {
                                        MainTracker.track(
                                            "click",
                                            "Clicked on First Time Order Question"
                                        );
                                    }
                                }}
                            />

                            <SubmitButton
                                id="submit-button"
                                className="questionnaire-submitButton"
                                language={language}
                                placeholder={translator.translate(language, "default", "QuestionnairePage/submit_button_text")
                                }
                                showArrow={true}
                                width={"auto"}
                                margin="15px 0px"
                                backgroundColor={"var(--primary-lightgreen)"}
                                loading={loading}
                                disabled={(files.length !== 1 || !Object.keys(selectedMention)[0]) && true}
                                onSubmit={onSubmit}
                            />

                            <span id='dont-have-e-recipe'>{translator.translate(language, "default", "ErecipeUploadPage/noErecipe")} <a onClick={reRouteToQuestionnaire}>{translator.translate(language, "default", "ErecipeUploadPage/clickHere")}</a></span>

                            <h1 id='faq-title'>{translator.translate(language, "default", "ErecipeUploadPage/qaTitle")}</h1>

                            <Accordion
                                defaultOpenIndex={0}
                                items={[
                                    { title: translator.translate(language, "default", "ErecipeUploadPage/q1"), content: <InnerHTMLRenderer htmlContent={translator.translate(language, "default", "ErecipeUploadPage/a1")} className='text' />, imageUrl: eRecipeImage },
                                    { title: translator.translate(language, "default", "ErecipeUploadPage/q2"), content: <InnerHTMLRenderer htmlContent={translator.translate(language, "default", "ErecipeUploadPage/a2")} className='text' /> },
                                    { title: translator.translate(language, "default", "ErecipeUploadPage/q3"), content: <InnerHTMLRenderer htmlContent={translator.translate(language, "default", "ErecipeUploadPage/a3")} className='text' /> }
                                ]} />
                        </div>
                    </div>



                    <Footer page="Questionnaire" language={language} />
                </>
            )}
        </>
    )
}

export default ErecipeUpload