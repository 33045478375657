import { useState, useEffect } from "react";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import * as http from "../../utils/http";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import ServerError from "../../components/ServerError/ServerError";
import { MainTracker } from "../../PixelTrackers/MainTracker";

const positives = ['true', 1, true, '1']

export default function PaymentPageCallBack(props) {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  let langauge = searchParams.get("language");
  let country = searchParams.get("country");
  let hashID = searchParams.get("hashID");
  let thankyouHash = searchParams.get("thankyouHash");
  let paymentTypeID = searchParams.get("paymentTypeID");
  let isSubscription = positives.includes(searchParams.get("isSubscription"));

  let age = searchParams.get("a");
  let ord = searchParams.get("ord");
  let gender = searchParams.get("g");
  const cat = searchParams.get("cat");
  const pm = searchParams.get("pm");

  let redirectToRetryPaymentStatuses = ["Cancelled", "Refused"];

  useEffect(() => {

    if (window.eventsTracking.getData("last_paid_order") === hashID){
      setError(true)
      window.clientLogger.warn(`check3ds after payment was already paid`,{hashID});
      return;
    }

    http
      .postToServer(
        `adyen/check_3ds_status`,
        {
          redirectResult: searchParams.get("redirectResult"),
          paymentTypeID,
          isSubscription,
          hashID
        },
        [http.MIDDLEWARES.CUSTOMER_ID]
      )
      .then((response) => {
        // if checking status failed then redirect back to payment page
        if (
          redirectToRetryPaymentStatuses.indexOf(response.data.resultCode) > -1
        ) {
          window.clientLogger.warn(
            "check payment callback failed, redirect to payment page"
          );
          navigate({
            pathname: `/payment/${country}/${langauge}/${hashID}/`,
            search: createSearchParams({
              result: response.data.resultCode,
              rr : response.data.rr
            }).toString(),
          });

          return;
        }

        // save paid in client - in order to not load payment page
        window.eventsTracking.setData("last_paid_order", hashID)
        navigate({
          pathname: `/thank-you/${country}/${langauge}/${hashID}`,
          search: createSearchParams({
            result: response.data.resultCode,
            thankyouHash: thankyouHash,
            a: age,
            ord,
            g: gender,
            cat,
            pm
          }).toString(),
        });
      })
      .catch((error) => {
        setError("error");
        MainTracker.track('custom', 'Payment Error',{
          paymentMethod:pm,
          paymentSource:"3ds",
          paymentTypeID,
          hashID,
          orderID:ord,
          catalog:cat
        });
      });



  }, []);

  return (
    <div>
      {error && <ServerError status={error} />}
      {!error && (
        <span>
          <LoadingModal />
        </span>
      )}
    </div>
  );
}
