import "./ShippingForm.css";
import InputField from "../../../../components/CredentialsInput/InputField";
import GoogleApiInputField from "../../../../components/CredentialsInput/GoogleApiInputField";
import * as translator from "../../../../utils/translator";
import { useContext } from "react";
import {ShippingDataContext} from "../../Shipping";
import ToggleRadioButton from "../../../../components/ToggleRadioButton/ToggleRadioButton";
import { BsTelephone } from "react-icons/bs";
import BillingForm from "../BillingForm/BillingForm";
import Select from "../../../../components/Select/Select";

const ShippingForm = ({ language = "de", hideToggle = false, selected }) => {
  const { limitToPrescriptionOnly, useAddressAsBillingToggle, forceCountry, updateDispatch, destinationCountry, setDestinationCountry } =
    useContext(ShippingDataContext);
  const shippingFormAttributes =
    useContext(ShippingDataContext).personalShippingFormAttributes;

const ADDRESS_COMPONENT_INDEX = {
      street_and_number: 0,
      city: 1,
      country : 2
    }
const ADDRESS_FIELD_TYPE = {
      street_and_number: "address",
      city: "(cities)",
      country : "country"
    }
    
  return (
    <div className="details-form">
      <div className="name-fields">
        <InputField
          id = "shipping_first_name"
          value={shippingFormAttributes.form["first_name"]}
          onChange={(value) => {
            shippingFormAttributes.updateForm("first_name", value);
          }}
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/first_name"
          )}
          mandatory={true}
          errorMsg={shippingFormAttributes.errorMessagePerField["first_name"]}
          language={language}
          onBlur={() => shippingFormAttributes.onFieldBlur("first_name")}
          touched={shippingFormAttributes.touched.first_name}
        />

        <InputField
          id = "shipping_last_name"
          value={shippingFormAttributes.form["last_name"]}
          onChange={(value) => {
            shippingFormAttributes.updateForm("last_name", value);
          }}
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/surname"
          )}
          mandatory={true}
          language={language}
          errorMsg={shippingFormAttributes.errorMessagePerField["last_name"]}
          onBlur={() => shippingFormAttributes.onFieldBlur("last_name")}
          touched={shippingFormAttributes.touched.last_name}
        />
      </div>

      <div className="location-fields">
        <InputField
          id = "shipping_zip"
          value={shippingFormAttributes.form["zip"]}
          onChange={(value) => {
            shippingFormAttributes.updateForm("zip", value);
          }}
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/postal_code"
          )}
          mandatory={true}
          language={language}
          errorMsg={shippingFormAttributes.errorMessagePerField["zip"]}
          onBlur={() => shippingFormAttributes.onFieldBlur("zip")}
          touched={shippingFormAttributes.touched.zip}
        />

        <GoogleApiInputField
          id = "shipping_city"
          value={shippingFormAttributes.form["city"]}
          onChange={(value) => {
            // Show only city from google suggestion
            if(Array.isArray(value.split(","))){
              value = value.split(",")[0]
            }
            shippingFormAttributes.updateForm("city", value);
          }}
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/location"
          )}
          mandatory={true}
          language={language}
          country={destinationCountry}
          errorMsg={shippingFormAttributes.errorMessagePerField["city"]}
          onBlur={() => shippingFormAttributes.onFieldBlur("city")}
          touched={shippingFormAttributes.touched.city}
          addressComponentIndex={ADDRESS_COMPONENT_INDEX.city}
          addressType = {ADDRESS_FIELD_TYPE.city}
        />
      </div>

      <div className="location-fields">
        <GoogleApiInputField
          id = "shipping_address"
          value={shippingFormAttributes.form["address"]}
          isValid={(boolValue) => {}}
          onChange={(value) => {
            // Show only city from google suggestion
            if(Array.isArray(value.split(","))){
              value = value.split(",")[0]
            }
            shippingFormAttributes.updateForm("address", value);
          }}
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/street_and_house"
          )}
          mandatory={true}
          language={language}
          country={destinationCountry}
          errorMsg={shippingFormAttributes.errorMessagePerField["address"]}
          onBlur={() => shippingFormAttributes.onFieldBlur("address")}
          touched={shippingFormAttributes.touched.address}
          addressComponentIndex={ADDRESS_COMPONENT_INDEX.street_and_number}
          addressType = {ADDRESS_FIELD_TYPE.street_and_number}
        />
      </div>

      <div className="location-fields">
      {language != 'fr' && !limitToPrescriptionOnly && !forceCountry ?
      <Select
        id = "shipping_country"
        initialValue={{value:translator.translate(language,"shipping",`ShippingForm/country_name/${destinationCountry}`), code:destinationCountry}}
        options = {[{value:translator.translate(language,"shipping","ShippingForm/country_name/de"), code:'de'}, {value:translator.translate(language,"shipping",`ShippingForm/country_name/ch`), code:'ch'}]} //enable ch
        // options = {[{value:translator.translate(language,"shipping","ShippingForm/country_name/de"), code:'de'}]}
        onChangeCallback = {(option) => {
          setDestinationCountry(option.option.code); 
          shippingFormAttributes.updateForm("country", option.option.code);
        }}

        mandatory={true}
        style={{
          border: "1px solid var(--sub-gray)",
          height: "56px",
          width: "100%",
          borderRadius: "6px",
          margin: "15px 0px"
        }}
        label={translator.translate(language,"shipping","ShippingForm/country")}
      />
      :
      <InputField
        id = "shipping_country"
        value={translator.translate(
          language,
          "shipping",
          `ShippingForm/country_name/${language}`
        )}
        placeholder={translator.translate(
          language,
          "shipping",
          "ShippingForm/country"
        )}
        mandatory={true}
        language={language}
        errorMsg={shippingFormAttributes.errorMessagePerField["country"]}
        disabled
        onBlur={() => shippingFormAttributes.onFieldBlur("country")}
        touched={shippingFormAttributes.touched.country}
        />
      }

        <InputField
          id = "shipping_company"
          value={shippingFormAttributes.form["company"]}
          onChange={(value) => {
            shippingFormAttributes.updateForm("company", value);
          }}
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/firm_name"
          )}
          mandatory={false}
          language={language}
          errorMsg={shippingFormAttributes.errorMessagePerField["company"]}
          onBlur={() => shippingFormAttributes.onFieldBlur("company")}
          touched={shippingFormAttributes.touched.company}
        />
      </div>


     
        <>
          {!hideToggle ? 
          <>
            <div className="address-as-billing">
              <ToggleRadioButton
                id="toggle_address_as_billing"
                isToggleEnabled={useAddressAsBillingToggle}
                updateToggle={(value)=> updateDispatch("use_address_as_billing",value)}
              />
              <p className="address-as-billing-text">
                {translator.translate(
                      language,
                      "shipping",
                      "ShippingForm/address_as_billing_text"
                )}
              </p>
            </div>

            <BillingForm
              isToggleEnabled={useAddressAsBillingToggle}
              language={language}
            />
          </>
          :
           null
          }
          
          <div className="phone-fields">
            <div className="phone-icon-and-field">
              <div className="phone-icon">
                <BsTelephone className="telephone" />
                <p className="phone-icon-text">
                  {translator.translate(
                    language,
                    "shipping",
                    "ShippingForm/phone_icon_text"
                  )}
                </p>
              </div>

              <InputField
                id = "shipping_phone"
                value={shippingFormAttributes.form["phone"]}
                onChange={(value) => {
                  shippingFormAttributes.updateForm("phone", value);
                }}
                placeholder={translator.translate(
                  language,
                  "shipping",
                  "ShippingForm/telephone"
                )}
                mandatory={true}
                language={language}
                errorMsg={shippingFormAttributes.errorMessagePerField["phone"]}
                onBlur={() => shippingFormAttributes.onFieldBlur("phone")}
                touched={shippingFormAttributes.touched.phone}
              />
            </div>
          </div>
        </>
    
    </div>
  );
};

export default ShippingForm;
