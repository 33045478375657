import React,{useState,useEffect} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import useForm from "../../../../customHooks/useForm";
import "./SubscriptionDeliveryAddressForms.css";
import RadioSelect from "../../../../components/RadioSelect/RadioSelect";
import SubscriptionPersonalAddressForm from "../SubscriptionPersonalAddressForm";
import SubscriptionDHLPackstationForm from "../SubscriptionDHLPackstationForm";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import * as translator from '../../../../utils/translator';
import * as http from "../../../../utils/http";


const PERSONAL_ADDRESS_TYPE = "regular";
const PACKSTATION_ADDRESS_TYPE = "packstation";

const SubscriptionDeliveryAddressForm = () => {

  const {setRefreshData ,myAccountData, setStickyNotificationDetails} = useOutletContext<any>();
  const {order_hash_id} = useParams<{  order_hash_id: string | undefined }>(); //the hashID from url
  const language = myAccountData.locale.language_id
  const [selectedAddressType,setSelectedAddressType] = useState(PERSONAL_ADDRESS_TYPE);
  const [submitLoading,setSubmitLoading] = useState<boolean>(false);


  useEffect(() => {
    if(order_hash_id){
      setSelectedAddressType(myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressType) //Personal or DHL-Packstation 
    }
  }, [order_hash_id])
  
  let personalAddress:any
  let dhlPackstationAddress:any
  if(order_hash_id){
    personalAddress = {
      first_name: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.firstName,
      last_name: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.lastName,
      zip: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.zip,
      city: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.city,
      street:myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.street,
      house_number:myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.houseNumber,
      company: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.company,
      country: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.country,
    }

    dhlPackstationAddress = {
      packstation_personal_first_name: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.firstName,
      packstation_personal_last_name: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.lastName,
      packstation_number: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.packstationNumber,
      packstation_address: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.packstationAddress,
      packstation_zip: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.zip,
      packstation_city: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.city,
      packstation_country: myAccountData.mySubscriptions.subscriptionData[order_hash_id].addressDetails.country,
    }
  }
  
  const personalAddressAttributes = useForm(personalAddress ,language)
  const DHLPackstationAttributes = useForm(dhlPackstationAddress ,language)
  
  const onSubmit = () => {
    setSubmitLoading(true);
    let selectedFormAttributes:any;
    if(selectedAddressType === PERSONAL_ADDRESS_TYPE){
      selectedFormAttributes = personalAddressAttributes;

    } else if(selectedAddressType === PACKSTATION_ADDRESS_TYPE){
      selectedFormAttributes = DHLPackstationAttributes;
    }

    if(selectedFormAttributes.validateForm()){
      http
      .postToServer(`account-update/subscription/address`,{
        address: selectedFormAttributes.form,
        hashID: order_hash_id,
        type: selectedAddressType
      },
      [http.MIDDLEWARES.CUSTOMER_ID]
      )
      .then((response) => {
        setRefreshData((current:boolean) => !current)
        setStickyNotificationDetails((currentDetails : any) => ({
          show: true,
          title: "",
          content: "Ihre Lieferadresse wurde erfolgreich aktualisier",
          level : "info",
          showInfoIcon:false,
          refresh : !currentDetails.refresh
        }))
      })
      .catch((error) => {

      })
     
      .finally(()=> {
        setSubmitLoading(false);
      })

    } else {
      setSubmitLoading(false);
    }
  }


  return (
    <div className="subscription-delivery-address-forms-container">
      <div onClick={()=> setSelectedAddressType(PERSONAL_ADDRESS_TYPE)} className="subscription-delivery-address-forms-option-row">
        <RadioSelect selected={selectedAddressType === PERSONAL_ADDRESS_TYPE ? true : false} disabled={false} />
        <span>{translator.translate(language, "my_account", `SubscriptionOrders/addressInfoCard/personalAddress`)}</span>
      </div>

      {
        selectedAddressType === PERSONAL_ADDRESS_TYPE ?
        <SubscriptionPersonalAddressForm personalAddressAttributes={personalAddressAttributes}/>
        :
        null
      }

      <div onClick={()=> setSelectedAddressType(PACKSTATION_ADDRESS_TYPE)} className="subscription-delivery-address-forms-option-row">
        <RadioSelect selected={selectedAddressType === PACKSTATION_ADDRESS_TYPE ? true : false} disabled={false} />
        <span>{translator.translate(language, "my_account", `SubscriptionOrders/addressInfoCard/packstationAddress`)}</span>
      </div>

      {
        selectedAddressType === PACKSTATION_ADDRESS_TYPE ?
        <SubscriptionDHLPackstationForm DHLPackstationAttributes={DHLPackstationAttributes}/>
        :
        null
      }


      <SubmitButton 
        loading={submitLoading}
        onSubmit={onSubmit}
        id={"subscription-delivery-address-submit"}
        fontsize={"14px"}
        padding={"0px 30px"}
        height={"45px"}
        placeholder="Behandlungsplan aktualisieren" 
        isEnabledState />

    </div>
    


  );
};

export default SubscriptionDeliveryAddressForm;
