import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as http from "../../../utils/http";
import * as translator from "../../../utils/translator";
import Select from '../../../components/Select/Select';
import InputField from '../../../components/CredentialsInput/InputField';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import useForm from '../../../customHooks/useForm';
import ContactFormSubmitted from './ContactFormSubmitted';
import Popover from '../../../components/Popover/Popover';
import InfoIconGray from '../../../icons/info-icon-gray.svg';
import TextArea from '../../../components/CredentialsInput/TextArea';
import { clientConfig } from "../../../config";
import ReCAPTCHA from "react-google-recaptcha";
import { MainTracker } from "../../../PixelTrackers/MainTracker";



type ContatFormOption = {
  id: number;
  field: string;
  value: string;
}

type Props = {
  toggleStickyNotification: (args: any) => void
}

const ContactPageForm: React.FC<Props> = ({ toggleStickyNotification }) => {
  const { country } = useParams();
  const language = translator.getLanguageByCountry(country!);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ContatFormOption>({} as ContatFormOption);
  const [showPopover, setShowPopover] = useState(false);
  const optionsArray: ContatFormOption[] = [
    { id: 0, field: "delivery_problem", value: translator.translate(language, "contact", "SelectOptionsText/delivery_problem") },
    { id: 1, field: "order_cancellation", value: translator.translate(language, "contact", "SelectOptionsText/order_cancellation") },
    { id: 2, field: "subscription_questions", value: translator.translate(language, "contact", "SelectOptionsText/subscription_questions") },
    { id: 3, field: "refund_request", value: translator.translate(language, "contact", "SelectOptionsText/refund_request") },
    { id: 4, field: "payment_problem", value: translator.translate(language, "contact", "SelectOptionsText/payment_problem") },
    { id: 5, field: "medical_request", value: translator.translate(language, "contact", "SelectOptionsText/medical_request") },
    { id: 7, field: "order_status_request", value: translator.translate(language, "contact", "SelectOptionsText/order_status_request") },
    { id: 8, field: "general_request", value: translator.translate(language, "contact", "SelectOptionsText/general_request") },
  ];

  const contactFormAttributes = useForm(
    { nameAndSurname: "", email: "", orderNumber: "", orderNumberOptional: "", content: "", },
    language
  )
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const reRef = useRef<ReCAPTCHA>(null);


  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile('ontouchstart' in window || navigator.maxTouchPoints > 0);
    };
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);



  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitButtonLoading(true);
    MainTracker.track('custom', 'Submit support form', {selectedOptionField: selectedOption.field, 
                                                        orderHashID: contactFormAttributes.form.orderNumber ? contactFormAttributes.form.orderNumber : contactFormAttributes.form.orderNumberOptional});

    const orderNumberShouldBeOptional = ["subscription_questions", "payment_problem", "general_request", "medical_request"].includes(selectedOption.field)
    const ignoreValidationFields = orderNumberShouldBeOptional ? ["orderNumber"] : []

    if (contactFormAttributes.validateForm(ignoreValidationFields)) {

      let captchaToken

      if (clientConfig.IS_RECAPTCHA_ENABLED) {
        captchaToken = await reRef.current?.executeAsync();
        reRef.current?.reset();
      }
      const formmatedContactForm = {
        ...contactFormAttributes.form,
        captchaToken
      };

      http.postToServer("contact_form", { subject: selectedOption.field, formmatedContactForm, country })
        .then((response) => {
          if (response.data.status === "success") {
            setFormSubmitted(true);
          }
        })
        .catch((error) => {
          toggleStickyNotification({
            show: true,
            title: translator.translate(language, "contact", "error/title"),
            content: translator.translate(language, "contact", "error/content"),
            level: "error"
          })
        }).finally(() => {
          setSubmitButtonLoading(false);

        })
    } else {
      setSubmitButtonLoading(false)
    }
  }


  return (
    <>
      {!formSubmitted ?
        (<div id='contact-form-container' className='py-3  w-full '>
          <h1 className='text-2xl text-[#0D2C54] font-[700] text-center mb-10 mt-14'>
            {translator.translate(language, "contact", "ContactFormPage/subtitle")}
          </h1>

          <div id='contact-form' className='bg-white rounded-lg p-3 md:p-6'>
            <p className='text-gray-500 mb-6'>
              {translator.translate(language, "contact", "SelectCategoryOfSupport/title")}
            </p>

            <Select
              id={"customer-support-request"}
              options={optionsArray}
              placeholder={translator.translate(language, "contact", "SelectCategoryOfSupport/placeholder")}
              questionnaireValidationObject={{}} //passing an empty object because this prop is mandatory for some reason
              errorMode={""}
              onChangeCallback={(optionObj) => setSelectedOption(optionObj.option)}
            />

            {Boolean(selectedOption.field) &&
              <div
                id='description-depended-category'
                dangerouslySetInnerHTML={{ __html: translator.translate(language, "contact", `SelectOptionsDescription/${selectedOption.field}`) }}
                style={{ border: "1px solid #C6F4E9" }}
                className='bg-[#E7FCF7] text-gray-700 p-4 rounded-md my-4'>

              </div>
            }

            {Boolean(selectedOption.field) && (<form id='contact form' className='flex flex-col'>

              <section id='contact-form-inputs' className=''>
                <InputField id='name-and-surname-input'
                  placeholder={translator.translate(language, "contact", "ContactPageForm/name_and_surname")}
                  value={contactFormAttributes.form.nameAndSurname}
                  errorMsg={
                    contactFormAttributes.errorMessagePerField.nameAndSurname
                  }
                  mandatory
                  onChange={(inputValue: string) =>
                    contactFormAttributes.updateForm("nameAndSurname", inputValue)
                  }
                  language={language}
                  onBlur={() => contactFormAttributes.onFieldBlur("nameAndSurname")}
                  touched={contactFormAttributes.touched.nameAndSurname}
                />

                <InputField id='email-input'
                  placeholder={translator.translate(language, "contact", "ContactPageForm/email_address")}
                  value={contactFormAttributes.form.email}
                  errorMsg={
                    contactFormAttributes.errorMessagePerField.email
                  }
                  mandatory
                  onChange={(inputValue: string) =>
                    contactFormAttributes.updateForm("email", inputValue)
                  }
                  language={language}
                  onBlur={() => contactFormAttributes.onFieldBlur("email")}
                  touched={contactFormAttributes.touched.email}
                />

                {(selectedOption.field !== "subscription_questions") && (selectedOption.field !== "payment_problem") && (selectedOption.field !== "general_request") && (selectedOption.field !== "medical_request") && (
                  <InputField id='order-number-input'
                    placeholder={translator.translate(language, "contact", "ContactPageForm/order_number")}
                    value={contactFormAttributes.form.orderNumber}
                    errorMsg={
                      contactFormAttributes.errorMessagePerField.orderNumber
                    }
                    mandatory
                    onChange={(inputValue: string) =>
                      contactFormAttributes.updateForm("orderNumber", inputValue)
                    }
                    language={language}
                    onBlur={() => contactFormAttributes.onFieldBlur("orderNumber")}
                    touched={contactFormAttributes.touched.orderNumber}

                    childrenIcon={

                      <div id='order-number-popover' className='absolute top-0 right-[6%] p-2'>
                        <div id='popover-container' className='relative flex mt-[1px] ml-1 desktop:ml-0 desktop:mr-1 p-2 '>
                          <img
                            id='info-icon'
                            className=' select-none cursor-pointer w-[20px] h-[20px] absolute left-3'
                            src={InfoIconGray}
                            alt="i"
                            onMouseLeave={!isMobile ? () =>setShowPopover(false) : undefined}
                            onMouseEnter={!isMobile ? () => setShowPopover(true) : undefined}
                            onClick={isMobile ? () => setShowPopover(true) : undefined}
                          />

                          {/* Popover */}
                          {showPopover && (
                            <Popover
                              content={translator.translate(language, "contact", "ContactPageForm/popover_content")}
                              onClose={() => setShowPopover(false)}
                              containerClassName='bottom-5'
                              traingleClassName='bottom-4'
                            />
                          )}
                        </div>
                      </div>
                    }
                  />
                )}

                {(selectedOption.field === "subscription_questions" || selectedOption.field === "payment_problem" || selectedOption.field === "general_request" || selectedOption.field === "medical_request") && (
                  <InputField id='optional-order-number-input'
                    placeholder={translator.translate(language, "contact", "ContactPageForm/optional_order_number")}
                    value={contactFormAttributes.form.orderNumberOptional}
                    errorMsg={
                      contactFormAttributes.errorMessagePerField.orderNumberOptional
                    }
                    mandatory={false}
                    onChange={(inputValue: string) =>
                      contactFormAttributes.updateForm("orderNumberOptional", inputValue)
                    }
                    language={language}
                    onBlur={() => contactFormAttributes.onFieldBlur("orderNumberOptional")}
                    touched={contactFormAttributes.touched.orderNumberOptional}

                    childrenIcon={

                      <div id='order-number-popover' className='absolute top-0 right-[6%] p-2'>
                        <div id='popover-container' className='relative flex mt-[1px] ml-1 desktop:ml-0 desktop:mr-1 p-2 '>
                          <img
                            id='info-icon'
                            className=' select-none cursor-pointer w-[20px] h-[20px] absolute left-3'
                            src={InfoIconGray}
                            alt="i"
                            onClick={() => setShowPopover(!showPopover)}
                          />

                          {/* Popover */}
                          {showPopover && (
                            <Popover
                              content={translator.translate(language, "contact", "ContactPageForm/popover_content")}
                              onClose={() => setShowPopover(false)}
                              containerClassName='bottom-9'
                              traingleClassName='bottom-8'
                            />
                          )}
                        </div>
                      </div>
                    }

                  />
                )
                }


                <TextArea id='message-input'
                  placeholder={translator.translate(language, "contact", "ContactPageForm/message_with_minimum")}
                  value={contactFormAttributes.form.content}
                  errorMsg={
                    contactFormAttributes.errorMessagePerField.content
                  }
                  mandatory
                  onChange={(inputValue: string) =>
                    contactFormAttributes.updateForm("content", inputValue)
                  }
                  onBlur={() => contactFormAttributes.onFieldBlur("content")}
                  touched={contactFormAttributes.touched.content}
                />
              </section>


              {clientConfig.IS_RECAPTCHA_ENABLED &&

                <ReCAPTCHA
                  sitekey={clientConfig.RECAPTCHA_SITE_KEY}
                  size="invisible"
                  ref={reRef}
                />
              }
              
              <SubmitButton
                id={"contact-form-submit"}
                language={language}
                loading={submitButtonLoading}
                className="bg-[#11DDAC] text-xl text-white font-bold my-4 py-2 px-4 rounded-sm border-0 outline-0 hover:opacity-90 cursor-pointer p-10"
                onSubmit={onSubmit}
                placeholder={translator.translate(language, "contact", "ContactPageForm/submit")}
              />

            </form>)}
          </div>

        </div>) : (
          <ContactFormSubmitted selectedIssue={selectedOption.field} language={language} />
        )
      }
    </>

  )
}

export default ContactPageForm