import { useContext } from "react";
import InputField from "../../../../components/CredentialsInput/InputField";
import { ShippingDataContext } from "../../Shipping";
import * as translator from "../../../../utils/translator";
import GoogleApiInputField from "../../../../components/CredentialsInput/GoogleApiInputField";
import Select from "../../../../components/Select/Select";

const BillingForm = ({ isToggleEnabled, language, country = "de" }) => {
  const shippingFormAttributes = useContext(ShippingDataContext).billingShippingFormAttributes;
  const { destinationCountry, setDestinationCountry} = useContext(ShippingDataContext);

  return (
    <div>
      {!isToggleEnabled ? (
        <div className="payment-information">
          <div className="name-fields">
            <InputField
              value={shippingFormAttributes.form["billing_first_name"]}
              errorMsg={
                shippingFormAttributes.errorMessagePerField[
                  "billing_first_name"
                ]
              }
              onChange={(value) =>
                shippingFormAttributes.updateForm("billing_first_name", value)
              }
              placeholder={translator.translate(
                language,
                "shipping",
                "ShippingForm/first_name"
              )}
              mandatory={true}
              language={language}
              onBlur={() =>
                shippingFormAttributes.onFieldBlur("billing_first_name")
              }
              touched={shippingFormAttributes.touched.billing_first_name}
            />

            <InputField
              value={shippingFormAttributes.form["billing_last_name"]}
              errorMsg={
                shippingFormAttributes.errorMessagePerField["billing_last_name"]
              }
              onChange={(value) =>
                shippingFormAttributes.updateForm("billing_last_name", value)
              }
              placeholder={translator.translate(
                language,
                "shipping",
                "ShippingForm/surname"
              )}
              mandatory={true}
              language={language}
              onBlur={() => shippingFormAttributes.onFieldBlur("billing_last_name")}
              touched={shippingFormAttributes.touched.billing_last_name}
            />
          </div>
          <div className="location-fields">
            <InputField
              value={shippingFormAttributes.form["billing_zip"]}
              errorMsg={
                shippingFormAttributes.errorMessagePerField["billing_zip"]
              }
              onChange={(value) =>
                shippingFormAttributes.updateForm("billing_zip", value)
              }
              placeholder={translator.translate(
                language,
                "shipping",
                "ShippingForm/postal_code"
              )}
              mandatory={true}
              language={language}
              onBlur={() => shippingFormAttributes.onFieldBlur("billing_zip")}
              touched={shippingFormAttributes.touched.billing_zip}
            />

            <InputField
              value={shippingFormAttributes.form["billing_city"]}
              errorMsg={
                shippingFormAttributes.errorMessagePerField["billing_city"]
              }
              onChange={(value) =>
                shippingFormAttributes.updateForm("billing_city", value)
              }
              placeholder={translator.translate(
                language,
                "shipping",
                "ShippingForm/location"
              )}
              mandatory={true}
              language={language}
              onBlur={() => shippingFormAttributes.onFieldBlur("billing_city")}
              touched={shippingFormAttributes.touched.billing_city}
            />
          </div>

          <div className="location-fields">
            <GoogleApiInputField
              value={shippingFormAttributes.form["billing_address"]}
              errorMsg={
                shippingFormAttributes.errorMessagePerField["billing_address"]
              }
              onChange={(value) =>
                shippingFormAttributes.updateForm("billing_address", value)
              }
              placeholder={translator.translate(
                language,
                "shipping",
                "ShippingForm/street_and_house"
              )}
              mandatory={true}
              language={language}
              country={country}
              onBlur={() => shippingFormAttributes.onFieldBlur("billing_address")}
              touched={shippingFormAttributes.touched.billing_address}
            />


           {
             destinationCountry == "fr" ? 
             <InputField
                id = "shipping_country"
                value={translator.translate(
                  language,
                  "shipping",
                  `ShippingForm/country_name/${language}`
                )}
                placeholder={translator.translate(
                  language,
                  "shipping",
                  "ShippingForm/country"
                )}
                mandatory={true}
                language={language}
                errorMsg={shippingFormAttributes.errorMessagePerField["country"]}
                disabled
                onBlur={() => shippingFormAttributes.onFieldBlur("country")}
                touched={shippingFormAttributes.touched.country}
              />
              :
              <Select
              id = "shipping_country"
              initialValue={{value:translator.translate(language,"shipping",`ShippingForm/country_name/${destinationCountry}`), code:destinationCountry}}
              options = {[{value:translator.translate(language,"shipping","ShippingForm/country_name/de"), code:'de'}, {value:translator.translate(language,"shipping",`ShippingForm/country_name/ch`), code:'ch'}]}
              onChangeCallback = {(option) => {
                setDestinationCountry(option.option.code); 
                shippingFormAttributes.updateForm("country", option.option.code);
              }}

              mandatory={true}
              style={{
                border: "1px solid var(--sub-gray)",
                height: "56px",
                width: "100%",
                borderRadius: "6px",
                margin: "15px 0px"
              }}
              label={translator.translate(language,"shipping","ShippingForm/country")}
            />
           }

          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BillingForm;
