import React, { useContext } from 'react'
import { ReturingCustomersLayoutContext, groupedProductsType } from '../ReturningCustomersLayout';
import DosageOption from './DosageOption';
import * as translator from "../../../../utils/translator"
import { sorted } from '../../../../utils/helpers';


interface DosageSelectionProps {
  isColLayout: boolean,
  isResponsiveLayout?: boolean
}

const DosageSelection: React.FC<DosageSelectionProps> = ({ isColLayout, isResponsiveLayout }) => {

  const context = useContext(ReturingCustomersLayoutContext);
  const groupedProducts = context?.groupedProducts! as groupedProductsType;
  const selectedCatalogID = context?.selectedCatalogID;


  const products = selectedCatalogID ? groupedProducts[selectedCatalogID] : [];


  return (
    <>
      <p className='text-xs text-[#98a0af] font-bold px-2'>{translator.translate(
        context?.languageID || "de",
        "default",
        "DrugSelectionSidePanel/select-dosage"
      )}</p>
      <div id='dosage-selection' className={`grid grid-cols-${isColLayout ? 1 : 3} gap-5`}>
        {
          sorted(products, (a, b) => a.treatments_order - b.treatments_order).map((product) => {
            return <DosageOption isResponsiveLayout={isResponsiveLayout} key={product.product_id} isColLayout={isColLayout} productID={product.product_id} dosageStr={product.dosage_str || product.product_title} />
          })
        }
      </div>
    </>
  )
}

export default DosageSelection
