import { useState } from "react";
import "./TextInput.css";

export default function TextInput({
  placeholder = "A textInput",
  onChange,
  id,
  questionsValidationObject,
  errorMode,
  value,
}) {
  const [showError, setShowError] = useState("");

  return (
    <input
      className={
        showError || errorMode
          ? "numberInput-error-container"
          : "numberInput-container"
      }
      type="text"
      value={value}
      id={id}
      onChange={(event) => {
        setShowError(event.target.value.length === 0 ? true : false);
        onChange(event.target.value);
      }}
      placeholder={placeholder}
    />
  );
}
