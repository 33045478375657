import React, { useContext, useEffect } from "react";
import { CustomerContext } from "../reducers/CustomerReducer";
import { useParams, useNavigate } from "react-router-dom";
import LoadingModal from "../components/Modals/LoadingModal/LoadingModal";

interface ProtectedCustomerMyAccountProps {
  children: any;
}

// If: customer logged in -> move to MyAccount (return children)
// else: redirect him to LoginPage

const ProtectedMyAccount: React.FC<ProtectedCustomerMyAccountProps> = ({
  children,
}) => {
  const fullURL = window.location.href;
  const baseURL = window.location.origin;
  const paramsOnly = fullURL.replace(baseURL, '');
  const { country } = useParams();
  const navigate = useNavigate();
  const { customerState } = useContext(CustomerContext);

  useEffect(() => {
    if (!customerState || !customerState.customerID) {
      navigate(`/${country}/login?redirect=${paramsOnly}`);
    }
  }, [customerState, country, navigate]);

  if (!customerState || !customerState.customerID) {
    return <LoadingModal /> //load until it navigates (this line happens pre useeffect)
  } else {
    return children;
  }
};

export default ProtectedMyAccount;
