import "./OrderWindow.css";
import LoadingModal from "../Modals/LoadingModal/LoadingModal";
import vCheck from "../../icons/vCheck.svg";
import { useParams } from "react-router-dom";
import * as translator from "../../utils/translator";
import ToggleRadioButton from "../ToggleRadioButton/ToggleRadioButton";
import RadioSelect from "../RadioSelect/RadioSelect";
import { useState, useContext, useEffect } from "react";
import { ShippingDataContext } from "../../pages/Shipping/Shipping";
import { PaymentDataContext } from "../../pages/Payment/PaymentPage";
import { formatNumberByLocale } from "../../utils/translator";
import { clientConfig } from "../../config";
import { resetBonusesInCart, updateBonusDBCart, updateSubscriptionDBCart } from "../../pages/Shipping/helpers";

const SHOW_SUBSCRIPTION_PLANS = "show_subscription_plans";
const SELECTED_PLAN_INDEX = "selected_plan_index";
const SHOW_COUPON_CONFIRMED = "show_coupon_confirmed";
const SHOW_COUPON_INPUT = "show_coupon_input";
const SET_IS_SUBSCRIPTION_FLOW = "is_subscription_flow";
const PAYMENT_METHOD_INDEX = "payment_method_index";
const AUTO_BONUS_VALUE = "auto_bonus_value";
const IS_AUTO_BONUS = "is_auto_bonus";

export default function OrderWindow({
  itemImageUrl,
  itemTitle,
  itemQuantity,
  treatmentFee,
  Price,
  serviceFee,
  isSubsidized,
  shippingFee,
  subscriptionPlans = null,
  subscriptionDiscount = 0,
  changeSubscriptionDiscount = null,
  couponDiscount = 0,
  changeCouponDiscount = null,
  languageProp = null,
  openSubscriptionModal = null,
  showSubscriptionPlans,
  selectedPlanIndex,
  updateDispatch,
  cartID = null,
  autoBonusValue,
  didntChooseSubscriptionInCT = true,
  catalogSettings,
  setRxProductFinalPrice, //the ability to set the final price after all logic is done
  prescriptionOnlyFlow = false,
  isSelfPickup = false,
  isCannabisIDAddedToOrder = false,
  disclaimer = "",
  cannabisIdCardCost = 0,
  onlyPrescriptionForPayment
}) {

  const DISCOUNTED_SHIPPING_FEE = 10;
  const ShippingData = useContext(ShippingDataContext);
  const PaymentData = useContext(PaymentDataContext);

  const dataContext = ShippingData || PaymentData
  let prescriptionOnly = ShippingData?.prescriptionOnly || prescriptionOnlyFlow || onlyPrescriptionForPayment;


  let catalogID = ShippingData ? ShippingData.catalogID : null;
  let limitToPrescriptionOnly = ShippingData ? ShippingData.limitToPrescriptionOnly : null;


  const prescriptionOnlyImage = clientConfig.IMAGES_URL + clientConfig.PRESCRIPTION_IMAGE;
  let language = 'de';
  let country = 'de';
  ({ country, language } = useParams());
  country = dataContext?.destinationCountry ? dataContext.destinationCountry : country
  const locale = language.toLowerCase() + '-' + country.toUpperCase();

  const [loading, setLoading] = useState(false);
  const countryCurrency = dataContext?.currencySign || '€';

  const changeSelectedPlan = async (index) => {
    setLoading(true);
    updateDispatch(SELECTED_PLAN_INDEX, index);
    changeSubscriptionDiscount(subscriptionPlans[index].discount);
    await updateSubscriptionDBCart(cartID, subscriptionPlans[index].product_plan_id);
    setLoading(false);
  };

  const getMaxDiscountOutOfPlans = () => {
    return `${Math.max(...subscriptionPlans.map(plan => plan.discount)).toString()} ${countryCurrency}`;
  }

  function calculateRxFinalPrice() {
    let rxFinalPrice = 0
    if (!prescriptionOnly) {
      rxFinalPrice = (treatmentFee + Price + shippingFee - (subscriptionDiscount || couponDiscount) - (autoBonusValue || 0))
    } else if (isCannabisIDAddedToOrder && (prescriptionOnly || onlyPrescriptionForPayment)) {
      rxFinalPrice = (treatmentFee - couponDiscount) + Number(cannabisIdCardCost)
    } else {
      rxFinalPrice = (treatmentFee - couponDiscount)
    }
    return rxFinalPrice;
  }


  //Updating rxFinalPrice when every discount state changing
  useEffect(() => {
    let rxFinalPrice = calculateRxFinalPrice();

    //if we are in shipping page, (the only page that needs rxFinalPrice Globally)
    if (setRxProductFinalPrice) {
      setRxProductFinalPrice(rxFinalPrice);
    }
  }, [subscriptionDiscount, Price, treatmentFee, couponDiscount, autoBonusValue,prescriptionOnly])


  const toggleUpdate = async (isToggleEnabled) => {
    updateDispatch(SHOW_SUBSCRIPTION_PLANS, isToggleEnabled);
    updateDispatch(PAYMENT_METHOD_INDEX, 0);

    if (isToggleEnabled) {
      setLoading(true);
      updateDispatch(SET_IS_SUBSCRIPTION_FLOW, true);
      changeSelectedPlan(0);
      await updateBonusDBCart(null, null, false);
      await updateSubscriptionDBCart(cartID, subscriptionPlans[0].product_plan_id)
      changeSubscriptionDiscount(subscriptionPlans[0].discount);
      updateDispatch(AUTO_BONUS_VALUE, 0);
      changeCouponDiscount(0);
      setLoading(false);

    } else {
      setLoading(true);
      await updateSubscriptionDBCart(cartID, null);
      updateDispatch(SET_IS_SUBSCRIPTION_FLOW, false);
      updateDispatch(SHOW_COUPON_CONFIRMED, false);
      updateDispatch(SHOW_COUPON_INPUT, true);
      updateDispatch(SELECTED_PLAN_INDEX, null);
      changeSubscriptionDiscount(ShippingData.subscriptionFtd); //else we reset the paramater that we got (initlized to 0)
      if (catalogID) {
        //if the toggle is disabled, I.E the subscription has been toggled off, reset original catalog bonuses
        let { autoDiscountData } = await resetBonusesInCart(catalogID, cartID, country, ShippingData.subscriptionFtd);
        if (autoDiscountData?.discount_value) {
          updateDispatch(AUTO_BONUS_VALUE, autoDiscountData.discount_value);
          updateDispatch(IS_AUTO_BONUS, autoDiscountData.enabled)
        }
      }
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingModal />}
      {
        <div className="orderWindow-container ">
          <p className="orderWindow-container-title">
            {translator.translate(language, "default", "OrderWindow/title")}
          </p>

          <div className={"orderWindow"}>
            <div className="orderWindow-header-container">
              <img src={!prescriptionOnly ? itemImageUrl : prescriptionOnlyImage} alt="itemImage" />

              <p className="orderWindow-image-disclaimer">
                {translator.translate(
                  language,
                  "default",
                  "OrderWindow/image_disclaimer"
                )}
              </p>
              <p className="orderWindow-item-title">{itemTitle}</p>
              <p className="orderWindow-item-quantity">{itemQuantity}</p>
            </div>

            {
              <div className="orderWindow-price-row ">
                <p>
                  {translator.translate(
                    language,
                    "default",
                    "OrderWindow/treatment_fee"
                  )}
                </p>
                <div className="orderWindow-price-row-with_discount">
                  {
                    autoBonusValue > 0 && !prescriptionOnly ?
                      <span className="orderwindow-old-treatmentfee-deleted">
                        {formatNumberByLocale(treatmentFee, locale).toString() + ` ${countryCurrency}`}
                      </span> : null
                  }

                  <p>{prescriptionOnly ? formatNumberByLocale(treatmentFee, locale) + ` ${countryCurrency}` : formatNumberByLocale(treatmentFee - (autoBonusValue || 0), locale) + ` ${countryCurrency}`}</p>
                </div>
              </div>
            }

            {!prescriptionOnly && isSubsidized && serviceFee ? //display service fee
              <div className="orderWindow-price-row">
                <p>
                  {translator.translate(
                    language,
                    "default",
                    "OrderWindow/service_fee"
                  )}
                </p>
                <p>{formatNumberByLocale(serviceFee, locale) + ` ${countryCurrency}`}</p>
              </div>
              :
              null
            }

            {!prescriptionOnly && Price ?
              <div className="orderWindow-price-row">
                <p>
                  {translator.translate(
                    language,
                    "default",
                    "OrderWindow/drug_price"
                  )}
                </p>

                {language == "en" ? ( //in UK/FR we show treatment and item price together
                  <p>{formatNumberByLocale((Price + treatmentFee), locale) + ` ${countryCurrency}`}</p>
                ) : (isSubsidized && serviceFee) ? ( //display official price only
                  <p>{formatNumberByLocale(Price - serviceFee, locale) + ` ${countryCurrency}`}</p>
                ) : (
                  <p>{formatNumberByLocale(Price, locale) + ` ${countryCurrency}`}</p>
                )
                }
              </div>
              :
              null
            }

            {couponDiscount > 0 && !subscriptionDiscount > 0 && (
              <div className="orderWindow-price-row">
                <p>Coupon</p>
                <p>{"-" + formatNumberByLocale(couponDiscount, locale).toString() + ` ${countryCurrency}`}</p>
              </div>
            )}


            {/* Digital Cannabis ID Price row  */}
            {Boolean(isCannabisIDAddedToOrder) && (
              <div className="orderWindow-price-row ">
                <p>Digitaler Cannabis-Ausweis</p>
                <p className={`${prescriptionOnly ? "" : "line-thru-price"}`} > {formatNumberByLocale(Number(cannabisIdCardCost), locale).toString() + ` ${countryCurrency}`} </p>
              </div>
            )}


            {/* Delivery price row  */}
            <div className="orderWindow-price-row">

              { //Self pick up price row (0 euro)
                isSelfPickup ?
                  <p>{translator.translate(language, "default", "OrderWindow/self_pickup")}</p>
                  :
                  !prescriptionOnly && Price > 0 ?
                    <p>{translator.translate(language, "default", "OrderWindow/next_day_delivery")}</p>
                    :
                    <p> {translator.translate(language, "default", "OrderWindow/prescription_delivery")} </p>
              }

              {
                isSelfPickup || shippingFee > 0 ?
                  <p> {formatNumberByLocale(shippingFee, locale) + ' ' + countryCurrency} </p>
                  :
                  <p className="line-thru-price">{formatNumberByLocale(DISCOUNTED_SHIPPING_FEE, locale) + ' ' + countryCurrency}</p>
              }

            </div>


            {subscriptionDiscount > 0 && !prescriptionOnly && language == "de" && (
              <div className="orderWindow-price-row ">
                <p>

                  {translator.translate(
                    language,
                    "default",
                    "OrderWindow/treatment_plan_discount"
                  )}
                </p>
                <p id="subscription-discount">
                  {"- " + formatNumberByLocale(parseFloat(subscriptionDiscount), locale) + ` ${countryCurrency}`}
                </p>
              </div>
            )}


            {!limitToPrescriptionOnly && subscriptionPlans && country !== "fr" && !prescriptionOnly && didntChooseSubscriptionInCT && catalogSettings.subscription_view_type && (
              <div className="orderWindow-subscription-plans-container ">
                <div className="orderWindow-subscription-plans-header">
                  <p className="orderWindow-subscription-plans-title">
                    {translator.translate(language, "default", "OrderWindow/subscription_plans_title")}
                    <span id="up-to-discount">
                      {
                        getMaxDiscountOutOfPlans()
                      }
                    </span>{" sparen"}

                  </p>
                  <ToggleRadioButton
                    id="subscription_toggle"
                    isToggleEnabled={showSubscriptionPlans}
                    updateToggle={(isToggleEnabled) => {
                      toggleUpdate(isToggleEnabled);
                    }}
                  />
                </div>

                <ul className="orderWindow-subscription-plans-checks">
                  <li className="orderWindow-subscription-plan-check-row ">
                    <img src={vCheck} alt="check" />
                    <p>{translator.translate(language, "default", "OrderWindow/subscription_plan_check_row_top")}</p>
                  </li>

                  <li className="orderWindow-subscription-plan-check-row">
                    <img src={vCheck} alt="check" />
                    <p>{translator.translate(language, "default", "OrderWindow/subscription_plan_check_row_bot")}</p>
                  </li>
                </ul>

                {showSubscriptionPlans && (
                  <div className="orderWindow-subscription-plans-intervals-container ">
                    <p className="orderWindow-subscription-plans-intervals-title">
                      {translator.translate(language, "default", "OrderWindow/subscription_plans_intervals_title")}
                    </p>
                    {subscriptionPlans.map((plan, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          changeSubscriptionDiscount(plan.discount);
                          changeSelectedPlan(index)
                        }}
                        className={
                          selectedPlanIndex === index
                            ? "orderWindow-subscription-plan-picked "
                            : "orderWindow-subscription-plan "
                        }
                      >
                        <RadioSelect selected={index == selectedPlanIndex} />
                        <p
                          dangerouslySetInnerHTML={{
                            __html: plan.checkout_title,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {!limitToPrescriptionOnly && openSubscriptionModal && catalogSettings.subscription_view_type && didntChooseSubscriptionInCT && !prescriptionOnly && subscriptionPlans && (
              <span
                onClick={() => openSubscriptionModal(true)}
                className="orderWindow-open-subscription-modal "
              >
                {translator.translate(language, "default", "OrderWindow/open_subscription_modal")}
              </span>
            )}

            <div className="orderWindow-total-price-row notranslate">
              <p>
                {translator.translate(
                  language,
                  "default",
                  "OrderWindow/total_price"
                )}
              </p>


              {/* Total price row  */}
              <p>
                {/* Deleted price in red  */}
                {subscriptionDiscount > 0 && !prescriptionOnly ? (
                  <span className="orderWindow-old-price-deleted notranslate">
                    {formatNumberByLocale((treatmentFee + Price + shippingFee), locale).toString() + ` ${countryCurrency}`}
                  </span>
                ) : null}

                <span id="shipping_final_price">
                  {/* final Rx Total  */}
                  {formatNumberByLocale(calculateRxFinalPrice(), locale) + ` ${countryCurrency}`}
                </span>
              </p>
            </div>
          </div>
        </div>
      }

      {disclaimer ?
        <p className="orderWindow-disclaimer">
          {disclaimer}
        </p>
        : null
      }
    </>
  );
}
