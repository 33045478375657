import React, { useContext } from 'react'
import { ChooseTreatmentContext } from '../FreeDosageLayout';
import SettingsIcon from "../../../../icons/settings-icon.svg"

type Props = {
    filtersCount?: number
}

const ProductFiltersControl: React.FC<Props> = ({ filtersCount }) => {

    const chooseTreatmentContext = useContext(ChooseTreatmentContext);
    const appliedFiltersCount = chooseTreatmentContext?.appliedFiltersCount || 0;

    return (
        <>
            {/* Filters Menu Trigger  */}
            <div
                id='filters-menu-trigger'
                className='w-[60px] h-[60px] rounded-md bg-[#11ddac] flex items-center justify-center hover:opacity-85 ease cursor-pointer relative'
                onClick={() => chooseTreatmentContext?.setIsFilterMenuOpen(!chooseTreatmentContext?.isFilterMenuOpen)}>
                <img id='settings' src={SettingsIcon} className='w-[25px] h-[25px]' />
                <span id='filters-menu-count' className='absolute rounded-full w-[20px] h-[20px] flex justify-center items-center -right-1 -top-1 text-[#0A9281] font-medium p-[10px] bg-white'>
                    {appliedFiltersCount}
                </span>

            </div>

         
        </>

    )
}

export default ProductFiltersControl
