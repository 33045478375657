import React, { useState } from "react";
import "./SubscriptionModal.css";
import * as translator from "../../../utils/translator";
import xIcon from "../../../../src/icons/X.svg";
import activeCheck from "../../../icons/Active.svg";
import SubmitButton from "../../SubmitButton/SubmitButton";

interface SubscriptionModalProps {
  language:string | undefined,
  showModalCallback:any,
  discount : number,
  currencySign?:string,
  ftd : number
}

//ftd  = first time discount
const SubscriptionModal:React.FC<SubscriptionModalProps> = ({language,showModalCallback = null, discount, currencySign, ftd = 0}) => {
  const [showModal,setShowModal] = useState(true);

  return (
    
    <>
    {showModal && language === "de" &&
      <div
        className="subscriptionModal-background"
        onClick={(event) => {
        }}
      >
        <div
          className="subscriptionModal"
          onClick={(event) => event.stopPropagation()}
        >
          <img 
          onClick={() => { showModalCallback && showModalCallback(false); setShowModal(false)}}
          className="loginModal-x" 
          src={xIcon} 
          alt="x" />
          <p className="subscriptionModal-title">
            {translator.translate(language, "default", "SubscriptionModal/title_1")} <br/> {translator.translate(language, "default", "SubscriptionModal/title_2")} 
          </p>

          <p className="subscriptionModal-sub-title">
            {translator.translate(language, "default", "SubscriptionModal/sub_title")}
          </p>


        <div className="subscription-modal-bullets-container">
          <div className="subscription-modal-bullet">
            <img src={activeCheck} alt="subscription-active-bullet"/>
            <p>{translator.translate(language, "default", "SubscriptionModal/active_bullet_1")}</p>
          </div>

          <div className="subscription-modal-bullet">
            <img src={activeCheck} alt="subscription-active-bullet"/>
            <p>
              {translator.translate(language, "default", "SubscriptionModal/discount_label_1")} 
              {currencySign ?
              <span className="subscription-modal-discount-label"> {discount} {currencySign} </span>
              :
              <span className="subscription-modal-discount-label"> {discount} € </span>
              }
             {translator.translate(language, "default", "SubscriptionModal/discount_label_2")} </p>
          </div>


          <div className="subscription-modal-bullet">
            <img src={activeCheck} alt="subscription-active-bullet"/>
            <p>{translator.translate(language, "default", "SubscriptionModal/active_bullet_2")}</p>
          </div>


          <div className="subscription-modal-bullet">
            <img src={activeCheck} alt="subscription-active-bullet"/>
            <p>{translator.translate(language, "default", "SubscriptionModal/active_bullet_3")}</p>
          </div>

          <div className="subscription-modal-bullet">
            <img src={activeCheck} alt="subscription-active-bullet"/>
            <p>{translator.translate(language, "default", "SubscriptionModal/active_bullet_4")}</p>
          </div>
        </div>


        <SubmitButton
          id={"submit-button"}
          placeholder={"Verstanden"}
          onSubmit={() => {showModalCallback && showModalCallback(false); setShowModal(false)}}
        />


        <p className="subscription-modal-condition">
        {translator.translate(language, "default", "SubscriptionModal/modal_condition_1")}<br/>
        {translator.translate(language, "default", "SubscriptionModal/modal_condition_2")}
        </p>



        </div>
      </div>
    }
    </>
    
  );
}
export default SubscriptionModal
