import React, { useCallback, useContext, useMemo } from 'react'
import { ProductItem, ReturingCustomersLayoutContext } from '../ReturningCustomersLayout';
import QuantityOption from './QuantityOption';
import * as translator from "../../../../utils/translator";
import { sorted } from '../../../../utils/helpers';

interface ProductItemSelectionProps {
  isColLayout: boolean,
  isResponsiveLayout?: boolean
}

const ProductItemSelection: React.FC<ProductItemSelectionProps> = ({ isColLayout, isResponsiveLayout }) => {

  const context = useContext(ReturingCustomersLayoutContext);
  const selectedProduct = context?.selectedProduct;

  const productItems = selectedProduct?.items || [];

  const sparedCost = useCallback((item: ProductItem) => {
    const mostExpensive = sorted(productItems, (a, b) => b.price / b.quantity - a.price / a.quantity)[0];

    return (mostExpensive.price / mostExpensive.quantity) * item.quantity - (item.price)
  }, [selectedProduct]);


  const layOutCss = useMemo(() => {
    return isColLayout ? 'grid-cols-1' : 'grid-cols-3'
  }, [isColLayout])

  const isFrOrAllowPrescription = context?.isFR || context?.allowPrescriptionOnlyFlow;

  return (
    <>
      <p className='text-xs text-[#98a0af] font-bold px-2'>{translator.translate(
        context?.languageID || "de",
        "default",
        "DrugSelectionSidePanel/choose-pack"
      )}</p>
      <div id='quantity-selection' className={`grid ${layOutCss} gap-5`}>
        {
          sorted(productItems, (a: ProductItem, b: ProductItem) => a.quantity - b.quantity).map((productItem: ProductItem) => {
            return <QuantityOption isResponsiveLayout={isResponsiveLayout} key={productItem.product_item_id} sparedCost={sparedCost(productItem)} customQuantity={productItem.custom_quantity_title} isColLayout={isColLayout} price={productItem.price} quantity={productItem.quantity} productItemID={productItem.product_item_id} inStock={Boolean(productItem.in_stock)} disabled={Boolean(!productItem.in_stock) && !isFrOrAllowPrescription} />
          })
        }
      </div>
    </>
  )
}

export default ProductItemSelection
