import "./SubscriptionPlanCard.css";
import { AiOutlineCheck } from "react-icons/ai";
import { useEffect, useContext } from "react";
import { MainTracker } from "../../../../PixelTrackers/MainTracker";
import { CatalogSettingContext } from "../../ChooseTreatmentRouter";

const SUBS_DISCOUNT_FTD = "subs_discount_ftd";

//fills in the description details, product name, quanitity, ...
const formatDescription = (unformattedDescription, name, units) => {
  let copy = unformattedDescription.replace(":product_name", name);
  copy = copy.replace(":product_units", units);

  return copy;
};

const SubscriptionPlanCard = (props) => {
  const CatalogSettings = useContext(CatalogSettingContext);

  useEffect(() => {
    return () => {
      props.setSelectedSubscriptionPlanID(null);
    };
  }, []);

  return (
    <div
      className={
        (props.index === props.selectedPlanIndex
          ? "Subscription-plan-card-selected"
          : "Subscription-plan-card") +
        (props.showDeliveryErrorMessage ? " error-card" : "")
      }
      onClick={() => {
        MainTracker.track(
          "custom",
          "Choose Subscription On Choose Treatment Page"
        );
        props.setSelectedPlanIndex(props.index);
        props.setSelectedSubscriptionPlanID(props.plan.product_plan_id);
        props.setShowDeliveryMessage(false);
      }}
    >
      {props.bestPrice && <div className="best-price">BESTER PREIS</div>}
      <div className="title-plan-container">
        <div
          className={
            (props.index === props.selectedPlanIndex
              ? "title-plan-radio-selected"
              : "title-plan-radio") +
            (props.showDeliveryErrorMessage ? " error-title-plan-radio" : "")
          }
        ></div>
        <h2
          className={
            "title-plan" +
            (props.showDeliveryErrorMessage ? " error-title-plan" : "")
          }
        >
          {props.plan.choose_treatments_title}
        </h2>
      </div>

      <p className="plan-description">
        {formatDescription(
          props.plan.frequency_description,
          props.productName,
          props.productQuantity
        )}
      </p>

      {CatalogSettings.subscription_discount_logic === SUBS_DISCOUNT_FTD ? (
        <div className="saved-cost-description-container">
          <AiOutlineCheck className="check-cost" />
          <p className="saved-cost-description">
            Sparen Sie{" "}
            <span className="saved">{props.plan.first_time_discount}€</span> bei
            dieser Bestellung
          </p>
        </div>
      ) : null}

      <div className="saved-cost-description-container">
        <AiOutlineCheck className="check-cost" />
        <p className="saved-cost-description">
          Sparen Sie <span className={CatalogSettings.subscription_discount_logic !== SUBS_DISCOUNT_FTD ? "saved" : null}>{props.plan.discount}€</span> bei
          jeder Bestellung*
        </p>
      </div>

      <div className="saved-cost-description-container">
        <AiOutlineCheck className="check-cost" />
        <p className="saved-cost-description">Jederzeit kündbar</p>
      </div>
    </div>
  );
};

export default SubscriptionPlanCard;
