import React, { ReactNode } from "react";
import "./ModalsWrapper.css"

type ModalsWrapperProps = {
  children: ReactNode;
  shouldShowModal: boolean;
  shouldBackgroundClickHideModal?: boolean;
  backgroundColor?: string;
  hideModal:() => void;
  showModal?:() => void;
};

const ModalsWrapper: React.FC<ModalsWrapperProps> = ({
  children,
  shouldShowModal,
  shouldBackgroundClickHideModal = true,
  backgroundColor = "rgba(24, 39, 59, 0.75)",
  hideModal,
  showModal,

}) => {

  if (shouldShowModal) {
    return (
      <div
        className="modals-wrapper-background"
        onClick={() =>
          shouldBackgroundClickHideModal ? hideModal() : null
        }
        style={{ backgroundColor }}
      >
        {children}
      </div>
    );
  } else {
    return <></>;
  }
};

export default ModalsWrapper;
