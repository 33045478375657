import React,{useEffect} from "react";
import { useParams } from 'react-router-dom';
import Questionnaire from "../../../Questionnaire/Questionnaire";
import "./SubscriptionRenewQuestionnaire.css";
import * as http from "../../../../utils/http";


const SubscriptionRenewQuestionnaire = ({
  setHidePastQuestionnaire,
  setRefreshData,
  setCurrentGlobalModalName, 
  allSubscriptionData,
  language,
  country,
  orientForSpecificComponent,
  setChangeMedicationModalContent,
  setShowUpdateQuestionnaire,
  showUpdateQuestionnaire,
  invokeOnSubmit,
  setInvokeOnSubmit
}:any) => {

  const {order_hash_id} = useParams();
  const modalClass:string = `renew-questionnaire-message ${orientForSpecificComponent}`

  const onSubmit = (wasRefilled:boolean, questionnaire?:any, files?:any) =>{
    http
      .postToServer(`account-update/subscription/questionnaire`,
      {
        wasRefilled,
        hashID: order_hash_id,
        questionnaire,
        files
      },
      [http.MIDDLEWARES.CUSTOMER_ID]
      )
      .then((response) => {
        setShowUpdateQuestionnaire(false)
        if(setHidePastQuestionnaire){
          setHidePastQuestionnaire(false);
        }
        if(orientForSpecificComponent == 'change-medication'){
          setChangeMedicationModalContent('choose treatment')
        }else{
          setCurrentGlobalModalName("THANKS_FOR_UPDATE");
        }
        setRefreshData((current:boolean) => !current)
      })
      .catch((error) => {

      })
      .finally(()=> {
        
      })
  }

  useEffect(() => {
    if(setHidePastQuestionnaire){
      setHidePastQuestionnaire(true)
    }
    if(invokeOnSubmit){
      onSubmit(false)
      setInvokeOnSubmit(false)
    }
  }, [invokeOnSubmit])
  
  
  if(order_hash_id){
    const subscriptionData = allSubscriptionData[order_hash_id];
    const today = new Date()
    const wasFilledAt = new Date(subscriptionData.wasFilledAt);
    const monthsDifference = (today.getFullYear() - wasFilledAt.getFullYear()) * 12 + (today.getMonth() - wasFilledAt.getMonth());
    
    return (
      <div className={orientForSpecificComponent == 'change-medication' ? "renew-questionnaire-container height-limit" : "renew-questionnaire-container"}>
        <div className={modalClass}>
          {orientForSpecificComponent == 'change-medication' ? (
            <>
            <p>
              <strong>
                Hat sich Ihr Gesundheitszustand in letzter Zeit verändert?
              </strong>
            </p>
            <p>
              Bitte klicken Sie auf den entsprechenden Button unten und teilen Sie uns mit, ob sich seit dem letzten Ausfüllen unseres medizinischen Fragebogens etwas an Ihrem Gesundheitszustand geändert hat oder ob Sie inzwischen neue Medikamente einnehmen.
            </p>
            </>
          )
          :
          (
            <>
            <p>
              <strong>
                Aktualisieren Sie ihre medizinischen Angaben um den Behandlungsplan
                zu erneuern
              </strong>
            </p>
            <p>
              Es ist schon eine Weile her, seit Sie Ihren Fragebogen ausgefüllt
              haben.
            </p>
            <p>
              Um Ihr Rezept zu erneuern und sicherzustellen, dass Ihre Medikamente
              immer noch zu Ihnen passen, benötigt Ihr Arzt ein Update:
            </p>
            <p>
              <strong>
                Hat sich Ihr Gesundheitszustand in den letzten {monthsDifference} Monaten verändert
                oder haben Sie andere Medikamente eingenommen?
              </strong>
            </p>
            </>
          )
          }

          <div className="buttons-container">
            <div className="buttons-inner-container">
              <div 
                className="renew-questionnaire-button const-width"
                onClick={()=> {
                  if(setHidePastQuestionnaire){
                    setHidePastQuestionnaire(true);
                  }
                  setShowUpdateQuestionnaire(true);
                }}
               >
                Ja
              </div>
              <div 
                className="renew-questionnaire-button const-width"
                onClick={()=> {onSubmit(false);}}
              >
                Nein
              </div>
            </div>
            {
            orientForSpecificComponent != 'change-medication' && //dont display btn for display old q-re for change medication
            <div className="renew-questionnaire-show-last-questionnaire" onClick={()=> {setHidePastQuestionnaire(false); setShowUpdateQuestionnaire(false)}}>Meinen letzten medizinischen Fragebogen ansehen</div>
            }
          </div>
        </div>

        {
          showUpdateQuestionnaire ? 
          <Questionnaire 
          submitButtonText = {'Fragebogen aktualisieren'}
          prefilledQuestionnaire={subscriptionData.prefilledQuestionnaire}
          overrideQuestionnaireParams={{            
            language,
            country,
            optionalCategory: subscriptionData.catalog_mention
          }}
          onSubmitOverride={onSubmit}
          />
          :
          null
        }
      </div>
    );
  } else {
    return (<> </>)
  }
};

export default SubscriptionRenewQuestionnaire;
