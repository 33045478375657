import React from 'react'
import ProductQuantityControl from './ProductQuantityControl'
import { getMaxPricePerQuantity, getMinPricePerProduct, getMinPricePerQuantity, Product } from '../FreeDosageLayout'
import ProductAvailabilityTag from './ProductAvailabilityTag';

interface Props {
  product: Product
  hideTags?: boolean
}

const ProductInfoCard: React.FC<Props> = ({product, hideTags = false}) => {

  let customDosageForCannabis = ''
  let strain = ''
  let shouldUseCannabisDosage = false;
  const additionalDataObj = product?.additional_data ? JSON.parse(product?.additional_data) : {};

  if (additionalDataObj) {
    if (additionalDataObj['THC'] && additionalDataObj['CBD'] && additionalDataObj['strain']) {
      shouldUseCannabisDosage = true;
      customDosageForCannabis = `THC: ${additionalDataObj['THC']} | CBD: ${additionalDataObj['CBD']}`;
      strain = `Sorte: ${additionalDataObj['strain']}`
    }
  }

  return (
    <div id='product_info' className='rounded-md bg-white shadow-lg'>
      {/* upper section (green) */}
      <div className='px-4 pt-8 pb-8 rounded-lg border-2 rounded-br-3xl flex justify-around gap-4 '>
        <div className='flex flex-col ml-2'>
          <p className='text-xl font-bold text-[#0D2C54]'>{product?.title}</p>
          {
            shouldUseCannabisDosage ? <div className='mt-4'>
              <p className='text-sm text-[#0A9281] font-medium'>{customDosageForCannabis}</p>
              <p className='text-sm text-[#0A9281] font-medium'>{strain}</p>
            </div> : null
          }
        </div>

        <div className='flex flex-col gap-5 items-center'>
          {/* price and tags */}
          {
            product.in_stock ? 
            <div id='price_and_tags' className='p-2 flex flex-col justify-center items-center'>
                    <div className='flex gap-1 '>
                    {product.quantity_type == 'gram' ?
                    <>
                      <p className='text-sm text-nowrap font-medium text-[#0D2C54]'>{getMinPricePerQuantity(product)}</p>
                      <p className='text-sm text-nowrap font-medium text-[#0D2C54]'> - </p>
                      <p className='text-sm text-nowrap font-medium text-[#0D2C54]'>{getMaxPricePerQuantity(product)} €</p>
                      <p className='text-sm text-green-500'> * </p>
                    </>
                    :
                    <>
                      <p className='text-sm text-nowrap font-medium text-[#0D2C54]'>ab {getMinPricePerProduct(product)} €</p>
                    </>
                    }
                    </div>

                    {product.quantity_type == 'gram' &&
                      <p className='text-xs whitespace-nowrap text-[#556D8C]'>pro Gramm</p>
                    }
            </div>
            :
            null
          }
          
          { hideTags ? null : <ProductAvailabilityTag inStock={product.in_stock} />  }                      
        </div>
      </div>

      {/* lower section (white) */}
      {
        // If premium then filter out-of-stock items
        <ProductQuantityControl product={product} />
      }
    </div>
  )
}

export default ProductInfoCard
