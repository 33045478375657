import React, { useState, useEffect, useCallback } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import BinarySelect from "../../../../components/BinarySelect/BinarySelect";
import InputField from "../../../../components/CredentialsInput/InputField";
import InfoCell from "../../../../components/InfoCell/InfoCell";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import useForm from "../../../../customHooks/useForm";
import MyAccountContentHeader from "../MyAccountContentHeader/MyAccountContentHeader";
import * as translator from '../../../../utils/translator';
import * as http from "../../../../utils/http";
import "./MyAccountPersonalData.css"




const MyAccountPersonalData = () => {
  const { setActiveMenuLinkId, setStickyNotificationDetails, myAccountData, country, language } = useOutletContext<any>();
  const [deactivateAccount, setDeactivateAccount] = useState(false)
  const navigate = useNavigate()
  const [disabledChangeEmail, setDisabledChangeEmail] = useState(true)
  const [verificationStatusMessage, setVerificationStatusMessage] = useState("address_verified");


  const getVerificationStatusMessageByCode = useCallback(() => {
    switch (verificationStatusMessage) {
      case "address_needs_to_be_verified":
        return <p className="text-sm text-[#facc15]"> {translator.translate(language, "my_account", `PersonalData/${verificationStatusMessage}`)} </p>
      case "address_verified":
        return <p className="text-sm text-[#11ddac]"> {translator.translate(language, "my_account", `PersonalData/${verificationStatusMessage}`)} </p>
      default:
        return null
    }
  },[verificationStatusMessage])


  const emailField = useForm({
    email: myAccountData.personalDetails.email
  }, language)

  const onSubmit = () => {
    http.postToServer(`confirmation-email`,
      {
        email: myAccountData.customer.email,
        newEmail: emailField.form["email"],
        country,
        language,
        emailType: 'changeEmail'
      })
      .then((response) => {
        if (response.data == 'email taken') {
          setStickyNotificationDetails({
            show: true,
            title: translator.translate(language, "my_account", `stickyNotifications/error/errorTitle`),
            content: translator.translate(language, "my_account", `stickyNotifications/error/emailTaken`),
            level: "info",
            showInfoIcon: true,
          })
        } else {
          setStickyNotificationDetails({
            show: true,
            title: translator.translate(language, "my_account", `stickyNotifications/success/changeEmailTitle`),
            content: translator.translate(language, "my_account", `stickyNotifications/success/changeEmail`),
            level: "additional_action_required",
            showInfoIcon: true,
          })
          setVerificationStatusMessage("address_needs_to_be_verified")
        }
      })
      .catch(
        setStickyNotificationDetails({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
          level: "error",
          showInfoIcon: false,
        })
      )
  }

  useEffect(() => {
    setActiveMenuLinkId("personal-data");
    if (deactivateAccount) {
      if (myAccountData.mySubscriptions?.subscriptionCount > 0) { // the ? because FR doesn't have mySubscriptions
        navigate(`/${country}/account/orders-subscription`);
        setStickyNotificationDetails({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/error/deactivateAccount`),
          level: "error",
          showInfoIcon: false,
        })
        window.scrollTo(0, 0);

      } else {
        http.postToServer(`confirmation-email`, { email: myAccountData.customer.email, country, language, emailType: 'deactivateAccount' })
          .then((response) => {
            setStickyNotificationDetails({
              show: true,
              title: translator.translate(language, "my_account", `stickyNotifications/success/deactivateAccountTitle`),
              content: translator.translate(language, "my_account", `stickyNotifications/success/deactivateAccount`),
              level: "error",
              showInfoIcon: true,
            })
          })
          .catch(
            setStickyNotificationDetails({
              show: true,
              title: "",
              content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
              level: "error",
              showInfoIcon: false,
            })
          )
      }
    }
  }, [deactivateAccount]);

  const verificationTranslation = getVerificationStatusMessageByCode();

  return (
    <>
      <div className="account-content">
        <MyAccountContentHeader
          title={translator.translate(language, "my_account", `PersonalData/title`)}
          description={translator.translate(language, "my_account", `PersonalData/subTitle`)}
        />

        <div className="personal-data-form">

          <InputField
            mandatory={true}
            disabled={disabledChangeEmail}
            id="email-field"
            placeholder={translator.translate(language, "my_account", `forms/email`)}
            value={emailField.form["email"]}
            errorMsg={emailField.errorMessagePerField["email"]}
            language={language}
            onBlur={() => emailField.onFieldBlur("email")}
            touched={emailField.touched.email}
            onChange={(value: any) => {
              emailField.updateForm("email", value);
            }}
            childrenIcon={(
              <>
                {
                  disabledChangeEmail && <div id='change-email-button' className='absolute top-0 right-[6%] p-2'>
                    <div id='change-email-button-container' className='relative flex mt-[1px] ml-1 desktop:ml-0 desktop:mr-1 p-2 '>
                      <span
                        id='change-email-button-icon'
                        className='select-none cursor-pointer text-[var(--primary-lightgreen)] font-[P]oppins] hover:underline'
                        onClick={() => {
                          setDisabledChangeEmail(false)
                          setVerificationStatusMessage("")
                        }}
                      >
                        Change
                      </span>
                    </div>
                  </div>
                }

               
                {verificationTranslation }
                    
                

              </>

            )
            }

          />

          <div className="info-cells-row">
            <span className="info-cell-title">{translator.translate(language, "my_account", `PersonalData/birthday`)}</span>
            <InfoCell title={""} value={myAccountData.personalDetails.birthday.day} />
            <InfoCell title={""} value={myAccountData.personalDetails.birthday.month} />
            <InfoCell title={""} value={myAccountData.personalDetails.birthday.year} />
          </div>

          <div className="info-cells-row-2">
            <span className="info-cell-title"> {translator.translate(language, "my_account", `PersonalData/gender`)} </span>
            <BinarySelect
              locked
              valueLeft={translator.translate(language, "my_account", `PersonalData/man`)}
              valueRight={translator.translate(language, "my_account", `PersonalData/woman`)}
              valuePicked={
                myAccountData.personalDetails.gender === 1 ?
                  translator.translate(language, "my_account", `PersonalData/man`)
                  :
                  translator.translate(language, "my_account", `PersonalData/woman`)
              }
            />
          </div>

          <div className="info-cells-row-3">
            <InfoCell title={translator.translate(language, "my_account", `PersonalData/firstName`)} value={myAccountData.personalDetails.firstName} />
            <InfoCell title={translator.translate(language, "my_account", `PersonalData/lastName`)} value={myAccountData.personalDetails.lastName} />
          </div>

          <div className="delivery-address-last-row">
            <span className="deactivate-account" onClick={() => { setDeactivateAccount(true) }}>
              {translator.translate(language, "my_account", `PersonalData/deactivate`)}
            </span>
            {disabledChangeEmail ? <></> : <SubmitButton
              onSubmit={onSubmit}
              id={"delivery-address-submit"}
              placeholder={translator.translate(language, "my_account", `DeliveryAddress/submit`)}
            />}
          </div>
        </div>
      </div>
    </>
  )
};

export default MyAccountPersonalData;
