import React from 'react'
import Disclaimer from '../../components/Disclaimers/Disclaimer'
import SubmitButton from './SubmitButton'
import { useParams } from 'react-router-dom'
import ChevronUp from "../../../../icons/Chevron-Up-Green.svg";
import ShoopingCart from "../../../../icons/Shopping-Cart.svg";

type Props = {
    disclaimerText: string,
    showDisclaimer: boolean,
    buttonDisabled: boolean,
    selectedProductsAmount: number,
    showAmountOfSelectedProducts: boolean,
    onSubmit: () => void
    onArrowClick: () => void; // Fixed type
}

const StickySubmitButton: React.FC<Props> = ({ disclaimerText, showDisclaimer, buttonDisabled, selectedProductsAmount, showAmountOfSelectedProducts, onSubmit, onArrowClick }) => {

    const { language } = useParams();

    return (
        <div id='sticky-submit-container' className='w-full flex max-md:flex-col bg-white  shadow-lg gap-y-2 items-center  justify-center sticky bottom-0 right-0 lg:py-4' style={{ boxShadow: "0 -10px 50px #f7f7f7" }}>


            {/* Mobile Disclaimer  */}
            {
                showDisclaimer ?
                    <Disclaimer
                        text={disclaimerText}
                        containerClassName={`max-md:flex md:hidden p-2 py-4    items-center bg-[#EDFCF0] gap-2 transition-all duration-300 ${showDisclaimer ? "opacity-100 animate-fade-in-1" : "opacity-0 pointer-events-none"}`}
                        textClassName='!text-[#0D2C54] font-roboto text-lg !font-medium'
                        iconClassName='!text-[#19D845]'
                    />
                    :

                    null
            }



            <div id='sticky-disclaimers-and-button' className='w-full lg:w-[1000px] p-4 pb-2 lg:p-0 flex  items-center justify-between gap-[15px] lg:gap-[60px] relative'>

                {/* Desktop Disclaimer  */}
                <Disclaimer
                    text={disclaimerText}
                    containerClassName={`max-md:hidden md:flex px-0 max-lg:p-0  items-center bg-white gap-2 transition-all duration-300 ${showDisclaimer ? "opacity-100 animate-fade-in-1" : "opacity-0 pointer-events-none"}`}
                    textClassName='text-black font-roboto text-lg !font-medium'
                />

                {/* Arrow Up */}
                <div id='arrow-up-container' onClick={onArrowClick} style={{ boxShadow: "0px 0px 35px rgba(152,160,175,0.8)", zIndex:"5" }} className='absolute md:hidden cursor-pointer hover:opacity-85 transition-opacity ease-in-out select-none left-4 -top-[25px] bg-white rounded-full flex items-center justify-center'>
                    <img src={ChevronUp} alt="^" className='w-[45px] h-[45px]' />
                </div>

                {/* Mobile - Selected products section  */}
                {
                    showAmountOfSelectedProducts ?
                        <>
                            <div id='sticky-submit-cart' className='md:hidden relative min-w-fit mt-2 cursor-pointer hover:opacity-85 transition-opacity ease-in-out' onClick={onArrowClick}>
                                <p id="sticky-submit-cart-products-amount" style={{ fontFamily: "Roboto, sans-serif" }} className='absolute -right-2 -top-2 bg-[#19D845] text-white rounded-full w-5 h-5 flex items-center justify-center p-42'> 
                                    {selectedProductsAmount}
                                </p>
                                <img src={ShoopingCart} alt="Cart" />
                            </div>
                        </>
                        :
                        null
                }


                <div id='sticky-submit-button' className='w-full lg:max-w-[350px]'>
                    <SubmitButton
                        languageID={language!}
                        disabled={buttonDisabled}
                        desktop
                        onClick={onSubmit}
                        className='rounded-md w-full min-w-fit'
                    />
                </div>

            </div>

        </div>
    )
}

export default StickySubmitButton