import React, { useState } from 'react';
import ConversationTabItem from '../ConversationTabItem';
import './ConversationTabs.css';
import ConversationAttachment from '../ConversationAttachment/ConversationAttachment';
import {useOutletContext} from "react-router-dom";
import * as translator from "../../../../utils/translator";

interface ConversationTabsProps {
  initialConversationProductsMentions: any[];
  initialConversationOrdersMentions: any[];
  initialConversationAttachmentsMentions: any[];
}

const ConversationTabs: React.FC<ConversationTabsProps> = ({
  initialConversationProductsMentions,
  initialConversationOrdersMentions,
  initialConversationAttachmentsMentions,
}) => {

  const {language} = useOutletContext<any>();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const isTabSelected = (tabIndex: number) => {
    return tabIndex === selectedTabIndex;
  };

  const onTabClick = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };

  const conversationTabsById: { [key: number]: { tabTitle: string; iconSrc: string; alt: string; info: string } } = {
    0: {
      tabTitle: translator.translate(language, "my_account", 'AskADoctor/conversation_tab_products_title'),
      iconSrc: isTabSelected(0) ? require('../../../../icons/At-Sign-Green.svg').default : require('../../../../icons/At-Sign.svg').default,
      alt: '@',
      info: translator.translate(language, "my_account", 'AskADoctor/conversation_tab_products_info_if_empty')
    },
    1: {
      tabTitle: translator.translate(language, "my_account", 'AskADoctor/conversation_tabs_orders_title'),
      iconSrc: isTabSelected(1) ? require('../../../../icons/Hashtag-Green.svg').default : require('../../../../icons/Hashtag.svg').default,
      alt: '#',
      info: translator.translate(language, "my_account", 'AskADoctor/conversation_tabs_orders_info_if_empty')
    },
    2: {
      tabTitle: translator.translate(language, "my_account", 'AskADoctor/conversation_tabs_files_title'),
      iconSrc: isTabSelected(2) ? require('../../../../icons/Paperclip-Green.svg').default : require('../../../../icons/Paperclip.svg').default,
      alt: 'attach',
      info: translator.translate(language, "my_account", 'AskADoctor/conversation_tabs_files_info_if_empty')
    },
  };


  const getListOfMentionsByTabIndex = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:

        //Products tab is chosen
        if (initialConversationProductsMentions.length) {
          return initialConversationProductsMentions.map((mention: any, index: number) => (
              <a 
              href={`${mention.mention_link}`}
              key={index} 
              className="clickable-mention"
              >
                {mention.mention_text}
              </a>

            )
          );
        } else {
          return (
            //if there are no mentions we show the tab info
            <span className="tab-mentions-info">
              {conversationTabsById[selectedTabIndex].info}
            </span>
          );
        }
      case 1:
        //Orders tab is chosen
        if (initialConversationOrdersMentions.length) {
          return initialConversationOrdersMentions.map((mention: any, index: number) => (
              <a
              href={`${mention.mention_link}`}
              key={index} 
              className="clickable-mention"
              >
                {mention.mention_text}
              </a>
            )
          );
        } else {
          return (
             //if there are no mentions we show the tab info
            <span className="tab-mentions-info">
              {conversationTabsById[selectedTabIndex].info}
            </span>
          );
        }
      case 2:
        //Attachments tab is chosen
        if (initialConversationAttachmentsMentions.length) {
          return initialConversationAttachmentsMentions.map((attachment: any, index: number) => (
              <ConversationAttachment
                key={index}
                attachment={attachment}
              />
            )
          );
        } else {
          return (
             //if there are no mentions we show the tab info
            <span className="tab-mentions-info">
              {conversationTabsById[selectedTabIndex].info}
            </span>
          );
        }
      default:
        return [];
    }
  };

  let visibleTabMentions = getListOfMentionsByTabIndex(selectedTabIndex);

  return (
    <div className="conversation-tabs-container">
      <div className="conversation-tabs">
        {Object.keys(conversationTabsById).map((tabIndex: string) => {
          const tabId = parseInt(tabIndex, 10);
          return (
            <ConversationTabItem
              key={tabId}
              onClick={() => onTabClick(tabId)}
              tabTitle={conversationTabsById[tabId].tabTitle}
              iconSrc={conversationTabsById[tabId].iconSrc}
              iconAlt={conversationTabsById[tabId].alt}
              selected={isTabSelected(tabId)}
            />
          );
        })}
      </div>

      <div className="tab-mentions-container">{visibleTabMentions}</div>
    </div>
  );
};

export default ConversationTabs;
