/* FACEBOOK TRACKER   */

const EVENTS = ["ViewThankyouPage"];

const track = (eventType, eventName, data) => {
  if (eventType === "pv") {


    
  } else {
    if (EVENTS.includes(eventName)) {
      window.fbThankYouPageVariables = data;
    }
  }
};

module.exports = {
  track,
};
