import React, { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import "./ReorderLayout.css"
import ServerError from "../../components/ServerError/ServerError";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import HeaderController from "../../components/Headers/HeaderController";
import * as http from "../../utils/http";
import SubscriptionModal from "../../components/Modals/SubscriptionModal/SubscriptionModal";
import StickyNotification from "../../components/StickyNotification/StickyNotification";


type ReorderData = {
    CatalogMention: string,
    rawQuestionnaire: string,
    prefilledQuestionnaire: any,
    orderProductItem: any,
    orderProductItemSubscriptionPlans: any,
    isOrderProductItemInStock: boolean,
    isOnlyPrescription:boolean,
}

const ReorderLayout = () => {
    const { country, language, order_hash_id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<number | null>(null);
    const [reorderData, setReorderData] = useState<ReorderData | null>(null);
    const [showSubscriptionModal,setShowSubscriptionModal] = useState<boolean>(false);
    const [headerStep,setHeaderStep,] = useState<string>("");
    const [stickyNotificationDetails, setStickyNotificationDetails] = useState({
        show: false,
        title: "",
        content: "",
        level: "",
        showInfoIcon: true,
        refresh: false,
    });

    useEffect(() => {
        http
            .getFromServer(`reorder/${order_hash_id}/${country}/${language}`, [http.MIDDLEWARES.CUSTOMER_ID])
            .then((response) => {
                setReorderData(response.data);
            })
            .catch((error) => {
                if(error.response.status === 400){
                    window.clientLogger.warn(`Unauthorize try to access reorder of order: ${order_hash_id}`)
                    setError(400);
                } else if(error.response.status === 404){
                    setError(400);
                } else {
                    window.clientLogger.error("error getFromServer MedicalDisclaimerPage: ", error)
                    setError(500);
                }
            })
            .finally(() => {
                setLoading(false);
            })

    }, []);

    const getMaxDiscountOfSubscriptionPlans = () => {
        const productItemSubscriptionPlans = reorderData!.orderProductItemSubscriptionPlans[reorderData!.orderProductItem.product_item_id];
        let maxDiscount = 0;
        productItemSubscriptionPlans.forEach((subscriptionPlan:any) => {
            if (subscriptionPlan?.discount > maxDiscount){
                maxDiscount = subscriptionPlan.discount;
            }
        })

        return maxDiscount;
    }


    //Loading block
    if (loading) {
        return <LoadingModal />;

    //Error Block
    } else if (error) {
        return (
            <>
                <HeaderController showSteps={false} stage={"Questionnaire"} />
                <ServerError status={error} />
            </>
        );

    //Component Block
    } else {
        return (
            <>
                {
                    showSubscriptionModal ? 
                    <SubscriptionModal 
                    showModalCallback={(show:boolean)=> setShowSubscriptionModal(show)}
                    language={language}
                    discount={getMaxDiscountOfSubscriptionPlans()}
                    ftd={99}
                    />
                    :
                    null
                }
               
                <div className="page-wrapper">

                    <StickyNotification
                        showNotification={stickyNotificationDetails.show}
                        title={stickyNotificationDetails.title}
                        onClick={() => setStickyNotificationDetails((currentDetails:any) => { return {...currentDetails, show:false}})}  
                        content={stickyNotificationDetails.content}
                        level={stickyNotificationDetails.level}
                        showInfoIcon={stickyNotificationDetails.showInfoIcon}
                        refresh={stickyNotificationDetails.refresh}
                    />

                    <HeaderController language={language} stage={headerStep} />
                    {
                        reorderData &&
                        <main className="page-content-container">
                            <Outlet context={{
                                reorderData,
                                country,
                                language,
                                orderHashID: order_hash_id,
                                setLoading,
                                setStickyNotificationDetails,
                                setShowSubscriptionModal,
                                getMaxDiscountOfSubscriptionPlans,
                                setHeaderStep,
                            }}
                            />
                        </main>
                    }
                </div>
            </>
        );
    }
};

export default ReorderLayout;
