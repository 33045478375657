import { useState } from "react";
import "./BinarySelect.css";

export default function BinarySelect({
  valueLeft = "LEFT",
  valueRight = "RIGHT",
  onChange = null,
  errorMsg="",
  valuePicked="",
  locked=false,
}) {

  const [valuePickedState, setValuePickedState] = useState(valuePicked);

  if (locked){
    return (
      <div className="binarySelect-locked-container">
        <div 
          style={{borderRight: valuePicked === valueLeft || valuePicked === "" ? "1px solid var(--sub-gray)" : null}}
          className={valuePicked === valueLeft ? "binarySelect-button-locked-selected" : "binarySelect-button-locked"}>
          {valuePicked === valueLeft ?
           <img className="locked-icon" src={require("../../icons/Lock-Darkgray.svg").default} alt="lock-grey" /> 
            :
            null
          }
          <span> {valueLeft} </span>
        </div>

        <div 
          style={{borderLeft: valuePicked === valueRight ? "1px solid var(--sub-gray)":null}}
          className={valuePicked === valueRight ? "binarySelect-button-locked-selected" : "binarySelect-button-locked"}>
          {valuePicked === valueRight ?
           <img className="locked-icon" src={require("../../icons/Lock.svg").default} alt="lock-grey" /> 
           :
            null
           }
          <span> {valueRight} </span>
        </div>
      </div>
    )

  } else {

    return (
      <>
        <div className={errorMsg != "" ? "binarySelect-error-container" :"binarySelect-container"}>
          <div
            style={{borderRight: valuePicked === valueLeft || valuePicked === "" ? "1px solid var(--sub-gray)":null}}
            onClick={() => {
              setValuePickedState(valueLeft);
              onChange(valueLeft);
            }}
            className={
              valuePickedState === valueLeft ?
                "binarySelect-button-selected"
                : "binarySelect-button"
            }
          >
            <span>{valueLeft}</span>
          </div>

          <div
          style={{borderLeft: valuePicked === valueRight ? "1px solid var(--sub-gray)":null}}
            onClick={() => {
              setValuePickedState(valueRight);
              onChange(valueRight)
            }}
            className={
              valuePickedState === valueRight
                ? "binarySelect-button-selected"
                : "binarySelect-button"
            }
          >
            <span>{valueRight}</span>
          </div>
        </div>
      </>
    );

  }
}
