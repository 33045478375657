import React, { useEffect, useState } from 'react'
import "./MyAccountAsideBar.css"
import AsideBarLink from '../AsidebarLink';
import * as translator from "../../../../utils/translator";
import { clientConfig } from "../../../../config";
import { clearCustomerFromSession } from '../../../../reducers/CustomerReducer';

interface MyAccountAsideBarProps {
  activeMenuLinkId:string,
  asideBarData:any,
  myAccountData:any,
}

const MyAccountAsideBar: React.FC<MyAccountAsideBarProps> = ({activeMenuLinkId,asideBarData,myAccountData}) => {

  const [showAllLinks,setShowAllLinks] = useState<boolean>(false); //For Mobile Accordion
  const [show,setShow] = useState<boolean>(false); //state to selectively show <AsideBarLink>, passed as true by default (assumes component is available to all)
  const country = asideBarData.locale.url_prefix
  const language = asideBarData.locale.language_id

  useEffect(() => {
    if(country == 'de'){
      setShow(true)
    }
  }, [])
  
  
  const iconByMenuLinkId:any = {
    "my-orders" : {iconSrc:require("../../../../icons/Shopping-Bag-Green.svg").default,alt:"shopping-bag"},
    "orders-subscription" : {iconSrc:require("../../../../icons/Refresh.svg").default,alt:"refresh"},
    "questionnaire-defaults" : {iconSrc:require("../../../../icons/File-Plus.svg").default,alt:"file-plus"},
    "delivery-address": {iconSrc : country === "fr" ? require("../../../../icons/Map-Green.svg").default : require("../../../../icons/Delivery.svg").default,alt:"delivery"},
    "personal-data" :{iconSrc:require("../../../../icons/User-Green.svg").default,alt:"user"},
    "ask-a-doctor" : {iconSrc:require("../../../../icons/Message-Green.svg").default,alt:"message"},
    "contact" : {iconSrc:require("../../../../icons/Help.svg").default,alt:"help"},
    "newsletter" : {iconSrc:require("../../../../icons/Mail.svg").default,alt:"newsletter"},
    "change-password" : {iconSrc:require("../../../../icons/Lock-Green.svg").default,alt:"lock"},
    "logout" : {iconSrc:require("../../../../icons/Log-Out.svg").default,alt:"logout"}
  }

    return (
      <aside className="account-asidebar-container">
        <div className="asidebar-details">
          <p className="asidebar-details-name">{translator.translate(language, "my_account", 'AsideBar/name')} {asideBarData.firstName} {asideBarData.lastName}</p>
          <p className="asidebar-details-id">{translator.translate(language, "my_account", 'AsideBar/uuid')} {asideBarData.uuid}</p>


        {/*Asidebar accordion - only shown in mobile size*/}
          <div
            className="asidebar-details-accordion"
            onClick={() => setShowAllLinks((current : boolean) => !current)}
          >
            <p className="asidebar-link">
            <img
                className="asidebar-link-icon"
                src={iconByMenuLinkId[activeMenuLinkId].iconSrc} 
                alt={iconByMenuLinkId[activeMenuLinkId].alt}
              />
            {translator.translate(language, "my_account", `AsideBar/${activeMenuLinkId}`)}
            </p>

            <img 
              className={showAllLinks ? "chevron-up": "chevron-down"}
              src={require("../../../../icons/Chevron-Down-Green.svg").default} alt="chevron" 
            />
          </div> 
        {/*End of Mobile Asidebar accordion */}

        </div>

        <ul className={showAllLinks ? "asidebar-links" : "asidebar-links-closed"}>
          <AsideBarLink
            id="my-orders"
            iconSrc={iconByMenuLinkId['my-orders'].iconSrc}
            linkText={translator.translate(language, "my_account", 'AsideBar/my-orders')}
            link={`/${country}/account/my-orders`}
            alt={iconByMenuLinkId['my-orders'].alt}
            activeMenuLinkId={activeMenuLinkId}
            onClick={() => setShowAllLinks(false)}
            notificationsAmount={myAccountData.myOrders.routeNotifications}
            />
          
          <AsideBarLink
            id="orders-subscription"
            iconSrc={iconByMenuLinkId['orders-subscription'].iconSrc}
            linkText={translator.translate(language, "my_account", 'AsideBar/orders-subscription')}
            link={`/${country}/account/orders-subscription`}
            alt={iconByMenuLinkId['orders-subscription'].alt}
            activeMenuLinkId={activeMenuLinkId}
            onClick={() => setShowAllLinks(false)}
            show={show}
            showBadgeNew={true}
            />

          <AsideBarLink
            id="questionnaire-defaults"
            iconSrc={iconByMenuLinkId['questionnaire-defaults'].iconSrc}
            linkText={translator.translate(language, "my_account", 'AsideBar/questionnaire-defaults')}
            link={`/${country}/account/questionnaire-defaults`}
            alt={iconByMenuLinkId['questionnaire-defaults'].alt}
            activeMenuLinkId={activeMenuLinkId}
            onClick={() => setShowAllLinks(false)}
            show={show}
            
          />

          <AsideBarLink
            id="delivery-address"
            iconSrc={iconByMenuLinkId['delivery-address'].iconSrc}
            linkText={translator.translate(language, "my_account", 'AsideBar/delivery-address')}
            link={`/${country}/account/delivery-address`}
            alt={iconByMenuLinkId['delivery-address'].alt}
            activeMenuLinkId={activeMenuLinkId}
            onClick={() => setShowAllLinks(false)}
          />

          <AsideBarLink
            id="personal-data"
            iconSrc={iconByMenuLinkId['personal-data'].iconSrc}
            linkText={translator.translate(language, "my_account", 'AsideBar/personal-data')}
            link={`/${country}/account/personal-data`}
            alt={iconByMenuLinkId['personal-data'].alt}
            activeMenuLinkId={activeMenuLinkId}
            onClick={() => setShowAllLinks(false)}
          />

          <AsideBarLink
            id="ask-a-doctor"
            iconSrc={iconByMenuLinkId['ask-a-doctor'].iconSrc}
            linkText={translator.translate(language, "my_account", 'AsideBar/ask-a-doctor')}
            link={`/${country}/account/ask-a-doctor`}
            alt={iconByMenuLinkId['ask-a-doctor'].alt}
            activeMenuLinkId={activeMenuLinkId}
            onClick={() => setShowAllLinks(false)}
            notificationsAmount={myAccountData.askADoctor.conversations.routeNotifications}
          />

          <AsideBarLink
            id="contact"
            iconSrc={iconByMenuLinkId['contact'].iconSrc}
            linkText={translator.translate(language, "my_account", 'AsideBar/contact')}
            href={clientConfig.MAIN_WEBSITE_URL + '/' + country + '/' + translator.translate(language, "my_account", 'routes/contact')}
            alt={iconByMenuLinkId['contact'].alt}
            activeMenuLinkId={activeMenuLinkId}
            onClick={() => setShowAllLinks(false)}
          />

          <AsideBarLink
            id="newsletter"
            iconSrc={iconByMenuLinkId['newsletter'].iconSrc}
            linkText={translator.translate(language, "my_account", 'AsideBar/newsletter')}
            link={`/${country}/account/newsletter`}
            alt={iconByMenuLinkId['newsletter'].alt}
            activeMenuLinkId={activeMenuLinkId}
            onClick={() => setShowAllLinks(false)}
          />

          <AsideBarLink
            id="change-password"
            iconSrc={iconByMenuLinkId['change-password'].iconSrc}
            linkText={translator.translate(language, "my_account", 'AsideBar/change-password')}
            link={`/${country}/account/change-password`}
            alt={iconByMenuLinkId['change-password'].alt}
            activeMenuLinkId={activeMenuLinkId}
            onClick={() => setShowAllLinks(false)}
          />

          <AsideBarLink
            id="logout"
            onClick={clearCustomerFromSession}
            linkText={translator.translate(language, "my_account", 'AsideBar/logout')}
            iconSrc={iconByMenuLinkId['logout'].iconSrc}
            alt={iconByMenuLinkId['logout'].alt}
            href={clientConfig.MAIN_WEBSITE_URL + '/' + country}
            activeMenuLinkId={activeMenuLinkId}
          />

        </ul>
      </aside>
    );
};

export default MyAccountAsideBar;
