import React from "react";
import "./RadioButton.css";

export default function RadioButton({
  value,
  id,
  groupName,
  label,
  questionsValidationObject,
  errorMode,
  questionId,
  onClick,
  selected
}) {

 
  return (
    

    <>
      <input
        className={selected ? "radio-button-input-selected" : "radio-button-input"}
        type="radio"
        id={id}
        value={value}
        name={groupName}
        onChange={(event) => {
          onClick && onClick();
        }}
      />

      <label
        className={
          errorMode ? "radio-button-error-container" : "radio-button-container"
        }
        htmlFor={id}
        id={`label_${id}`}
      >
        <span className="radio-button-text">{label} </span>
      </label>
    </>
  );
}
