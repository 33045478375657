

export const deRegistrationForm = {
    first_name : "",
    last_name : "",
    email : "",
    password : "",
    repeat_password : "",
    gender : "",
    marketing_emails_enabled : "1",
    category : "",
    year:"",
    month:"",
    day:"",

}

export const frRegistrationForm = {
    first_name : "",
    last_name : "",
    email : "",
    password : "",
    repeat_password : "",
    gender : "",
    category : "",
    marketing_emails_enabled : "1",
    agree_to_terms : "",
    year:"",
    month:"",
    day:"",
}