import React,{useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import useForm from "../../../../customHooks/useForm";
import MyAccountContentHeader from "../MyAccountContentHeader/MyAccountContentHeader";
import "./MyAccountChangePassword.css";
import * as translator from '../../../../utils/translator';
import * as http from "../../../../utils/http";
import InputField from "../../../../components/CredentialsInput/InputField";

const MyAccountChangePassword = () => {
  const {setActiveMenuLinkId , setStickyNotificationDetails ,myAccountData} = useOutletContext<any>();
  const language = myAccountData.locale.language_id 


  let changePasswordFormAttributes = useForm({
    old_password:"",
    password : "",
    repeat_password : "",
  },language)

  useEffect(() => {
    setActiveMenuLinkId("change-password");
  },[]);

  const onSubmit = () => {
    if(changePasswordFormAttributes.validateForm()){
      http
      .postToServer(`account-update/password`,
      {
        oldPassword:changePasswordFormAttributes.form.old_password,
        newPassword:changePasswordFormAttributes.form.password
      },
      [http.MIDDLEWARES.CUSTOMER_ID]
      )
      .then((response) => {
       setStickyNotificationDetails((currentDetails : any) => ({
          show: true,
          title: translator.translate(language, "my_account", `stickyNotifications/success/ChangePassword/${response.data.message}`),
          content: "",
          level : response.data.message,
          showInfoIcon:true,
          refresh : !currentDetails.refresh
        }))
      })
      .catch((error) => {
        setStickyNotificationDetails((currentDetails : any) => ({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
          level : 'error',
          showInfoIcon:false,
          refresh : !currentDetails.refresh
        }))
      })
      .finally(()=> {
      })
    }
  }

  return(

    <>
      <div className="account-content">
        <MyAccountContentHeader
          title={translator.translate(language, "my_account", `ChangePassword/title`)}
          description={translator.translate(language, "my_account", `ChangePassword/subTitle`)}
        />

        <div className="change-password-form">
          <div className="inputs-container">
            <InputField
                id="change-password-old-password"
                value={changePasswordFormAttributes.form.old_password}
                errorMsg={changePasswordFormAttributes.errorMessagePerField.old_password}
                type="password"
                placeholder={translator.translate(language, "my_account", `ChangePassword/oldPassword`)}
                mandatory
                onChange={(inputValue:string) =>
                  changePasswordFormAttributes.updateForm("old_password", inputValue)
                }
                language={language}
                onBlur={() => changePasswordFormAttributes.onFieldBlur("old_password")}
                touched={changePasswordFormAttributes.touched.password}
              />


              <InputField
                id="change-password"
                value={changePasswordFormAttributes.form.password}
                errorMsg={changePasswordFormAttributes.errorMessagePerField.password}
                type="password"
                placeholder={translator.translate(language, "my_account", `ChangePassword/newPassword`)}
                onChange={(inputValue:string) =>
                  changePasswordFormAttributes.updateForm("password", inputValue)
                }
                language={language}
                mandatory
                onBlur={() => changePasswordFormAttributes.onFieldBlur("password")}
                touched={changePasswordFormAttributes.touched.password}
              />

              <InputField
                id="change-password-repeat-password"
                value={changePasswordFormAttributes.form.repeat_password}
                errorMsg={changePasswordFormAttributes.errorMessagePerField.repeat_password}
                type="password"
                placeholder={translator.translate(language, "my_account", `ChangePassword/newPasswordVerification`)}
                onChange={(inputValue:string) =>
                  changePasswordFormAttributes.updateForm("repeat_password", inputValue)
                }
                language={language}
                mandatory
                onBlur={() => changePasswordFormAttributes.onFieldBlur("repeat_password")}
                touched={changePasswordFormAttributes.touched.repeat_password}
              />
          </div>

          <SubmitButton
            id={"change-password-submit"}
            placeholder={translator.translate(language, "my_account", `ChangePassword/submit`)}
            onSubmit={onSubmit}
          />
        </div> 
      
      </div>
    </>
  )
};

export default MyAccountChangePassword;
