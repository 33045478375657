import React from 'react'
import "./AddressValidationModal.css";
import * as translator from "../../utils/translator";
import { MainTracker } from '../../PixelTrackers/MainTracker';


const AddressValidationModal = ({
    language,
    suggestionType,
    onSubmitParams,
    onSubmit,
    setShowAddressValidation,
    setSubmitButtonLoading,
    suggestedAddress
}) => {

    let translations

    if (suggestionType === 'invalid') {
        translations = {
            title: "AddressValidationModal/title-invalid",
            text: "AddressValidationModal/text-invalid",
            buttonChange: "AddressValidationModal/button-change-invalid",
            buttonKeep: "AddressValidationModal/button-keep-invalid",
        }
    } else {
        translations = {
            title: "AddressValidationModal/title-suspected",
            text: "AddressValidationModal/text-suspected",
            buttonChange: "AddressValidationModal/button-change-suspected",
            buttonKeep: "AddressValidationModal/button-keep-suspected",
        }
    }

    MainTracker.track('pv', 'View Pop Up', {'Pop Up Name':translator.translate('en', "default", translations.title)})

    return (
        <>
        <div className="popup-overlay">
            <div className="addressValidationModal white">
                <div id='addressValidationModal-close-button' onClick={() => { 
                setShowAddressValidation('');
                setSubmitButtonLoading(false); 
                if (suggestionType === 'invalid') {
                    MainTracker.track('custom', 'Decision on Invalid address', {'Answer': 'Close'}) 
                } else {
                    MainTracker.track('custom', 'Decision on Suspect address', {'Answer': 'Close'}) 
                }
                }}>✖</div>
                <div className="agreement-info">
                    <div className="title">{translator.translate(language, "default", translations.title)}</div>
                    {translator.translate(language, "default", translations.text)}
                </div>
                <div id="suggested-address-container">
                    <p id="suggested-address">{suggestedAddress.formattedaddress}</p>
                </div>
                <div className="btn-container-addressValidationModal">
                    <div>
                        <button id="address_validation_button_keep" className="btn-addressValidationModal" onClick={() => {
                            if (suggestionType === 'invalid') {
                                MainTracker.track('custom', 'Decision on Invalid address', {'Answer': translator.translate('en', "default", translations.buttonKeep)}) 
                                setShowAddressValidation('hide')
                                onSubmit(onSubmitParams.paymentTypeID, onSubmitParams.paymentType,  onSubmitParams.shippingMethodID)
                            } else {
                                MainTracker.track('custom', 'Decision on Suspect address', {'Answer': translator.translate('en', "default", translations.buttonKeep)})
                                setShowAddressValidation('hide')
                                onSubmit(onSubmitParams.paymentTypeID, onSubmitParams.paymentType , onSubmitParams.shippingMethodID)
                            }
                        }} type="button">{translator.translate(language, "default", translations.buttonKeep)}</button>
                    </div>
                    <div>
                        <button id="address_validation_button_change" className="btn-addressValidationModal" onClick={() => {
                            if (suggestionType === 'invalid') {
                                MainTracker.track('custom', 'Decision on Invalid address', {'Answer': translator.translate('en', "default", translations.buttonChange)}) 
                                setSubmitButtonLoading(false)
                                setShowAddressValidation('')
                            } else {
                                MainTracker.track('custom', 'Decision on Suspect address', {'Answer': translator.translate('en', "default", translations.buttonChange)})
                                setShowAddressValidation('hide')
                                onSubmit(onSubmitParams.paymentTypeID, onSubmitParams.paymentType, onSubmitParams.shippingMethodID, suggestedAddress)
                            }
                        }} type="button">{translator.translate(language, "default", translations.buttonChange)}</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default AddressValidationModal