import RadioSelect from "../../../../components/RadioSelect/RadioSelect";
import * as translator from "../../../../utils/translator";
import ChooseTreatmentLandingPageDrugInfo from "../ChooseTreatmentLandingPageDrugInfo/ChooseTreatmentLandingPageDrugInfo";
import DrugSelectionSidePanel from "../DrugSelectionSidePanel/DrugSelectionSidePanel";

const DrugDisplayCard = (props) => {
  let landingPage = props.landingPage;
  const formattedNumber = translator.formatNumberByLocale(props.minPrices[props.drugName], props.language.toLowerCase() + '-' + props.country.toUpperCase());


  return (
    <>
      <div
        style={{ width: props.width }}
        className={
          props.selectedDrugID === props.index
            ? "single-item-container-selected"
            : "single-item-container"
        }
      >
        <li
          style={{ width: props.width, height: props.height }}
          className={
            props.selectedDrugID === props.index
              ? "product-item-selected"
              : "product-item" + (props.isDisabled ? " out-of-stock" : "")
          }
          onClick={
            props.isDisabled
              ? () => {}
              : () => {
                  if (props.index !== props.selectedDrugID) {
                    props.setSelectedDrugID(props.index);
                    props.changeDrugCallback(props.drugName);
                  }
                }
          }
        >
          <RadioSelect selected={props.index === props.selectedDrugID} />

          <div className="product-name-and-price">

          <span className="product-item-text">
            <span>{props.drugName}</span>
          </span>

          <span className="product-item-from-price">
            {props.showPrice ? (
              <>
                <div className="from-text">
                  {translator.translate(
                    props.language,
                    "default",
                    "DrugInfoPanel/from"
                  )}{" "}
                </div>

                <div className="from-price">
                  
                  {formattedNumber + " " + props.currencySign}
                </div>
              </>
            ) : null}
          </span>

          </div>

        </li>
        <div
          className={
            props.selectedDrugID === props.index
              ? "test-panel-selected"
              : "test-panel"
          }
        >
          {!landingPage ? (
            <DrugSelectionSidePanel
              currencySign={props.currencySign}
              navigationCallback={props.navigationCallback}
              selectedDosage={props.selectedDosage}
              currentSelectedProductItemIndex={
                props.currentSelectedProductItemIndex
              }
              changeCurrentlySelectedProductItemIndex={
                props.changeCurrentlySelectedProductItemIndex
              }
              productItemSubscriptionPlans={props.productItemSubscriptionPlans}
              pricesLogistics={props.pricesLogistics}
              language={props.language}
              drugFullNames={props.drugFullNames}
              hideOnResize={props.hideOnResize}
              products={props.products}
              listOfPackets={props.listOfPackets}
              selectedDrug={props.selectedDrug}
              defaultDosages={props.defaultDosages}
              listOfDosages={props.listOfDosages}
              changeDosage={props.changeDosage}
              changePacketsList={props.changePacketsList}
            />
          ) : (
            <ChooseTreatmentLandingPageDrugInfo
              products={props.products}
              navigationCallback={props.navigationCallback}
              currentSelectedProductItemIndex={
                props.currentSelectedProductItemIndex
              }
              changeCurrentlySelectedProductItemIndex={
                props.changeCurrentlySelectedProductItemIndex
              }
              productItemSubscriptionPlans={props.productItemSubscriptionPlans}
              pricesLogistics={props.pricesLogistics}
              language={props.language}
              minPrices={props.minPrices}
              hideable={props.hideable}
              drugDescription={props.drugDescription}
              drugImagePath={props.drugImagePath}
              selecetedDrugState={props.selecetedDrugState}
              listOfPackets={props.listOfPackets}
              changePacketsList={props.changePacketsList}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DrugDisplayCard;
