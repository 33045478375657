import React,{useState,useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import Select from "../../../../components/Select/Select";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import useForm from "../../../../customHooks/useForm";
import DeliveryAddressPackstationForm from "../DeliveryAddressPackstationForm";
import DeliveryAddressPersonalAddressForm from "../DeliveryAddressPersonalAddressForm";
import MyAccountContentHeader from "../MyAccountContentHeader/MyAccountContentHeader";
import "./MyAccountDeliveryAddress.css";
import * as http from "../../../../utils/http";
import * as translator from '../../../../utils/translator';
import InnerHTMLRenderer from "../../../../components/InnerHTMLRenderer/InnerHTMLRenderer";

const PERSONAL_OPTION_INDEX = 0;
const PACKSTATION_OPTION_INDEX = 1;

const MyAccountDeliveryAddress = () => {
  const {setActiveMenuLinkId ,setStickyNotificationDetails ,setRefreshData ,myAccountData,country} = useOutletContext<any>();
  const language = myAccountData.locale.language_id
  const formsOptions = [{value: translator.translate(language, "my_account", `DeliveryAddress/personal`),id:0},{value:translator.translate(language, "my_account", `DeliveryAddress/packstation`),id:1}]
  const [selectedFormIndex,setSelectedFormIndex] = useState<number>(PERSONAL_OPTION_INDEX);
  const address = myAccountData.deliveryAddress;
  const [destinationCountry, setDestinationCountry] = useState(address['regular']?.country_id.toLowerCase() || language)

  const personalAddressAttributes = useForm({
    first_name: address['regular']?.first_name || address['any'].first_name,
    last_name: address['regular']?.last_name || address['any'].last_name,
    zip: address['regular']?.zip,
    city: address['regular']?.city,
    address: address['regular']?.address,
    company: address['regular']?.company,
    phone: address['regular']?.phone,
    country: address['regular']?.country_id
  },language, destinationCountry)

  const packstationAttributes = useForm({
    packstation_personal_first_name: address['packstation']?.first_name || address['any'].first_name,
    packstation_personal_last_name: address['packstation']?.last_name || address['any'].last_name,
    packstation_number: address['packstation']?.packstation_number,
    packstation_address: address['packstation']?.packstation_address,
    packstation_zip: address['packstation']?.zip,
    packstation_city: address['packstation']?.city,
    packstation_personal_phone: address['packstation']?.phone,
    packstation_country: address['packstation']?.country_id
  },language, destinationCountry)
    
  useEffect(() => {
    setActiveMenuLinkId("delivery-address");
  },[]);
  
  const post = (postData:{}) =>{
    http
    .postToServer(`account-update/delivery-address`,
    {...postData, country:myAccountData.locale.country_id},
    [http.MIDDLEWARES.CUSTOMER_ID]
    )
    .then((response) => {
      setRefreshData((current:boolean) => !current)
      setStickyNotificationDetails((currentDetails:any)=>({
        show: true,
        title: "",
        content: translator.translate(language, "my_account", `stickyNotifications/success/deliveryAddress`),
        level : "info",
        showInfoIcon:false,
        refresh : !currentDetails.refresh
      }))
    })
    .catch((error) => {
      setStickyNotificationDetails((currentDetails:any)=>({
        show: true,
        title: "Oops",
        content: translator.translate(language, "my_account", `stickyNotifications/error/deliveryAddress`),
        level : "error",
        showInfoIcon:false,
        refresh : !currentDetails.refresh
      }))
    })
    .finally(()=> {
    })
  }

  const onSubmit = () => {
    switch(selectedFormIndex){
      case PERSONAL_OPTION_INDEX:
        if(personalAddressAttributes.validateForm()){
          const postData = {regular: personalAddressAttributes.form}
          post(postData)
        }
      break;

      case PACKSTATION_OPTION_INDEX:
        if(packstationAttributes.validateForm()){
          const postData = {packstation: packstationAttributes.form}
          post(postData)
        }
        
    }
  }
  return (
    <>
      <div className="account-content">
        <MyAccountContentHeader
          title={translator.translate(language, "my_account", `DeliveryAddress/title`)}
          description={translator.translate(language, "my_account", `DeliveryAddress/subTitle`)}
        />

        <div className="delivery-address-forms-container">
          {country == 'de' ? //select packstation address only available in de
            <Select //this select is an old component and should be replaced with something better,but it works fine.
              id={"delivery-address-form-select"}
              options={formsOptions}
              placeholder={formsOptions[selectedFormIndex].value}
              onChangeCallback={(optionObject)=>setSelectedFormIndex(optionObject.index)} //0 for personal - 1 for packstation
              questionnaireValidationObject={{}} //passing an empty object because this prop is mandatory for some reason
              errorMode={""}
              style={{
                border: "1px solid #dbe1eb",
                height: "56px",
                width: "100%",
                borderRadius: "6px",
                backgroundColor: "transparent",
                margin: "0px"
                }
              }
              includeShortDescription
            />
            :
            null
          }

          {
          selectedFormIndex === PERSONAL_OPTION_INDEX ?
            <DeliveryAddressPersonalAddressForm
              personalAddressAttributes={personalAddressAttributes}
              setDestinationCountry={setDestinationCountry}
            />
            :
            <DeliveryAddressPackstationForm
              packstationAttributes={packstationAttributes} 
              setDestinationCountry={setDestinationCountry}
            />
          }

          <SubmitButton 
            onSubmit={onSubmit}
            id={"delivery-address-submit"}
            placeholder={translator.translate(language, "my_account", `DeliveryAddress/submit`)} 
            isEnabledState
           />
        </div>
        {country == 'fr' &&
        <div className="delivery-address-disclaimer-container">
        <InnerHTMLRenderer htmlContent={translator.translate(language, "my_account", `DeliveryAddress/disclaimer`)} />
        </div>
        }
      </div>
    </>
  );
};

export default MyAccountDeliveryAddress;



