import { MainTracker } from "../../../../PixelTrackers/MainTracker";
import "./OneTimePurchaseCard.css";
import { AiOutlineCheck } from "react-icons/ai";

const formatDescription = (unformattedDescription, name, units) => {
  let descriptionFragments = unformattedDescription.split(
    ":product_name :product_units"
  );
  return (
    descriptionFragments[0] +
    " " +
    name +
    " " +
    units +
    " " +
    descriptionFragments[1]
  );
};

const OneTimePurchaseCard = (props) => {
  return (
    <div
      className={
        (props.index === props.selectedPlanIndex
          ? "Subscription-plan-card-selected"
          : "Subscription-plan-card") +
        (props.showDeliveryErrorMessage ? " error-card" : "")
      }
      onClick={() => {
        MainTracker.track(
          "custom",
          "Choose One Time Purchase On Choose Treatment Page"
        );
        props.setSelectedPlanIndex(props.index);
        props.setSelectedSubscriptionPlanID('one-time');
        props.setShowDeliveryMessage(false);
      }}
    >
      <div className="title-plan-container">
        <div
          className={
            (props.index === props.selectedPlanIndex
              ? "title-plan-radio-selected"
              : "title-plan-radio") +
            (props.showDeliveryErrorMessage ? " error-title-plan-radio" : "")
          }
        ></div>
        <h2
          className={
            "title-plan" +
            (props.showDeliveryErrorMessage ? " error-title-plan" : "")
          }
        >
          Einmalkauf
        </h2>
      </div>

      <p className="plan-description">
        {formatDescription(
          "Erhalten Sie einmalig :product_name :product_units zum vollen Preis.",
          props.productName,
          props.productQuantity
        )}
      </p>


      
      {props.selectedPackItemObject?.subscription_ftd ? (
        <div className="saved-cost-description-container">
          <AiOutlineCheck className="check-cost" />
          <p className="saved-cost-description">
            Sparen Sie{" "}
            <span className="saved">
              {props.selectedPackItemObject.subscription_ftd}€
            </span>{" "}
            bei dieser Bestellung
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default OneTimePurchaseCard;
