import React,{useEffect, useState} from "react";
import { useParams, useOutletContext } from 'react-router-dom';
import SubscriptionDeliveryAddressForm from "../SubscriptionDeliveryAddressForms/SubscriptionDeliveryAddressForms";
import SubscriptionRenewQuestionnaire from "../SubscriptionRenewQuestionnaire/SubscriptionRenewQuestionnaire";
import "./EditableInfoCard.css";
import "../SubscriptionRenewQuestionnaire/SubscriptionRenewQuestionnaire.css";
import * as translator from '../../../../utils/translator';
import MyAccountOrderPrescriptionQuestion from "../MyAccountOrderPrescriptionQuestion/MyAccountOrderPrescriptionQuestion";

interface EditableInfoCardProps {
  title: string;
  value: string;
  editSection: "treatment-questionnaire" | "delivery-address" | "treatment-questionnaire-edit";
  language:string
}

const EditableInfoCard: React.FC<EditableInfoCardProps> = ({
  title,
  value,
  editSection,
  language
}) => {

  const {setRefreshData ,myAccountData,country,setCurrentGlobalModalName} = useOutletContext<any>();
  const [showEditSection,setShowEditSection] = useState(false); //the state the shows the edit panel after clicking on administer
  const [hidePastQuestionnaire, setHidePastQuestionnaire] = useState<boolean>(false)
  const [showUpdateQuestionnaire, setShowUpdateQuestionnaire] = useState<boolean>(false)
  const [invokeOnSubmit, setInvokeOnSubmit] = useState(false)
  const {order_hash_id} = useParams();
  
  useEffect(() => {
    const isAutoShowSegment = window.location.hash;
    if(editSection == "treatment-questionnaire"){ 
      switch (isAutoShowSegment) {
        case '#renew_questionnaire':
          setShowUpdateQuestionnaire(true)
        break;
        case '#show_previous_questionnaire':
          setHidePastQuestionnaire(false)
        break;
      }
      isAutoShowSegment && scrollToView(".renew-questionnaire-container")
    }
  }, [showEditSection])

  const scrollToView = (scrollTo:string) =>{
    setShowEditSection(true)
    const segment = document.querySelector(scrollTo);
    if (segment) {
      setTimeout(function() {
        segment.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
      }, 300);
    }
  }

  const getEditSectionAccordionStyle = () => {
    
    return {
      maxHeight: showEditSection ? "1000vh" : "0",
      transition: "max-height 0.7s ease",
      overflow: "hidden",
    };
  };
  if(order_hash_id){
    const subscriptionData = myAccountData.mySubscriptions.subscriptionData[order_hash_id];
    const sectionButtonTitle = subscriptionData.isQuestionnaireExpired ? 'treatment-questionnaire-edit' : editSection

    return (
      <div className="editable-info-card-body-container">
        <div className="editable-info-card">
          <div className="editable-info-card-text-container">
            <span className="editable-info-card-title">{title}</span>
            <span className="editable-info-card-value">{value}</span>
          </div>
          <span onClick={()=>setShowEditSection(current => !current)} className="administer">{translator.translate(language, "my_account", `SubscriptionOrders/infoCards/${sectionButtonTitle}`)}</span>
        </div>
        
        <div
          className="edit-section-accordion"
          style={getEditSectionAccordionStyle()}
        >
        {
          editSection === "treatment-questionnaire" ? 
          <>
            {
              (subscriptionData.isQuestionnaireExpired) && <SubscriptionRenewQuestionnaire
              setHidePastQuestionnaire={setHidePastQuestionnaire}
              setShowUpdateQuestionnaire={setShowUpdateQuestionnaire}
              showUpdateQuestionnaire={showUpdateQuestionnaire}
              setRefreshData={setRefreshData}
              setCurrentGlobalModalName={setCurrentGlobalModalName} 
              allSubscriptionData={myAccountData.mySubscriptions.subscriptionData}
              language={language}
              country={country}
              orientForSpecificComponent={''}
              invokeOnSubmit={invokeOnSubmit}
              setInvokeOnSubmit={setInvokeOnSubmit}
              />
            }
            {
              hidePastQuestionnaire == false &&
              <div className="renew-questionnaire-subscription-prescription">
                  {
                    subscriptionData.prescription.map((questionAnswer:any, index:number) => (
                      <MyAccountOrderPrescriptionQuestion
                        key={index}
                        questionHtmlContent={questionAnswer.question}
                        answerText={questionAnswer.answer}
                        questionType={questionAnswer.question_type}
                      />
                    ))
                  }
                  {
                  (subscriptionData.isQuestionnaireExpired) &&
                  <div className="renew-questionnaire-subscription-prescription-buttons">
                    <div className="renew-questionnaire-button" onClick={()=> {setHidePastQuestionnaire(true); setShowUpdateQuestionnaire(true);}}>Gesundheitszustand hat sich geändert - Daten aktualisieren</div>
                    <div className="renew-questionnaire-button" onClick={()=>{setInvokeOnSubmit(true)}}>Gesundheitszustand hat sich nicht geändert</div>
                  </div>
                  }
              </div>
            }
          </>
          :
            <SubscriptionDeliveryAddressForm />
        }
        </div>

      </div>
    );
  } else {
    return(<></>)
  }
};

export default EditableInfoCard;
