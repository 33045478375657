import React from 'react'
import { useParams } from 'react-router-dom';
import { translate } from '../utils/translator';
import OrderWindowSummary from './CartViewSummary';
import { useCartContext } from '../customHooks/useCart';
import CartViewProductRow from './CartViewProductRow';

type Props = {
  className?: string
}

const CartView: React.FC<Props> = ({ className }) => {

  const { language } = useParams()
  const { getCart } = useCartContext();
  const cart = getCart();

  return (
    <div id='cart-view-container' className={`flex flex-col bg-white px-8 pt-8 pb-6 rounded-[6px] h-fit ${className} `}>

      {/* Product rows  */}
      <div id='cart-view-products-container' className='flex flex-col'>
        {
          cart.products.map((productItem) =>
            <CartViewProductRow
              key={productItem.product_item_id}
              productItem={productItem}
              className='py-3'
            />
          )
        }

        {/* Images disclaimer */}
        { cart.flow !== "cannaleo_pre_selected" ?
          <p 
            id='cart-view-images-disclaimer' 
            className='text-[10px] mt-4 text font-roboto text-center text-[#98a0af]'>{translate(language, "default", "OrderWindow/image_disclaimer")}</p>
          :
          null
        }
      </div>

      {/* Calculations Summary */}
      <OrderWindowSummary />

    </div>
  )
}

export default CartView