import React from "react";

type MenuLink = {
  text: string;
  url: string;
};

interface HeaderCMSLinksProps {
  menuLinks: Array<MenuLink>;
}

const HeaderCMSLinks: React.FC<HeaderCMSLinksProps> = ({ menuLinks }) => {
  return (
    <div>
      <ul className="cms-header-links">
        {menuLinks.map((menuLink: MenuLink,index:number) => (
          <li key={index}>
            <a href={menuLink.url}>{menuLink.text}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderCMSLinks;
