import React, { useContext, useState } from "react";
import "./DrugSelectionSidePanel.css";
import DrugDosagesPanel from "../DrugDosagesPanel/DrugDosagesPanel";
import DrugPacksChoicePanel from "../DrugPacksChoicePanel/DrugPacksChoicePanel";
import * as translator from "../../../../utils/translator";
import * as http from "../../../../utils/http";
import { useParams } from "react-router-dom";
import { StickyNotificationContext } from "../../ChooseTreatment/ChooseTreatment";
import { loadCustomerFromSession } from "../../../../reducers/CustomerReducer";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import NoPreferenceTreatmentInfo from "../../../../components/NoPreferenceTreatmentInfo/NoPreferenceTreatmentInfo";
import { CatalogSettingContext } from "../../ChooseTreatmentRouter";
import {submitOverrideContext} from "../../../../components/Modals/MyAccountChangeSubscriptionMedicationModal/MyAccountChangeSubscriptionMedicationModal";

const SUBS_DISCOUNT_FTD = "subs_discount_ftd";

const MAX_CHARS_FOR_SHORT_DOSAGE_STRING = 7;

const DrugSelectionSidePanel = ({
  currencySign,
  imageUrlSuffix = null,
  navigationCallback = null,
  currentSelectedProductItemIndex,
  changeCurrentlySelectedProductItemIndex,
  productItemSubscriptionPlans,
  pricesLogistics,
  selectedDosage: selectedDosageString,
  language,
  drugFullNames,
  hideOnResize,
  products,
  selectedDrug: selectedDrugTitle,
  defaultDosages: defaultDosagesByDrugTitle,
  listOfDosages,
  changeDosage,
  listOfPackets,
  changePacketsList,
  noPreference = false,
}) => {
  const triggerStickyNotification = useContext(StickyNotificationContext);
  const { country, category } = useParams();
  const CatalogSettings = useContext(CatalogSettingContext);

  const [selectedSubscriptionPlanID, setSelectedSubscriptionPlanID] =
    useState(null);

  const [showDeliveryErrorMessage, setShowDeliveryMessage] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  function isDosageStringLongLine() {
    let useColLayout = false;

    listOfDosages.forEach((dosage) => {
      if (dosage.length > MAX_CHARS_FOR_SHORT_DOSAGE_STRING) {
        useColLayout = true;
      }
    });

    return useColLayout;
  }

  const getIndexOfSelectedPlan = (productItemID) => {
    let index = null;

    if (productItemSubscriptionPlans[String(productItemID)]) {
      productItemSubscriptionPlans[String(productItemID)].forEach(
        (plan, planIndex) => {
          if (plan.product_plan_id === selectedSubscriptionPlanID) {
            index = planIndex;
          }
        }
      );
    }

    return index;
  };

  let selectedTreatmentData;

  function getSubscriptionDiscount(productItemID, indexOfSelectedPlan) {
    let productItemPlan = productItemSubscriptionPlans[String(productItemID)];

    let oneTimeFTD =
      products[selectedDrugTitle][selectedDosageString][
        currentSelectedProductItemIndex
      ].subscription_ftd;

    if (selectedSubscriptionPlanID === "one-time") {
      return oneTimeFTD ? oneTimeFTD : 0;
    }

    if (
      productItemPlan &&
      selectedSubscriptionPlanID !== "none" &&
      productItemPlan[indexOfSelectedPlan]
    ) {
      if (CatalogSettings.subscription_discount_logic === SUBS_DISCOUNT_FTD) {
        return productItemPlan[indexOfSelectedPlan].first_time_discount;
      }

      return productItemPlan[indexOfSelectedPlan].discount;
    } else {
      return 0;
    }
  }

  if (
    selectedDrugTitle !== "no-preference" &&
    currentSelectedProductItemIndex !== null &&
    products[selectedDrugTitle] &&
    products[selectedDrugTitle][selectedDosageString] &&
    products[selectedDrugTitle][selectedDosageString][
      currentSelectedProductItemIndex
    ]
  ) {
    let productItem =
      products[selectedDrugTitle][selectedDosageString][
        currentSelectedProductItemIndex
      ];

    let productItemID = productItem.product_items_id;

    let indexOfSelectedPlan = getIndexOfSelectedPlan(productItemID);

    selectedTreatmentData = {
      product_item_id: productItemID,
      item_price: productItem.price,
      subscription_plan_id:
        selectedSubscriptionPlanID && selectedSubscriptionPlanID !== "one-time"
          ? selectedSubscriptionPlanID
          : null,
      treatment_fee: productItem.treatment_fee,
      catalog_id: productItem.catalog_id,
      applied_subscription_discount: getSubscriptionDiscount(
        productItemID,
        indexOfSelectedPlan
      ),
      item_confirmed: 1,
      product_in_stock : productItem.in_stock,
      is_sftd: selectedSubscriptionPlanID === "one-time" ? 1 : 0,
    };

    // if (country.toUpperCase() === "FR") {
    //   selectedTreatmentData.item_price = 0;
    // }
  } else {
    selectedTreatmentData = null;
  }

  const { postChangeSubscriptionMediaction, chooseTreatmentButtonText } = useContext(submitOverrideContext); // overrides for change subscription medication

  const overrideOnSubmit = () =>{
    if (!selectedTreatmentData) {
      triggerStickyNotification(
        translator.translate(
          language,
          "default",
          "StickyNotification/danger_title"
        ),
        ""
      )
      return;
    }
    if(postChangeSubscriptionMediaction){
      postChangeSubscriptionMediaction(selectedTreatmentData)
    }else{
      onSubmit()
    }
  }

  const onSubmit = () => {
    //if user must select something
    if (selectedSubscriptionPlanID === "none") {
      setShowDeliveryMessage(true);
    } else {
      setSubmitButtonLoading(true);
      http
        .postToServer(
          `products/${country}/${language}/${category}/false`,
          selectedTreatmentData,
          [http.MIDDLEWARES.CART_ORDER]
        )
        .then((res) => {
          let customerFromSession = loadCustomerFromSession();
          if (!customerFromSession) {
            // if no customer id send to registration
            navigationCallback(`/sign_up/${country}/${language}`);
          } else {
            navigationCallback(`/shipping/${country}/${language}`);
          }
        })
        .catch((err) => {
          triggerStickyNotification(
            translator.translate(
              language,
              "default",
              "StickyNotification/danger_title"
            ),
            "Server Failed"
          );
          window.clientLogger.error(
            "error post data in DrugSelectionSidePanel - with subscription",
            err
          );
        })
        .finally(() => {
          setSubmitButtonLoading(false);
        });
    }
  };

  return (
    <div
      className={
        hideOnResize
          ? "dosages-and-variants-section hideable"
          : "dosages-and-variants-section openable"
      }
    >
      {!noPreference ? (
        <>
          <h1 className="mini-title">
            {drugFullNames[selectedDrugTitle]
              ? drugFullNames[selectedDrugTitle]
              : selectedDrugTitle}
          </h1>

          <DrugDosagesPanel
            selectedDosage={selectedDosageString}
            defaultDosages={defaultDosagesByDrugTitle}
            products={products}
            selectedDrug={selectedDrugTitle}
            listOfProductDosages={listOfDosages}
            changeDosageCallback={changeDosage}
            changePacketsListCallback={changePacketsList}
            language={language}
            colLayout={
              language.toUpperCase() === "FR" || isDosageStringLongLine()
                ? true
                : false
            }
          />

          <div className="packets-container">
            <p className="orderWindow-container-title">
              {translator.translate(
                language,
                "default",
                "DrugSelectionSidePanel/choose-pack"
              )}
            </p>

            <DrugPacksChoicePanel
              language={language}
              currencySign={currencySign}
              selectedSubscriptionPlanID={selectedSubscriptionPlanID}
              setShowDeliveryMessage={setShowDeliveryMessage}
              setSelectedSubscriptionPlanID={setSelectedSubscriptionPlanID}
              showDeliveryErrorMessage={showDeliveryErrorMessage}
              currentSelectedProductItemIndex={currentSelectedProductItemIndex}
              changeCurrentlySelectedProductItemIndex={
                changeCurrentlySelectedProductItemIndex
              }
              productItemSubscriptionPlans={productItemSubscriptionPlans}
              pricesLogistics={pricesLogistics}
              listOfPackets={listOfPackets}
              changePacketsListCallback={changePacketsList}
              colLayout={
                language.toUpperCase() === "FR" || isDosageStringLongLine()
                  ? true
                  : false
              }
            />

            <SubmitButton
              id={"submit-button"}
              loading={submitButtonLoading}
              showArrow
              onSubmit={overrideOnSubmit}
              margin={"0"}
              padding={"15px 0px"}
              width={"100%"}
              language={language}
              placeholder={
                chooseTreatmentButtonText ? //override button text from change subscription medication modal
                chooseTreatmentButtonText 
                :
                selectedTreatmentData?.product_in_stock ? 
                translator.translate(language, "default", "DrugSelectionSidePanel/button-text")
                : 
                translator.translate(language, "default", "DrugSelectionSidePanel/out-of-stock-button-text")}
            />
          </div>
        </>
      ) : (
        <NoPreferenceTreatmentInfo />
      )}
    </div>
  );
};

export default DrugSelectionSidePanel;