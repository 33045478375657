import React, { useContext } from "react";
import "./OTCAddonsModal.css";
import OTCProductRow from "../../OTCProductRow/OTCProductRow";
import X from "../../../icons/X.svg";
import { ShippingDataContext } from "../../../pages/Shipping/Shipping";
import { getLanguageByCountry, translate } from "../../../utils/translator";

type OTCAddonsModalProps = {
  productItems: Array<ProductItem>;
  title: string;
  OTCAddonsAttributes: OTCAddonsAttributes; //from useOTCAddons
  countryId:string
};

const OTCAddonsModal: React.FC<OTCAddonsModalProps> = ({
  productItems,
  title,
  OTCAddonsAttributes,
  countryId
}) => {

  const shippingData = useContext(ShippingDataContext);

  return (
    <div
      onClick={(event: any) => event.stopPropagation()}
      className="otc-addons-modal-container"
    >
      <img src={X} alt="X" onClick={() => OTCAddonsAttributes.hideModal()} />
      <h1>{title}</h1>

      <div className="otc-addons-modal-product-rows">
        {productItems.map((productItem: ProductItem) => {
          return (
            <OTCProductRow
              key={productItem.id}
              OTCAddonsAttributes={OTCAddonsAttributes}
              productItem={productItem}
              countryId={countryId}
              currencyRate={shippingData.currencyRates[shippingData.destinationCountry].rate}
              currencySign={shippingData.currencySign}
              showBin={true}
            />
          );
        })}
      </div>

      <button onClick={() => OTCAddonsAttributes.hideModal()} className="otc-addons-modal-submit">
          
          {OTCAddonsAttributes.totalPickedQuantity > 0 ?
            translate(getLanguageByCountry(countryId), "shipping", "OTCAddonsModal/add_to_order")
            :
            translate(getLanguageByCountry(countryId), "shipping", "OTCAddonsModal/close_and_proceed")
          }
      </button>

     
    </div>
  );
};

export default OTCAddonsModal;
