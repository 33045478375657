import InputField from "../../../../components/CredentialsInput/InputField";
import * as translator from "../../../../utils/translator";
import { useContext } from "react";
import { ShippingDataContext } from "../../Shipping";
import { BsTelephone } from "react-icons/bs";
import GoogleApiInputField from "../../../../components/CredentialsInput/GoogleApiInputField";
import Select from "../../../../components/Select/Select";

const ADDRESS_COMPONENT_INDEX = {
  street_and_number: 0,
  city: 1,
  country : 2
}
const ADDRESS_FIELD_TYPE = {
  street_and_number: "address",
  city: "(cities)",
  country : "country"
}


const DHLPackstationForm = ({ language = "de", selected }) => {
  const { destinationCountry, setDestinationCountry, forceCountry } = useContext(ShippingDataContext);
  const shippingFormAttributes = useContext(ShippingDataContext).packstationShippingFormAttributes;

  return (
    <div className="details-form">
      <div className="name-fields">
        <InputField
          value={shippingFormAttributes.form["packstation_number"]}
          onChange={(value) => {
            shippingFormAttributes.updateForm("packstation_number", value);
          }}
          placeholder="Packstationnummer"
          mandatory={true}
          errorMsg={
            shippingFormAttributes.errorMessagePerField["packstation_number"]
          }
          onBlur={() => shippingFormAttributes.onFieldBlur("packstation_number")}
          touched={shippingFormAttributes.touched.packstation_number}
        />

        <InputField
          id="packstation_address"
          value={shippingFormAttributes.form["packstation_address"]}
          onChange={(value) => {
            shippingFormAttributes.updateForm("packstation_address", value);
          }}
          placeholder={"Postnummer"}
          mandatory={true}
          errorMsg={
            shippingFormAttributes.errorMessagePerField["packstation_address"]
          }
          onBlur={() => shippingFormAttributes.onFieldBlur("packstation_address")}
          touched={shippingFormAttributes.touched.packstation_address}
        />
      </div>

      <div className="name-fields">
        <InputField
          value={shippingFormAttributes.form["packstation_zip"]}
          onChange={(value) =>
            shippingFormAttributes.updateForm("packstation_zip", value)
          }
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/postal_code"
          )}
          mandatory={true}
          errorMsg={
            shippingFormAttributes.errorMessagePerField["packstation_zip"]
          }
          onBlur={() => shippingFormAttributes.onFieldBlur("packstation_zip")}
          touched={shippingFormAttributes.touched.packstation_zip}
        />

        <GoogleApiInputField
          value={shippingFormAttributes.form["packstation_city"]}
          onChange={(value) => {
            // Show only city from google suggestion
            if(Array.isArray(value.split(","))){
              value = value.split(",")[0]
            }
            shippingFormAttributes.updateForm("packstation_city", value)
          }}
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/location"
          )}
          mandatory={true}
          
          errorMsg={
            shippingFormAttributes.errorMessagePerField["packstation_city"]
          }
          onBlur={() => shippingFormAttributes.onFieldBlur("packstation_city")}
          touched={shippingFormAttributes.touched.packstation_city}
          language={language}
          country={language}
          addressComponentIndex={ADDRESS_COMPONENT_INDEX.city}
          addressType = {ADDRESS_FIELD_TYPE.city}
        />
      </div>

      <div>
      {!forceCountry ?
        <Select
        id = "shipping_country"
        initialValue={{value:translator.translate(language,"shipping",`ShippingForm/country_name/${destinationCountry}`), code:destinationCountry}}
        options = {[{value:translator.translate(language,"shipping","ShippingForm/country_name/de"), code:'de'}, {value:translator.translate(language,"shipping",`ShippingForm/country_name/ch`), code:'ch'}]} //enable ch
        // options = {[{value:translator.translate(language,"shipping","ShippingForm/country_name/de"), code:'de'}]}
        onChangeCallback = {(option) => {
          setDestinationCountry(option.option.code);  
          shippingFormAttributes.updateForm("country", option.option.code);
        }}
        mandatory={true}
        style={{
          border: "1px solid var(--sub-gray)",
          height: "56px",
          width: "100%",
          borderRadius: "6px",
          margin: "15px 0px"
        }}
        label={translator.translate(language,"shipping","ShippingForm/country")}
        />
      :
        <InputField
        id = "shipping_country"
        value={translator.translate(
          language,
          "shipping",
          `ShippingForm/country_name/${language}`
        )}
        placeholder={translator.translate(
          language,
          "shipping",
          "ShippingForm/country"
        )}
        mandatory={true}
        language={language}
        errorMsg={shippingFormAttributes.errorMessagePerField["country"]}
        disabled
        onBlur={() => shippingFormAttributes.onFieldBlur("country")}
        touched={shippingFormAttributes.touched.country}
        />
      }
      </div>

      <div className="phone-fields">
        <div className="phone-icon-and-field">
          <div className="phone-icon">
            <BsTelephone className="telephone" />
            <p className="phone-icon-text">
              {translator.translate(
                language,
                "shipping",
                "ShippingForm/phone_icon_text"
              )}
            </p>
          </div>

          <InputField
            value={shippingFormAttributes.form["packstation_personal_phone"]}
            placeholder={translator.translate(
              language,
              "shipping",
              "ShippingForm/telephone"
            )}
            onChange={(value) =>
              shippingFormAttributes.updateForm(
                "packstation_personal_phone",
                value
              )
            }
            errorMsg={
              shippingFormAttributes.errorMessagePerField[
                "packstation_personal_phone"
              ]
            }
            mandatory
            onBlur={() =>
              shippingFormAttributes.onFieldBlur("packstation_personal_phone")
            }
            touched={shippingFormAttributes.touched.packstation_personal_phone}
          />
        </div>
      </div>

      <div className="name-fields">
        <InputField
          value={shippingFormAttributes.form["packstation_personal_first_name"]}
          onChange={(value) =>
            shippingFormAttributes.updateForm(
              "packstation_personal_first_name",
              value
            )
          }
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/first_name"
          )}
          mandatory={true}
          errorMsg={
            shippingFormAttributes.errorMessagePerField[
              "packstation_personal_first_name"
            ]
          }
          onBlur={() =>
            shippingFormAttributes.onFieldBlur("packstation_personal_first_name")
          }
          touched={shippingFormAttributes.touched.packstation_personal_first_name}
        />

        <InputField
          value={shippingFormAttributes.form["packstation_personal_last_name"]}
          onChange={(value) =>
            shippingFormAttributes.updateForm(
              "packstation_personal_last_name",
              value
            )
          }
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/surname"
          )}
          mandatory={true}
          errorMsg={
            shippingFormAttributes.errorMessagePerField[
              "packstation_personal_last_name"
            ]
          }
          onBlur={() =>
            shippingFormAttributes.onFieldBlur("packstation_personal_last_name")
          }
          touched={shippingFormAttributes.touched.packstation_personal_last_name}
        />
      </div>

      <div className="location-fields">
        <InputField
          value={shippingFormAttributes.form["packstation_personal_zip"]}
          onChange={(value) =>
            shippingFormAttributes.updateForm("packstation_personal_zip", value)
          }
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/postal_code"
          )}
          mandatory={true}
          errorMsg={
            shippingFormAttributes.errorMessagePerField[
              "packstation_personal_zip"
            ]
          }
          onBlur={() =>
            shippingFormAttributes.onFieldBlur("packstation_personal_zip")
          }
          touched={shippingFormAttributes.touched.packstation_personal_zip}
        />

        <GoogleApiInputField
          value={shippingFormAttributes.form["packstation_personal_city"]}
          onChange={(value) => {
            // Show only city from google suggestion
            if(Array.isArray(value.split(","))){
              value = value.split(",")[0]
            }
            shippingFormAttributes.updateForm("packstation_personal_city", value)
          }}
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/location"
          )}
          mandatory={true}
          errorMsg={
            shippingFormAttributes.errorMessagePerField[
              "packstation_personal_city"
            ]
          }
          onBlur={() =>
            shippingFormAttributes.onFieldBlur("packstation_personal_city")
          }
          touched={shippingFormAttributes.touched.packstation_personal_city}
          language={language}
          country={language}
          addressComponentIndex={ADDRESS_COMPONENT_INDEX.city}
          addressType = {ADDRESS_FIELD_TYPE.city}
        />
      </div>

      <div className="location-fields">
        <GoogleApiInputField
          value={shippingFormAttributes.form["packstation_personal_address"]}
          onChange={(value) => {
            // Show only city from google suggestion
            if(Array.isArray(value.split(","))){
              value = value.split(",")[0]
            }
            shippingFormAttributes.updateForm("packstation_personal_address", value);
          }}
          placeholder={translator.translate(
            language,
            "shipping",
            "ShippingForm/street_and_house"
          )}
          mandatory={true}
          errorMsg={
            shippingFormAttributes.errorMessagePerField[
              "packstation_personal_address"
            ]
          }
          onBlur={() =>
            shippingFormAttributes.onFieldBlur("packstation_personal_address")
          }
          touched={shippingFormAttributes.touched.packstation_personal_address}
          language={language}
          country={language}
          addressComponentIndex={ADDRESS_COMPONENT_INDEX.street_and_number}
          addressType = {ADDRESS_FIELD_TYPE.street_and_number}
        />
      </div>

      <div className="location-fields">
      {!forceCountry ?
        <Select
          id = "shipping_country"
          initialValue={{value:translator.translate(language,"shipping",`ShippingForm/country_name/${destinationCountry}`), code:destinationCountry}}
          options = {[{value:translator.translate(language,"shipping","ShippingForm/country_name/de"), code:'de'}, {value:translator.translate(language,"shipping",`ShippingForm/country_name/ch`), code:'ch'}]} //enable ch
          // options = {[{value:translator.translate(language,"shipping","ShippingForm/country_name/de"), code:'de'}]}
          onChangeCallback = {(option) => {
              setDestinationCountry(option.option.code);
              shippingFormAttributes.updateForm("country", option.option.code);
          }}
          mandatory={true}
          style={{
            border: "1px solid var(--sub-gray)",
            height: "56px",
            width: "100%",
            borderRadius: "6px",
            margin: "15px 0px"
          }}
          label={translator.translate(language,"shipping","ShippingForm/country")}
        />
      :
        <InputField
        id = "shipping_country"
        value={translator.translate(
          language,
          "shipping",
          `ShippingForm/country_name/${language}`
        )}
        placeholder={translator.translate(
          language,
          "shipping",
          "ShippingForm/country"
        )}
        mandatory={true}
        language={language}
        errorMsg={shippingFormAttributes.errorMessagePerField["country"]}
        disabled
        onBlur={() => shippingFormAttributes.onFieldBlur("country")}
        touched={shippingFormAttributes.touched.country}
        />
      }
      </div>
    </div>
  );
};

export default DHLPackstationForm;
