import * as React from "react";
import './SkipQuestionnaireModal.css'
import { useCustomerContext } from "../../../reducers/CustomerReducer";
import * as http from "../../../utils/http"
import * as translator from "../../../utils/translator";
import { useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import SubmitButton from "../../SubmitButton/SubmitButton";
import Significance from "../../../pages/Questionnaire/components/Significance/Significance";
import { MainTracker } from "../../../PixelTrackers/MainTracker";
import { useCartContext } from "../../../customHooks/useCart";


type Props = {
  language:string|undefined
  country:string|undefined
  mention:string|undefined
}

export const SkipQuestionnaireModal: React.FC<Props> = ({language, country, mention}) => {
  const { customerState } = useCustomerContext();
  const [showSkipQuestionnaireModal, setShowSkipQuestionnaireModal] = useState(0)
  const [attemptGet, setAttemptGet] = useState(true)
  const [questionnaire, setQuestionnaire] = useState('')
  const {saveCart} = useCartContext();
  const navigate = useNavigate();
  const [searchParamas] = useSearchParams();


  const submit = async() =>{
    http
    .postMultiPartsToServer(`questionnaire/${country}/${language}/${mention}?${searchParamas.toString()}`, {
      questionnaire: JSON.parse(questionnaire),
      mention,
      isBlocking: false,
      country,
      language,
      allowOnlyPrescription: false,
      isExpressReorder:false,
      
    }, [], [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.CART_ORDER])
    .then((response) => {
  
      saveCart({
        id: response.data.cartID,
        token: response.data.cartToken,
        mention,
        mixpanel_title: response.data.catalog.mixpanel_title,
        products:[],
      })

      MainTracker.track("custom", "skipped q'r")
      navigate({pathname:`/select_treatment/${country}/${language}/${mention}`, search: `?${searchParamas.toString()}`})
    })
    .catch((err) => {
      window.clientLogger.error("error postToServer MedicalDisclaimerPage: ", err);
    });
  }



  if(customerState && customerState.customerID && attemptGet){
    //http request to get previous orders
    http.getFromServer(`skip_questionnaire/${mention}/${customerState.customerID}`)
    .then((response:any)=>{
      if(response.data.matchHistoricPurchase){
        if(customerState.loginSource == 'questionnaire'){
          setShowSkipQuestionnaireModal(2)
        }else{
          setShowSkipQuestionnaireModal(1)
        }
        MainTracker.track("custom", "start skip q'r")
        setQuestionnaire(response.data.questionnaire)
      }
      setAttemptGet(false)
    })
    .catch((error)=>{window.clientLogger.error("error get from server skip questionnaire",error)})
  }

  return (
    showSkipQuestionnaireModal ? (
      <>
        <div className="dark-loading-container">
          <div className="skip-questionnaire-card">
            <Significance
            level={'info'}
            text={translator.translate(language, "default", "skip_questionnaire/disclaimer")}
            fontWeight={'500'}
            fontSize={'13px'}
            dangerSignificancesRefsDispatch={null}
            parentID={null}
            />
            {showSkipQuestionnaireModal == 1 &&
              <div className="inner-card">
                <h2>
                  {translator.translate(language, "default", "skip_questionnaire/previous_order_question")}
                </h2>
                <div className="buttons-container">
                  <SubmitButton onSubmit={()=>setShowSkipQuestionnaireModal(2)} placeholder={translator.translate(language, "default", "LoginQuestion/yes")} className={'outlined'} id={''}/>
                  <SubmitButton onSubmit={()=>setShowSkipQuestionnaireModal(0)} placeholder={translator.translate(language, "default", "LoginQuestion/no")} className={'outlined'} id={''}/>      
                </div>
              </div>
            }
            {showSkipQuestionnaireModal == 2 &&
              <div className="inner-card">
                <h2>
                  {translator.translate(language, "default", "skip_questionnaire/health_changed_question")}
                </h2>
                <div className="buttons-container">
                  <SubmitButton onSubmit={()=>submit()} placeholder={translator.translate(language, "default", "LoginQuestion/no")} className={'outlined'} id={''}/>      
                  <SubmitButton onSubmit={()=>setShowSkipQuestionnaireModal(0)} placeholder={translator.translate(language, "default", "LoginQuestion/yes")} className={'outlined'} id={''}/>
                </div>
              </div>
            }
          </div>
        </div>
      </>
    ) : null
  );
}