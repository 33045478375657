import React from 'react';
import './AutoCompletionMenu.css';

interface AutoCompletionMenuProps {
  mentions: Array<ConversationMention>;
  onAutoCompletionSelect: (mention: ConversationMention) => void;
  prefixInputState: string;
}

type ConversationMention = {
  mention_type: string;
  mention_id: number;
  mention_text: string;
};

const AutoCompletionMenu: React.FC<AutoCompletionMenuProps> = ({
  mentions,
  onAutoCompletionSelect,
  prefixInputState,
}) => {
  return (
    <div className="auto-complete-options-container">
      {mentions.map((mention: ConversationMention, idx: number) => {
        const prefixIndex = mention.mention_text.toLowerCase().indexOf(prefixInputState.toLowerCase());
        const prefix = mention.mention_text.slice(prefixIndex, prefixIndex + prefixInputState.length);
        const remainingText = mention.mention_text.slice(prefixIndex + prefixInputState.length);

        return (
          <div
            className="auto-complete-option notranslate" //No translate for google translate react bug
            key={idx}
            onClick={() => onAutoCompletionSelect(mention)}
          >
            {prefix && <span className="prefix-input">{prefix}</span>}
            {remainingText}
          </div>
        );
      })}
    </div>
  );
};

export default AutoCompletionMenu;
