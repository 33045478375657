import React, { useState } from 'react';
import './Accordion.css';
import plusIcon from '../../icons/Plus-Green.svg'
import minusIcon from '../../icons/Minus-Green.svg'


interface AccordionProps {
  items: { title: string; content: any; imageUrl?:string }[];
  defaultOpenIndex:number
}

const Accordion: React.FC<AccordionProps> = ({ items, defaultOpenIndex=null }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(defaultOpenIndex);

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div className="accordion-item" key={index}>
          <div
            className={`accordion-title ${activeIndex === index ? 'active' : ''}`}
            onClick={() => handleClick(index)}
          >
            {item.title}
            <img src={activeIndex == index ? minusIcon : plusIcon} />
          </div>
          <div className={`accordion-content ${activeIndex === index ? 'open' : ''}`}>
                {item.content}
            {item.imageUrl &&
                <img src={item.imageUrl}/>
            }
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
