import React from 'react'
import * as translator from "../../../utils/translator";
import BigCheck from "../../../icons/BigCheck.svg"

type Props = {
    selectedIssue: string,
    language: string,
}


const ContactFormSubmitted: React.FC<Props> = ({ selectedIssue, language }) => {
    return (
        <div id='thank-you-container' className="flex items-center justify-center py-10 w-full">
            <div id='thank-you' className="flex flex-col items-center bg-white rounded-lg shadow-md p-6 text-center w-full" >
                <img src={BigCheck} style={{  width: '50px', height: '50px', }} /> 

                <p className="text-xl font-bold ml-2 py-4 text-gray-600 inline-block">
                    <span>{translator.translate(language, "contact", "ContactFormSubmitted/thank_you_1")} </span>
                    <span>{translator.translate(language, "contact", `SelectOptionsText/${selectedIssue}`)} </span>  
                    <span>{translator.translate(language, "contact", "ContactFormSubmitted/thank_you_2")}</span>
                </p>

            </div>

        </div>
    )
}

export default ContactFormSubmitted