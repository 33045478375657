import React, { useEffect } from "react";
import { useParams, useOutletContext } from 'react-router-dom';
import MyAccountContentHeader from '../MyAccountContentHeader/MyAccountContentHeader'
import MyAccountOrderPrescriptionQuestion from "../MyAccountOrderPrescriptionQuestion/MyAccountOrderPrescriptionQuestion";
import MyAccountOrderPrescriptionDetails from "../MyAccountOrderPrescriptionDetails/MyAccountOrderPrescriptionDetails";

const MyAccountOrderQuestionnaire = () => {
  const {setError ,myAccountData,country} = useOutletContext<any>();
  const { order_hash_id } = useParams();
  
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  
  if(!order_hash_id || !country){
    return setError(400)
  }else if(!myAccountData.myOrders.ordersData[order_hash_id]){
    return setError(400)
  }
  const orderData = myAccountData.myOrders.ordersData[order_hash_id] 

  return (
    <>
    <div className="account-content">
      <MyAccountContentHeader
        title={orderData['title']}
        description={""}
      />

      <MyAccountOrderPrescriptionDetails
        orderHashId={order_hash_id}
      />

      {orderData.prescription.map((questionAnswer:any, index:number) => (
        <MyAccountOrderPrescriptionQuestion
          key={index}
          questionHtmlContent={questionAnswer.question}
          answerText={questionAnswer.answer}
          questionType={questionAnswer.question_type}
        />
      ))}

    </div>
  </>
  )
}

export default MyAccountOrderQuestionnaire