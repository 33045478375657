import React from "react";
import "./PharmacyRequestNotificationModal.css";
import * as translator from "../../../utils/translator";
import InnerHTMLRenderer from "../../InnerHTMLRenderer/InnerHTMLRenderer";
import CloseIcon from "../../../../src/icons/X.svg";

interface PharmacyRequestNotificationModalProps {
  closeModal: () => void
  language: string,
  pharmacyInfo: { name: string, city: string, email: string },
  setSearchQuery: (searchQuery: string) => void,
  disclaimerType?: string,
}

const disclaimerTypes = {
  SUCCESS: "success",
  PHARMACY_ALREADY_ACTIVE: "pharmacy_is_already_active",
  PHARMACY_ALREADY_DISABLED: "pharmacy_is_already_disabled",
  PHARMACY_ALREADY_SUBMITTED: "request_already_submitted_by_customer",
} as const;


const PharmacyRequestNotificationModal: React.FC<PharmacyRequestNotificationModalProps> = ({ closeModal, language, pharmacyInfo, setSearchQuery, disclaimerType = disclaimerTypes.SUCCESS }) => {

  const disclaimerTitle = translator.translate(language, "my_account", `PrescriptionRedemptoin/popup_request_notification/${disclaimerType}/title`)

  const onClick = () => {
    if (disclaimerType === disclaimerTypes.PHARMACY_ALREADY_ACTIVE) {
      setSearchQuery(pharmacyInfo.name.toLowerCase()); // bug?
    }
    closeModal();
  }

  return (

    <div onClick={closeModal} className="pharmacy-request-modal-background">
      <div
        className="pharmacy-request-modal-container"
        onClick={(event) => event.stopPropagation()}
      >
        <img onClick={closeModal} className="close-btn" src={CloseIcon} alt="Close" />
        <h6>
          {disclaimerTitle}
        </h6>

        {(disclaimerType === disclaimerTypes.SUCCESS || disclaimerType === disclaimerTypes.PHARMACY_ALREADY_ACTIVE) &&
          <TextBodyPharmacyInfo
            disclaimerType={disclaimerType}
            language={language}
            pharmacyInfo={pharmacyInfo}
          />
        }

        {
          (disclaimerType === disclaimerTypes.PHARMACY_ALREADY_DISABLED || disclaimerType === disclaimerTypes.PHARMACY_ALREADY_SUBMITTED) &&
          <TextBody
            disclaimerType={disclaimerType}
            language={language}
          />
        }

        <span className="confirm-btn" onClick={onClick}>{translator.translate(language, "my_account", 'PrescriptionRedemptoin/popup_request_notification/okay')}</span>

      </div>
    </div>
  );
}

interface TextBodyPharmacyInfoProps {
  disclaimerType: string,
  language: string,
  pharmacyInfo: { name: string, city: string, email: string },
}

const TextBodyPharmacyInfo: React.FC<TextBodyPharmacyInfoProps> = ({ language, pharmacyInfo, disclaimerType }) => {
  const note1: string = translator.translate(language, "my_account", `PrescriptionRedemptoin/popup_request_notification/${disclaimerType}/note1`);
  const note2: string = translator.translate(language, "my_account", `PrescriptionRedemptoin/popup_request_notification/${disclaimerType}/note2`);

  return (
    <div id="pharmacy-request-text" className="pharmacy-request-text-container">
      <p className='pharmacy-request-note'>
        <InnerHTMLRenderer htmlContent={note1} />
      </p>

      <div>
        <div className="pharmacy-info">{pharmacyInfo.name}</div>
        <div className="pharmacy-info">{pharmacyInfo.city}</div>
        <div className="pharmacy-info">{pharmacyInfo.email}</div>
      </div>

      <p className='pharmacy-request-note'>
        <InnerHTMLRenderer htmlContent={note2} />
      </p>
    </div>
  )
}


interface TextBodyProps {
  disclaimerType: string,
  language: string,
}


const TextBody: React.FC<TextBodyProps> = ({ language, disclaimerType }) => {
  const text: string = translator.translate(language, "my_account", `PrescriptionRedemptoin/popup_request_notification/${disclaimerType}/text`)

  return (
    <p className='pharmacy-request-note'>
      <InnerHTMLRenderer htmlContent={text} />
    </p>
  )
}


export default PharmacyRequestNotificationModal