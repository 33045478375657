import XIcon from "../../icons/X.png"

type Props = {
    content: string;
    onClose: () => void;
    closeColor?: string;
    bgColor?: string;
    contentColor?: string;
    containerClassName?:string;
    traingleClassName?: string;
}


const Popover: React.FC<Props> = ({ content, onClose, bgColor = 'bg-[#656D79]', contentColor = 'text-white', closeColor = "#F7F7F7", containerClassName, traingleClassName }) => {
    return (

        <>
        <div id="popover-container" className={`${bgColor} ${contentColor} absolute flex flex-col gap-y-2 left-[-100px]  max-md:left-[-175px]  bottom-6 w-[250px] p-2 mt-1 text-xs rounded-md shadow-lg z-[999] ${containerClassName}`}>

            <div id="popover-close" className={`p-1 bg-[#F7F7F7] self-end  w-[22px] h-[22px] rounded-full flex justify-center items-center cursor-pointer `}>
                <img
                    className={`w-full h-full`}
                    src={XIcon}
                    onClick={onClose}
                    alt="X" />
            </div>

            <p id="popover-content" className="pb-2 px-1 pt-0" style={{ fontFamily: "Roboto, sans-serif", fontWeight: "400" }} > {content} </p>
        </div>
        
        {/* Triangle (thought bubble) */}
        <div id="popover-traingle" className={`${bgColor} p-2 absolute bottom-5 z-[999] ${traingleClassName}`} style={{transform: "rotate(45deg)"}}/>
        
        </>
    );
};

export default Popover;
