import React from 'react';
import "./BadgeNotification.css";

interface BadgeNotificationProps{
    amount : number;
}

const BadgeNotification:React.FC<BadgeNotificationProps> = (props) => {
    if(props.amount > 0){
        return (
            <span className="badge-notifications">{props.amount}</span>
          )
    } else {
        return <></>
    }
}

export default BadgeNotification