import { useState, useContext } from "react";
import { CustomerContext } from "../reducers/CustomerReducer";
import { loadCart } from "../reducers/CartReducer";
import LoginModal from "../components/Modals/LoginModal/LoginModal";
import StickyNotification from "../components/StickyNotification/StickyNotification";
import ServerError from "../components/ServerError/ServerError";
import HeaderController from "../components/Headers/HeaderController";
import ForgotPasswordModal from "../components/Modals/ForgotPasswordModal/ForgotPasswordModal";
import { useParams } from "react-router-dom";



export default function ProtectedCustomerRoute({ children, shouldHaveCart = true }: { children: any, shouldHaveCart?: boolean }) {
    const [showForgotPasswordModal, setShowForgotPassword] = useState(false);
    const customer = useContext(CustomerContext);
    const { language } = useParams();
    let sessionCart = loadCart() as any;
    const [stickyNotificationDetails, setStickyNotificationDetails] = useState({ // global state for sticky handling
        show: false,
        title: "",
        content: "",
        level: "",
        showInfoIcon: true,
        refresh: false,
    });

    if (customer.customerState && customer.customerState.customerID) {
        return children;

    } else if (shouldHaveCart && !sessionCart.id) {
        return (
            <>
                <HeaderController showSteps={false} stage={"Questionnaire"} />
                <ServerError status={404} />
            </>
        )
    }

    else {
        return (
            <>
                <StickyNotification
                    showNotification={stickyNotificationDetails.show}
                    title={stickyNotificationDetails.title}
                    onClick={() => setStickyNotificationDetails((currentDetails: any) => { return { ...currentDetails, show: false } })}
                    content={stickyNotificationDetails.content}
                    level={stickyNotificationDetails.level}
                    showInfoIcon={stickyNotificationDetails.showInfoIcon}
                    refresh={stickyNotificationDetails.refresh}
                />

                <LoginModal
                    setStickyNotificationDetails={setStickyNotificationDetails}
                    closeModal={() => { }}
                    closeable={false}
                    navigationCallback={() => { }}
                    showForgotPasswordOption={true}
                    changeShowForgotPassword ={(boolValue:any) => {setShowForgotPassword(boolValue)}}
                />


                {showForgotPasswordModal && language && (
                    <ForgotPasswordModal
                        closeModal={() => setShowForgotPassword(false)}
                        navigationCallback={() => setShowForgotPassword(false)}
                        language={language}
                    />
                )}
            </>
        )
    }
};