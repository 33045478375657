import React, { useState, useRef } from "react";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import * as translator from "../../../utils/translator";
import {useOutletContext} from "react-router-dom";

interface MyAccountOrderPrescriptionDetailsAnswerProps {
  onSubmit: (file: { binary_data: ArrayBuffer; filename: string; type: string } | null, message:string) => void;
  isLoading:boolean
}

const MyAccountOrderPrescriptionDetailsAnswer: React.FC<MyAccountOrderPrescriptionDetailsAnswerProps> = ({ onSubmit, isLoading }) => {
  const {language} = useOutletContext<any>();
  const [answer, setAnswer] = useState("");
  const [file, setFile] = useState<{ binary_data: ArrayBuffer; filename: string; type: string } | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const fileBuffer = fileReader.result as ArrayBuffer;
        setFile({
          binary_data: fileBuffer,
          filename: selectedFile.name,
          type: selectedFile.type
        }); // Set the file state with the necessary fields

        const uploadFileSpan = document.querySelector(".upload-file-label > span");
        if (uploadFileSpan) {
          uploadFileSpan.innerHTML = selectedFile.name;
        }
      };
      fileReader.readAsArrayBuffer(selectedFile);
    }
  };

  const handleLabelClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="order-prescription-details-answer-container">
        <label htmlFor="answer-textarea">
          {translator.translate(language, "my_account", `MyOrders/prescription/customer_answer_info`)}
        </label>
        <textarea
          onChange={(event) => setAnswer(event.target.value)}
          rows={4}
          cols={50}
          name="answer-textarea"
          placeholder={translator.translate(language, "my_account", `MyOrders/prescription/textarea_placeholder`)}
        ></textarea>

        <div className="order-prescription-details-answer-bottom-row">
          <label
            className="upload-file-label"
            htmlFor="upload-file"
            onClick={handleLabelClick}
          >
            <img
              src={require("../../../icons/Plus-Green.svg").default}
              alt="plus"
            />
            <span>{translator.translate(language, "my_account", `MyOrders/prescription/attach_file`)} </span>
            <input
              type="file"
              name="upload-file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </label>

          <SubmitButton
            id="order-prescription-details-answer-submit"
            isEnabledState={true}
            placeholder={translator.translate(language, "my_account", `MyOrders/prescription/submit_placeholder`)}
            loading={isLoading}
            onSubmit={() => onSubmit(file, answer)}
          />
        </div>
    </div>
  );
};

export default MyAccountOrderPrescriptionDetailsAnswer;
