import "./StickyNotification.css";
import { RiInformationLine } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { useState,useEffect } from "react";

export default function StickyNotification({
  showNotification = false,
  level = "error",
  title = "",
  content = "",
  showInfoIcon = true,
  onClick,
  refresh = false,
}) {

  const [fadeMode, setFadeMode] = useState("hidden");
  
  let uid;
  if (level === "error"){
    uid = window.eventsTracking && window.eventsTracking.getData("uid")
  }

  useEffect(() => {
    setFadeMode("hidden");
    setTimeout(() => setFadeMode("fadeIn") ,100);
  }, [refresh])
  

  const getBackgroundColorByLevel = (level) => {
    if (level === "error") {
      return { backgroundColor: "var(--primary-danger)" }; //default error mode
    }

    if (level === "warning") {
      return { backgroundColor: "var(--primary-warning)" };
    }

    if (level === "info") {
      return { backgroundColor: "var(--primary-lightgreen)" };
    }

    if (level === "success") {
      return { backgroundColor: "var(--primary-success)" };
    }

    if (level === "disclaimer") {
      return { backgroundColor: "#5580ed" };
    }

    if (level === "additional_action_required") {
      return { backgroundColor: "#facc15" };
    }
  };

 

  const fadeOut = () => {
    setFadeMode("fadeOut");
 
    setTimeout(() => {
      if(fadeMode != "fadeIn"){
        onClick(false); //basicelly changes showNotification prop state to be false;
        setFadeMode("hidden"); //return it to init class
      }
    }, 1500);
  };

  return (
    <>
      { showNotification ? 
          <div
            onClick={fadeOut}
            style={getBackgroundColorByLevel(level)}
            className={
              fadeMode === "hidden" ? 
              `stickyNotification-container-hidden`
              :
              `stickyNotification-container-${fadeMode}`
              
            }
          >
            <div className="stickyNotification-content-container">
                <span className="stickyNotification-title">
                  {showInfoIcon ? <RiInformationLine className="stickyNotification-logo-info" /> : null}                
                  {title}
                </span>

                <span className="stickyNotification-content">{content}</span>
                {uid && <span className="stickyNotification-uid">{uid}</span>}
                </div>
              <IoMdClose
                onClick={fadeOut}
                className="stickyNotification-logo-close"
              />
           
          </div>
        : null
      }
    </>
  );
}