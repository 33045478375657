import React from 'react'

interface IProductAvailabilityTagProps {
    inStock: boolean
}

const ProductAvailabilityTag:React.FC<IProductAvailabilityTagProps> = ({inStock}) => {
  return (
    <div className='text-white text-sm text-center'>
      {
        inStock ? <div className='bg-[#19D845] rounded-sm px-1'>Premium</div> : <div className='bg-blue-500 rounded-sm px-1'>Regular</div>
      }
    </div>
  )
}

export default ProductAvailabilityTag
