import React from 'react';
import InnerHTMLRenderer from '../InnerHTMLRenderer/InnerHTMLRenderer';
import "./InfoCell.css";

interface InfoCellProps{
  title:string;
  value:string;
  locked?:boolean;
}

const InfoCell:React.FC <InfoCellProps> = ({title,value,locked=true}) => {
  return (
    <div className="info-cell-container">
      <p className="info-cell-title">{title}</p>
      <div className="info-cell-field">
        <span className="info-cell-value"> <InnerHTMLRenderer htmlContent={value}/> </span>
        <img src={require("../../icons/Lock.svg").default} alt="lock" />
      </div>
   
    </div>
  )
}

export default InfoCell