import React from "react";
import NoPreferenceTreatmentInfo from "../NoPreferenceTreatmentInfo/NoPreferenceTreatmentInfo";
import RadioSelect from "../RadioSelect/RadioSelect";


const NoPreferenceTreatmentCard = (props) => {
  return (
    <>
      <div
        style={{ width: props.width }}
        className={
          props.selectedDrugID == props.index
            ? "single-item-container-selected"
            : "single-item-container"
        }
      >
        <li
          style={{ width: props.width, height: props.height }}
          className={
            props.selectedDrugID == props.index
              ? "product-item-selected"
              : "product-item"
          }
          onClick={() => {
            if (props.index !== props.selectedDrugID) {
              props.setSelectedDrugID(props.index);
              props.changeDrugCallback("no-preference");
            }
          }}
        >
            <RadioSelect selected={props.index === props.selectedDrugID} />


          <span className="product-item-text">
            <span>Aucune préférence de traitement</span>
          </span>

        </li>
        <div
          className={
            props.selectedDrugID == props.index
              ? "test-panel-selected"
              : "test-panel"
          }
        ></div>

        <div
          className={
            props.selectedDrugID == props.index
              ? "test-panel-selected"
              : "test-panel"
          }
        >

          <NoPreferenceTreatmentInfo />
        </div>
      </div>

      <p className="orderWindow-container-title treatment-options">
        Options de traitement
      </p>
    </>
  );
};

export default NoPreferenceTreatmentCard;
