import React, {useState,useEffect,useContext } from 'react';
import "./HeaderCMSModals.css";
import SearchBar from '../../SearchBar/SearchBar';
import { clientConfig } from '../../../config';
import HeaderCMSHamburgerMenu from '../../Headers/HeaderCMS/HeaderCMSHamburgerMenu/HeaderCMSHamburgerMenu';
import TrustpilotBanner from '../../TrustpilotBanner/TrustpilotBanner';
import { headerCMSContext } from "../../Headers/HeaderCMS/HeaderCMS";

const TREATMENTS = "treatments";
const SEARCH = "search";
const HAMBURGER = "hamburger";

interface HeaderCMSModalsProps {
  cancelModal: () => void;
}

const HeaderCMSModals: React.FC<HeaderCMSModalsProps> = ({ cancelModal}) => {
  const { currentOpenModal,country,headerData } = useContext(headerCMSContext); // Use the useContext hook to access the context values
  const [showHamburgerMenu,setShowHamburgerMenu] = useState<boolean>(currentOpenModal === HAMBURGER);
   
  useEffect(()=>{
    if(currentOpenModal === HAMBURGER){
      setShowHamburgerMenu(true);
    }
  },[currentOpenModal])

  const tempGetLocale = () => {
    //TODO: Need to have a global function in client that returns locale, this is temp
    let locale;
    if (country === "de") {
      locale = "de-DE";
    } else if (country === "fr") {
      locale = "fr-FR";
    } else {
      return "en-GB";
    }
    return locale;
  }

  const onSearchSubmit = (searchQuery: string) => {
    if (searchQuery) {
      //location TopBar because the search is from the header.
      window.location.href = `${clientConfig.MAIN_WEBSITE_URL}/search/${tempGetLocale()}?q=${searchQuery}&from=TopBar`;
    }
  };

  const getModalContent = (modalName: string) => {
    if (modalName === TREATMENTS) {
      return (
        <div className="treatments-modal">
          <div
            className="treatments-container"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
          >
            {headerData?.rootCategoriesPairs && headerData.rootCategoriesPairs.map((rootCategoryPair: any, index: number) => {
              return (
                <div className="root-category-pair" key={index}>
                  {rootCategoryPair.map((rootCategory: any) => (
                    <div key={rootCategory.title} className={`root-category`}>
                      <h4>{rootCategory.title}</h4>
                      <ul>
                        {rootCategory.children.map((childCategory: any, childIndex: number) => (
                          <li key={childCategory.id + childIndex}>
                            <a className="treatment-category-link" href={childCategory.uri}>
                              {childCategory.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
          
          <TrustpilotBanner locale={tempGetLocale()}/>
        </div>
      );

    } else if (modalName === SEARCH) {
      return (
        <div
          className="search-container"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        >
          <SearchBar shouldFocusOnRender={true} onSubmit={(searchQuery: string) => onSearchSubmit(searchQuery)} country={country} />
        </div>
      );
    } else if (modalName === HAMBURGER){
      return (
        <>
          <img className={"cancel-icon"} src={require("../../../icons/X.svg").default} alt="X" />
          <HeaderCMSHamburgerMenu show={showHamburgerMenu} />
        </>
      )
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if(showHamburgerMenu){
      setShowHamburgerMenu(false);
      //timeout for hamburger menu animation
      setTimeout(() => {
        cancelModal();
      }, 500);

    } else {
      //no need for animation
      cancelModal();
    }
  };

  return (
    <div
      className={`header-cms-modal-background${currentOpenModal !== "" ? '-show' : ''}`}
      onClick={handleClick}
    >
      {getModalContent(currentOpenModal)}
    </div>
  );
};

export default HeaderCMSModals;
