import { Fragment } from "react";
import Checkbox from "../../../../components/Checkbox/Checkbox";

const CheckBoxList = ({
  children,
  rootQuestion = null,
  onAnswerClick,
  errorMode,
  answersSelectedMap
}) => {


  return (
      <div className="questionnaire-answers-checkbox-list">
        {children.map((childNode, idx) => (
          <Fragment key={childNode.recipe.id}>
            <div
              className="questionnaire-question-item-text-container questionnaire-subquestion-text "
              dangerouslySetInnerHTML={{
                __html:
                  childNode.recipe.label !== ""
                    ? childNode.recipe.label
                    : childNode.recipe.long_label,
              }}
            />
            <Checkbox
              errorMode={errorMode}
              value={answersSelectedMap[childNode.recipe.id]}
              id={"answer_" + childNode.recipe.id}
              onClick={() => onAnswerClick(childNode)}
              label={childNode.recipe.value}
              questionId={childNode.recipe.recipe_question_id}
            />
          </Fragment>
        ))}
    </div>
  );
};

export default CheckBoxList;
