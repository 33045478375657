import React from 'react';
import "./ConversationAttachment.css";
import * as http from "../../../../utils/http";

interface ConversationAttachmentProps {
  attachment: ConversationAttachmentType,
}

type ConversationAttachmentType = {
  display_name: string;
  base_path: string;
  name: string;
  mime_type: string;
};

const ConversationAttachment: React.FC<ConversationAttachmentProps> = ({attachment}) => {
  const getPhoto = (basePath: string, name: string) => {

    http
      .postToServer(
        `download-file`,
        {
          s3Key: basePath + name,
        },
        [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.GENERATE_FILE]
      )
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data],{type:attachment.mime_type}));
        const link = document.createElement("a");
        link.className = "blob-link"
        link.href = url;
        link.setAttribute("download", attachment.display_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        // Handle error
      });
  };

  return (
    <div className="attachment">
      <img
        className="icon"
        src={require("../../../../icons/Paperclip-Green.svg").default}
        alt="file"
      />
      <a onClick={() => getPhoto(attachment.base_path, attachment.name)}>
        {attachment.display_name}
      </a>
    </div>
  );
};

export default ConversationAttachment;
