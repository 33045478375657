import React from "react";
import {useOutletContext} from "react-router-dom";
import InputField from "../../../components/CredentialsInput/InputField";
import * as translator from '../../../utils/translator';

/* 
    STYLING OF THIS COMPONENT IN subscriptionDeliveryAddressForms.css
*/


interface SubscriptionPersonalAddressFormProps{
    personalAddressAttributes:any //useForm attributes from the subscriptionDeliveryAddressForm component
}

const SubscriptionPersonalAddressForm: React.FC<SubscriptionPersonalAddressFormProps> = ({personalAddressAttributes}) => {
  const {myAccountData} = useOutletContext<any>();
  const language = myAccountData.locale.language_id
  return (
    <div className="subscription-delivery-address-forms-personal-form">
      <InputField
        mandatory
        id="personal_first_name"
        placeholder={translator.translate(language, "my_account", `forms/firstName`)}
        value={personalAddressAttributes.form["first_name"]}
        errorMsg={personalAddressAttributes.errorMessagePerField["first_name"]}
        language={language}
        onBlur={() => personalAddressAttributes.onFieldBlur("first_name")}
        touched={personalAddressAttributes.touched.first_name}
        onChange={(value: any) => {
          personalAddressAttributes.updateForm("first_name", value);
        }}
      />

      <InputField
        mandatory
        id="personal_last_name"
        placeholder={translator.translate(language, "my_account", `forms/lastName`)}
        value={personalAddressAttributes.form["last_name"]}
        errorMsg={personalAddressAttributes.errorMessagePerField["last_name"]}
        language={language}
        onBlur={() => personalAddressAttributes.onFieldBlur("last_name")}
        touched={personalAddressAttributes.touched.last_name}
        onChange={(value: any) => {
          personalAddressAttributes.updateForm("last_name", value);
        }}
      />

    

      <InputField
        mandatory
        id="personal_zip"
        placeholder={translator.translate(language, "my_account", `forms/zip`)}
        value={personalAddressAttributes.form["zip"]}
        errorMsg={personalAddressAttributes.errorMessagePerField["zip"]}
        language={language}
        onBlur={() => personalAddressAttributes.onFieldBlur("zip")}
        touched={personalAddressAttributes.touched.zip}
        onChange={(value: any) => {
          personalAddressAttributes.updateForm("zip", value);
        }}
      />

      

      <InputField
        mandatory
        id="personal_city"
        placeholder={translator.translate(language, "my_account", `forms/city`)}
        value={personalAddressAttributes.form["city"]}
        errorMsg={personalAddressAttributes.errorMessagePerField["city"]}
        language={language}
        onBlur={() => personalAddressAttributes.onFieldBlur("city")}
        touched={personalAddressAttributes.touched.city}
        onChange={(value: any) => {
          personalAddressAttributes.updateForm("city", value);
        }}
      />


      <InputField
        mandatory
        id="personal_street"
        placeholder={translator.translate(language, "my_account", `forms/street`)}
        value={personalAddressAttributes.form["street"]}
        errorMsg={personalAddressAttributes.errorMessagePerField["street"]}
        language={language}
        onBlur={() => personalAddressAttributes.onFieldBlur("street")}
        touched={personalAddressAttributes.touched.street}
        onChange={(value: any) => {
          personalAddressAttributes.updateForm("street", value);
        }}
      />  

      <InputField
        mandatory
        id="personal_house_number"
        placeholder={translator.translate(language, "my_account", `forms/houseNumber`)}
        value={personalAddressAttributes.form["house_number"]}
        errorMsg={personalAddressAttributes.errorMessagePerField["house_number"]}
        language={language}
        onBlur={() => personalAddressAttributes.onFieldBlur("house_number")}
        touched={personalAddressAttributes.touched.house_number}
        onChange={(value: any) => {
          personalAddressAttributes.updateForm("house_number", value);
        }}
      />    


      <InputField
        mandatory
        id="personal_country"
        value={translator.translate(language,"shipping",`ShippingForm/country_name/${personalAddressAttributes.form.country.toLowerCase()}`)}
        placeholder={translator.translate(language, "my_account", `forms/country`)}
        language={language}
        errorMsg={personalAddressAttributes.errorMessagePerField["country"]}
        disabled //DISABLED WHICH MEANS IT'S LOCKED AND GRAY 
        onBlur={() => personalAddressAttributes.onFieldBlur("country")}
        touched={personalAddressAttributes.touched.country}
        onChange={(value: any) => {
          return;
        }}
      />

      <InputField
        mandatory={false}
        id="personal_company"
        placeholder={translator.translate(language, "my_account", `forms/company`)}
        value={personalAddressAttributes.form["company"]}
        errorMsg={personalAddressAttributes.errorMessagePerField["company"]}
        language={language}
        onBlur={() => personalAddressAttributes.onFieldBlur("company")}
        touched={personalAddressAttributes.touched.company}
        onChange={(value: any) => {
          personalAddressAttributes.updateForm("company", value);
        }}
      />

   
    </div>
  );
};

export default SubscriptionPersonalAddressForm;
