import React, { useState } from 'react';
import "./SubscriptionDisclaimer.css"
import * as translator from "../../../../utils/translator"
import InnerHTMLRenderer from '../../../../components/InnerHTMLRenderer/InnerHTMLRenderer';

interface SubscriptionDisclaimerProps {
    country:string
}


const SubscriptionDisclaimer: React.FC<SubscriptionDisclaimerProps> = ({country}) => {

    const [showSubscriptionProducts,setShowSubscriptionProducts] = useState<boolean>(false);
    const accordionStyling = {
        transition: 'max-height 300ms ease-in-out',
        maxHeight: showSubscriptionProducts ? '100px' : '0px',
        overflow:"hidden"
    };


  return (
    <div className="subscription-disclaimer">
        <div className="subscription-disclaimer-head">
            <div className="subscription-disclaimer-title-and-description">
                <p className="subscription-disclaimer-title">{translator.translate(country, "my_account", 'MyOrders/bannerTitle')}</p>
                <p className="subscription-disclaimer-description">{translator.translate(country, "my_account", 'MyOrders/bannerSubTitle')}</p>
            </div>
            
            <div className="banner-container">
                <div className="banner">{translator.translate(country, "my_account", 'MyOrders/bannerBadge')}</div>
            </div>
        </div>

        <div className="subscription-disclaimer-bottom">
            <p onClick={()=> setShowSubscriptionProducts((current) => !current)} className="subscription-disclaimer-bottom-title">
                 {translator.translate(country, "my_account", 'MyOrders/bannerHowToJoin')} 
                 <img className={`${showSubscriptionProducts ? "chevron-up": "chevron-down"}`} src={require("../../../../icons/Chevron-Down.svg").default} alt="Chevron"/> 
            </p>

            <div className="subscription-disclaimer-bottom-accordion" style={accordionStyling} >
                <div className="subscription-disclaimer-bottom-description"><InnerHTMLRenderer htmlContent={translator.translate(country, "my_account", 'MyOrders/howToJoinContent')} /></div>
                <p className="disclaimer-show-subscription-products">{translator.translate(country, "my_account", 'MyOrders/howToJoinLink')}</p>
            </div>
        </div>
    </div>
  )
}

export default SubscriptionDisclaimer