import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const sorted = <T>(arr: T[], callback: (a: T, b: T) => number): T[] => {
    try {
        const copy = JSON.parse(JSON.stringify(arr));
        copy.sort(callback);
        return copy;
    } catch (error) {
        return arr;
    }
};



export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}