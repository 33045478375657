import React from 'react'
import * as translator from "../../../utils/translator";
import headphones from "../../../images/headphones-contact-form.png";
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import BookIcon from "../../../icons/BookIcon.png";

type Props = {
    language: string,
}

const SupportTicketSection: React.FC<Props> = ({ language }) => {
    return (
        <div id='support-ticket-section' className='bg-white flex gap-8 relative p-3 md:p-8 rounded-lg'>

            <div id='ticket-message' className=''>
                <h1 className='font-poppins text-[#0D2C54] text-[24px] md:text-[32px] font-[500] leading-[32px] text-left pb-4'>
                    {translator.translate(language, "contact", "HeaderMessage/title")}
                </h1>
                <p style={{ fontFamily: "Roboto, sans-serif", fontWeight: "400", color: "var(--primary-blue)" }} className='pb-4 '>
                    {translator.translate(language, "contact", "HeaderMessage/text")}
                </p>


                <div id='ticket-buttons' className="py-4 flex flex-col md:flex-row items-center gap-5">
                    <a href={translator.translate(language, "contact", "HeaderMessage/first_button_link")} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }} className="w-full md:w-fit">
                        <button style={{ border: "2px solid #11DDAC"}} className="w-full md:w-fit flex items-center justify-center text-[#00ca94] bg-transparent rounded-[3px] py-2 h-[56px] px-[40px] outline-0 cursor-pointer transition hover:opacity-80">
                            <img src={BookIcon} className="mr-2"/>
                            <span className="font-semibold" >{translator.translate(language, "contact", "HeaderMessage/first_button")}</span>
                        </button>
                    </a>

                    <SubmitButton
                        id={"open-ticket-button2"}
                        placeholder={translator.translate(language, "contact", "HeaderMessage/second_button")}
                        minWidth={"200px"}
                        onSubmit={() => {
                            const currentUrl = window.location.href.split('#')[0];
                            window.location.href = `${currentUrl}#contact-form`;
                        }} />
                </div>
            </div>

            <img id='headphones-img' className='w-60 h-60 max-md:hidden' src={headphones} alt="" />


        </div>

    )
}

export default SupportTicketSection