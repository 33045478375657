
import React, { useContext } from 'react'
import ProductCard from './ProductCard'
import { ChooseTreatmentContext, Product, getMinPricePerProduct, getMinPricePerQuantity } from '../FreeDosageLayout'


interface Props {
  products: Product[],
  languageID: string,
  className?: string,
  productCardClassName?: string
}

const ProductSelectionPanel: React.FC<Props> = ({ products, languageID, className, productCardClassName = "" }) => {

  const chooseTreatmentContext = useContext(ChooseTreatmentContext);
  
  return (
    <div id='product-selection-container' className='flex flex-col gap-5'>

      <div id='product-selection' className={`flex flex-col gap-5 pb-6 md:overflow-y-auto ${className}`}>
        {
          products.map((product, index) => {
            const minPricePerQuantity = getMinPricePerQuantity(product);
            const minPricePerProduct = getMinPricePerProduct(product);
            const priceString = ['gram'].includes(product.quantity_type) ? minPricePerQuantity : minPricePerProduct
            return (
              <ProductCard
                id={`product-card-container-${index}`}
                key={product.product_id.toString() + index + product.isSelected} 
                product={product}
                price={priceString}
                hidePrices={chooseTreatmentContext?.hidePrices}
                hideTags={chooseTreatmentContext?.hideTags} 
                containerClassName={productCardClassName}
              
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default ProductSelectionPanel
