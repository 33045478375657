import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import "./MyAccountSkipSubscriptionModal.css"
import SubmitButton from '../../SubmitButton/SubmitButton';
import * as http from "../../../utils/http"
import * as translator from "../../../utils/translator"
import {MainTracker} from '../../../PixelTrackers/MainTracker'

interface MyAccountSkipSubscriptionModalProps {
    closeModal: () => void;
    allSubscriptionData: any;
    modalData: any;
    setStickyNotificationDetails: any;
    setRefreshData: any;
    language: string
}

const MyAccountSkipSubscriptionModal: React.FC<MyAccountSkipSubscriptionModalProps> = ({ closeModal, allSubscriptionData, modalData, setStickyNotificationDetails, setRefreshData, language }) => {
    const [showThankYouModal, setShowThankYouModal] = useState<boolean>(false)
    const subscriptionData = allSubscriptionData[modalData.hashID]

    //calc new next delivery date
    const dbDateTime = new Date(subscriptionData.rawNextDelivery);
    const newDate = new Date(dbDateTime);
    newDate.setDate(newDate.getDate() + subscriptionData.ordersInterval);
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const newNextDelivery = newDate.toLocaleDateString('en-GB', options);
    const subscriptionDiscount = Math.round(subscriptionData.regularPrice - subscriptionData.subscriptionPrice)
    const navigate = useNavigate()

    //delivery address for post's email
    let deliveryAddress:string
    const addressDetails = subscriptionData.addressDetails
    if(subscriptionData.addressType == 'regular'){
        deliveryAddress = `${addressDetails.street} ${addressDetails.houseNumber}, ${addressDetails.city} ${addressDetails.zip}`
    }else{
        deliveryAddress = `${addressDetails.packstationAddress} ${addressDetails.packstationNumber}`
    }

    const closeEvent = (eventIndex:number, answer:string) =>{
        const EVENTS:any= {
            1: 'Decision on skip my order pop-up',
            2: 'Decision on I have enough meds'
        }
        closeModal()
        MainTracker.track('custom', EVENTS[eventIndex], {'Answer': answer})
    }

    const postSkipSubscription = (skipType:string) => {
        http
            .postToServer(
                `account-update/subscription/skip`,
                {
                    hashID: modalData.hashID,
                    deliverySkipped: subscriptionData.rawDeliverySkipped,
                    skipType,
                    productName: subscriptionData.title,
                    interval:subscriptionData.ordersInterval,
                    nextDelivery: newNextDelivery,
                    deliveryAddress: deliveryAddress,
                    rawNextBilling: subscriptionData.rawNextBilling
                },
                [http.MIDDLEWARES.CUSTOMER_ID]
            )
            .then((response) => {
                setShowThankYouModal(true)
            })
            .catch((error) => {
                setStickyNotificationDetails((currentDetails: any) => ({
                    show: true,
                    title: "",
                    content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
                    level: "error",
                    showInfoIcon: false,
                    refresh: !currentDetails.refresh
                }))
            })
    }

    const postSubscriptionCancellation = () =>{
        http
        .postToServer(
        `account-update/subscription/cancel`,
        {
          option: { id: 2, translation: translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/2'), value:`I have enough meds to keep me going for now` },
          hashID: modalData.hashID
        },
        [http.MIDDLEWARES.CUSTOMER_ID]
        )
        .then((response) => {
          setStickyNotificationDetails((currentDetails:any) => ({
            show: true,
            title: "",
            content: 'subscription cancelled',
            level : "info",
            showInfoIcon:false,
            refresh: !currentDetails.refresh
          }))
          setRefreshData((current:boolean)=>!current)
          closeEvent(2, 'Cancel subscription')
          navigate(`orders-subscription`);
        })
        .catch(
          setStickyNotificationDetails((currentDetails:any) => ({
            show: true,
            title: "",
            content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
            level : "error",
            showInfoIcon:false,
            refresh: !currentDetails.refresh
          }))
        )
        .finally()
      }


    return <>
        {(!showThankYouModal && modalData.from == 'mySubscriptions') ? (
            <div onClick={()=>closeEvent(1, 'Close')} className="skip-subscription-modal-background-white">
                <div
                    className="skip-subscription-modal-popup-container"
                    onClick={(event) => event.stopPropagation()}
                >
                    <img
                        onClick={()=>closeEvent(1, 'Close')}
                        className="close-btn"
                        src={require("../../../../src/icons/X.svg").default}
                        alt="X"
                    />
                    <h4>Bitte beachten</h4>
                    <p>Sie überspringen Ihre nächste Sendung für {subscriptionData.title} mit {subscriptionDiscount}€ Rabatt, die am {subscriptionData.next_delivery} geliefert werden sollte. Sie erhalten Ihre nächste Lieferung jetzt am {newNextDelivery}.</p>
                    <div className="skip-subscription-modal-button-container">
                        <SubmitButton
                            loading={false}
                            onSubmit={()=>{postSkipSubscription('decision_skip_my_subscriptions')}}
                            id={"subscription-delivery-address-submit"}
                            fontsize={"14px"}
                            padding={"0px 30px"}
                            height={"45px"}
                            placeholder="Bestätigen"
                            isEnabledState={true}
                        />
                        <SubmitButton
                            loading={false}
                            onSubmit={()=>{closeEvent(1, 'Discard')}}
                            id={"subscription-delivery-address-submit"}
                            fontsize={"14px"}
                            padding={"0px 30px"}
                            height={"45px"}
                            placeholder="Verwerfen"
                            isEnabledState={true}
                        />
                    </div>
                </div>
            </div>
        ) : (!showThankYouModal && modalData.from == 'cancelSubscription') ? (
            <div onClick={()=>{closeEvent(2, 'Close')}} className="skip-subscription-modal-background-blue">
                <div
                    className="skip-subscription-white-modal-popup-container"
                    onClick={(event) => event.stopPropagation()}
                >
                    <img
                        onClick={()=>{closeEvent(2, 'Close')}}
                        className="close-btn"
                        src={require("../../../../src/icons/X.svg").default}
                        alt="X"
                    />
                    <h4>Möchten Sie Ihre nächste Lieferung überspringen?</h4>
                    <p>Sie können Ihre nächste Lieferung für {subscriptionData.title} auslassen. Ihr Behandlungsplan mit {subscriptionDiscount}€ Rabatt bleibt aktiv. Sie erhalten Ihre nächste Sendung am {newNextDelivery}.</p>
                    
                        <SubmitButton
                            loading={false}
                            onSubmit={()=>{postSkipSubscription('decision_skip_pre_cancel')}}
                            id={"subscription-delivery-address-submit"}
                            fontsize={"14px"}
                            padding={"0px 30px"}
                            height={"45px"}
                            placeholder="Nächste Lieferung überspringen"
                            isEnabledState={true}
                        />
                        <p className="skip-subscription-cancel-subscription" onClick={postSubscriptionCancellation}>Behandlungsplan stornieren</p>
                    
                </div>
            </div>
        ) : (
            <div onClick={() => { setRefreshData((current: boolean) => !current); closeModal(); }} className="skip-subscription-modal-background-blue">
                <div
                    className="skip-subscription-white-modal-popup-container"
                    onClick={(event) => event.stopPropagation()}
                >
                    <img
                        onClick={() => { setRefreshData((current: boolean) => !current); closeModal(); }}
                        className="close-btn"
                        src={require("../../../../src/icons/X.svg").default}
                        alt="X"
                    />
                    <h4>Vielen Dank!</h4>
                    <p>Ihre nächste Lieferung wurde übersprungen. Sie erhalten die nächste Sendung am {newNextDelivery}.</p>
                </div>
            </div>
        )}
    </>
}

export default MyAccountSkipSubscriptionModal