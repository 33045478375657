import RadioSelect from "../../../../components/RadioSelect/RadioSelect"
import "./OptionCardCategory.css"


const OptionCardCategory = ({selected, title, onClick, disabled=false}) => {
  return (
    <div onClick={onClick} className={"option-main-header-card" + (disabled ? " disabled" : "")}>
        <div className="option-content"> 
          <RadioSelect selected={selected}/>
          <p>{title}</p>
        </div>
      </div>
  )
}

export default OptionCardCategory