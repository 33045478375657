import './HeaderFunnel.css'
import StepsList from '../../StepsList/StepsList';
import Logo from "../../../images/logo-dark.svg";
import mobileLogo from "../../../images/logo-symbol.svg";
import chevronLeft from "../../../icons/Chevron-Left.png";
import * as translator from "../../../utils/translator";
import { useNavigate } from "react-router-dom";
import { useState,useEffect } from 'react';
import { clientConfig } from "../../../config";

export default function HeaderFunnel({stage, language, showSteps=true, flow, showBackButton=true}) {

  const navigate = useNavigate()
  const [isMobile,setIsMobile] = useState(window.innerWidth < 767);
  
  const handleScreenResize = () => {
   if (window.innerWidth < 767){
    setIsMobile(true)
   } else {
    setIsMobile(false)
   }
  }

  useEffect(() => {
    window.addEventListener("resize", handleScreenResize)
  })

  return (
    <>
    
     <div className='header-container'>
        <div className='header-links'>
               {
                showBackButton && (stage==="Questionnaire" || stage === "Payment" || stage === "RegistrationPage" || stage === "StartConsultation") ?
                <div className='header-return-container'>
                  <img onClick={() => navigate(-1)} style={isMobile? {width:22, height:22,} : null} src={chevronLeft}/>
                  {
                    isMobile ? 
                    null 
                    :
                    <a onClick={() => navigate(-1)} className='header-return-text'>{translator.translate(language, "default", "HeaderQuestionnaire/return")}</a>
                  }
                </div>
                :
                <span></span>
              }
                
                <img className={isMobile ? 'header-mobileLogo' : 'header-logo' } src={isMobile ? mobileLogo : Logo} alt="logo" onClick={() => window.location = (`${clientConfig.MAIN_WEBSITE_URL}/${language}`)}/>
        </div>
    </div>

    {
      showSteps &&
      <StepsList isMobile={isMobile} language={language} stage={stage} flow={flow}/>
    }
              
    </>
  )
}
