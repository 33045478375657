export const personalShippingFormInitialValues = {
  first_name: "",
  last_name: "",
  zip: "",
  city: "",
  address: "",
  phone: "",
  company: "",
};

export const billingShippingFormInitialValues = {
  billing_first_name: "",
  billing_last_name: "",
  billing_zip: "",
  billing_city: "",
  billing_address: "",
};

export const packstationShippingFormInitialValues = {
  packstation_number: "",
  packstation_address: "",
  packstation_zip: "",
  packstation_city: "",
  packstation_personal_first_name: "",
  packstation_personal_last_name: "",
  packstation_personal_zip: "",
  packstation_personal_city: "",
  packstation_personal_address: "",
  packstation_personal_phone: "",
};

export const methodIDsMap = {
  REGULAR_METHOD_ID: 1,
  PACKSTATION_METHOD_ID: 1,
  PERSONAL_ADDRESS_METHOD_ID: 4,
  ROYAL_MAIL_METHOD_ID: 18,
  PRESCRIPTION_BY_MAIL_METHOD_ID: 17,
};

export const COD = "cod";
export const WIRE = "wire";
export const PACKSTATION_DELIVERY_METHOD_INDEX = 2;
export const SUBS_DISCOUNT_REGULAR = "subs_discount_regular";
export const SUBS_DISCOUNT_FTD = "subs_discount_ftd"

export const countries = {
  Switzerland: 'ch',
  Deutschland: 'de',
  France: 'fr',
  UK: 'en'
}
