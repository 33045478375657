import React, { createContext, useContext, useState } from "react";
import * as SessionStorageWrapper from "../utils/SessionStorageWrapper";
import * as http from "../utils/http";

// CartContext type
type CartContextType = {
  getCart: () => CartView;
  getSessionFields: () => { mention: string | null; id: string | null; token: string | null; mixpanel_title: string | null };
  saveCart: (cart: CartView) => void;
  clearCart: () => void;
  updateCart: (fields: Partial<CartView>) => void;
  updateCartAndDB: (fields: Partial<CartView>) => Promise<Record<string, string | number>>;
  addProduct: (product: CartProductView) => void;
  removeProduct: (productItemIdToRemove: number) => void;
}

// CartState type
type CartView = Cart & { products: CartProductView[] }

// Create the context
const CartContext = createContext<CartContextType | undefined>(undefined);

// Custom hook to use the context
export function useCartContext(): CartContextType {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCartContext must be used within a CartProvider");
  }
  return context;
}

// Provider
export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const [cartViewState, setCartViewState] = useState<CartView>({ products: [] });


  const getCart = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const cartFromParam = urlParams.get("crt");

    if (cartFromParam && SessionStorageWrapper.getItem("last_shipped_cart") !== cartFromParam) {
      window.eventsTracking.setData(`cart_id`, cartFromParam, true);
    }

    return cartViewState;
  }


  const getSessionFields = () => {
    return {
      id: window.eventsTracking.getData("cart_id"),
      token: window.eventsTracking.getData("cart_token"),
      mention: window.eventsTracking.getData("cart_mention"),
      mixpanel_title: window.eventsTracking.getData("cart_mixpanel_title"),
      is_e_recipe: window.eventsTracking.getData("cart_is_e_recipe"),
    }
  }

  const saveCart = (cart: CartView) => {

    // 1. Save in eventsTracking  
    window.eventsTracking.setData(`cart_id`, cart.id!, true);
    window.eventsTracking.setData(`cart_token`, cart.token!, true);
    window.eventsTracking.setData(`cart_is_e_recipe`, cart.is_e_recipe!, true);

    if (cart.mention && cart.mixpanel_title) {
      window.eventsTracking.setData('cart_mention', cart.mention!, true);
      window.eventsTracking.setData('cart_mixpanel_title', cart.mixpanel_title!, true);
    }

    // 2. Save the state
    setCartViewState(cart);

  };

  const clearCart = () => {

    // 1. Clear eventsTracking
    window.eventsTracking.setData(`cart_id`, "", true);
    window.eventsTracking.setData(`cart_token`, "", true);
    window.eventsTracking.setData('cart_mention', "", true);
    window.eventsTracking.setData('cart_mixpanel_title', "", true);
    window.eventsTracking.setData('cart_is_e_recipe', "", true);

    // 2. Clear the state
    setCartViewState({products: []});

  };

  const updateCart = (fields: Partial<CartView>) => {
    setCartViewState((current) => ({ ...current, ...fields }));
  };

  const addProduct = (product: CartProductView) => {
    setCartViewState((prev) => ({
      ...prev,
      products: [...prev.products, product],
    }));
  };

  const removeProduct = (productItemIdToRemove: number) => {
    setCartViewState((prev) => ({
      ...prev,
      products: prev.products.filter((product) => product.product_item_id !== productItemIdToRemove),
    }));
  };


  // Maybe we won't use that, we will see...
  const updateCartAndDB = async (fields: Partial<CartView>) => {

    const cartID = window.eventsTracking.getData(`cart_id`)

    if (!cartID){
      return {status:"failed"}
    }

    try {
      const response = await http.postToServer("cart/update",{ ...fields }, [http.MIDDLEWARES.CART_ORDER]);
  
      if (response.data.status === "ok" && response.data.cartView) {
        const cartView = response.data.cartView;
        setCartViewState({
          ...cartView.cart,
          products: cartView.products
        });
        
        return {status:"ok"};
        
      } else {
        window.clientLogger.error("Couldn't update cart", response);
        return {status:"failed"}
      }

    } catch (error) {
      window.clientLogger.error("Server failed to update the cart:", error);
      return {status:"failed"}
    }
  };
  


  return (
    <CartContext.Provider value={{ getCart, getSessionFields, saveCart, clearCart, updateCart, updateCartAndDB ,addProduct, removeProduct }} >
      {children}
    </CartContext.Provider>
  );
};
