// Raw DB statuses: 
export const TEST = 'test';
export const BLACKLIST = 'black_list';
export const WAIT_MONEY_TRANSFER = 'wait_money_transfer';
export const WAIT_PAYMENT_CONFIRM = 'wait_payment_confirm';
export const WAIT_CHARGE_BACK = 'wait_charge_back';
export const WAIT_REFUND = 'wait_refund';
export const WAIT_REFUND_CONFIRMATION = 'wait_refund_confirmation';
export const CANCELLED = 'cancelled';
export const ADDRESS_VERIFY = 'address_verify';
export const ADDRESS_VERIFY_UPS = 'address_verify_ups';
export const ADDRESS_VERIFY_DHL = 'address_verify_dhl';
export const WAIT_PRESCRIPTION = 'wait_prescription';
export const PAUSED_FOR_STOCK_VERIFICATION_SWISS = 'paused_for_stock_verification_swiss';
export const WAIT_REPEAT_PRESCRIPTION = 'wait_repeat_prescription';
export const NEED_CUSTOMER_ANSWER = 'need_customer_answer';
export const DOCTOR_REQUESTED_CLARIFICATION = 'doctor_requested_clarification';
export const CLARIFIED_BY_SUPPORT = 'clarified_by_support';
export const READY_TO_SIGN = 'ready_to_sign';
export const WAIT_SIGN_CONFIRMATION = 'wait_sign_confirmation';
export const PRESCRIPTION_REJECTED = 'prescription_rejected';
export const SENT_TO_PHARMACY = 'sent_to_pharmacy';
export const REJECTED_BY_PHARMACY = 'rejected_by_pharmacy';
export const RETURNED_TO_PHARMACY = 'returned_to_pharmacy';
export const SENT_TO_CUSTOMER = 'sent_to_customer';
export const DELIVERED = 'delivered';
export const PHARMACY_INTERNAL_ERROR = 'pharmacy_internal_error';
export const DELIVERY_ISSUES = 'delivery_issues'
export const WAIT_CUSTOMER_PHARMACY_SELECTION = 'wait_customer_pharmacy_selection';
export const FORWARDED_REQUEST_TO_EXTERNAL_PHARMACY = 'forwarded_request_to_external_pharmacy';
export const COMPLETED = 'completed';