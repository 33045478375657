import "./ToggleRadioButton.css";

const ToggleRadioButton = ({ isToggleEnabled, updateToggle,id }) => {

  return (
    <div className="toggle-radio-button-container">
      <div
        id={id}
        onClick={() => {
          updateToggle(!isToggleEnabled);
        }}
        className={
          !isToggleEnabled
            ? "toggle-radio-button opaque"
            : "toggle-radio-button"
        }
      >
        <div
          className={
            isToggleEnabled
              ? "toggle-radio-button-widget-right"
              : "toggle-radio-button-widget-left"
          }
        ></div>
      </div>
    </div>
  );
};

export default ToggleRadioButton;
