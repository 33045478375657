import "./DrugSubscription.css";
import SubscriptionPlanCard from "../SubscriptionPlanCard/SubscriptionPlanCard";
import OneTimePurchaseCard from "../OneTimePurchaseCard/OneTimePurchaseCard";
import { SubscriptionModalContext } from "../../ChooseTreatmentRouter";
import { useEffect, useState, useContext } from "react";
import { useOutletContext } from "react-router-dom";

const MAX_DISCOUNT = 15;

const DrugSubscription = (props) => {
  const selectDefaultPlan = () => {
    //choose a default plan with minimum interval
    let availablePlans =
      props.productItemSubscriptionPlans[props.selectedPackItem];

    if (availablePlans) {
      let minIntervalPlan = availablePlans.sort(
        (planA, planB) => planA.interval - planB.interval
      )[0];
      let planIndex = availablePlans.findIndex(
        (plan) => plan.product_plan_id === minIntervalPlan.product_plan_id
      );

      setSelectedPlanIndex(planIndex);
      props.setSelectedSubscriptionPlanID(minIntervalPlan.product_plan_id);
    }
  };

  const subscriptionModalContext = useContext(SubscriptionModalContext);
  const outletContext = useOutletContext(); //From reorderLayout in reorderflow


  useEffect(() => {
    setSelectedPlanIndex(null);
    props.setSelectedSubscriptionPlanID(undefined);

    if (
      props.selectedPackItem &&
      props.productItemSubscriptionPlans[props.selectedPackItem] &&
      props.productItemSubscriptionPlans[props.selectedPackItem].length > 0
    ) {
      props.setSelectedSubscriptionPlanID("none");
      selectDefaultPlan();
    }
  }, [props.selectedPackItem]);

  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);

  function handleSelectedPlanClick(index) {
    setSelectedPlanIndex(index);
  }

  return props.selectedPackItem &&
    props.productItemSubscriptionPlans[props.selectedPackItem] ? (
    <div className="outer-sub-container">
      <div
        className={
          props.productItemSubscriptionPlans[props.selectedPackItem].length > 0
            ? "DrugSubscription-container"
            : ""
        }
      >
        {props.productItemSubscriptionPlans[props.selectedPackItem].length >
        0 ? (
          <h1 className="DrugSubscription-title">Lieferintervall wählen</h1>
        ) : null}

        {props.productItemSubscriptionPlans[props.selectedPackItem].length >
          0 && props.showDeliveryErrorMessage  ? (
          <h1 className="warning-title">
            Um fortzufahren, wählen Sie bitte das Lieferintervall
          </h1>
        ) : null}

        {props.productItemSubscriptionPlans[props.selectedPackItem] ? props.productItemSubscriptionPlans[props.selectedPackItem].map(
          (plan, index) => {
            return (
              <SubscriptionPlanCard
                showDeliveryErrorMessage={props.showDeliveryErrorMessage}
                setShowDeliveryMessage={props.setShowDeliveryMessage}
                selectedSubscriptionPlanID={props.selectedSubscriptionPlanID}
                setSelectedSubscriptionPlanID={
                  props.setSelectedSubscriptionPlanID
                }
                productName={props.productName}
                productQuantity={props.productQuantity}
                key={index}
                index={index}
                plan={plan}
                selectedPlanIndex={selectedPlanIndex}
                setSelectedPlanIndex={handleSelectedPlanClick}
                bestPrice={plan.discount === MAX_DISCOUNT ? true : false}
              />
            );
          }
        ) : 
          <h1 className="warning-title">
            Already have an active subscription!
          </h1>
        }


        {props.productItemSubscriptionPlans[props.selectedPackItem].length > 0 ?
          <p
            className="learn-more"
            onClick={() => {
              if(subscriptionModalContext?.showSubscriptionModalCallback){
                subscriptionModalContext.showSubscriptionModalCallback(true);

              }
              if(outletContext?.setShowSubscriptionModal){
                outletContext.setShowSubscriptionModal(true);
              }
            }
            }
          >
            Erfahren Sie mehr über Behandlungspläne
          </p>
          : 
          null
        }
        <OneTimePurchaseCard
          showDeliveryErrorMessage={props.showDeliveryErrorMessage}
          setShowDeliveryMessage={props.setShowDeliveryMessage}
          setSelectedSubscriptionPlanID={props.setSelectedSubscriptionPlanID}
          productName={props.productName}
          productQuantity={props.productQuantity}
          key={
            props.productItemSubscriptionPlans[props.selectedPackItem].length
          }
          index={
            props.productItemSubscriptionPlans[props.selectedPackItem].length
          }
          selectedPlanIndex={selectedPlanIndex}
          selectedPackItemObject={props.selectedPackItemObject}
          setSelectedPlanIndex={handleSelectedPlanClick}
        />
      </div>
    </div>
  ) : null;
};

export default DrugSubscription;
