import React, { useState } from 'react';

interface HamburgerMenuCategoryAccordionProps {
  rootCategory: any;
}

const HamburgerMenuCategoryAccordion: React.FC<HamburgerMenuCategoryAccordionProps> = ({ rootCategory }) => {
  const [active, setActive] = useState(false);

  return (
    <div className="root-category-accordion">
      <div
        onClick={() => setActive((current) => !current)}
        className={`root-category-accordion-head`}
      >
        <h4 className={`root-category-title${active ? "-active" : ""}`}>{rootCategory.title}</h4>
        <img
          className={`chevron-icon ${active ? "chevron-up" : "chevron-down"}`}
          src={require("../../../icons/Chevron-Down-Green.svg").default}
          alt="Chevron"
        />
      </div>

      {active ? 
        <ul className="child-categories">
          {
            rootCategory.children.map((child : any) => {
              return (
                <li key={child.id} className="child-category"><a href={child.uri}>{child.title}</a></li>
              )
            })
          }
        </ul>
       : null}
    </div>
  );
};



export default HamburgerMenuCategoryAccordion;
