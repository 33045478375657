import "./LoginQuestion.css";
import RadioButton from "../RadioButton/RadioButton";
import { useState } from "react";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import InputField from "../../../../components/CredentialsInput/InputField";
import * as http from "../../../../utils/http";
import * as translator from "../../../../utils/translator";
import { useCustomerContext } from "../../../../reducers/CustomerReducer";
import StickyNotification from "../../../../components/StickyNotification/StickyNotification";
import { MainTracker } from "../../../../PixelTrackers/MainTracker";
import useForm from "../../../../customHooks/useForm";
import Significance from "../Significance/Significance";

export default function LoginQuestion({
  onChange,
  language,
  showForgotPasswordModal,
}) {
  const [showLoginWindow, setShowLoginWindow] = useState(false);
  const [stickyNotification, setStickyNotification] = useState({ show: false, errorCode: 'login_failed' });
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const { customerState, customerDispatch } = useCustomerContext();
  const loginFormAttributes = useForm(
    { email: "", loginPassword: "" },
    language
  );

  const onSubmit = async (e) => {
    e.preventDefault();

    setSubmitButtonLoading(true);

    if (loginFormAttributes.validateForm()) {
      await http
        .postToServer(`login`, loginFormAttributes.form)
        .then((response) => {
          if (response.data.status === "ok") {
            customerDispatch({
              type: "logged_in",
              customerID: response.data.customerID,
              uuid: response.data.uuid,
              rememberToken: response.data.rememberToken,
              email: loginFormAttributes.form.email,
              optForSendables: response.data.optForSendables,
              loginSource: "questionnaire"
            });
          } else {
            setStickyNotification(prevStickyNotification => ({ ...prevStickyNotification, show: true }));
          }
        })
        .catch((error) => {
          let errorCode = 'login_failed'
          if (error.response.data.status === 'user_blocked') {
            errorCode = 'user_blocked'
          }
          setStickyNotification(prevStickyNotification => ({ show:true, errorCode }))
        });
    }
    setSubmitButtonLoading(false);
  };


  if (customerState && customerState.customerID) {
    //if customer is Logged-in, we don't show the question
    return null;
  } else {
    return (
      <>
        {stickyNotification.show ? (
          <StickyNotification
            showNotification={stickyNotification.show}
            title={translator.translate(
              language,
              "default",
              "StickyNotification/danger_title"
            )}
            onClick={() => setStickyNotification(prevStickyNotification => ({ ...prevStickyNotification, show: false }))}
            content={translator.translate(
              language,
              "default",
              `StickyNotification/${stickyNotification.errorCode}`
            )}
          />
        ) : null}

        <div className="questionnaire-question-item-container">
          <div className="questionnaire-question-item">
            <div className="questionnaire-question-item-text-container">
              {translator.translate(
                language,
                "default",
                "LoginQuestion/first_time_ordering"
              )}
            </div>
            <div className="questionnaire-answers-container">
              <RadioButton
                value={"No"}
                id={"login_no"}
                groupName={"LoginQuestion"}
                label={translator.translate(
                  language,
                  "default",
                  "LoginQuestion/no"
                )}
                onClick={() => {
                  MainTracker.track("custom", "View Login Box");
                  setShowLoginWindow(true);
                  onChange(true);
                }}
              />
              <RadioButton
                value={"Yes"}
                id={"login_yes"}
                groupName={"LoginQuestion"}
                label={translator.translate(
                  language,
                  "default",
                  "LoginQuestion/yes"
                )}
                onClick={() => {
                  setShowLoginWindow(false);
                }}
              />
            </div>
          </div>
        </div>

        {showLoginWindow ? (
          <div className="questionnaire-subquestion-container">
            <p className="questionnaire-loginquestion-title">
              {translator.translate(
                language,
                "default",
                "LoginQuestion/login_and_continue"
              )}
            </p>
            <form onSubmit={onSubmit} className="questionnaire-loginquestion-inputs-container" id="questionnaire-login-form">
              <InputField
                value={loginFormAttributes.form.email}
                errorMsg={loginFormAttributes.errorMessagePerField.email}
                onChange={(value) => {
                  loginFormAttributes.updateForm("email", value);
                }}
                LoginQuestion
                type="email"
                placeholder={"E-Mail"}
                language={language}
                mandatory
                onBlur={() => loginFormAttributes.onFieldBlur("email")}
                touched={loginFormAttributes.touched.email}
              />

              <InputField
                value={loginFormAttributes.form.loginPassword}
                errorMsg={
                  loginFormAttributes.errorMessagePerField.loginPassword
                }
                onChange={(value) => {
                  loginFormAttributes.updateForm("loginPassword", value);
                }}
                type="password"
                placeholder={translator.translate(
                  language,
                  "default",
                  "LoginQuestion/password"
                )}
                language={language}
                mandatory
                onBlur={() => loginFormAttributes.onFieldBlur("loginPassword")}
                touched={loginFormAttributes.touched.loginPassword}
              />
              <input type="submit" hidden />
            </form>

            <SubmitButton
              language={language}
              loading={submitButtonLoading}
              onSubmit={onSubmit}
              width={"100%"}
              placeholder={translator.translate(
                language,
                "default",
                "LoginQuestion/login_and_continue"
              )}
            />

            <p
              onClick={() => showForgotPasswordModal()}
              className="loginquestion-forgot-password"
            >
              {translator.translate(
                language,
                "default",
                "LoginQuestion/forgot_password"
              )}
            </p>
          </div>
        ) : null}
        <Significance
          level={'info'}
          text={translator.translate(language, "default", "skip_questionnaire/disclaimer2")}
          fontWeight={'500'}
          fontSize={'13px'}
          margin={'0 0 25px 0'}
          dangerSignificancesRefsDispatch={null}
          parentID={null}
        />
      </>
    );
  }
}
