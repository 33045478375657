import RadioButtons from "./RadioButtons/RadioButtons";
import TextArea from "./TextArea/TextArea";
import TextInput from "./TextInput/TextInput";
import NumberInput from "./NumberInput/NumberInput";
import Select from "../../../components/Select/Select";
import CheckBoxList from "./CheckBoxList/CheckBoxList";
import { useEffect } from "react";
import { clientConfig } from "../../../config";
import DatePickerInput from "./DatePickerInput/DatePickerInput";


export const validateQuestion = (question) => {
  let isValid = true;

  if (question.answerGroup.recipe.type.includes("check_list")) {
    return question.answer.includes("[+]");
  }

  if (!question.answer && question.answer !== 0) {
    isValid = false;
  }

  return isValid;
};

export const formatQuestionnaire = (questionnaire, answersSelectedMap) => {
  questionnaire.forEach((question) => {
    if (question.answerGroup?.recipe?.type === "check_list") {
      let selectedOptions = [];

      question.answerGroup.children.forEach((optionNode) => {
        let formattedOptionValue =
          (answersSelectedMap[optionNode.recipe.id] ? "[+] " : "[-] ") +
          optionNode.recipe.value;
        selectedOptions.push(formattedOptionValue);
      });

      if (selectedOptions.length > 0) {
        question.answer = selectedOptions.join("\n");
      }
    } else {
      if (Array.isArray(question.answer)) {
        question.answer = question.answer[0];
      }
    }
  });


  return questionnaire;
};

export const bundleQuestionnaireForSending = (
  flattenedQuestionnaire,
  answersSelectedMap,
  revealedAnswerGroups
) => {
  let flattenedFiltred = JSON.parse(JSON.stringify(flattenedQuestionnaire));
  let bundledQuestionnaire = [];


  //for immediate answer groups, only keep selected answers
  flattenedFiltred.forEach((question) => {

    //save answers of questions WITH children
    if (question.answersGroup?.children) {
      let filteredChildren = question.answersGroup?.children?.filter(
        (child) => answersSelectedMap[child.recipe.id] || answersSelectedMap[child.recipe.id] === 0
      );

      let answer;
      let answerID;

      if (
        ["number", "textarea", "text","date"].includes(
          question.answersGroup.recipe.type
        )
      ) {
       
        answer = answersSelectedMap[question.answersGroup.recipe.id];
        answerID = [question.answersGroup.recipe.id];
      } else {
        answer = filteredChildren.map((child, index) => child.recipe.value);
        answerID = filteredChildren.map((child) => child.recipe.id);
      }

      let questionObject = {
        question_id: question.id,
        question: question.text,
        question_type: question.question_type,
        answerGroup: question.answersGroup,
        answer,
        answer_id: answerID,
        specification: null,
        auto_processing: null,
        hide_from_doctor: question.hide_from_doctor,
        show_to_pharmacy: question.show_to_pharmacy,
        is_answer_blocking: null,
        is_prescription_diagnosis: question.is_prescription_diagnosis,
        specification_data: [],
      };

      bundledQuestionnaire.push(questionObject);
    } else if (question.question_type === "title") {
      let questionObject = {
        question_id: question.id,
        question: question.text,
        question_type: question.question_type,
        answerGroup: question.answersGroup,
        answer: null,
        answer_id: [],
        specification: null,
        auto_processing: null,
        hide_from_doctor: question.hide_from_doctor,
        show_to_pharmacy: question.show_to_pharmacy,
        is_answer_blocking: null,
        specification_data: [],
      };

      bundledQuestionnaire.push(questionObject);
    }

    //for additional answerGroups (aka subquestions), only keep selected answers

    if (question.additional) {
      question.additional.forEach((subquestion) => {
        //ONLY IF THE SUBQUESTION IS EVEN REVEALED
        if (revealedAnswerGroups[subquestion.recipe.id]) {
          subquestion.children = subquestion.children?.filter(
            (child) => answersSelectedMap[child.recipe.id]
          );

          let answer;
          //save answers of questions WITHOUT children
          if (["textarea","date", "number"].includes(subquestion.recipe.type)) {
            answer = answersSelectedMap[subquestion.recipe.id];
          } else {
            answer = subquestion.children.map((child) => child.recipe.value);
          }

          let questionObject = {
            question_id: subquestion.recipe.id,
            answerGroup: subquestion,
            question:
              subquestion.recipe.long_label ||
              subquestion.recipe.label ||
              subquestion.recipe.text ||
              subquestion.recipe.value,
            question_type: "subquestion_" + subquestion.recipe.type,
            answer,
            answer_id: subquestion.children?.map((child) => child.recipe.id),
            specification: null,
            auto_processing: null,
            hide_from_doctor: subquestion.hide_from_doctor,
            show_to_pharmacy: subquestion.show_to_pharmacy,
            is_answer_blocking: null,
            specification_data: [],
          };

          bundledQuestionnaire.push(questionObject);
        }
      });
    }
  });

  return bundledQuestionnaire;
};

export const CHECKBOX_OPTION = "checkbox_option";
export const CHECKBOX = 'checkbox';
// helper components

//dummy componenet to render the labels and texts of subquestions
const SubquestionText = ({ recipeAnswer, answerType = "" }) => {
  switch (answerType) {
    case "check_list":
      return (
        <div className="questionnaire-question-item-text-container  ">
          {recipeAnswer.label || recipeAnswer.long_label}
        </div>
      );

    default:
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: recipeAnswer.label || recipeAnswer.long_label,
          }}
          className="questionnaire-question-item-text-container questionnaire-subquestion-text "
        />
      );
  }
};

// ---------------------------------------

//this component renders the content of an answer group,
//e.g "select" is a group of "select_options"
export const RenderAnswerGroup = ({
  answersGroup,
  onTextChange,
  onAnswerClick,
  onHide,
  onReveal,
  questionnaireValidationObject,
  answersSelectedMap,
}) => {
  useEffect(() => {
    onReveal(answersGroup);

    return () => {
      onHide(answersGroup);
    };
  }, []);

  switch (answersGroup.recipe?.type) {
    case "radio_buttons":
      return (
        <RadioButtons
          errorMode={
            questionnaireValidationObject[answersGroup.recipe.id] === false
          }
          onAnswerClick={onAnswerClick}
          children={answersGroup.children}
          answersSelectedMap={answersSelectedMap}
        />
      );
    case "select":
      let filteredChildren = answersGroup.children.filter(option => {
        return answersSelectedMap[option.recipe.id];
      }); 

      //if there is an initialValue that came from prefill (customerAnswers) we pass it
      let initialValue = filteredChildren.length ? filteredChildren[0].recipe : null

      return (
        <Select
          errorMode={
            questionnaireValidationObject[answersGroup.recipe.id] === false
          }
          onAnswerClick={onAnswerClick}
          page={"questionnaire"}
          options={answersGroup.children}
          id={"answer_" + answersGroup.recipe.id}
          initialValue={initialValue}
          style={{
            border: "1px solid #aaa",
            height: "44px",
            width: "250px",
            borderRadius: "3px",
            backgroundColor: "transparent",
            margin: "0px"
            }}
        />
      );
      
    case "check_list":
      return (
        <CheckBoxList
          errorMode={
            questionnaireValidationObject[answersGroup.recipe.id] === false
          }
          onAnswerClick={onAnswerClick}
          children={answersGroup.children}
          answersSelectedMap={answersSelectedMap}
        />
      );

    case "number":
      return (
        <NumberInput
          maxValue={answersGroup.recipe.validation_rule.max_value}
          minValue={answersGroup.recipe.validation_rule.min_value}
          errorMode={
            questionnaireValidationObject[answersGroup.recipe.id] === false
          }
          placeholder={answersGroup.recipe.placeholder}
          onChange={(text) => {
            onTextChange(
              answersGroup.recipe.id,
              text,
              answersGroup.recipe.validation_rule,
              answersGroup.recipe.recipe_question_id
            );
          }}
          id={"answer_" + answersGroup.recipe.id}
          value={answersSelectedMap[answersGroup.recipe.id]}
        />
      );

    case "text":
      return (
        <TextInput
          errorMode={
            questionnaireValidationObject[answersGroup.recipe.id] === false
          }
          placeholder={answersGroup.recipe?.placeholder}
          onChange={(text) =>
            onTextChange(
              answersGroup.recipe.id,
              text,
              answersGroup.recipe.validation_rule,
              answersGroup.recipe.recipe_question_id
            )
          }
          id={"answer_" + answersGroup.recipe.id}
        />
    );

    case "date":
      return (
        // <DateInput
        //   errorMode={
        //     questionnaireValidationObject[answersGroup.recipe.id] === false
        //   }
        //   placeholder={answersGroup.recipe?.placeholder}
        //   onChange={(text) =>
        //     onTextChange(
        //       answersGroup.recipe.id,
        //       text,
        //       answersGroup.recipe.validation_rule,
        //       answersGroup.recipe.recipe_question_id
        //     )
        //   }
        //   id={"answer_" + answersGroup.recipe.id}
        //   value={answersSelectedMap[answersGroup.recipe.id]}
        // />

        <DatePickerInput
          errorMode={questionnaireValidationObject[answersGroup.recipe.id] === false}
          onChange={(text) =>{
            onTextChange(
              answersGroup.recipe.id,
              text,
              answersGroup.recipe.validation_rule,
              answersGroup.recipe.recipe_question_id
              )
            }
          }
          id={"answer_" + answersGroup.recipe.id}
          value={answersSelectedMap[answersGroup.recipe.id]}
        />          
    );

    case "textarea":
      return (
        <div
          style={{ width: "100%" }}
          className="questionnaire-textarea-question"
        >
          <TextArea
            errorMode={
              questionnaireValidationObject[answersGroup.recipe.id] === false
            }
            onTextChange={(answerID, text) => {
              onTextChange(
                answerID,
                text,
                null,
                answersGroup.recipe.recipe_question_id
              );
            }}
            helpblock={answersGroup.recipe.help_block}
            placeholder={
              answersGroup.recipe.label || answersGroup.recipe.long_label
            }
            answerID={answersGroup.recipe.id}
            id={"answer_" + answersGroup.recipe.id}
            value={answersSelectedMap[answersGroup.recipe.id] ? answersSelectedMap[answersGroup.recipe.id] : ""}
          />
        </div>
      );

    default:
      return null;
  }
};

//takes an answer group and renders its content, example:
// answerGroup of type "radio_buttons", will be the anscestor of child (or more) of type radio button
//but this time, those groups act as subquestions
export const RenderAnswerGroupAsSubquestion = ({
  answersGroup,
  onTextChange,
  onAnswerClick,
  onHide,
  onReveal,
  questionnaireValidationObject,
  answersSelectedMap,
}) => {
  useEffect(() => {
    onReveal(answersGroup);

    return () => {
      onHide(answersGroup);
    };
  }, []);

    //For subquestions with children:
  if (answersGroup && answersGroup.children?.length > 0) {
    switch (answersGroup.recipe.type) {
      case "radio_buttons":
        return (
          <div className="questionnaire-subquestion">
            <SubquestionText recipeAnswer={answersGroup.recipe} />
            <RadioButtons
              errorMode={
                questionnaireValidationObject[answersGroup.recipe.id] === false
              }
              onAnswerClick={onAnswerClick}
              children={answersGroup.children}
              answersSelectedMap={answersSelectedMap}
            />
          </div>
        );

      case "select":
        return (
          <div className="questionnaire-subquestion">
            <SubquestionText recipeAnswer={answersGroup.recipe} />
            <Select
              errorMode={
                questionnaireValidationObject[answersGroup.recipe.id] === false
              }
              onAnswerClick={onAnswerClick}
              page={"questionnaire"}
              options={answersGroup.children}
              id={"answer_" + answersGroup.recipe.id}
              initialValue={answersSelectedMap[answersGroup.recipe.value] || ""}
            />
          </div>
        );

  

      case "check_list":
        return (
          <>
            <div className="questionnaire-question-item-text-container  ">
              {answersGroup.recipe.label || answersGroup.recipe.long_label}
            </div>
            <CheckBoxList
              errorMode={
                questionnaireValidationObject[answersGroup.recipe.id] === false
              }
              onAnswerClick={onAnswerClick}
              children={answersGroup.children}
              answersSelectedMap={answersSelectedMap}
            />
          </>
        );
    }



    
    //For subquestions without children:
  } else if (answersGroup && ["textarea","date", "number","text"].includes(answersGroup.recipe?.type)) {
    
    switch (answersGroup.recipe.type){
      case "date":
          return (
            <div className="questionnaire-subquestion">
              <SubquestionText recipeAnswer={answersGroup.recipe} />
              <div>
                <DatePickerInput
                  isBirthdayInput={false}
                  errorMode={questionnaireValidationObject[answersGroup.recipe.id] === false}
                  onChange={(text) =>{
                    onTextChange(
                      answersGroup.recipe.id,
                      text,
                      answersGroup.recipe.validation_rule,
                      answersGroup.recipe.recipe_question_id
                      )
                    }
                  }
                  id={"answer_" + answersGroup.recipe.id}
                  value={answersSelectedMap[answersGroup.recipe.id]} 
                  
                  />
              </div>
            </div>
          );

      case "number":
        return (
          <div className="questionnaire-subquestion">
            <SubquestionText recipeAnswer={answersGroup.recipe} />
            <NumberInput
              maxValue={answersGroup.recipe.validation_rule.max_value}
              minValue={answersGroup.recipe.validation_rule.min_value}
              errorMode={questionnaireValidationObject[answersGroup.recipe.id] === false}
              placeholder={answersGroup.recipe.placeholder}
              onChange={(text) => {
                onTextChange(
                  answersGroup.recipe.id,
                  text,
                  answersGroup.recipe.validation_rule,
                  answersGroup.recipe.recipe_question_id
                );
              }}
              id={"answer_" + answersGroup.recipe.id}
              value={answersSelectedMap[answersGroup.recipe.id]}
            />
          </div>
        );

      case "text":
      case "textarea" :
        return (
          <TextArea
            errorMode={
              questionnaireValidationObject[answersGroup.recipe.id] === false
            }
            helpblock={answersGroup.recipe.help_block}
            placeholder={
              answersGroup.recipe.label || answersGroup.recipe.long_label || answersGroup.recipe.placeholder
            }
            onTextChange={(answerID, text) => {
              onTextChange(
                answerID,
                text,
                null,
                answersGroup.recipe.recipe_question_id
              );
            }}
            answerID={answersGroup.recipe.id}
            id={"answer_" + answersGroup.recipe.id}
            value={answersSelectedMap[answersGroup.recipe.id] ? answersSelectedMap[answersGroup.recipe.id] : "" }
          />
        );
      }
    } 

  return null;
};

//recursive function to get any node from the root question
export const getNode = (root, targetID) => {
  let targetNode;

  if (root?.recipe.id === targetID) {
    //if we found the node, return it
    return root;
  }

  root?.children.forEach((child) => {
    //keep checking starting from every children, and save the result once found
    targetNode = targetNode || getNode(child, targetID);
  });

  return targetNode;
};

export const isQuestionaireBlocking = (questionnaire, answersSelectedMap) => {
  let isBlocking = false;

  questionnaire.forEach((question) => {
    let answers = question.answerGroup.children;

    answers?.forEach((answer) => {
      // the answers? incase we talking about questions type that don't have answers like title or photo diagnos
      let significances = answer.recipe.significances;

      significances.forEach((significance) => {
        if (
          significance.evaluation_type === "contraindications" &&
          answersSelectedMap[answer.recipe.id]
        ) {
          isBlocking = true;
        }
      });
    });
  });

  return isBlocking;
};

export const hairLossPhotoSelectionURLS = [
  clientConfig.IMAGES_URL +
    "uploads/recipe_answer/3669/php0pLzS0.png?abc-v=v7.221122.1.s3",
  clientConfig.IMAGES_URL +
    "uploads/recipe_answer/3670/phpup6lJQ.png?abc-v=v7.221122.1.s3",
  clientConfig.IMAGES_URL +
    "uploads/recipe_answer/3671/phpK2WmOX.png?abc-v=v7.221122.1.s3",
  clientConfig.IMAGES_URL +
    "uploads/recipe_answer/3672/php2KVvbj.png?abc-v=v7.221122.1.s3",
  clientConfig.IMAGES_URL +
    "uploads/recipe_answer/3673/phphDBokf.png?abc-v=v7.221122.1.s3",
  clientConfig.IMAGES_URL +
    "uploads/recipe_answer/3674/phpYNJoPf.png?abc-v=v7.221122.1.s3",
];
