import React, { useContext } from "react";
import "./HeaderCMSHamburgerMenu.css";
import HamburgerMenuCategoryAccordion from "../HamburgerMenuCategoryAccordion";
import HeaderCMSNavigation from "../HeaderCMSNavigation";
import { headerCMSContext } from "../HeaderCMS";


interface HeaderCMSHamburgerMenuProps{
  show:boolean;
}

const HeaderCMSHamburgerMenu:React.FC<HeaderCMSHamburgerMenuProps> = ({show}) => {

  const {headerData} = useContext(headerCMSContext); 

  return (
    <div onClick={(e)=> e.stopPropagation()} className={`hamburger-menu${show ? "-show" : ""}`}>
      <div className="hamburger-menu-head">
          <img className="doktor-logo" src={require("../../../../images/logo-dark.svg").default} alt="DoktorABC - Online Arzt und Apotheken" />
          <HeaderCMSNavigation />
      </div>

      <div className="hamburger-menu-treatments">
        {
          headerData.categoriesByRoot.map((rootCategory: any, index: number) => {
            return (
              <HamburgerMenuCategoryAccordion key={index} rootCategory={rootCategory} />
            )
          })
        }
      </div>

      <ul className="hamburger-menu-links">
        {
          headerData.menuLinks.map((menuLink: any, index: number) => {
            return (
              <li key={index} className="hamburger-menu-link">
                <a href={menuLink.url}>
                  {menuLink.text}
                </a>
              </li>
            )
          })
        }
      </ul>

      <div className="hamburger-menu-footer">
         <a className="hamburger-menu-footer-link">
            <img src={require ("../../../../images/Messages.svg").default} alt="messages" />
            <span>Live Chat</span>
         </a>
         <a href={headerData.hamburgerMenuFooterLinks['front_v4/contact_us.contacts.phone_link']} className="hamburger-menu-footer-link">
            <img src={require ("../../../../images/Phone.svg").default} alt="messages" />
            <span>Telefon</span>
         </a>

         {/* REMOVED WHATSAPP ACCORIDNG TO PRODUCTS REQUEST */}
         {/* <a href={headerData.hamburgerMenuFooterLinks['front_v4/contact_us.contacts.whatsup_link']} className="hamburger-menu-footer-link">
            <img src={require ("../../../../images/Whatsapp.svg").default} alt="messages" />
            <span>Whatsapp</span>
         </a> */}
      </div>
    </div>
  );
};

export default HeaderCMSHamburgerMenu;
