import { useState, useEffect } from "react";
import "./Checkbox.css";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";

export default function Checkbox({
  value,
  id,
  label,
  onClick,
  isValid = null,
  submitClicked = false,
  errorMsg = "",
  errorMode,
  groupName,
  isChosenInCheckboxlist //passed from parent if we wish to uncheck all other checkboxes on click
}) {

  const [checked, setChecked] = useState(value ? true : false);
  const [showError, setShowError] = useState(false);


  useEffect(()=>{
    if(isChosenInCheckboxlist){
      setChecked(true)
    } else {
      setChecked(value ? true : false);
    }
  },[isChosenInCheckboxlist])


  useEffect(() => {
    if (submitClicked && checked === false) {
      setShowError(true);
    }
  }, [submitClicked]);


  return (
    <div className="checkbox-label-container">
      <label className="checkbox-label" htmlFor={id}>
        <input
          type="checkbox"
          name={groupName}
          id={id}
          checked={checked}
          value={value}
          onChange={(event) => {
            setChecked((checked) => !checked);
            onClick(!checked ? '[X]' : '[-]');
          }}
          
        />

        {checked ? (
          <div className="checkbox-checked-background-container">
            <MdCheckBox className="checkbox-checked-logo" />
          </div>
        ) : showError || errorMsg.length > 0 || errorMode ? (
          <div className="checkbox-unchecked-background-container">
            <MdCheckBoxOutlineBlank className="checkbox-error-logo" />
          </div>
        ) : (
          <div className="checkbox-unchecked-background-container">
            <MdCheckBoxOutlineBlank className="checkbox-unchecked-logo" />
          </div>
        )}
        <span className="questionnaire-checkbox-placeholder"> {label} </span>
      </label>
    </div>
  );
}
