import PaymentOptionCard from "../PaymentOptionCard/PaymentOptionCard";
import VideoConsultationAddressShipping from "./VideoConsultationAddressShipping";
import * as translator from "../../../../utils/translator";
import { clientConfig } from "../../../../config";


/* eslint-disable */
//shipping constants

const PERSONAL_ADDRESS_METHOD_INDEX = 1;
const DHL_ADDRESS_METHOD_INDEX = 2;

//payment constants

const ONLINE_PAYMENT_OPTION_INDEX = 0;
const BANK_TRANSFER_OPTION_INDEX = 1;

const ONLINE_PAYMENT_METHOD_ID = clientConfig.paymentsToIDs['DE'].scheme;
const BANK_TRANSFER_METHOD_ID = clientConfig.paymentsToIDs['DE'].wire;
/* eslint-enable */

interface VideoConsultationLayoutProps  {
  isSubscriptionFlow:boolean,
  isTreatmentOTC:boolean,
  methodIDsMap:any,
  updatePrescriptionOnly:any,
  selectedDeliveryMethodIndex:any,
  orderWindowDetails:any,
  updateDeliveryMethodID:any,
  pageState:any,
  onDeliveryMethodChange:any,
  isMethodSelected:any,
  setPrescriptionOnly:any,
  selectedDeliveryMethodID:any,
  selectedPaymentMethod:any,
  paymentImages:any,
  onSubmit:any,
  onPaymentMethodChange:any,
  generateOrderWindow:any,
  children:any,
  language:"de"
}

const VideoConsultationLayout:React.FC<VideoConsultationLayoutProps> = ({
  isSubscriptionFlow,
  isTreatmentOTC,
  methodIDsMap,
  updatePrescriptionOnly,
  selectedDeliveryMethodIndex,
  orderWindowDetails,
  updateDeliveryMethodID,
  pageState,
  onDeliveryMethodChange,
  isMethodSelected,
  setPrescriptionOnly,
  selectedDeliveryMethodID,
  selectedPaymentMethod,
  paymentImages,
  onSubmit,
  onPaymentMethodChange,
  generateOrderWindow,
  children,
  language="de"
}) => {
  return (
    <>
      <p className="shipping-instruction-title">{translator.translate(language, "shipping", "layout/shipping_title")}</p>
      <div className="shipping-container">
        <VideoConsultationAddressShipping
          language={'de'}
          allowPrescriptionOnly={!isSubscriptionFlow && !isTreatmentOTC}
          onClick={() =>
            onDeliveryMethodChange(
              PERSONAL_ADDRESS_METHOD_INDEX,
              methodIDsMap.ROYAL_MAIL_METHOD_ID
            )
          }
          updatePrescriptionOnly={updatePrescriptionOnly}
          pageState={pageState}
          selected={isMethodSelected(
            selectedDeliveryMethodIndex,
            PERSONAL_ADDRESS_METHOD_INDEX
          )}
          params={{
            price: String(orderWindowDetails.treatmentFee) + "€",
          }}
          updateDeliveryMethodID={updateDeliveryMethodID}
        />
      </div>

      <div className="countryLayout-orderWindow">{children}</div>

      <div className="payment-info-container">
        <p className="shipping-instruction-title">{translator.translate(language, "shipping", "layout/select_payment")}</p>


        <PaymentOptionCard
          icons={[
        
            paymentImages.frTransfer
          ]}
          onSubmit={() =>
            onSubmit(BANK_TRANSFER_METHOD_ID, selectedDeliveryMethodID)
          }
          description={
            "Überweisen Sie direkt von Ihrem Konto. Bitte beachten Sie, dass dies bis zu drei Werktage dauern kann."
          }
          title={"Bezahlen Sie per Überweisung"}
          onClick={() => onPaymentMethodChange(BANK_TRANSFER_OPTION_INDEX)}
          selected={isMethodSelected(
            selectedPaymentMethod,
            BANK_TRANSFER_OPTION_INDEX
          )}
          disabled={isSubscriptionFlow}
          phoneInput={false}
          language={"de"}
        />
      </div>
    </>
  );
};

export default VideoConsultationLayout;
