import React, { useContext, useMemo } from 'react'
import { ShippingDataContext } from '../../Shipping';
import ShippingForm from "../ShippingForm/ShippingForm";
import OptionCardCategory from '../OptionCardCategory/OptionCardCategory';
import * as translator from "../../../../utils/translator";
import OptionCard from '../OptionCard/OptionCard';


const SelfPickupShipping = () => {

  const { language, selfPickupPharmacy } = useContext(ShippingDataContext);
  
  const pharmacyDataForOptionCard = useMemo(() => {
    if (selfPickupPharmacy) {
      const shipperAddress = selfPickupPharmacy.shipper_address //selfPickupPharmacy is just the profile_settings column
      const title = `${shipperAddress.city} Apotheke: ${selfPickupPharmacy.title},  ${shipperAddress["street1"]}, ${shipperAddress.zip}, ${shipperAddress.city}`
      const description = shipperAddress.shipping_page_description;
      
      return {
        title,
        description
      }
    }

  }, [selfPickupPharmacy])

  return (
    <div className={"delivery-panel-outer-container selected-pm"}>

      {/* Self pickup at pharmacy */}
      <OptionCardCategory
        title={translator.translate(language, "shipping", "serviceOptions/self_pickup")}
        selected={true}
        onClick={() => {}}
      />

      <div className="delivery-form-personal-address ">
        <div className='hideable-content'>
          <p className="delivery-options-title">Ihre gewählte Apotheke</p>
          <OptionCard
            icon={""}
            badgeText={""}
            isOptionSelected={true}
            data={{
              value: pharmacyDataForOptionCard!.title || "",
              description: pharmacyDataForOptionCard!.description || "",
            }}
            onClick={() => {}}
          />

          <p className="delivery-options-title" style={{marginTop:15, marginBottom:0}}>Rechnungsdetails</p>

          <ShippingForm
            language={language}
            selected={true}
            hideToggle
          />
        </div>
      </div>
    </div>

  )
}

export default SelfPickupShipping