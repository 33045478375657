import "./DrugDosagesPanel.css";
import { useContext, useEffect, useState } from "react";
import activeLogo from "../../../../icons/Active.svg";
import * as translator from "../../../../utils/translator";
import { MainTracker } from "../../../../PixelTrackers/MainTracker";
import { CatalogSettingContext } from "../../ChooseTreatmentRouter";

const MAX_CHARS_FOR_SHORT_DOSAGE_STRING = 7;

const DrugDosagesPanel = ({
  products,
  selectedDosage,
  defaultDosages,
  selectedDrug,
  listOfProductDosages,
  changeDosageCallback,
  changePacketsListCallback,
  colLayout = false,
  language = "de",
}) => {

  const catalogSettings = useContext(CatalogSettingContext);
  const [selectedDrugDosageIndex, setSelectedDrugDosageIndex] = useState(null);

  const [outOfStockDosages, setOutOfStockDosages] = useState({});

  function isDosageInStock(dosage) {
    let dosageInStock = false;

    products[selectedDrug][dosage].forEach((prod_item, index) => {
      if (prod_item.in_stock === 1 || catalogSettings.allowPrescriptionForOutOfStock) {
        dosageInStock = true;
      }
    });

    return dosageInStock;
  }

  function markAllOutOfStockDosages() {
    let copy = { ...outOfStockDosages };

    listOfProductDosages.forEach((dosage, index) => {
      if (selectedDrug !== "no-preference" && !isDosageInStock(dosage)) {

        if (!copy[selectedDrug]) {
          copy[selectedDrug] = {};
        }

        copy[selectedDrug][dosage] = true;
      }
    });

    setOutOfStockDosages({ ...copy });
  }

  //get a dosage in which there is a product item in stock, otherwise null
  function getAlternativeDosageInStock() {
    let alternativeDosage = null;
    let alternativeDosageIndex = null;

    listOfProductDosages.forEach((dosage, index) => {
      if (selectedDrug !== "no-preference" && isDosageInStock(dosage)) {
        alternativeDosage = dosage;
        alternativeDosageIndex = index;
      }
    });

    return { alternativeDosage, alternativeDosageIndex };
  }

  function isDosageStringLongLine() {
    let useColLayout = false;

    listOfProductDosages.forEach((dosage) => {
      if (dosage.length > MAX_CHARS_FOR_SHORT_DOSAGE_STRING) {
        useColLayout = true;
      }
    });

    return useColLayout;
  }

  useEffect(() => {
    //mark all out of stock dosages for styling
    markAllOutOfStockDosages();

    //if a drug has a default dosage and its in stock, then select it by default
    if (
      selectedDrug !== "no-preference" &&
      defaultDosages[selectedDrug] &&
      isDosageInStock(defaultDosages[selectedDrug])
    ) {
      let index = listOfProductDosages.indexOf(defaultDosages[selectedDrug]);
      setSelectedDrugDosageIndex(index);
      changeDosageCallback(defaultDosages[selectedDrug]);
      changePacketsListCallback(
        products[selectedDrug][defaultDosages[selectedDrug]]
      );
    }

    //otherwise , if there is no default dosage or its out of stock, find an alternative
    else {
      let { alternativeDosage, alternativeDosageIndex } =
        getAlternativeDosageInStock();

      if (alternativeDosage) {
        setSelectedDrugDosageIndex(alternativeDosageIndex);
        changeDosageCallback(alternativeDosage);
        changePacketsListCallback(products[selectedDrug][alternativeDosage]);
      }
    }
  }, [selectedDrug]);

  return (
    <div className="drug-dosage-panel-container">
      <div
        className={
          colLayout || isDosageStringLongLine()
            ? "drug-dosage-col-layout"
            : "drug-dosage-container"
        }
      >
        <p className="orderWindow-container-title">
          {translator.translate(
            language,
            "default",
            "DrugSelectionSidePanel/select-dosage"
          )}
        </p>

        <div className="drug-dosage">
          {listOfProductDosages.map((dosage, index) => {
            return (
              <div key={index} className="li-container">
                {index === selectedDrugDosageIndex ? (
                  <img className={"check-mark"} src={activeLogo} />
                ) : null}
                <li
                  key={index}
                  className={
                    (index === selectedDrugDosageIndex
                      ? "drug-dosage-item-selected"
                      : "drug-dosage-item") +
                    (outOfStockDosages[selectedDrug] &&
                    outOfStockDosages[selectedDrug][dosage]
                      ? " out-of-stock"
                      : "")
                  }
                  onClick={
                    !(
                      outOfStockDosages[selectedDrug] &&
                      outOfStockDosages[selectedDrug][dosage]
                    )
                      ? () => {
                          MainTracker.superProperties({
                            "Product Dosage": dosage,
                          });
                          MainTracker.track(
                            "click",
                            "Click change Product Dosage"
                          );
                          changeDosageCallback(dosage);
                          setSelectedDrugDosageIndex(index);
                          changePacketsListCallback(
                            products[selectedDrug][dosage]
                          );
                        }
                      : null
                  }
                >
                  {dosage}
                </li>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DrugDosagesPanel;
