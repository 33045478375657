import CMSModal from './CMSModal';
import Sprite from "../../images/StartConsultation/sprite-main.png";

type Doctor = {
	name: string;
	number: string;
	address: string;
};

export type DoctorsModalInfo = {
	title: string;
	triggerText: string;
	doctors: Doctor[];
};
type Props = {
	doctorsModalInfo: DoctorsModalInfo;
};
const PrescribingPhysiciansModalTrigger: React.FC<Props> = ({ doctorsModalInfo }) => {

	const content = (
		<div className="max-h[190px]">
			{doctorsModalInfo.doctors?.map(doctor => (
				<div className="popup-info">
					<strong className="name">{doctor.name}</strong>
					{Boolean(doctor.number) && <p className="address">{doctor.number}</p>}
					<p className="address">{doctor.address}</p>
				</div>
			))}
	</div>
	);
	return (
		<CMSModal
			title={doctorsModalInfo.title}
			className="max-w-[530px]"
			centerTitle={true}
			content={content}
			trigger={
				<a id="open-modal-doctors" href="#" className="relative text-[#656d79] no-underline group">
					{doctorsModalInfo.triggerText}
					<span
						id="animated-underline"
						className="absolute left-0 bottom-0 h-[1px] w-0 bg-[#656d79] transition-all duration-500 ease-in-out group-hover:w-full"
					/>
				</a>
			}
			image={<img
				src={Sprite}
				className="mb-4 w-[63px] h-[65px] object-none object-[-815px_-137px]"
				alt="Sprite"
			/>}
		/>
	);
};

export default PrescribingPhysiciansModalTrigger;
