import React, { useState, useEffect, createContext } from 'react';
import "./HeaderCMS.css";
import HeaderCMSLinks from './HeaderCMSLinks';
import HeaderCMSNavigation from './HeaderCMSNavigation';
import HeaderCMSModals from '../../Modals/HeaderCMSModals/HeaderCMSModals';
import * as translator from "../../../utils/translator";
import * as http from "../../../utils/http";
import { clientConfig } from '../../../config';

interface HeaderCMSProps {
  country: string;
  refreshData?: boolean;
}

type MenuLink = {
  text: string;
  url: string;
}

type HeaderData = {
  menuLinks: Array<MenuLink>;
  rootCategoriesPairs: any;
  categoriesByRoot: any[];
  unreadNotifications: number;
}

const TREATMENTS = "treatments";
const SEARCH = "search";
const HAMBURGER = "hamburger";
type ModalName = typeof TREATMENTS | typeof SEARCH | typeof HAMBURGER | "";

// Create the headerCMSContext outside of the component
export const headerCMSContext = createContext<any>(null);


const HeaderCMS: React.FC<HeaderCMSProps> = ({ country, refreshData }) => {
  const [currentOpenModal, setCurrentOpenModal] = useState<ModalName>("");
  const [headerData, setHeaderData] = useState<HeaderData | null>(null);
  const lastOrder = (window as any).eventsTracking.getData("last_order")
  const lastPaidOrder = (window as any).eventsTracking.getData("last_paid_order")
  const cartID = (window as any).eventsTracking.getData("cart_id")
  const customerID = (window as any).eventsTracking.getData("customerID")
  const language = translator.getLanguageByCountry(country)

  useEffect(() => {
    http
      .getFromServer(`header/${language}/${country}`, [http.MIDDLEWARES.CUSTOMER_ID])
      .then((response) => {
        setHeaderData(response.data);
      })
      .catch((error) => {
        window.clientLogger.error("Error in MyAccountLayout: ", error);
      })
      .finally(() => {

      });
  }, [refreshData]);

  const handleModals = (modalName: ModalName) => {
    setCurrentOpenModal((currentModal) =>
      currentModal === modalName ? "" : modalName
    );
  };

  const redirectToCart = () =>{

    const isLastNotOrderPaid = (lastOrder && lastOrder !== lastPaidOrder) ? true : false; 
  
    http
        .postToServer(`checkout/step`,
        {
          language: language,
          country: country.toLowerCase(),
          order_id: isLastNotOrderPaid ? lastOrder : null,
          customerID
        },
        [
          http.MIDDLEWARES.CART_ORDER,
        ])
        .then((response) => {
          window.location.href = "/" + response.data.resumePath;
        })
        .catch((error) => {
          window.clientLogger.error('fail in HeaderCMS/redirectToCart with error : ', error)
        })

  }

  const getHeaderClassNameByModals = () => {
    if (currentOpenModal === HAMBURGER) {
      return "cms-header-container-disabled";
    } else if (currentOpenModal === "") {
      return "cms-header-container";
    } else {
      return "cms-header-container-modal-active";
    }
  };

  return (
    <headerCMSContext.Provider
      value={{
        handleModals,
        country,
        currentOpenModal,
        shoppingBagNotification: (cartID || (lastOrder && lastOrder !== lastPaidOrder)) ? true : false,
        myAccountTotalNotifications: headerData?.unreadNotifications || 0,
        headerData,
        redirectToCart,
      }}
    >
      <HeaderCMSModals
        cancelModal={() => setCurrentOpenModal("")}
      />

      <header className={getHeaderClassNameByModals()}>
        {/* This HeaderCMSNavigation is for mobile only */}
        <HeaderCMSNavigation isMobile={true} />
        {/* End of Mobile only HeaderCMSNavigation*/}

        <div
          className="doktorabc-logo-container"
          onClick={() => (window.location.href = clientConfig.MAIN_WEBSITE_URL + "/" + country)}
        >
          <img
            className="doktorabc-logo"
            src={currentOpenModal !== "" ? require("../../../images/logo-white.svg").default : require("../../../images/logo-dark.svg").default}
            alt="DoktorABC - Online doctor and pharmacies"
          />
        </div>
        
        <HeaderCMSLinks menuLinks={headerData?.menuLinks || []} />
        <HeaderCMSNavigation />
        
      </header>
    </headerCMSContext.Provider>
  );
};

export default HeaderCMS;
