import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import * as http from "../../../../utils/http";
import * as translator from "../../../../utils/translator"
import "./ChooseTreatmentReorder.css"
import ReorderProductCard from "../ReorderProductCard/ReorderProductCard";
import DrugSubscription from "../../../ChooseTreatmentVariants/components/DrugSubscription/DrugSubscription";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";


const ChooseTreatmentReorder = () => {
  const navigate = useNavigate();
  const [submitButtonLoading,setSubmitButtonLoading] = useState<boolean>(false);
  const { reorderData, language, country, setStickyNotificationDetails,setShowSubscriptionModal,getMaxDiscountOfSubscriptionPlans,setHeaderStep } = useOutletContext<any>(); //context of ReorderLayout
  const productItem = reorderData.orderProductItem;
  const defaultSelectedPlanID = reorderData.orderProductItemSubscriptionPlans[reorderData.orderProductItem.product_item_id][0]?.id || null;
  const [selectedSubscriptionPlanID,setSelectedSubscriptionPlanID] = useState<number | string | null>(defaultSelectedPlanID);
  const productItemSubscriptionPlans = reorderData.orderProductItemSubscriptionPlans;

  const onlyPrescription = reorderData.isOnlyPrescription;
  const displayPrice = onlyPrescription ? productItem.treatment_fee : (productItem.product_item_price + productItem.treatment_fee)
  
  const getDiscountOfSelectedPlan = () => {
    let selectedPlan = (productItemSubscriptionPlans[productItem.product_item_id].filter((subscriptionPlan:any) => subscriptionPlan.product_plan_id === selectedSubscriptionPlanID))[0];
    if(selectedPlan){
      return selectedPlan.discount;
    }
    return 0
  }

  useEffect(()=>{
    setHeaderStep("Treatment");

    if(productItemSubscriptionPlans[productItem.product_item_id].length){
      setShowSubscriptionModal(true); //if there are plans to the product show the subscription modal
    }

  },[])

  const onSubmit = () => {
    let chooseTreatmentPostBody = {
      product_item_id: productItem.product_item_id,
      item_price: productItem.product_item_price ,
      subscription_plan_id: selectedSubscriptionPlanID === "one-time" || !selectedSubscriptionPlanID ? null : selectedSubscriptionPlanID,
      treatment_fee: productItem.treatment_fee,
      catalog_id: productItem.category_catalog_id,
      applied_subscription_discount: getDiscountOfSelectedPlan(),
      item_confirmed: 1,
      is_sftd: selectedSubscriptionPlanID === "one-time" ? 1 : 0,
      product_in_stock: reorderData.isOnlyPrescription ? 0 : 1, //To turn on the buy prescription out of stock mechanism in shipping
      only_prescription: reorderData.isOnlyPrescription ? 1 : 0,
      allow_only_prescription: reorderData.isOnlyPrescription ? 1 : 0 //Customer should be able to order only prescription if the original order was prescription only
    } 

    //The data saved and posted to the same endpoint as the other original choose treatment pages.
    http
    .postToServer(
      `products/${country}/${language}/${reorderData.catalogMention}/false`,
      chooseTreatmentPostBody,
      [http.MIDDLEWARES.CART_ORDER]
    )
    .then((res) => {
        navigate(`/shipping/${country}/${language}?reorder=true`);
    })
    .catch((err) => {
      setStickyNotificationDetails((currentDetails: any) => ({
          show: true,
          title: translator.translate(language, "default", `StickyNotification/danger_title`),
          content: "Server Failed",
          level: "error",
          showInfoIcon: false,
          refresh: !currentDetails.refresh
      }));
    })
    .finally(() => {
      setSubmitButtonLoading(false);
    });
  }

  return (
    <>
      <ReorderProductCard
        productImgSrc={productItem.base_path + "/" + productItem.name}
        productPrice={displayPrice}
        productQuantity={productItem.custom_quantity_title}
        productTitle={productItem.title}
      />


      {/* Diffrent plan link */}
      <a 
      className="change-treatment-link" 
      href={`/select_treatment/${country}/${language}/${reorderData.catalogMention}`}>
        {translator.translate(language,"reorder","ChooseTreatmentReorder/different_treatment")}
      </a>
      
      {/* Choose treatment title */}
      {productItemSubscriptionPlans[productItem.product_item_id].length ?
         <h1>{translator.translate(language,"reorder","ChooseTreatmentReorder/ct_plan")}</h1>
        :
        null
       }


      {/* Treatment plans disclaimer text */}
      {
        getMaxDiscountOfSubscriptionPlans() > 0 ?
        <p className="treatment-plan-disclaimer"> 
          {translator.translate(language,"reorder","ChooseTreatmentReorder/treatment-plan-disclaimer").replace("::discount",getMaxDiscountOfSubscriptionPlans())}
        </p> 
        :
        null
      }
      

      {/* Render plans */}
      <div className="treatment-plans-container">
        {
          country.toLowerCase() === "de" &&  productItemSubscriptionPlans[productItem.product_item_id].length ?
          <DrugSubscription
            showDeliveryErrorMessage={''}
            setShowDeliveryMessage={()=>{}}
            selectedSubscriptionPlanID={selectedSubscriptionPlanID}
            setSelectedSubscriptionPlanID={setSelectedSubscriptionPlanID}
            productItemSubscriptionPlans={productItemSubscriptionPlans}
            selectedPackItemObject={productItem}
            selectedPackItem={productItem.product_item_id}          
            productName={productItem.title}
            productQuantity={productItem.quantity}
          />
          :
          null
        }
        
        <SubmitButton
          id="reorder-submit"
          className="reorder-submit"
          language={language}
          placeholder={translator.translate(language,"reorder","ChooseTreatmentReorder/submitButtonText")}
          showArrow={true}
          width={"auto"}
          backgroundColor={"var(--primary-lightgreen)"}
          onSubmit={onSubmit}
          loading={submitButtonLoading}
        />
      </div>
    </>
  );
};

export default ChooseTreatmentReorder;
