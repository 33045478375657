import ShippingLayout from "../ShippingLayout/ShippingLayout";
import VideoConsultationLayout from "../VideoConsultationLayout/VideoConsultationLayout";
import { LayoutByCountryContext } from "../../Shipping";
import { useContext } from "react";

const LayoutByCountry = (props) => {

  const Attributes = useContext(LayoutByCountryContext);
  const compeleteAttributes = {...Attributes, ...props}

  switch (Attributes.countryID.toUpperCase()) {
    case "DE":
    case "CH":
    case "FR":
    case "UK":
      if (Attributes.catalog?.is_video_consultation) {
        return <VideoConsultationLayout {...compeleteAttributes} />;
      } else {
        return <ShippingLayout {...compeleteAttributes} />;
      }
  }
};

export default LayoutByCountry;
