/* VwoTracker TRACKER   */
const EVENTS = ["Placed Order"];

export const track = (eventType, eventName, data) => {
    if (eventType === "pv") {
        
    } else {
        if (EVENTS.includes(eventName)) {
        window.VWO = ['track.revenueConversion', data.revenue]; //revenue

        if(!window['_vis_opt_queue']){
            window._vis_opt_queue = [];
        }
        window._vis_opt_queue.push(
            function(){
                window._vis_opt_revenue_conversion(`{${data.revenue}}`); //revenue
            }
        )
    }
    }
};