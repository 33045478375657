// Example of a TextArea component definition
import React, { useEffect, useState } from "react";
import "./TextArea.css";

export default function TextArea({
  placeholder,
  disabled = false,
  id = "",
  mandatory,
  value,
  onChange,
  onBlur = () => {},
  touched = false,
  errorMsg = "",
}) {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(value || false);
    onBlur();
  };

  const handleOnChange = (event) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    setIsInputFocused(value || isInputFocused);
  }, [value]);

  return (
    <div id={`${id}-container`} className={disabled ? "text-area-v2-disabled" : "text-area-v2"}>
      <label className="textarea-label-placeholder">
        <span className={isInputFocused ? "textarea-placeholder-focused" : "textarea-placeholder"}>
          {placeholder}
          <span className="credential-input-mandatory-astrix">
            {mandatory ? "*" : null}
          </span>
        </span>
      </label>
      <textarea
        id={id}
        onBlur={handleBlur}
        value={value}
        className={
          errorMsg.length === 0 || !touched
            ? "textarea-input"
            : "textarea-input-error"
        }
        onChange={handleOnChange}
        onFocus={handleFocus}
        disabled={disabled}
      />

      {errorMsg && touched && (
        <label className="textarea-error-label">
          <p className="textarea-error-message">{errorMsg}</p>
        </label>
      )}
    </div>
  );
}
