import "./Footer.css"
import * as translator from "../../utils/translator";
import { useNavigate } from "react-router-dom";

interface FooterProps {
    page:string;
    language?:string;
    showDiscountDisclaimer?:boolean
}

export const Footer:React.FC<FooterProps> = ({language,page,showDiscountDisclaimer=false}) => {

    const navigate = useNavigate()
    const getFooterStylingByPage = () => {
        if (page === "Questionnaire" || page === "RegistrationPage" ) {
            return {
            footerJustifyContent: "space-between",
            showFooterReturn: true,
            footerTextAlign: "center",
            };
        }

        else if (page === "ChooseTreatment" || page === "ChooseTreatmentLandingPage" || page === "MyAccount") {
            return {
            footerJustifyContent: "center",
            showFooterReturn: false,
            footerTextAlign: "center",
            };
        }

        else if (page === "Shipping") {
            return {
            footerJustifyContent: "space-between",
            showFooterReturn: true,
            footerTextAlign: "end",
            };
        }


        else{
            return {
                footerJustifyContent: "center",
                showFooterReturn: false,
                footerTextAlign: "center",
            };
        }
    };

    let footerStyling = getFooterStylingByPage();
    const nowYear = new Date().getFullYear();


  return (
    <footer className="footer-container">
        <div style={{justifyContent:footerStyling.footerJustifyContent}} className="footer">

            { footerStyling.showFooterReturn && 
                <a className="footer-return" onClick={() => navigate(-1)}>{translator.translate(language, "default", "Footer/return")}</a>
            }

            <div style={{textAlign:footerStyling.footerTextAlign}  as React.CSSProperties } className="footer-text-container">
            {
                showDiscountDisclaimer && language ?
                <span className="footer-discount_disclaimer">
                    {translator.translate(language, "default", "SubscriptionModal/modal_condition_1")}<br/>
                    {translator.translate(language, "default", "SubscriptionModal/modal_condition_2")}
                </span>
                :
                null
            }
             <span style={{textAlign:footerStyling.footerTextAlign}  as React.CSSProperties } className="footer-copyright">
                © {nowYear} DoktorABC.com 
             </span>
            </div>
         
        </div>
    </footer>
  )
}


export default Footer;