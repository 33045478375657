import React from 'react';

interface ConversationTabItemProps {
  iconSrc:string;
  iconAlt:string;
  selected:boolean;
  tabTitle:string
  onClick: () => void;
}

const ConversationTabItem: React.FC<ConversationTabItemProps> = ({iconSrc,iconAlt,tabTitle,selected,onClick}) => {
  return (
    <div
      onClick={onClick}
      className={"conversation-tab-item" + (selected ? " selected-tab-item" : "")}
    >
      <img src={iconSrc} alt={iconAlt} />
      <span>{tabTitle}</span>
    </div>
  );
};

export default ConversationTabItem;
