import React from 'react';
import "./BadgeNew.css";

interface BadgeNewProps{
    show : boolean;
}

const BadgeNew:React.FC<BadgeNewProps> = ({show}) => {
  if(show){
    return (
        <div className="badge-new">Neu</div>
      )
    
  } else {
    return <></>
  }
}

export default BadgeNew