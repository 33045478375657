import checkEnevelope from "../../../../images/checkEnvelope.svg";

const ThankyouMainTitleWidget = ({thankyouTitle, thankyouSubTitle} 
    :{thankyouTitle:string, thankyouSubTitle:string} ) => {
    return (
        <div className="thankyou-titles-container">
            <img src={checkEnevelope} alt="thankyou" />
            <p className="thankyou-title">
                {thankyouTitle}
            </p>
            <p className="thankyou-subtitle">
                {thankyouSubTitle}
            </p>
        </div>
    )
}

export default ThankyouMainTitleWidget