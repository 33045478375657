import ThankYouStep from "../ThankYouStep/ThankYouStep";

type Props = {
    placeHoldersMap: Record<string, string>,
    isCannaleoPreSelected?: boolean,
    isEPrescriptionOnly?: boolean,
    language?: string,
    page?: string,
    isOrderOnlyPrescription?: boolean,
    isERecipe?: boolean,
    isSelfPickUp?: boolean
}

const ThankyouStepsWidget : React.FC<Props> = ({ placeHoldersMap, isCannaleoPreSelected = false, isEPrescriptionOnly = false ,language = 'de', page = 'thankyou', isOrderOnlyPrescription = false, isERecipe = false, isSelfPickUp = false }) => {
    return (
        
        <div className="thankyou-steps">
            <ThankYouStep placeHoldersMap={placeHoldersMap} isCannaleoPreSelected={isCannaleoPreSelected} isEPrescriptionOnly={isEPrescriptionOnly} language={language} page={page} stepNumber={1} />
            <ThankYouStep placeHoldersMap={placeHoldersMap} isCannaleoPreSelected={isCannaleoPreSelected} isEPrescriptionOnly={isEPrescriptionOnly} language={language} page={page} stepNumber={2} />
            <ThankYouStep placeHoldersMap={placeHoldersMap} isCannaleoPreSelected={isCannaleoPreSelected} isSelfPickUp={isSelfPickUp} isEPrescriptionOnly={isEPrescriptionOnly} language={language} page={page} stepNumber={3} />

            {page === 'wire' && <ThankYouStep placeHoldersMap={placeHoldersMap} isEPrescriptionOnly={isEPrescriptionOnly} language={language} page={page} stepNumber={4} />}
        </div>
    );
};

export default ThankyouStepsWidget;
