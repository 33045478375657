import SubmitButton from "../SubmitButton/SubmitButton";
import "./NoPreferenceTreatmentInfo.css";
import { loadCustomerFromSession } from "../../reducers/CustomerReducer";
import { DefaultProductContext } from "../../pages/ChooseTreatmentVariants/ChooseTreatment/ChooseTreatment";
import * as http from "../../utils/http";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";

const NoPreferenceTreatmentInfo = () => {
  const defaultProductItem = useContext(DefaultProductContext);
  const { country, language, category } = useParams();
  const navigate = useNavigate();
  const [submitLoading, setSubmitButtonLoading] = useState(false);
  const onSubmit = () => {
    setSubmitButtonLoading(true);

    let data = {
      product_item_id: defaultProductItem.product_items_id,
      item_price: 0,
      subscription_plan_id: null,
      treatment_fee: defaultProductItem.treatment_fee,
      catalog_id: defaultProductItem.catalog_id,
      applied_subscription_discount: 0,
      item_confirmed: 1,
      no_preference: 1,
      only_prescription: 1,
    };

    http
      .postToServer(`products/${country}/${language}/${category}/false`, data, [
        http.MIDDLEWARES.CART_ORDER,
      ])
      .then((res) => {
        let customerFromSession = loadCustomerFromSession();
        if (!customerFromSession) {
          // if no customer id send to registration
          navigate(`/sign_up/${country}/${language}`);
        } else {
          navigate(`/shipping/${country}/${language}`);
        }
      })
      .catch((err) => {
        setSubmitButtonLoading(false);
        window.clientLogger.error("Error in NoPreferenceTreatmentInfo: ", err);
      });
  };

  return (
    <div className="noPreferenceInfo-container">
      <div className="noPreferenceInfo-texts">
        <p className="noPreferenceInfo-title">
          Informations pour Aucune préférence de traitement
        </p>

        <p className="noPreferenceInfo-description">
          En choisissant cette option, le médecin vous prescrira le traitement
          le plus adapté en fonction des réponses fournies dans votre
          questionnaire médical.
        </p>
      </div>

      <SubmitButton
        placeholder={"PROCÉDER AU PAIEMENT "}
        showArrow
        loading={submitLoading}
        width={"90%"}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default NoPreferenceTreatmentInfo;
