import React, { useState } from 'react'
import "./LoginPage.css";
import {useParams,Link} from "react-router-dom";
import LoginForm from '../../components/LoginForm/LoginForm';
import ForgotPasswordModal from '../../components/Modals/ForgotPasswordModal/ForgotPasswordModal';
import Footer from '../../components/Footer/Footer';
import StickyNotification from '../../components/StickyNotification/StickyNotification';
import * as translator from "../../utils/translator";
import HeaderCMS from '../../components/Headers/HeaderCMS/HeaderCMS';

const LoginPage = () => {

  const {country} = useParams();
  const language = translator.getLanguageByCountry(country);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [stickyNotificationDetails, setStickyNotificationDetails] = useState({ // global state for sticky handling
    show: false,
    title: "",
    content: "",
    level : "",
    showInfoIcon:true,
    refresh:false,
  });
  


  const loginInfoCards = [ 
    {
      title:translator.translate(language, "authentication", 'LoginPage/login_info_card_1_title'),
      content:translator.translate(language, "authentication", 'LoginPage/login_info_card_1_content'),
      iconSrc:require("../../icons/Edit-Green.svg").default
    },
    {
      title:translator.translate(language, "authentication", 'LoginPage/login_info_card_2_title'),
      content:translator.translate(language, "authentication", 'LoginPage/login_info_card_2_content'),
      iconSrc:require("../../icons/Orders-Green.svg").default},
    {
      title:translator.translate(language, "authentication", 'LoginPage/login_info_card_3_title'),
      content:translator.translate(language, "authentication", 'LoginPage/login_info_card_3_content'),
      iconSrc:require("../../icons/Clock-Green.svg").default},
    {
      title:translator.translate(language, "authentication", 'LoginPage/login_info_card_4_title'),
      content:translator.translate(language, "authentication", 'LoginPage/login_info_card_4_content'),
      iconSrc:require("../../icons/Message-Green.svg").default
    },
  ]

 

  if(language && country){
    return (
      <>
      <div className="login-page">
        <HeaderCMS 
          country={country}
        />  

          <StickyNotification
              showNotification={stickyNotificationDetails.show}
              title={stickyNotificationDetails.title}
              onClick={() => setStickyNotificationDetails((currentDetails:any) => { return {...currentDetails, show:false}})}  
              content={stickyNotificationDetails.content}
              level={stickyNotificationDetails.level}
              showInfoIcon={stickyNotificationDetails.showInfoIcon}
              refresh={stickyNotificationDetails.refresh}
          />

          {
            showForgotPasswordModal &&           
            <ForgotPasswordModal
              closeModal={() => setShowForgotPasswordModal(false)}
              navigationCallback={() => { }} 
              language={language} 
            />  
          }
          
          <div className="login-content-container">
            <div className="login-content">
              <div className="login-form-container">
                  <span className="login-form-container-title">{translator.translate(language, "authentication", 'LoginPage/login_form_title')}</span>
                  <LoginForm 
                    language={language} 
                    submitButtonPlaceholder={translator.translate(language, "authentication", 'LoginPage/submit')}
                    setStickyNotificationDetails={setStickyNotificationDetails}
                  />

                  <span onClick={()=> setShowForgotPasswordModal(true)} className="forgot-password-button">{translator.translate(language, "authentication", 'LoginPage/forgot_password')}</span>
                  <span className="new-to-doktorabc">
                    {translator.translate(language, "authentication", 'LoginPage/new_to_doktorabc')}
                    <Link to={`/${country}/register`} className="register-text">
                    {translator.translate(language, "authentication", 'LoginPage/register_now')}
                    </Link>
                  </span>

              </div>

              <div className="login-info">
                <span className="login-info-title">
                  {translator.translate(language, "authentication", 'LoginPage/login_info_title')}
                </span>

                <div className="login-info-cards-container">
                  {
                    loginInfoCards.map((loginInfoCard:any,index:number) => (
                      <div key={index} className="login-info-card">
                        <img id="login-info-card-icon" src={loginInfoCard.iconSrc} alt="icon"/>
                        <div className="login-info-card-description">
                            <span className="login-info-card-content-title">{loginInfoCard.title}</span>
                            <p className="login-info-card-content-content">{loginInfoCard.content}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              
              
              </div>
            </div>
          </div>


          <Footer page="CheckoutRegister" language={country} />  

      </div>
      </>
    )
  } else {
    return <></>
  }
}

export default LoginPage