import "./TreatmentOptionsInfo.css";
import {VscQuote} from 'react-icons/vsc';

const TreatmentOptionsInfo = ({ doctorImg, isExpressReorderFlow = false }) => {
  return (
    <div className="treatment-options">
      <h1 className="treatment-options-title">Behandlungsoptionen</h1>
      <div className="coupon-message-container">
        {!isExpressReorderFlow ? 
        <div className="coupon">
          <div className="gift-icon">🎁</div>

          <h3 className="coupon-message">
          Der Coupon-Rabatt wird in den nächsten Schritten angewendet. 
          </h3>
        </div>
        :
        null
        }
        
        <p className="article-quote"><VscQuote className='upward-quote'/>
          Für diejenigen, die in der Vergangenheit noch nie ED-Medikamente
          verwendet haben, verschreiben die Ärzte auf unserer Plattform für
          gewöhnlich das Medikament Viagra (Wirkstoff Sildenafil).
        </p>

        <p className="article-quote">
          74% der Patienten, welche die 50mg-Dosis von Viagra einnehmen,
          berichteten über eine Verbesserung ihrer Erektion.*
        </p>

        <p className="article-quote">
          Eine Anfangsdosis von 50mg ist die gängigste für Erstanwender des
          Medikaments, der Arzt wird entscheiden, ob diese Dosis angemessen ist.
          <VscQuote className='downward-quote'/>
        </p>
      </div>

      <div className="img-container">
        <img src={doctorImg} alt="" className="dr-img" />
        <div className="doctor-info">
          <p className="doctor-name">-Hasan Igde</p>
          <p className="doctor-role">Medical consultant, urologist</p>
        </div>
      </div>
    </div>
  );
};

export default TreatmentOptionsInfo;
