import React,{useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import Questionnaire from "../../../Questionnaire/Questionnaire";
import MyAccountContentHeader from "../MyAccountContentHeader/MyAccountContentHeader";
import "./MyAccountQuestionnaireDefaults.css";
import * as http from "../../../../utils/http";
import * as translator from "../../../../utils/translator";

const MyAccountQuestionnaire = () => {
  const { setActiveMenuLinkId, setStickyNotificationDetails ,myAccountData,country,language} = useOutletContext<any>();
    
  useEffect(() => {
    setActiveMenuLinkId("questionnaire-defaults");
    window.scrollTo(0, 0);
  },[]);


  const onSubmitOverride = async (wasRefilled:boolean,bundledQuestionnaireForSending:any[],files:Array<any>) => {
    http
    .postMultiPartsToServer(
      `account-update/questionnaire-defaults`,
      {
        questionnaire: bundledQuestionnaireForSending,
        localeID:myAccountData.locale.locale_id,
      },
      files,
      [http.MIDDLEWARES.CUSTOMER_ID]
    )
    .then((res) => {
      setStickyNotificationDetails((currentDetails : any) => ({
        show: true,
        title: "",
        content: "Patient record updated successfully.",
        level : res.data.message,
        showInfoIcon:false,
      }))
    
    })
    .catch((err) => {
      window.clientLogger.error("error submit my account questionnaire",err)
    })
    .finally(()=>{
    });
  }


  const questionnaireDefaults = {
    flattenedQuestionnaire: myAccountData.questionnaireDefaults.flattenedQuestionnaire,
    questionAnswersObject: myAccountData.questionnaireDefaults.questionAnswersObject,
    country,
    language,
  }

  return (
    <>
      <div className="account-content">
        <MyAccountContentHeader
          title={translator.translate(
            language,
            "my_account",
            "QuestionnaireDefaultSettings/title"
          )} 

          description={translator.translate(
            language,
            "my_account",
            "QuestionnaireDefaultSettings/subTitle"
          )} 
          
        />
        <div className="questionnaire-defaults-container">
        <Questionnaire 
          submitButtonText={translator.translate(language, "my_account", "QuestionnaireDefaultSettings/submit_button_text")}
          myAccountQuestionnaireDefaults={questionnaireDefaults}
          onSubmitOverride={onSubmitOverride}
          prefilledQuestionnaire={myAccountData.questionnaireDefaults.prefilledQuestionnaire}
        />
        </div>
      </div>
    </>
  );
};

export default MyAccountQuestionnaire;
