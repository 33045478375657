import CMSModal from './CMSModal';

export type LimitationsModalInfo = {
	main_title: string;
	triggerText: string;
	service: {
		title: string;
		paragraph: string;
	};
	procedure: {
		title: string;
		paragraph: string[];
		bullets: string[];
	};
};

type Props = {
	limitationsInfo: LimitationsModalInfo;
};

const LimitationsModalTrigger: React.FC<Props> = ({ limitationsInfo }) => {
	let content = (
		<>
			<div className="popup-info">
				<strong className="title">{limitationsInfo.main_title}</strong>
				<p dangerouslySetInnerHTML={{ __html: limitationsInfo.service.paragraph }} />
			</div>
			<div className="popup-info">
				<strong className="title">{limitationsInfo.procedure.title}</strong>
				<div>
					{limitationsInfo.procedure.paragraph.map((paragraph) => (
						<p dangerouslySetInnerHTML={{ __html: paragraph }} />
					))}
					<ul>
						{limitationsInfo.procedure.bullets.map((bullet) => (
							<li dangerouslySetInnerHTML={{ __html: bullet }} />
						))}
					</ul>
				</div>
			</div>
		</>
	);
	return (
		<CMSModal
			className="max-w-[700px] max-h-[500px]"
			trigger={
				<a id="open-modal-doctors" href="#" className="relative text-[#656d79] no-underline group">
					{limitationsInfo.triggerText}
					<span className="absolute left-0 bottom-0 h-[1px] w-0 bg-[#656d79] transition-all duration-500 ease-in-out group-hover:w-full" />
				</a>
			}
			title={'The DoktorABC service and its limitations'}
			content={content}
		/>
	);
};

export default LimitationsModalTrigger;
