import React from 'react';
import { MdInfoOutline } from "react-icons/md";

type Props = {
    text: string,
    containerClassName?: string,
    textClassName?: string,
    showIcon?: boolean,
    iconClassName?:string,
    containerStyle?: React.CSSProperties,
    onLinkClick?: (e: React.MouseEvent) => void
};

const Disclaimer: React.FC<Props> = ({ text, containerClassName = "", textClassName = "", showIcon = true, iconClassName="text-[#4B91FA]" ,containerStyle = {}, onLinkClick }) => {

    // Handle click events in the container

    return (
        <div id='disclaimer' className={`p-2 bg-[#E8F1FF] rounded-md flex items-start text-lg gap-1 w-full  ${containerClassName}`} style={containerStyle}>

            {showIcon &&
                <div id='disclaimer-icon-container' className='flex justify-center items-center'>
                    <MdInfoOutline size={24} className={`${iconClassName}`} />
                </div>
            }

            <div onClick={onLinkClick}>
                <div dangerouslySetInnerHTML={{ __html: text}} className={`text-[#4B91FA] font-semibold text-sm ${textClassName}`}></div>
            </div>
        </div>
    );
};

export default Disclaimer