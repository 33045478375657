import OptionCard from "../OptionCard/OptionCard";
import OptionCardCategory from "../OptionCardCategory/OptionCardCategory";
import "./DHLPackstationShipping.css";
import { useState } from "react";
import DHLPackstationForm from "../DHLPackstationForm/DHLPackstationForm";
import { clientConfig } from "../../../../config";



const DHLPackstationShipping = ({
  pageState,
  selected = false,
  onClick,
  disabled = false,
}) => {
  const [selectedOption, setSelectedOption] = useState(0);

  const isOptionSelected = (key) => {
    if (key === selectedOption) {
      return true;
    }

    return false;
  };

  return (
    <div className={"delivery-panel-outer-container" + (selected ? " selected-pm" : "")}>
      <OptionCardCategory
        title={"An DHL Packstation liefern"}
        onClick={!disabled ? onClick : () => {}}
        selected={selected}
        data={null}
        disabled={disabled}
      />

      <div className="delivery-form-personal-address">
        <div className={"hideable-content" + (selected ? "" : " hidden")}>
          <OptionCard
            isOptionSelected={isOptionSelected(0)}
            data={{
              value: "DHL Packstation - Kostenfreie Lieferung am nächsten Tag",
            }}
            pageState={pageState}
            onClick={() => setSelectedOption(0)}
            option={"service_option_1"}
            icon={
              clientConfig.IMAGES_URL + "uploads/pages/144/dhl-delivery.png"
            }
          />

          <p className="delivery-options-title">Lieferadresse</p>

          <DHLPackstationForm selected={selected} />
        </div>
      </div>
    </div>
  );
};

export default DHLPackstationShipping;
