import React, { useCallback, useContext, useRef, useState } from 'react'
import ProductQuantityControl from './ProductQuantityControl'
import ProductAvailabilityTag from './ProductAvailabilityTag';
import { ChooseTreatmentContext, Product } from '../FreeDosageLayout';
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import HoverCard from '../../../../components/HoverCard';


const MOBILE_BREAK_POINT = 768

interface Props {
    id: string;
    product: Product;
    price: string;
    hidePrices?: boolean
    hideTags?: boolean
    containerClassName?: string
}

const ProductCard: React.FC<Props> = ({ id,product, price, hidePrices = false, hideTags = false, containerClassName }) => {
    const chooseTreatmentContext = useContext(ChooseTreatmentContext);
    const additionalDataObj = product.additional_data ? JSON.parse(product.additional_data) : {};
    const cardRef = useRef<HTMLDivElement>(null);
    const isMaxNumOfProductSelected = chooseTreatmentContext?.selectedProductsCounter == chooseTreatmentContext?.maxCartProducts; 
    const [shouldHoverExpand, setShouldHoverExpand] = useState(false);


    let customDosageForCannabis = ''
    let strain = ''
    let shouldUseCannabisDosage = false;

    if (additionalDataObj) {
        if (additionalDataObj['THC'] && additionalDataObj['CBD'] && additionalDataObj['strain']) {
            shouldUseCannabisDosage = true;
            customDosageForCannabis = `THC: ${additionalDataObj['THC']} | CBD: ${additionalDataObj['CBD']}`;
            strain = `Sorte: ${additionalDataObj['strain']}`
        }
    }

    const scrollToTop = () => {
        if (window.screen.width < MOBILE_BREAK_POINT) {
            setTimeout(() => {
                if (cardRef.current) {
                    cardRef.current.scrollIntoView({ behavior: 'smooth', block: "start" });
                }
            }, 200)
        }
    }

    const onToggle = useCallback(() => {
        
        const toggleAction = product.isSelected ? "REMOVE" : "ADD";
        const maxCartProducts = chooseTreatmentContext?.maxCartProducts!;
        
        if (toggleAction === "ADD" && isMaxNumOfProductSelected && maxCartProducts > 1 ) {
            return
        }

        if (toggleAction === "ADD" && maxCartProducts > 1){
            setShouldHoverExpand(toggleAction === "ADD")
        }
        
        // 1. Select the product 
        chooseTreatmentContext?.handleProductToggle(product, toggleAction);

        // 2. Scroll to top!
        scrollToTop();

    }, [product, shouldHoverExpand, chooseTreatmentContext?.selectedProducts, chooseTreatmentContext?.selectedProductItems])



    return (
        <div id={id ? id : 'product-card-container'} ref={cardRef} className={`scroll-mt-[100px] bg-white shadow-lg md:mr-4 rounded-xl hover:shadow-xl ${containerClassName}`}>

            <div
                id={'product-card'}
                onClick={onToggle}
                className={`flex relative ${product.isSelected ? "" : "max-md: min-h-[110px]"} bg-white gap-2 rounded-xl p-4 lg:p-6 ${!isMaxNumOfProductSelected || product.isSelected || chooseTreatmentContext?.maxCartProducts === 1 ? "cursor-pointer" : "cursor-not-allowed" } `}>

                {/* HoverCard after clicking  */}
                <HoverCard shouldExpand={shouldHoverExpand} title={product.title} mainText={"Erfolgreich Ihrer Liste hinzugefügt"}/>

                {/* Checkbox */}
                {product.isSelected ? <MdCheckBox size={26} className='text-[#11DDAC] ' /> : <MdCheckBoxOutlineBlank size={26} className='text-[#11DDAC]' />}

                {/* Content */}
                <div id='product-card-content' className=' flex-1 flex flex-col '>
                    <p className='font-medium text-[#0D2C54]'>{product.title}</p>
                    {
                        shouldUseCannabisDosage ? <div>
                            <p style={{ fontFamily: "Roboto, sans-serif" }} className='text-sm text-[#4D4D4D] font-medium'>{customDosageForCannabis}</p>
                            <p style={{ fontFamily: "Roboto, sans-serif" }} className='text-sm text-[#4D4D4D] font-medium'>{strain}</p>
                        </div> : null
                    }
                </div>

                {/* Prices and tags */}
                <div id='product-card-prices-and-tags' className='p-2 flex flex-col'>
                    {product.in_stock && !hidePrices ?
                        <div>
                            <div className='flex items-center gap-1'>
                                <p className='text-xs text-[#556D8C]'>ab</p>
                                <p className='text-sm font-medium text-[#0D2C54]'>{price} €</p>
                            </div>
                            {product.quantity_type == 'gram' &&
                                <p className='text-xs text-[#556D8C]'> pro Gramm </p>
                            }
                        </div> : null
                    }

                    {hideTags ? null : <ProductAvailabilityTag inStock={product.in_stock} />}

                </div>

            </div>


            <div className='md:hidden md:p-4'>
                {product.isSelected && <ProductQuantityControl product={product} />}
            </div>
        </div>
    )
}

export default ProductCard