import React, { useCallback, useContext } from 'react'
import { ChooseTreatmentContext, Product, toLocalePrice } from '../FreeDosageLayout';
// import { RouterCartContext } from '../../ChooseTreatmentRouter';
import { useSearchParams } from 'react-router-dom';

interface Props {
    product: Product,
}

const getMaxPricePerQuantity = (product: Product | null) => {
    if (!product) return 0;

    if (product.items?.length > 0) {
        const firstItem = product.items[0];
        return firstItem?.price / firstItem?.quantity;
    };

    return 0;
}

const ProductQuantityControl: React.FC<Props> = ({ product }) => {

    const chooseTreatmentContext = useContext(ChooseTreatmentContext);
    const selectedProductItem = chooseTreatmentContext?.selectedProductItems.find((selectedItem) => selectedItem.catalog_id === product.catalog_id);
    const index = product.items.findIndex((item) => item.product_item_id === selectedProductItem?.product_item_id) || 0;
    const maxPerGramCost = getMaxPricePerQuantity(product);
    const [searchParamas] = useSearchParams();
    // const routerCartContext: any = useContext(RouterCartContext) //TODO: Uncomment it when selfpickup project is up again
    // const cart = routerCartContext.cartData //TODO: Uncomment it when selfpickup project is up again
    // const isSelfPickup = cart?.self_pickup_pharmacy_id ? true : false //TODO: Uncomment it when selfpickup project is up again


    const nextProduct = useCallback(() => {
        if (index < product.items.length - 1) {
            chooseTreatmentContext?.handleProductItemToggle(product.items[index + 1], "ADD");
        }
    },[product, index])

    const previousProduct = useCallback(() => {
        if (index > 0) {
            chooseTreatmentContext?.handleProductItemToggle(product.items[index - 1], "ADD");
        }
    },[product, index])


    const calculateSave = useCallback(() => {
        if (!selectedProductItem) {
            return 0;
        }

        const savePrice = selectedProductItem.quantity * maxPerGramCost - selectedProductItem.price;
        if (savePrice > 0){
            return toLocalePrice(savePrice);
        }
    },[product, selectedProductItem])

    const savedCost = calculateSave();

    return (
        <>
            {
                (product.items.length && selectedProductItem) ?
                    <div id='quantity-control' className='p-5 max-md:pt-0'>

                        <div className='flex items-start flex-1 justify-around '>

                            <div className='flex-1 w-9/12'>
                                <div style={{
                                    border: "1px solid #E5E5E5",
                                }} className='flex justify-between items-center py-1 rounded-md px-2'>
                                    <div onClick={previousProduct} className='cursor-pointer select-none rounded-md h-8 w-8 text-white bg-[#11DDAC] text-center flex items-center justify-center hover:opacity-90 transition-opacity'><div id='sub_quant'>-</div></div>
                                    <div className='flex flex-col justify-center items-center'>
                                        <p className='text-[#0D2C54] font-normal'>{selectedProductItem?.quantity}</p>
                                        <p className='text-[#98A0AF] text-sm'>{product.quantity_type == 'gram' ? 'Gramm' : 'Milliliter'}</p>
                                    </div>
                                    <div onClick={nextProduct} className='cursor-pointer select-none rounded-md h-8 w-8 text-white bg-[#11DDAC] text-center flex items-center justify-center hover:opacity-90 transition-opacity'><div id="add_quant">+</div></div>
                                </div>

                                {
                                    product.in_stock ?
                                        <div className='mt-2 flex items-end justify-center gap-1'>
                                            {product.quantity_type == 'gram' &&
                                                <>
                                                    <p className='text-[#0D2C54] text-sm font-normal'>{toLocalePrice(selectedProductItem?.price / selectedProductItem?.quantity)}</p>
                                                    <p className='text-xs text-[#98A0AF]'>pro Gramm</p>
                                                </>
                                            }
                                        </div> : null
                                }
                            </div>

                            {
                                searchParamas.get('epi') ? null :
                                <div className='_pricing p-1 w-3/12 text-center'>
                                    <p className='text-[#0D2C54] font-medium text-right'>{product?.in_stock ? toLocalePrice(selectedProductItem?.price) : toLocalePrice(product.treatment_fee!)} €</p>
                                    {savedCost && selectedProductItem?.in_stock ?
                                        <p className='text-xs font-medium text-nowrap text-[#0A9281]'>Spare {savedCost} €</p> : null
                                    }
                                </div>
                            }
                        </div>


                    </div> : null

            }
        </>
    )
}

export default ProductQuantityControl