import React from 'react'
import {useOutletContext} from "react-router-dom";
import InputField from '../../../components/CredentialsInput/InputField';
import * as translator from '../../../utils/translator';

/* 
    STYLING OF THIS COMPONENT IN subscriptionDeliveryAddressForms.css
*/

interface SubscriptionDHLPackstationFormProps{
    DHLPackstationAttributes:any //useForm attributes from the subscriptionDeliveryAddressForm component
}

const SubscriptionDHLPackstationForm: React.FC<SubscriptionDHLPackstationFormProps> = ({DHLPackstationAttributes}) => {
  const {myAccountData} = useOutletContext<any>();
  const language = myAccountData.locale.language_id
  return (
    <div className="subscription-delivery-address-forms-dhl-packstation-form">
      <InputField
        mandatory
        id="packstation_personal_first_name"
        placeholder={translator.translate(language, "my_account", `forms/firstName`)}
        value={DHLPackstationAttributes.form["packstation_personal_first_name"]}
        errorMsg={DHLPackstationAttributes.errorMessagePerField["packstation_personal_first_name"]}
        language={language}
        onBlur={() => DHLPackstationAttributes.onFieldBlur("packstation_personal_first_name")}
        touched={DHLPackstationAttributes.touched.packstation_personal_first_name}
        onChange={(value: any) => {
          DHLPackstationAttributes.updateForm("packstation_personal_first_name", value);
        }}
      />

      <InputField
        mandatory
        id="packstation_personal_last_name"
        placeholder={translator.translate(language, "my_account", `forms/lastName`)}
        value={DHLPackstationAttributes.form["packstation_personal_last_name"]}
        errorMsg={DHLPackstationAttributes.errorMessagePerField["packstation_personal_last_name"]}
        language={language}
        onBlur={() => DHLPackstationAttributes.onFieldBlur("packstation_personal_last_name")}
        touched={DHLPackstationAttributes.touched.packstation_personal_last_name}
        onChange={(value: any) => {
          DHLPackstationAttributes.updateForm("packstation_personal_last_name", value);
        }}
      />

      <InputField
        mandatory
        id="packstation_number"
        placeholder={translator.translate(language, "my_account", `forms/packstationNumber`)}
        value={DHLPackstationAttributes.form["packstation_number"]}
        errorMsg={DHLPackstationAttributes.errorMessagePerField["packstation_number"]}
        language={language}
        onBlur={() => DHLPackstationAttributes.onFieldBlur("packstation_number")}
        touched={DHLPackstationAttributes.touched.packstation_number}
        onChange={(value: any) => {
          DHLPackstationAttributes.updateForm("packstation_number", value);
        }}
      />

      <InputField
        mandatory
        id="packstation_address"
        placeholder={translator.translate(language, "my_account", `forms/postNumber`)}
        value={DHLPackstationAttributes.form["packstation_address"]}
        errorMsg={DHLPackstationAttributes.errorMessagePerField["packstation_address"]}
        language={language}
        onBlur={() => DHLPackstationAttributes.onFieldBlur("packstation_address")}
        touched={DHLPackstationAttributes.touched.packstation_address}
        onChange={(value: any) => {
          DHLPackstationAttributes.updateForm("packstation_address", value);
        }}
      />

   
      <InputField
        mandatory
        id="packstation_zip"
        placeholder={translator.translate(language, "my_account", `forms/zip`)}
        value={DHLPackstationAttributes.form["packstation_zip"]}
        errorMsg={DHLPackstationAttributes.errorMessagePerField["packstation_zip"]}
        language={language}
        onBlur={() => DHLPackstationAttributes.onFieldBlur("packstation_zip")}
        touched={DHLPackstationAttributes.touched.packstation_zip}
        onChange={(value: any) => {
          DHLPackstationAttributes.updateForm("packstation_zip", value);
        }}
      />

        <InputField
          mandatory
          id="packstation_city"
          placeholder={translator.translate(language, "my_account", `forms/city`)}
          value={DHLPackstationAttributes.form["packstation_city"]}
          errorMsg={DHLPackstationAttributes.errorMessagePerField["packstation_city"]}
          language={language}
          onBlur={() => DHLPackstationAttributes.onFieldBlur("packstation_city")}
          touched={DHLPackstationAttributes.touched.packstation_city}
          onChange={(value: any) => {
            DHLPackstationAttributes.updateForm("packstation_city", value);
          }}
      />


        <InputField
          mandatory
          id="packstation_country"
          value={translator.translate(language,"shipping",`ShippingForm/country_name/${DHLPackstationAttributes.form.packstation_country.toLowerCase()}`)}
          placeholder={translator.translate(language, "my_account", `forms/country`)}
          language={language}
          errorMsg={DHLPackstationAttributes.errorMessagePerField["country"]}
          disabled //DISABLED WHICH MEANS IT'S LOCKED AND GRAY
          onBlur={() => DHLPackstationAttributes.onFieldBlur("country")}
          touched={DHLPackstationAttributes.touched.country}
          onChange={(value: any) => {
            return;
          }}
      />
    </div>
  );
};

export default SubscriptionDHLPackstationForm;
