import {
  hairLossPhotoSelectionURLS,
  RenderAnswerGroup,
  RenderAnswerGroupAsSubquestion,
} from "../helpers";
import Significance from "../Significance/Significance";
import { Fragment, useState, useRef, useEffect } from "react";
import PhotoDiagnose from "../PhotoDiagnose/PhotoDiagnose";
import PhotoSelectionPanel from "../PhotoSelectionPanel/PhotoSelectionPanel";
import { useParams } from "react-router-dom";

/*
this component is responsible for the following:
1. rendering the content of a question, based on it's type
2. rendering the  immediate set of answers for this question (as an answerGroup)
3. rendering significances of a question , if the corresponding answer was selected
4. render all the additional subquestions ONLY IF their parent answer was selected, (that information is inside formData)

 */

const Question = ({
  handleRef,
  question,
  onAnswerClick,
  formData,
  onTextChange,
  onReveal,
  onHide,
  questionnaireValidationObject,
  files = [],
  setFiles,
  dangerSignificancesRefsDispatch,
  answersSelectedMap,
  mention
  
}: {
  handleRef: any
  question: any
  onAnswerClick: any
  formData: any
  onTextChange: any
  onReveal: any
  onHide: any
  questionnaireValidationObject: any
  files: Array<any>
  setFiles: any
  dangerSignificancesRefsDispatch:(action:"add" | "remove", ref:any) => void
  answersSelectedMap : any,
  mention:string | undefined
  
}) => {
  const [photoSelectionPickedIdx, setPhotoSelectionPickedIdx] = useState(null);
  let { category, language } = useParams();
  language = language || "de"

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  useEffect(() => {
    handleRef(question.id, ref);

    question.additional?.forEach((subquestion: any) => {
      handleRef(subquestion.recipe.id, ref);
    });
  }, []);

  function getQuestionBasedOnType(question: any) {

    switch (question.question_type) {
      case "title":
        return (
          <h2 className="questionnaire-questions-group-headline">
            {question.text}
          </h2>
        );

      case "photo_diagnose":
        return (
          <>
            <div
              id={"question_" + question.id}
              className="questionnaire-question-item-container"
              ref={ref}
            >
              <div className="questionnaire-question-item-column">
                {/* step 1: rendering the contents of the question (based on type) */}
                <div className="questionnaire-text-and-helpblock-container">
                  <div
                    className="questionnaire-question-item-text-container"
                    dangerouslySetInnerHTML={{ __html: question.text }}
                  />
                  <div
                    className="questionnaire-question-item-helpblock-container"
                    dangerouslySetInnerHTML={{ __html: question.help_block }}
                  />
                </div>

                <div className="questionnaire-answers-container">
                  <PhotoDiagnose
                    language={language}
                    category={category || mention}
                    files={files}
                    setFilesCallback={setFiles}
                  />
                  <RenderAnswerGroup
                    questionnaireValidationObject={
                      questionnaireValidationObject
                    }
                    onAnswerClick={onAnswerClick}
                    onTextChange={onTextChange}
                    answersGroup={question.answersGroup}
                    onReveal={onReveal}
                    onHide={onHide}
                    answersSelectedMap={answersSelectedMap}
                  />
                </div>
              </div>
            </div>
          </>
        );

      case "photo_selection":
        return (
          <>
            <PhotoSelectionPanel
              hairLossPhotoSelectionURLS={hairLossPhotoSelectionURLS}
              errorMode={
                questionnaireValidationObject[
                question.answersGroup.recipe.id
                ] === false
              }
              question={question}
              photoSelectionPickedIdx={photoSelectionPickedIdx}
              onPhotoChange={(answer: any, idx: any) => {
                setPhotoSelectionPickedIdx(idx);
                onAnswerClick(answer, idx);
              }}
            />
            <RenderAnswerGroup
              questionnaireValidationObject={questionnaireValidationObject}
              onAnswerClick={onAnswerClick}
              onTextChange={onTextChange}
              answersGroup={question.answersGroup}
              onReveal={onReveal}
              onHide={onHide}
              answersSelectedMap={answersSelectedMap}
            />
          </>
        );
      default:
        return (
          <>
            <div
              id={"question_" + question.id}
              className="questionnaire-question-item-container"
              ref={ref}
            >
              <div
                className={
                  question.answersGroup?.recipe?.type === "check_list" ||
                    question.answersGroup?.recipe?.type === "textarea"
                    ? "questionnaire-question-item-column"
                    : "questionnaire-question-item"
                }
              >
                {/* step 1: rendering the contents of the question (based on type) */}
                <div className="questionnaire-text-and-helpblock-container">
                  <div
                    className="questionnaire-question-item-text-container"
                    dangerouslySetInnerHTML={{ __html: question.text }}
                  />
                  <div
                    className="questionnaire-question-item-helpblock-container"
                    dangerouslySetInnerHTML={{ __html: question.help_block }}
                  />
                </div>

                <div className="questionnaire-answers-container">
                  {/* step 2 : rendering the answer group of the question*/}
                  <RenderAnswerGroup
                    questionnaireValidationObject={
                      questionnaireValidationObject
                    }
                    onTextChange={onTextChange}
                    onAnswerClick={onAnswerClick}
                    answersGroup={question.answersGroup}
                    onReveal={onReveal}
                    onHide={onHide}
                    answersSelectedMap={answersSelectedMap}
                  />
                </div>
              </div>

              {/* step 3: rendering the significances */}
              {question.answersGroup?.children.map((childAnswer: any, idx : number) => {
                if (
                  formData[childAnswer.recipe?.id] &&
                  childAnswer.recipe.significances.length > 0
                ) {
                  return (
                    <Significance
                      parentID={childAnswer.recipe.id}
                      key={childAnswer.recipe.id}
                      fontWeight
                      level={childAnswer.recipe.significances[0].level}
                      text={childAnswer.recipe.significances[0].title}
                      dangerSignificancesRefsDispatch={dangerSignificancesRefsDispatch}
                    />
                  );
                }
                return null;
              })}
            </div>

            {/* step 4: rendering additional (subquestions) if their parent is selected */}
            <div className="questionnaire-subquestion-container">
              {question.additional.map((additonalQuestionGroup: any, idx: number) => {
                if (
                  additonalQuestionGroup.recipe.parent_id &&
                  formData[additonalQuestionGroup.recipe.parent_id]
                ) {
                  return (
                    <Fragment key={additonalQuestionGroup.recipe.id}>
                      <RenderAnswerGroupAsSubquestion
                        questionnaireValidationObject={
                          questionnaireValidationObject
                        }
                        key={idx}
                        answersGroup={additonalQuestionGroup}
                        onAnswerClick={onAnswerClick}
                        onTextChange={onTextChange}
                        onReveal={onReveal}
                        onHide={onHide}
                        answersSelectedMap={answersSelectedMap}
                      />

                      {additonalQuestionGroup.children.map((child: any) => {
                        if (
                          formData[child.recipe.id] &&
                          child.recipe.significances.length > 0
                        ) {
                          return (
                            <Significance
                              parentID={child.recipe.id}
                              fontWeight
                              level={child.recipe.significances[0].level}
                              text={child.recipe.significances[0].title}
                              dangerSignificancesRefsDispatch={dangerSignificancesRefsDispatch}
                            />
                          );
                        }
                        return null;
                      })}
                    </Fragment>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </>
        );
    }
  }
  return <>{getQuestionBasedOnType(question)}</>;
};

export default Question;
