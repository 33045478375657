import "./TextArea.css";
export default function TextArea({
  placeholder = "Placeholder here :",
  helpblock,
  onTextChange,
  onChange,
  answerID,
  id,
  errorMode,
  value = '',
  isParentSubQuestion = false,
}) {
  return (
    <>
      <div className="textarea-placeholder-helpblock-container">
        <p className="textarea-placeholder">{placeholder}</p>
        <p className="textarea-helpblock">{helpblock}</p>
      </div>
      <textarea
        id={id}
        className={errorMode ? "textarea-error" : "textarea"}
        onChange={(event) => {
          if (event.target.value.length > 0) {
            onChange && onChange(answerID, event.target.value, true, "", "");
            onTextChange(answerID, event.target.value);
          } else {
            onChange && onChange(answerID, null, true, "", "");
            onTextChange(answerID, null);
          }
        }}
        value={value}
      />
    </>
  );
}
