import React, { createContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import "./MyAccountChangeSubscriptionMedicationModal.css"
import SubmitButton from '../../SubmitButton/SubmitButton';
import * as http from "../../../utils/http"
import * as translator from "../../../utils/translator"
import {MainTracker} from '../../../PixelTrackers/MainTracker'
import SubscriptionRenewQuestionnaire from '../../../pages/MyAccount/components/SubscriptionRenewQuestionnaire/SubscriptionRenewQuestionnaire';
import ChooseTreatmentPage from '../../../pages/ChooseTreatmentVariants/ChooseTreatment/ChooseTreatment';

interface MyAccountChangeSubscriptionMedicationModalProps {
    setCurrentGlobalModalName: any;
    allSubscriptionData: any;
    modalData: any;
    setStickyNotificationDetails: any;
    setRefreshData: any;
    refreshData: boolean;
    language: string;
    country: string | undefined;
}

export const submitOverrideContext = createContext<any>({}); //until choose treatment is refactored I'll provide the method via this context

const MyAccountChangeSubscriptionMedicationModal: React.FC<MyAccountChangeSubscriptionMedicationModalProps> = ({ setCurrentGlobalModalName, allSubscriptionData, modalData, setStickyNotificationDetails, setRefreshData, refreshData, language, country }) => {
    const [changeMedicationModalContent, setChangeMedicationModalContent] = useState<string>('suggest change medication')
    const [catalogSettings, setCatalogSettings] = useState<Record<string, any>>({})
    const [showUpdateQuestionnaire, setShowUpdateQuestionnaire] = useState(false)
    const subscriptionData = allSubscriptionData[modalData.hashID]

    //calc new next delivery date
    const nextDelivery = subscriptionData.next_delivery;
    const titleAndQuantity = subscriptionData.title
    const subscriptionDiscount = (subscriptionData.regularPrice - subscriptionData.subscriptionPrice).toFixed(2)
    const modalText = translator.translate(language, "my_account", `SubscriptionOrders/changeMedicationModal/text/${modalData.from}`).replace(":product", titleAndQuantity).replace(":nextDelivery", nextDelivery)
    const chooseTreatmentButtonText = 'Behandlung ersetzen';
    const navigate = useNavigate()

    const closeEvent = (eventIndex:number, answer:string, dontClose=false) =>{
        const EVENTS:any= {
            1: 'Decision on I want to change my medication', //cancel option #1
            3: 'Decision on Treatment is too expensive for me' //cancel option #3
        }
        MainTracker.track('custom', EVENTS[eventIndex], {'Answer': answer, "Flow Stage":changeMedicationModalContent})
        if(dontClose){ //overrides the closing of the modal, for sending the decision event only
            return
        }
        setCurrentGlobalModalName(null)
    }

    useEffect(() => {
        if(changeMedicationModalContent == 'has medical condition changed'){
            MainTracker.track('pv', 'View Pop Up', {'Pop Up Name': 'Has you your health changed'})
        }
    }, [changeMedicationModalContent])
    

    const postChangeSubscriptionMediaction = (selectedTreatmentData:any) => {
        http
            .postToServer(
                `account-update/subscription/change-medication`,
                {
                    hashID: modalData.hashID,
                    selectedTreatmentData,
                    subscriptionDiscount,
                    country,
                    language
                },
                [http.MIDDLEWARES.CUSTOMER_ID]
            )
            .then((response) => {
                setChangeMedicationModalContent((current:string)=>'thank you')
                setRefreshData((current:boolean)=>!current)
            })
            .catch((error) => {
                setStickyNotificationDetails((currentDetails: any) => ({
                    show: true,
                    title: "",
                    content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
                    level: "error",
                    showInfoIcon: false,
                    refresh: !currentDetails.refresh
                }))
            })
    }

    const postSubscriptionCancellation = (cancelOption:number) =>{
        const cancelOptions:any = {
            1:{ id: 1, translation: translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/1'), value:`I'd like to change my medication` },
            3:{ id: 3, translation: translator.translate(language, "my_account", 'SubscriptionOrders/cancelModal/3'), value:`The treatment is too expensive` }
        }
        http
        .postToServer(
        `account-update/subscription/cancel`,
        {
          option: cancelOptions[cancelOption],
          hashID: modalData.hashID
        },
        [http.MIDDLEWARES.CUSTOMER_ID]
        )
        .then((response) => {
          setStickyNotificationDetails((currentDetails:any) => ({
            show: true,
            title: "",
            content: 'subscription cancelled',
            level : "info",
            showInfoIcon:false,
            refresh: !currentDetails.refresh
          }))
          setRefreshData((current:boolean)=>!current)
          closeEvent(modalData.from, 'Cancel subscription');
          navigate(`orders-subscription`);
        })
        .catch(
          setStickyNotificationDetails((currentDetails:any) => ({
            show: true,
            title: "",
            content: translator.translate(language, "my_account", `stickyNotifications/error/error`),
            level : "error",
            showInfoIcon:false,
            refresh: !currentDetails.refresh
          }))
        )
        .finally()
      }

    return <>
        {(changeMedicationModalContent == 'suggest change medication') ? ( 
            <div onClick={()=>{closeEvent(modalData.from, 'Close')}} className="skip-subscription-modal-background-blue">
                <div className="skip-subscription-white-modal-popup-container" onClick={(event) => event.stopPropagation()}>
                    <img
                        onClick={()=>{closeEvent(modalData.from, 'Close')}}
                        className="close-btn"
                        src={require("../../../../src/icons/X.svg").default}
                        alt="X"
                    />
                    <h4>{translator.translate(language, "my_account", `SubscriptionOrders/changeMedicationModal/title/${modalData.from}`)}</h4>
                    <p>{modalText}</p>
                    
                        <SubmitButton
                            loading={false}
                            onSubmit={()=>{setChangeMedicationModalContent('has medical condition changed'); closeEvent(modalData.from, 'Change medication', true)}}
                            id={"subscription-delivery-address-submit"}
                            fontsize={"14px"}
                            padding={"0px 30px"}
                            height={"45px"}
                            placeholder="Meine Medikamente wechseln"
                            isEnabledState={true}
                        />
                        <p className="skip-subscription-cancel-subscription" onClick={()=>postSubscriptionCancellation(modalData.from)}>Meinen Behandlungsplan stornieren</p>
                </div>
            </div>
        ) : (changeMedicationModalContent == 'has medical condition changed') ? (
            <div className="skip-subscription-modal-background-blue">
                <div className="change-medication-white-modal-popup-container" onClick={(event) => event.stopPropagation()}>
                    <img
                        onClick={()=>{closeEvent(modalData.from, 'Close')}}
                        className="close-btn"
                        src={require("../../../../src/icons/X.svg").default}
                        alt="X"
                    />
                    <div>
                        <SubscriptionRenewQuestionnaire
                            setRefreshData={setRefreshData}
                            setCurrentGlobalModalName={setCurrentGlobalModalName}
                            allSubscriptionData={allSubscriptionData}
                            language={language}
                            country={country}
                            orientForSpecificComponent={'change-medication'}
                            setChangeMedicationModalContent={setChangeMedicationModalContent}
                            showUpdateQuestionnaire={showUpdateQuestionnaire}
                            setShowUpdateQuestionnaire={setShowUpdateQuestionnaire}
                        />
                    </div>
                </div>
            </div>
        ) : (changeMedicationModalContent == 'choose treatment') ? (
            <div className="skip-subscription-modal-background-blue">
                <div className="change-medication-white-modal-popup-container choose-treatment" onClick={(event) => event.stopPropagation()}>
                    <img
                        onClick={()=>{closeEvent(modalData.from, 'Close')}}
                        className="close-btn"
                        src={require("../../../../src/icons/X.svg").default}
                        alt="X"
                    />
                    <div>
                        <submitOverrideContext.Provider value={{subscriptionData, postChangeSubscriptionMediaction, setCatalogSettings, catalogSettings, subscriptionDiscount, chooseTreatmentButtonText}}>
                            <ChooseTreatmentPage />
                        </submitOverrideContext.Provider>
                    </div>
                </div>
            </div>
        ) : (
            <div onClick={() => { setRefreshData((current: boolean) => !current); setCurrentGlobalModalName(null); }} className="skip-subscription-modal-background-blue">
                <div
                    className="skip-subscription-white-modal-popup-container"
                    onClick={(event) => event.stopPropagation()}
                >
                    <img
                        onClick={() => { setRefreshData((current: boolean) => !current); setCurrentGlobalModalName(null); }}
                        className="close-btn"
                        src={require("../../../../src/icons/X.svg").default}
                        alt="X"
                    />
                    <h4>Vielen Dank!</h4>
                    <p>Ihr neuer Behandlungsplan für {titleAndQuantity} wurde für {subscriptionData.subscriptionPrice} erstellt (mit {subscriptionDiscount}{subscriptionData.currencySign} Rabatt pro Bestellung). Sie erhalten Ihre nächste Bestellung am {nextDelivery}.</p>
                </div>
            </div>
        )}
    </>
}

export default MyAccountChangeSubscriptionMedicationModal