import React from "react";
import {useOutletContext} from "react-router-dom";
import * as translator from '../../../utils/translator';
import InputField from "../../../components/CredentialsInput/InputField";
import Select from "../../../components/Select/Select";

/* 
    STYLING OF THIS COMPONENT IN MyAccountDeliveryAddress.css
*/

interface DeliveryAddressPackstationFormProps {
  packstationAttributes: any; //useForm attributes from the MyAccountDeliveryAddress component
  setDestinationCountry: any;
}

const DeliveryAddressPackstationForm: React.FC<DeliveryAddressPackstationFormProps> = ({ packstationAttributes, setDestinationCountry }) => {
  const {myAccountData} = useOutletContext<any>();
  const language = myAccountData.locale.language_id

  return (
    <div className="delivery-address-form">
        <div className="two-in-row-1">
            <InputField
            mandatory
            id="packstation_personal_first_name"
            placeholder={translator.translate(language, "my_account", `forms/firstName`)}
            value={packstationAttributes.form["packstation_personal_first_name"]}
            errorMsg={packstationAttributes.errorMessagePerField["packstation_personal_first_name"]}
            language={language}
            onBlur={() => packstationAttributes.onFieldBlur("packstation_personal_first_name")}
            touched={packstationAttributes.touched.packstation_personal_first_name}
            onChange={(value: any) => {
                packstationAttributes.updateForm("packstation_personal_first_name", value);
            }}
            />

            <InputField
            mandatory
            id="packstation_personal_last_name"
            placeholder={translator.translate(language, "my_account", `forms/lastName`)}
            value={packstationAttributes.form["packstation_personal_last_name"]}
            errorMsg={packstationAttributes.errorMessagePerField["packstation_personal_last_name"]}
            language={language}
            onBlur={() => packstationAttributes.onFieldBlur("packstation_personal_last_name")}
            touched={packstationAttributes.touched.packstation_personal_last_name}
            onChange={(value: any) => {
                packstationAttributes.updateForm("packstation_personal_last_name", value);
            }}
            />
      </div>

      <div className="two-in-row-2">
        <InputField
            mandatory
            id="packstation_number"
            placeholder={translator.translate(language, "my_account", `forms/packstationNumber`)}
            value={packstationAttributes.form["packstation_number"]}
            errorMsg={packstationAttributes.errorMessagePerField["packstation_number"]}
            language={language}
            onBlur={() => packstationAttributes.onFieldBlur("packstation_number")}
            touched={packstationAttributes.touched.packstation_number}
            onChange={(value: any) => {
            packstationAttributes.updateForm("packstation_number", value);
            }}
        />

        <InputField
            mandatory
            id="packstation_address"
            placeholder={translator.translate(language, "my_account", `forms/postNumber`)}
            value={packstationAttributes.form["packstation_address"]}
            errorMsg={packstationAttributes.errorMessagePerField["packstation_address"]}
            language={language}
            onBlur={() => packstationAttributes.onFieldBlur("packstation_address")}
            touched={packstationAttributes.touched.packstation_address}
            onChange={(value: any) => {
            packstationAttributes.updateForm("packstation_address", value);
            }}
        />
      </div>

   
      <InputField
        mandatory
        id="packstation_zip"
        placeholder={translator.translate(language, "my_account", `forms/zip`)}
        value={packstationAttributes.form["packstation_zip"]}
        errorMsg={packstationAttributes.errorMessagePerField["packstation_zip"]}
        language={language}
        onBlur={() => packstationAttributes.onFieldBlur("packstation_zip")}
        touched={packstationAttributes.touched.packstation_zip}
        onChange={(value: any) => {
          packstationAttributes.updateForm("packstation_zip", value);
        }}
      />

        <InputField
        mandatory
        id="packstation_city"
        placeholder={translator.translate(language, "my_account", `forms/city`)}
        value={packstationAttributes.form["packstation_city"]}
        errorMsg={packstationAttributes.errorMessagePerField["packstation_city"]}
        language={language}
        onBlur={() => packstationAttributes.onFieldBlur("packstation_city")}
        touched={packstationAttributes.touched.packstation_city}
        onChange={(value: any) => {
          packstationAttributes.updateForm("packstation_city", value);
        }}
      />

      <Select 
        id={"shipping_country"}
        initialValue={{value:translator.translate(language,"shipping",`ShippingForm/country_name/${packstationAttributes.form.packstation_country?.toLowerCase()}`)}}
        options = {[{value:translator.translate(language,"shipping","ShippingForm/country_name/de"), code:'de'}, {value:translator.translate(language,"shipping",`ShippingForm/country_name/ch`), code:'ch'}]}
        placeholder={""}
        onChangeCallback={(value: any) => {
          setDestinationCountry(value.option.code)
          packstationAttributes.updateForm("packstation_country", value.option.code.toUpperCase());
        }} 
        questionnaireValidationObject={{}}
        errorMode={""}
        style={{
          border: "1px solid #dbe1eb",
          height: "56px",
          width: "100%",
          borderRadius: "6px",
          backgroundColor: "white",
          margin: "15px 0px"
          }
        }
        includeShortDescription={false}
      />

    <div 
     className="phone-and-info two-in-row-4" 
     style={{width:"100%",marginBottom:20,display:"flex",flexDirection:"column",gap:0}} >
      <InputField
          mandatory={true}
          id="packstation_personal_phone"
          placeholder={translator.translate(language, "my_account", `forms/phone`)}
          value={packstationAttributes.form["packstation_personal_phone"]}
          errorMsg={packstationAttributes.errorMessagePerField["packstation_personal_phone"]}
          language={language}
          onBlur={() => packstationAttributes.onFieldBlur("packstation_personal_phone")}
          touched={packstationAttributes.touched.packstation_personal_phone}
          onChange={(value: any) => {
          packstationAttributes.updateForm("packstation_personal_phone", value);
          }}
      />


      <span className="delivery-phone-helpblock">
      {translator.translate(language, "my_account", `DeliveryAddress/note`)}
      </span>
     </div>
    </div>
  );
};

export default DeliveryAddressPackstationForm;
