import { clientConfig } from "../config";

/* Google tag manager TRACKER   */


const EVENTS = ["ViewThankyouPage", "cookie_consent_marketing"];

const loadArrivalVariables = ()=>{
  try{
    let trackingData = window.eventsTracking.getData();
    let lastArrival = trackingData.arrivals[trackingData.arrivals.length-1]

    let url

    // in case url starts only with ? -> in case of short arrival (iphone prevent localstorage cross subdomains)
    if (lastArrival.url.startsWith("?")){
      url = new URL("http://a.com" + lastArrival.url)
    }
    else{
      url = new URL(lastArrival.url)
    }

    let foundParams = false

    let dataLayerParams = {}

    url.searchParams.forEach((value,key)=>{
      foundParams = true
      dataLayerParams['ARRIVAL_PARAM_' + key.toUpperCase()] = value
    })

    if (foundParams){
      window.dataLayer.push(dataLayerParams);
    }

  }
  catch(err){
    window.clientLogger.error('error loading arrival Variables into GTM',err)
  }
}


// GTM is lazy loaded here and not in the main index.html because we need to set variables before it's loaded
export const init = () =>{
  window.dataLayer = window.dataLayer || [];
  // init the varaibles
  loadArrivalVariables();

  // load gtm
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })
      (window, document, "script", "dataLayer", clientConfig.GTM_ID);

}

export const track = (eventType, eventName, data={}) => {
  if (eventType === "pv") {

  } else {
    if (EVENTS.includes(eventName)) {
      window.dataLayer.push({event:eventName, ...data});
    }
  }
};

