import React, { useEffect, useRef, useState, Ref, useContext } from 'react';
import ToggleRadioButton from '../ToggleRadioButton/ToggleRadioButton';
import InfoIcon from "../../icons/info-icon.svg";
import InputField from '../CredentialsInput/InputField';
import Popover from '../Popover/Popover';
import { ShippingDataContext } from "../../pages/Shipping/Shipping";

type Props = {
    onTextChange: (text: string) => void;
    onToggle: (value: boolean) => void;
    isToggleEnabled: boolean;
    inputValue: string;
    inputErrorMsg?: string;
    setRef?: (ref: Ref<HTMLDivElement>) => void
}


const DigitalCannabisIDWidget: React.FC<Props> = ({ onTextChange, onToggle, isToggleEnabled, inputValue, inputErrorMsg, setRef }) => {
    const [showPopover, setShowPopover] = useState(false); // State for controlling the popover visibility
    const ref = useRef<HTMLDivElement>(null)
    const ShippingData = useContext(ShippingDataContext);
    const isOnlyPrescription = ShippingData.prescriptionOnly;
    const cannabisIdCardCost = ShippingData.cannabisIdCardCost;

    useEffect(() => {
        if (setRef && ref) {
            setRef(ref)
        }
    }, [ref])

    return (
        <div ref={ref} id='digital-cannabis-id-widget-container' className='p-4 rounded-[6px] my-[30px] bg-white scroll-mt-[200px]'>

            {/* Header  */}
            <div id='digital-cannabis-id-header' className='flex justify-between items-center'>


                {/* Cannabis Image  */}
                <img className='w-[50px] h-[50px] object-cover' 
                    src={require("../../icons/digital-cannabis-id-icon.svg").default} 
                    alt="Digital Cannabis ID"
                />


                {/* Header texts  */}
                <div id='digital-cannabis-id-header-container' className='flex flex-col lg:pl-4 lg:pr-2 items-start'>

                    {/* Title  */}
                    <span
                        id='digital-cannabis-id-header-title'
                        className='text-[#0d2c54] font-medium text-[14px] break-words '

                    >
                        Digitalen Cannabis-Ausweis hinzufügen
                    </span>


                    {/* Price & Info */}
                    <div className='flex items-center gap-1'>

                        <p id='digital-cannabis-id-price' className={`text-[#0A9281] text-[13px] mt-[1px] whitespace-nowrap font-medium  ${isOnlyPrescription ? "" : "line-through"}`}> {cannabisIdCardCost.toFixed(2)} € </p>


                        {/* Info button & popover  */}
                        <div id='digital-cannabis-header-info-container' className='relative flex mt-[1px] ml-1 desktop:ml-0 desktop:mr-1'>
                            <img
                                id='info-icon'
                                className=' select-none cursor-pointer'
                                src={InfoIcon}
                                alt="i"
                                onClick={() => setShowPopover(!showPopover)}
                            />

                            {/* Popover */}
                            {showPopover && (
                                <Popover
                                    content="Der Cannabis-Ausweis dient als Nachweis, dass der Inhaber zum Besitz und Konsum von Cannabis auf ärztliche Verschreibung berechtigt ist. Gilt nur in Deutschland"
                                    onClose={() => setShowPopover(false)}
                                />
                            )}
                        </div>
                    </div>
                </div>


                {/* Toggle  */}
                <ToggleRadioButton
                    id={"toggle-digital-cannabis-id"}
                    isToggleEnabled={isToggleEnabled}
                    updateToggle={onToggle}
                />

            </div>

            {/* Body */}
            {isToggleEnabled && (
                <div id='digital-cannabis-id-body' className='notranslate select-none'>
                    <InputField
                        id='personal-id-number-input'
                        type='text'
                        value={inputValue}
                        placeholder='Personalausweis-Nummer'
                        mandatory={true}
                        errorMsg={inputErrorMsg}
                        onChange={onTextChange}
                        touched={true}
                        language={""} // This field is deprecated, TODO: remove it from other inputs field then from the component itself
                    />
                    <p id='personal-id-info' style={{ fontSize: "12px", color: "var(--radiobutton-text-color)", fontFamily: "Roboto,sans-serif" }}>
                        Bitte geben Sie Ihre Personalausweis-Nummer ein. Sie wird auf Ihrem Cannabis-Ausweis angegeben. Beispiel, AB1234C56 <br /> Sie erhalten die Cannabis-ID per E-Mail als Anhang und können sie bequem herunterladen.
                    </p>
                </div>
            )}
        </div>
    );
}

export default DigitalCannabisIDWidget;
