import { useEffect, useState } from "react";
import "./Select.css";
import { TbChevronDown } from "react-icons/tb";

export default function Select({
  onAnswerClick = () => { },
  placeholder = "Please select",
  submitClicked = null,
  onChangeCallback = (option) => { },
  options = [],
  page = null,
  questionnaireValidationObject,
  style = {
    border: "1px solid #aaa",
    height: "44px",
    width: "100%",
    borderRadius: "3px",
    backgroundColor: "transparent",
    margin: "0px"
  },
  errorMode,
  id,
  initialValue = {value:""},
  includeShortDescription = false,
  label = ""
}) {

  const [optionSelected, setOptionSelected] = useState(initialValue?.value ?? null);
  const [showOptions, setShowOptions] = useState(false);
  const [showError, setShowError] = useState(false);

  //initial value sometimes depends on an external state so we force rerender
  useEffect(() => {
    if(initialValue?.value){
      setOptionSelected(initialValue)
    }
  }, [initialValue])
  

  useEffect(() => {
    if (submitClicked && optionSelected == "") {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [submitClicked, optionSelected]);


  return (
    <div
      className="questionnaire-select-dropdown-container"
      style={{
        width: style.width,
        height: style.height,
        border: errorMode ? null : style.border,
        borderRadius: style.borderRadius,
        backgroundColor: style.backgroundColor,
        margin: style.margin
      }}
    >
      {label &&
      <label className="">{label}</label>
      }
      <div
        className={errorMode || showError ? "questionnaire-select-error-container" : "questionnaire-select-container notranslate"}
        id={id}
        onClick={() => setShowOptions((currentMode) => !currentMode)}
        style={{ width: style.width, height: style.height }}
      >


        {
          includeShortDescription ? 
          <span className="legend">
            Zu ändernde Adresse
            <span className="credential-input-mandatory-astrix">
              {"*"}
            </span>
          </span> : null
        }

        {optionSelected ? (
          <span className="questionnaire-selected-item-placeholder">
            {" "}
            {optionSelected.value}
          </span>
        ) : (
          <span className="questionnaire-select-placeholder">
            {placeholder}
          </span>
        )}
        <TbChevronDown
          className={
            showOptions
              ? "questionnaire-select-logo-up"
              : "questionnaire-select-logo-down"
          }
        />
      </div>

      {showOptions ? (
        <div className="questionnaire-select-options-container">
          {options.map((option, index) => (
            <div
              className="questionnaire-select-option "
              key={index}
              onClick={() => {
                onAnswerClick(option);
                if (page && page.toLowerCase() === "questionnaire") {
                  setOptionSelected(option.recipe);
                } else {
                  setOptionSelected(option);
                }
                setShowOptions(false);
                onChangeCallback({ option, index });
              }}
            >
              <span
                id={"answer_" + (option.id || option.recipe?.id)}
                className="questionnaire-select-option-text"
              >
                {option.value || option.recipe.value}
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
