
import React from 'react'
import * as translator from "../../../../utils/translator";


interface ThankYouStepProps {
    stepNumber: number;
    language: string;
    page: string;
    isOrderOnlyPrescription?: boolean;
    isERecipe?: boolean;
    isEPrescriptionOnly: boolean;
    isSelfPickUp?: boolean;
    isCannaleoPreSelected?: boolean;
    placeHoldersMap: Record<string, string>;
}


const applyPlaceholders = (str: string, placeholdersToValues: Record<string, string>) => {
    let result = str;
    for (const [from, to] of Object.entries(placeholdersToValues)) {
        result = result.replace(new RegExp(from, 'g'), to); // replace all occurrences
    }
    return result;
};


const ThankYouStep: React.FC<ThankYouStepProps> = ({ placeHoldersMap, isEPrescriptionOnly, isCannaleoPreSelected, stepNumber, language, page, isOrderOnlyPrescription, isERecipe, isSelfPickUp = false }) => {

    const getTitle = () => {
        if (isSelfPickUp) {
            return translator.translate(language, page, "page/step" + stepNumber.toString() + "_title_self_pickup")
        } else if (isCannaleoPreSelected) {
            return applyPlaceholders(translator.translate(language, page, "page/step" + stepNumber.toString() + "_title_cannaleo_pre_selected"), placeHoldersMap)
        } else if (isEPrescriptionOnly) {
            return translator.translate(language, page, "page/step" + stepNumber.toString() + "_title_e_prescription_only")
        } else if (isOrderOnlyPrescription) {
            return translator.translate(language, page, "page/step" + stepNumber.toString() + "_title_only_prescription")
        } else if (isERecipe) {
            return translator.translate(language, page, "page/step" + stepNumber.toString() + "_title_erecipe")
        } else {
            return translator.translate(language, page, "page/step" + stepNumber.toString() + "_title")
        }
    }

    const getContent = () => {
        if (isSelfPickUp) {
            return translator.translate(language, page, "page/step" + stepNumber.toString() + "_content_self_pickup")
        } else if (isCannaleoPreSelected) {
            return applyPlaceholders(translator.translate(language, page, "page/step" + stepNumber.toString() + "_content_cannaleo_pre_selected"), placeHoldersMap)
        } else if (isEPrescriptionOnly) {
            return translator.translate(language, page, "page/step" + stepNumber.toString() + "_content_e_prescription_only") 
        } else if (isOrderOnlyPrescription) {
            return translator.translate(language, page, "page/step" + stepNumber.toString() + "_content_only_prescription")
        } else if (isERecipe) {
            return translator.translate(language, page, "page/step" + stepNumber.toString() + "_content_erecipe")
        } else {
            return translator.translate(language, page, "page/step" + stepNumber.toString() + "_content")
        } 
    }


    return (
        <div key={stepNumber} className="thankyou-step">
            <div className="thankyou-step-circle">
                {stepNumber}
            </div>

            <p className="thankyou-step-title">
                { getTitle() }
            </p>

            <p className="thankyou-step-content">
                { getContent() }
            </p>
        </div>
    )
}

export default ThankYouStep
