import React from "react";
import "./ChatMessage.css";
import ConversationAttachment from "../ConversationAttachment/ConversationAttachment";
import * as translator from "../../../../utils/translator";

interface ChatMessageProps {
  messageData: {
    content: string;
    post_time: string;
    doctor_name: string | null;
    user_type: string;
    mentions: ConversationMention[];
    attachments?: any;
  };
  language: string;
}

type ConversationMention = {
  mention_type: string;
  mention_id: number;
  mention_text: string;
  mention_link: string;
};

const formatMessage = (message : string, mentions : ConversationMention[]) => {
  //This function formatting [@,#] in text and make them clickable mentions
  let formattedText = message;

  mentions.forEach((mention : ConversationMention) => {
    const productsRegex = new RegExp('@' + mention.mention_text, "gi");
    const ordersRegex = new RegExp('#' + mention.mention_text, "gi");
    let mentionHTML = `<a href=${mention.mention_link} class='clickable-mention'>${mention.mention_text}</a>`
    formattedText = formattedText.replace(productsRegex, mentionHTML);
    formattedText = formattedText.replace(ordersRegex, mentionHTML);
  })

  return formattedText;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ messageData, language }) => {

  return (
    <>
      <div
        className={
          messageData.user_type === "doctor"
            ? "doctor-message-container"
            : "customer-message-container"
        }
      >
        <div className="message-content">
          <div className={`message-text-${messageData.user_type}`} 
            dangerouslySetInnerHTML={{__html: formatMessage(messageData.content, messageData.mentions)}}
          />
          {messageData.attachments.map((attachment: any, index: number) => (
            <ConversationAttachment key={index} attachment={attachment} />
          ))}
        </div>
        {
          messageData.doctor_name ?
          <span className="timestamp">
            {translator.translate(language, 'my_account', 'AskADoctor/message_by_doctor').replace(':doctorName', messageData.doctor_name)}
          </span>
          :
          null
        }
        <span className="timestamp">{messageData.post_time}</span>
      </div>
    </>
  );
};

export default ChatMessage;
