import React, { useContext } from 'react'
import DosageSelection from './DosageSelection'
import ProductItemSelection from './ProductItemSelection'
import { ReturingCustomersLayoutContext } from '../ReturningCustomersLayout';
import SubscriptionSelection from './SubscriptionSelection';
import SubmitButton from '../../FreeDosageChooseTreatment/components/SubmitButton';
import { CatalogSettingContext } from '../../ChooseTreatmentRouter';

const NoTreatmentSelectedInfo = () => { //HARD CODED IN FRENCH, feel free to change
  return <div className="noPreferenceInfo-container">
    <div className="noPreferenceInfo-texts">
      <p className="noPreferenceInfo-title">
        Informations pour Aucune préférence de traitement
      </p>

      <p className="noPreferenceInfo-description">
        En choisissant cette option, le médecin vous prescrira le traitement
        le plus adapté en fonction des réponses fournies dans votre
        questionnaire médical.
      </p>
    </div>
  </div>
}


interface TreatmentSelectionProps {
  isResponsiveLayout?: boolean
}
const TreatmentSelection:React.FC<TreatmentSelectionProps> = ({isResponsiveLayout = false}) => {

  const context = useContext(ReturingCustomersLayoutContext);
  const routerContext = useContext(CatalogSettingContext) as any;
  const groupedProducts = context?.groupedProducts!
  const selectedCatalogID = context?.selectedCatalogID;
  const products = selectedCatalogID ? groupedProducts[selectedCatalogID] : [];
  
  const isColLayout = products.find((product) => {
    const productTitle = product.dosage_str || product.product_title;
    return productTitle.length > 8
  }) !== undefined || context?.countryID.toUpperCase() === 'FR';

  if (!context?.languageID){
    return <></>
  }

  return (
    <div id='treatment-selection' className='p-5 flex flex-col gap-4 rounded-md w-full bg-white overflow-x-hidden'>
      
      {
        !context?.isNoPreferenceSelected && <p className='mini-title font-bold flex items-center justify-center' id='drug-title'>{context?.selectedProduct?.title}</p>
      }

      {
        context?.isNoPreferenceSelected ? <NoTreatmentSelectedInfo /> :
          <>
            <DosageSelection isResponsiveLayout={isResponsiveLayout} isColLayout={isColLayout} />

            <ProductItemSelection isResponsiveLayout={isResponsiveLayout} isColLayout={isColLayout} />

            {!routerContext?.showERezeptDisclaimer && <SubscriptionSelection isResponsiveLayout={isResponsiveLayout} />}
          </>
      }

      <SubmitButton languageID={context!.languageID} onClick={() => context?.onSubmit()} desktop={!isResponsiveLayout} />

    </div>
  )
}

export default TreatmentSelection
