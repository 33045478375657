import React, { useCallback, useContext , useMemo } from 'react'
import { ReturingCustomersLayoutContext } from '../ReturningCustomersLayout';
// import TreatmentSelection from './TreatmentSelection';
import RadioSelect from '../../../../components/RadioSelect/RadioSelect';
import TreatmentSelection from './TreatmentSelection';


interface ProductCardProps {
  title: string,
}

const NoPreferenceProductCard: React.FC<ProductCardProps> = ({ title }) => {

  const context = useContext(ReturingCustomersLayoutContext);

  const isNoPreferenceSelected = context?.isNoPreferenceSelected;
  const initalProduct = context?.initialProduct;

  const onSelect = useCallback(() => {
    if (!isNoPreferenceSelected) {
        context?.setIsNoPreferenceSelected(true);
        context?.setSelectedProductID(initalProduct!.product_id);
        context?.setSelectedProductItemID(initalProduct!.product_item_id);
        context?.setSelectedCatalogID(null);
    }   
  }, [isNoPreferenceSelected, context?.setIsNoPreferenceSelected]);


  const bgColor = useMemo(() => {
    if (isNoPreferenceSelected) {
      return "bg-[#e7fcf7]"
    } else {
      return "bg-white"
    }
  }, [isNoPreferenceSelected])

  return (
    <div id={`catalog_no-preference`} style={{ border: isNoPreferenceSelected ? "1px solid #11ddac" : "" }} className={`${bgColor} scroll-mt-[20px] flex flex-col items-center rounded-md cursor-pointer`}>
      <div onClick={onSelect} className={`flex px-4 select-none gap-2 p-2 flex-1 w-full py-5`}>
        <div className='flex w-[80%]'>
          <RadioSelect size={20} selected={Boolean(isNoPreferenceSelected)} disabled={false} />
          <p className='text-[#0d2c54] text-sm font-medium'>{title}</p>
        </div>

      </div>

      <div id='hidden-content' className='md:hidden w-full grow-anim'>
        {
          isNoPreferenceSelected && <TreatmentSelection />
        }
      </div>
    </div>
  )
}

export default NoPreferenceProductCard
