import * as React from "react";
import './DarkLoadingModal.css'

export default function DarkLoadingModal() {
  return (
    <>
    <div className="dark-loading-container">
        <div className="dark-loading dark-loading-circle"></div>
    </div>
    </>
  )
}