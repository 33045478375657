import React from 'react';
import "./MyAccountThanksForUpdateModal.css";

interface MyAccountThanksForUpdateModalProps{
    closeModal: () => void
}

const MyAccountThanksForUpdateModal:React.FC<MyAccountThanksForUpdateModalProps> = ({closeModal}) => {
  return (
    <div onClick={closeModal} className="modal-background">
        <div 
        className="modal-popup-container"
        onClick={(event) => event.stopPropagation()}
        >
            <img onClick={closeModal} className="close-btn" src={require("../../../../src/icons/X.svg").default} alt="X" />
            <h6>Vielen Dank für das Update.</h6>
            <p>Ihr Behandlungsplan wurde erfolgreich erneuert. Unser Arzt wird Ihre medizinischen Daten überprüfen. Wir melden uns bei Ihnen, sollten wir weitere Fragen haben.</p>
            <span onClick={closeModal}>Schließen</span>
        </div>
    </div>
  )
}

export default MyAccountThanksForUpdateModal