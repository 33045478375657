import React, { useContext, useEffect } from "react";
import { CustomerContext } from "../reducers/CustomerReducer";
import { useParams, useNavigate } from "react-router-dom";
import LoadingModal from "../components/Modals/LoadingModal/LoadingModal";

interface ProtectedAuthRoutesProps {
  children: any;
}

// If: customer logged in -> navigate to MyAccount
// else: let him use the auth route and login / register

const ProtectedAuthRoutes: React.FC<ProtectedAuthRoutesProps> = ({ children }) => {
  const { country } = useParams(); // Get the "country" parameter from the URL
  const navigate = useNavigate(); 
  const customer = useContext(CustomerContext);
  const fullURL = window.location.href;
  const redirectParam = '?redirect=';
  
  useEffect(() => {
    if (customer.customerState && customer.customerState.customerID) {
      if(fullURL.includes(redirectParam)){
        const redirectTo = fullURL.split(redirectParam)[1];
        navigate(redirectTo);
      }else{
        navigate(`/${country}/account/my-orders`);
      }
    }
  }, [customer, navigate, country]);

  if (customer.customerState && customer.customerState.customerID){
    return <LoadingModal /> //load until it navigates (this line happens pre useeffect)

  } else {
    return children;
  }

};

export default ProtectedAuthRoutes;
