import React, { useContext, useMemo } from 'react'
import { ReturingCustomersLayoutContext } from '../ReturningCustomersLayout';
import SubscriptionOption from './SubscriptionOption';


interface SubscriptionSelectionProps {
    isResponsiveLayout?: boolean
}

const SubscriptionSelection:React.FC<SubscriptionSelectionProps> = ({ isResponsiveLayout }) => {
    const context = useContext(ReturingCustomersLayoutContext);
    const selectedProductItemID = context?.selectedProductItemID;
    const productItemToPlan = context?.productItemToPlan;
    const plans = productItemToPlan && productItemToPlan[selectedProductItemID || 0];
    const plansLength = plans?.length || 0;
    const plansData = context?.subscriptionPlansData;

    const maxDiscount = useMemo(() => {
        let maxDiscount: number | undefined = 0;

        if (plansLength > 0 && plansData) {
            maxDiscount = plans?.reduce((value, planID) => {
                const planData = plansData[planID];
                return Math.max(value, planData?.discount || 0);
            }, 0);
        }

        return maxDiscount;
    }, [plans, plansData])


    return (
        plansLength ? <div className='pt-5' style={
            {
                borderTop: "1px solid #e0e0e0",
            }
        }>
            <h1 className="DrugSubscription-title pb-3">Lieferintervall wählen</h1>
            <div id='subscription-selection' className='flex flex-col gap-4 justify-center pt-2'>
                {
                    plans?.map((planID: number) => {
                        return <SubscriptionOption maxDiscount={maxDiscount} key={planID} oneTime={false} planID={planID} />
                    })
                }
                {
                    plans && plans?.length > 0 &&
                    <SubscriptionOption isResponsiveLayout={isResponsiveLayout} maxDiscount={maxDiscount} oneTime={true} planID={0} /> //this will be used as "ONE-TIME PURCHASE (meaning no subscription)"
                }
            </div>
        </div> : null
    )
}

export default SubscriptionSelection
