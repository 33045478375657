import { clientConfig } from "../../config";
import * as http from "../../utils/http";


export const updateSubscriptionDBCart = async (cartID, subscriptionPlanID) => {

  try {
    const response =await http.postToServer("cart/update_subscription_data", {
      subscriptionPlanID,
    },[http.MIDDLEWARES.CUSTOMER_ID,http.MIDDLEWARES.CART_ORDER]);
    if (response.data.forceRedirect) {
      window.location.href = `${window.location.pathname}?ec=forceRefresh`;
    }
  } catch (err) {
    throw new Error("error updating cart");
  }
};


export const updateBonusDBCart = async (
  bonusID,
  bonusValue,
  autoBonus,
  subscriptionDiscount = 0
) => {
  try {
    const response = await http.postToServer("cart/update_coupon_data", {
      bonusID,
      bonusValue,
      autoBonus,
      subscriptionDiscount
    },[http.MIDDLEWARES.CUSTOMER_ID,http.MIDDLEWARES.CART_ORDER]);

    if(response.data.status === "ok"){
      return response.data.cart;
    }

  } catch (err) {
    throw new Error("error updating cart");
  }
};

export const resetBonusesInCart = async () => {
  let response = await http.getFromServer(`cart/reset/bonuses`,  [http.MIDDLEWARES.CUSTOMER_ID,http.MIDDLEWARES.CART_ORDER]);

  const {autoDiscountData, cartView} = response.data; 
  return {autoDiscountData, cartView};
};

export const getPlanIndex = (plans, planID) => {
  let index = null;
  plans.forEach((plan, planIndex) => {
    if (plan.product_plan_id === planID) {
      index = planIndex;
    }
  });
  return index;
};

export const navigateBasedOnPaymentTypeID = (
  paymentType,
  navigationCallback,
  data
) => {


  switch (paymentType) {
  case "ONLINE":
    navigationCallback(
      `/payment/${data.country}/${data.language}/${data.hashID}`,
      {},
      {
        wl: data.bmi,
        bp: data.bloodPressure,
        erecipe: data.order?.is_e_recipe
      }
    );
    break;
  }
};

export const loadGoogleApi = (callback) => {
  const existingScript = document.getElementById("googleLocationsApi");
  if (!existingScript) {
    window.initMap = () => {};
    const script = document.createElement("script");
    script.src = clientConfig.GOOGLE_PLACES_API_KEY;
    script.id = "googleLocationsApi";
    document.head.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export const paymentImages = {
  paypal: clientConfig.IMAGES_URL + "uploads/pages/75/paypal_payment.png",
  klarna: clientConfig.IMAGES_URL + "uploads/pages/69/klarna.png",
  visa: clientConfig.IMAGES_URL + "uploads/pages/75/visaMain.png",
  mastercard: clientConfig.IMAGES_URL + "uploads/pages/69/master.jpg",
  giropay: clientConfig.IMAGES_URL + "uploads/pages/75/giropay.png",
  transfer: clientConfig.IMAGES_URL + "uploads/pages/75/de_Uberweisung.png",
  cashOnDelivery: clientConfig.IMAGES_URL + "uploads/pages/69/de_cod.png",
  wireTransfer: clientConfig.IMAGES_URL + "uploads/pages/69/de_cod.png",
  frTransfer: clientConfig.IMAGES_URL + "uploads/pages/69/bank-transfer-fr.png",
  googlePay: "/googlepay.png",
  applePay: "/applepay.png",
  frMaestro:
    "https://1000logos.net/wp-content/uploads/2020/07/Maestro-Logo-1992.png",
  frCartes:
    "https://storage.googleapis.com/adforum-media/34506363/ad_34506363_b0b0831318b62c47_web.jpg",
};

export const FR_ONLINE_PAYMENT_IMAGES = [
  paymentImages.frCartes,
  paymentImages.paypal,      
  paymentImages.visa,
  paymentImages.frMaestro,
  paymentImages.applePay,
  paymentImages.googlePay
]

export const DE_ONLINE_PAYMENT_IMAGES = [
  paymentImages.paypal,
  paymentImages.klarna,
  paymentImages.visa,
  paymentImages.applePay,
  paymentImages.googlePay
]

