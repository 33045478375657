import './RadioSelect.css'

export default function RadioSelect({selected,disabled=false,backgroundColor=null,borderColor=null, size=18 }:
  {
    selected:boolean,
    disabled:boolean|null,
    backgroundColor?:string|null,
    borderColor?:string|null,
    size?:number
  }
  ) {

    let defaultCss = {width: size+"px", height: size+"px"} as any
    let cssStyle = {width:size+"px", height:size+"px"} as any

    if (backgroundColor){
      cssStyle.backgroundColor = backgroundColor
      cssStyle.outlineColor = backgroundColor
    }
    if (borderColor){
      cssStyle.borderColor = borderColor
    }

  return (
    <div className="radio-select-container">
      <div
        className={
          (selected ? "radio-select-selected" : "radio-select") +
          (disabled ? " radio-select-disabled" : " ")
        }

        style={selected ? cssStyle : defaultCss}
      >


      </div>
    </div>
  );
}
