
import "./DrugInfoPanel.css";
import * as translator from "../../../../utils/translator";

const DrugInfoPanel = ({
  img,
  drugDescription,
  drugTitle,
  fromPrice,
  language,
}) => {


  return (
    <div className="DrugInfoPanel-container">
      <div className="drug-basic-info">
        <img src={img} alt="" className="drug-img" />

        <div className="drug-title-and-price">
          <h2 className="drug-main-title">{drugTitle}</h2>
          <h3 className="drug-info-price">
            {translator.translate(language, "default" , "DrugInfoPanel/from")} {fromPrice}€
          </h3>
        </div>
      </div>

      <div className="product-body">
        <p className="drug-info">
          {drugDescription
            ? drugDescription
            : "Echte generische Version von Viagra, der gleiche Wirkstoff Sildenafil. Genauso wirksam, aber günstiger."}
        </p>

        <ul className="key-points">
          <li>{translator.translate(language, "default" , "DrugInfoPanel/key_point_1")}</li>
          <li>{translator.translate(language, "default" , "DrugInfoPanel/key_point_2")}</li>
          <li>{translator.translate(language, "default" , "DrugInfoPanel/key_point_3")}</li>
        </ul>
      </div>
    </div>
  );
};

export default DrugInfoPanel;
