import React, { ReactElement } from 'react';

interface InnerHTMLRendererProps {
  htmlContent: string;
  className?: string;
  children?: any;
}

const InnerHTMLRenderer: React.FC<InnerHTMLRendererProps> = ({ htmlContent, className, children }): ReactElement => {
  // Manual function to sanitize the HTML content to prevent XSS attacks
  const sanitizeHTML = (htmlContent: string): string => {
    const div = document.createElement('div');
    div.innerHTML = htmlContent;
    return div.innerHTML;
  };

  const sanitizedHtml = sanitizeHTML(htmlContent);

  return (
    <div className={className ? className : ''}>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
      {children}
    </div>
  );
};

export default InnerHTMLRenderer;
