import React, { useState, useEffect } from 'react'
import "./ChangedEmailConfirmationPage.css" 
import { useParams } from "react-router-dom"
import Footer from '../../components/Footer/Footer';
import * as translator from "../../utils/translator";
import * as http from "../../utils/http"
import { clearCustomerFromSession } from '../../reducers/CustomerReducer';
import HeaderCMS from '../../components/Headers/HeaderCMS/HeaderCMS';
import emailChangeImage from "../../images/email-changed.png"
import StickyNotification from '../../components/StickyNotification/StickyNotification';
import { useNavigate } from 'react-router-dom';


const ChangedEmailConfirmationPage = () => {

  const { country } = useParams();
  const { token } = useParams();
  const [stickyNotificationDetails, setStickyNotificationDetails] = useState({ // global state for sticky handling
    show: false,
    title: "",
    content: "",
    level : "",
    showInfoIcon:true,
    refresh:false,
  });
  const navigate = useNavigate()

  useEffect(() => {
    if (!token) {
      navigate(`/${country}/login`);
    }
    http
    .postToServer(
    `update-email`,
    {
      token:token,
    },
    []
    )
    .then((response) => {
        clearCustomerFromSession()
    })
    .catch(error => {
      setStickyNotificationDetails({
        show: true,
        title: "",
        content: translator.translate(translator.getLanguageByCountry(country), "my_account", `stickyNotifications/error/error`),
        level: "error",
        showInfoIcon: false,
        refresh: false
      });
    });
  }, [])

  return (
    <>
      <StickyNotification
        showNotification={stickyNotificationDetails.show}
        title={stickyNotificationDetails.title}
        onClick={() => setStickyNotificationDetails({ ...stickyNotificationDetails, show: false })}
        content={stickyNotificationDetails.content}
      />
      {country && (
        <div className="email-change-page">
          <HeaderCMS country={country} />
          <div className="email-change-image-container">
          <img className="email-change-image" src={emailChangeImage} alt="Email changed" />
          <h1 className="email-change-title">Email changed successfully!</h1>
          </div>
          <div>
            <Footer page="CheckoutRegister" />
          </div>
        </div>
      )}
    </>
  )
}

export default ChangedEmailConfirmationPage;