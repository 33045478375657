import React from "react";
import PhotoSelection from "../PhotoSelection/PhotoSelection";

const PhotoSelectionPanel = ({
  photoSelectionPickedIdx,
  hairLossPhotoSelectionURLS,
  errorMode,
  question,
  onPhotoChange,
}) => {
  return (
    <>
      <div
        className="questionnaire-question-item-text-container"
        dangerouslySetInnerHTML={{ __html: question.text }}
      />
      <div
        className="questionnaire-question-item-helpblock-container"
        dangerouslySetInnerHTML={{ __html: question.help_block }}
      />
      <div className="questionnaire-answers-photoSelection-container">
        {hairLossPhotoSelectionURLS.map((photoSrc, index) => {
          return (
            <PhotoSelection
              errorMode={errorMode}
              questionId={question.id}
              answer={question.answersGroup.children[index]}
              questionsValidationObject={{}}
              pickedPhotoId={photoSelectionPickedIdx}
              onChangeValue={onPhotoChange}
              photoSrc={photoSrc}
              placeholder={index + 1}
              id={index}
              key={index}
            />
          );
        })}
      </div>
    </>
  );
};

export default PhotoSelectionPanel;
