import React from 'react';
import "./UnderMaintenance.css"

const UnderMaintenance = () => {
  return (
    <div className='under-maintenance-body'>
    
        <img src={require("../../images/logo-dark.svg").default} alt="DoktorABC" width="200" />
        
      <article>
        <h1>We’ll be back soon!</h1>
        <h2>The website is under maintenance mode.</h2>
        <div>
          <p>Sorry for the inconvenience but we're performing some maintenance at the moment. If you need to, you can always <a href="mailto:support@doktorabc.com">contact us</a>, otherwise, we'll be back online shortly!</p>
          <p>Your DoktorABC Team.</p>
        </div>
      </article>

      <div className="footer--copyright">
        © {new Date().getFullYear()} DoktorABC.com
      </div>
    </div>
  );
};

export default UnderMaintenance;
