import * as React from "react";
import "./ServerError.css";
import fourOfour from "../../images/error-404.jpg";
import serverError from "../../images/error-500.jpg";
import { MainTracker } from "../../PixelTrackers/MainTracker";
import { useEffect } from "react";
import SubmitButton from "../SubmitButton/SubmitButton";
import * as translator from "../../utils/translator"
import { clientConfig } from "../../config";

export default function ServerError({ status = 500 }) {
  useEffect(() => {
    MainTracker.track("pv", `View ${status} Page`);
  }, []);

  const fullPath = window.location.pathname;
  const urlSplits = fullPath.split("/");
  let country = urlSplits[1].toLowerCase();
  /*
  We have to get the country this way because this component can render with every kind of bad url 
  for example funnel.doktorabc.com/account (no locale params),

  incase the urlSplits[0] is a not convinient (not DE/GB/FR etc...) the translator will return english
  */
  let language = translator.getLanguageByCountry(country);

  const windowAsAny = window as any;
  let uid;

  if (windowAsAny.eventsTracking) {
    uid = windowAsAny.eventsTracking.getData("uid");
  }

  const redirectToCmsByCountry = () => {
    if (["de","fr"].includes(country.toLowerCase())){
      return window.location.href = clientConfig.MAIN_WEBSITE_URL + "/" + country
    } else {
      //to the geo page
      return window.location.href = clientConfig.MAIN_WEBSITE_URL
    }
  }

  
  return (
    <div className="server-error-container">
      <img src={status == 500 ? serverError : fourOfour} />
      <div className="server-error-text-container">
        <span className="server-error-uid">{uid}</span>
        {status != 500 ? (
          <>
            <span className="server-error-header">
              {translator.translate(language,"default","ServerError/title")} 
            </span>
            <h1 className="server-error-message">
              {translator.translate(language,"default","ServerError/info")}
            </h1>
            <SubmitButton
              id={"server-error-submit"}
              loading={false}
              onSubmit={redirectToCmsByCountry}
              width={"300px"}
              placeholder={translator.translate(language,"default","ServerError/submit_button_placeholder")}
            />
          </>
        ) : null}
      </div>
    </div>
  );
}
