let store = {} as any;

let sessionStorageEnabled = false;
let validated = false;

const isStorageEnabled = () => {
  
  if (!validated){
  validated = true;
  try {
    window.sessionStorage.setItem("a", "123");
    if (window.sessionStorage.getItem("a") === "123") {
      sessionStorageEnabled = true;
    }
  } catch (storageErr) {
    window.clientLogger.warn("storage disabled");
  }
  }
  return sessionStorageEnabled
};

export const getItem = (key: string) => {

  if (isStorageEnabled()){
    return window.sessionStorage.getItem(key)
  }
  else{
    return store[key];
  }
}


export const setItem = (key: string, value: any) => {
  if (isStorageEnabled()){
    return window.sessionStorage.setItem(key,value)
  }
  else{
    store[key] = value
  }
}