import React, { useState } from 'react';

interface CategoriesHamburgerMenuCategoryAccordionProps {
  rootCategory: any;
  setShow: any
  setSelectedMention:any
}

const CategoriesHamburgerMenuCategoryAccordion: React.FC<CategoriesHamburgerMenuCategoryAccordionProps> = ({ rootCategory, setShow, setSelectedMention }) => {
  const [active, setActive] = useState(false);

  const setItemAndClose = (child:any)=>{
    setSelectedMention({[child.mention]:child.title}); 
    setShow(false)
  }

  return (
    <div className="root-category-accordion">
      <div
        onClick={() => setActive((current) => !current)}
        className={`root-category-accordion-head`}
      >
        <h4 className={`root-category-title${active ? "-active" : ""}`}>{rootCategory.title}</h4>
        <img
          className={`chevron-icon ${active ? "chevron-up" : "chevron-down"}`}
          src={require("../../../icons/Chevron-Down-Green.svg").default}
          alt="Chevron"
        />
      </div>

      {active ? 
        <ul className="child-categories">
          {
            rootCategory.children.map((child : any) => {
              return (
                <li key={child.id} className="child-category"><a onClick={()=>setItemAndClose(child)}>{child.title}</a></li>
              )
            })
          }
        </ul>
       : null}
    </div>
  );
};



export default CategoriesHamburgerMenuCategoryAccordion;
