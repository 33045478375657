import React from "react";
import {useOutletContext} from "react-router-dom";
import * as translator from '../../../utils/translator';
import InputField from "../../../components/CredentialsInput/InputField";
import Select from "../../../components/Select/Select";

/* 
    STYLING OF THIS COMPONENT IN MyAccountDeliveryAddress.css
*/

interface DeliveryAddressPersonalAddressFormProps {
  personalAddressAttributes: any; //useForm attributes from the MyAccountDeliveryAddress component
  setDestinationCountry: any;
}

const DeliveryAddressPersonalAddressForm: React.FC<DeliveryAddressPersonalAddressFormProps> = ({ personalAddressAttributes, setDestinationCountry }) => {
  const {myAccountData,language} = useOutletContext<any>();

  return (
    <div className="delivery-address-form">
      <div className="two-in-row-1">
        <InputField
          mandatory
          id="personal_first_name"
          placeholder={translator.translate(language, "my_account", `forms/firstName`)}
          value={personalAddressAttributes.form["first_name"]}
          errorMsg={
            personalAddressAttributes.errorMessagePerField["first_name"]
          }
          language={language}
          onBlur={() => personalAddressAttributes.onFieldBlur("first_name")}
          touched={personalAddressAttributes.touched.first_name}
          onChange={(value: any) => {
            personalAddressAttributes.updateForm("first_name", value);
          }}
        />

        <InputField
          mandatory
          id="personal_last_name"
          placeholder={translator.translate(language, "my_account", `forms/lastName`)}
          value={personalAddressAttributes.form["last_name"]}
          errorMsg={personalAddressAttributes.errorMessagePerField["last_name"]}
          language={language}
          onBlur={() => personalAddressAttributes.onFieldBlur("last_name")}
          touched={personalAddressAttributes.touched.last_name}
          onChange={(value: any) => {
            personalAddressAttributes.updateForm("last_name", value);
          }}
        />
      </div>

      <InputField
        mandatory
        id="personal-address"
        placeholder={translator.translate(language, "my_account", `forms/streetAndNumber`)}
        value={personalAddressAttributes.form["address"]}
        errorMsg={personalAddressAttributes.errorMessagePerField["address"]}
        language={language}
        onBlur={() => personalAddressAttributes.onFieldBlur("address")}
        touched={personalAddressAttributes.touched.address}
        onChange={(value: any) => {
          personalAddressAttributes.updateForm("address", value);
        }}
      />

      <InputField
        mandatory
        id="personal_zip"
        placeholder={translator.translate(language, "my_account", `forms/zip`)}
        value={personalAddressAttributes.form["zip"]}
        errorMsg={personalAddressAttributes.errorMessagePerField["zip"]}
        language={language}
        onBlur={() => personalAddressAttributes.onFieldBlur("zip")}
        touched={personalAddressAttributes.touched.zip}
        onChange={(value: any) => {
          personalAddressAttributes.updateForm("zip", value);
        }}
      />

      <InputField
        mandatory
        id="personal_city"
        placeholder={translator.translate(language, "my_account", `forms/city`)}
        value={personalAddressAttributes.form["city"]}
        errorMsg={personalAddressAttributes.errorMessagePerField["city"]}
        language={language}
        onBlur={() => personalAddressAttributes.onFieldBlur("city")}
        touched={personalAddressAttributes.touched.city}
        onChange={(value: any) => {
          personalAddressAttributes.updateForm("city", value);
        }}
      />
      {language == 'fr' ?
      <InputField
        mandatory
        id="personal_country"
        value={myAccountData.locale.name}
        placeholder={translator.translate(language, "my_account", `forms/country`)}
        language={language}
        errorMsg={personalAddressAttributes.errorMessagePerField["country"]}
        disabled //DISABLED WHICH MEANS IT'S LOCKED AND GRAY
        onBlur={() => personalAddressAttributes.onFieldBlur("country")}
        touched={personalAddressAttributes.touched.country}
        onChange={(value: any) => {
          return;
        }}
      />
      :
      <Select 
        id={"shipping_country"}
        initialValue={{value:translator.translate(language,"shipping",`ShippingForm/country_name/${personalAddressAttributes.form.country?.toLowerCase()}`)}}
        options = {[{value:translator.translate(language,"shipping","ShippingForm/country_name/de"), code:'de'}, {value:translator.translate(language,"shipping",`ShippingForm/country_name/ch`), code:'ch'}]}
        placeholder={""}
        onChangeCallback={(value: any) => {
          setDestinationCountry(value.option.code)
          personalAddressAttributes.updateForm("country", value.option.code.toUpperCase());
        }} 
        questionnaireValidationObject={{}}
        errorMode={""}
        style={{
          border: "1px solid #dbe1eb",
          height: "56px",
          width: "100%",
          borderRadius: "6px",
          backgroundColor: "white",
          margin: "15px 0px"
          }
        }
        includeShortDescription={false}
      />
    }
      <div className="two-in-row-4">
      <InputField
          mandatory={false}
          id="personal_company"
          placeholder={translator.translate(language, "my_account", `forms/company`)}
          value={personalAddressAttributes.form["company"]}
          errorMsg={personalAddressAttributes.errorMessagePerField["company"]}
          language={language}
          onBlur={() => personalAddressAttributes.onFieldBlur("company")}
          touched={personalAddressAttributes.touched.company}
          onChange={(value: any) => {
            personalAddressAttributes.updateForm("company", value);
          }}
        />

        <div className="phone-and-info" style={{width:"100%",marginBottom:20}} >
          <InputField
              mandatory={true}
              id="personal_phone"
              placeholder={translator.translate(language, "my_account", `forms/phone`)}
              value={personalAddressAttributes.form["phone"]}
              errorMsg={personalAddressAttributes.errorMessagePerField["phone"]}
              language={language}
              onBlur={() => personalAddressAttributes.onFieldBlur("phone")}
              touched={personalAddressAttributes.touched.phone}
              onChange={(value: any) => {
                personalAddressAttributes.updateForm("phone", value);
              }}
          />
          <span className="delivery-phone-helpblock">
            {translator.translate(language, "my_account", `DeliveryAddress/note`)}
          </span>

        </div>
        
      </div>
      
    </div>
  );
};

export default DeliveryAddressPersonalAddressForm;
