import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import "./MyAccountOrderPrescriptionDetails.css";
import * as http from "../../../../utils/http";
import MyAccountOrderPrescriptionDetailsAnswer from "../MyAccountOrderPrescriptionDetailsAnswer";
import * as translator from '../../../../utils/translator';

interface MyAccountOrderPrescriptionDetailsProps{
  orderHashId:string;
}

const MyAccountOrderPrescriptionDetails:React.FC<MyAccountOrderPrescriptionDetailsProps> = ({orderHashId}) => {
  const { setRefreshData, setStickyNotificationDetails, myAccountData } = useOutletContext<any>();
  const [orderPrescriptionDetails,setOrderPrescriptionDetails] = useState([]);
  const [isShowAnswerBlock, setIsShowAnswerBlock] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const orderData = myAccountData.myOrders.ordersData[orderHashId];
  const language = myAccountData.locale.language_id

  useEffect(() => {
    setOrderPrescriptionDetails(myAccountData.myOrders.ordersData[orderHashId].orderPrescriptionDetails)
    setIsShowAnswerBlock(myAccountData.myOrders.ordersData[orderHashId].status === 'need_customer_answer')
    setIsLoading(false);
  }, [myAccountData])
  
  const getPhoto = (basePath: string, name: string) => {
    http
      .postToServer(
        `download-file`,
        {
          hashID: orderHashId,
          s3Key: basePath + name,
        },
        [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.GENERATE_FILE]
      )
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name); // replace with desired filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        // Handle error
      });
    };
    
    const onSubmit = (file: { binary_data: ArrayBuffer,filename:string,type:string } | null, message:string) => {
      setIsLoading(true)
      let fileToSend:any = null
      file ? fileToSend = [file] : fileToSend = []
      http
        .postMultiPartsToServer(
          `account-update/prescription-details`,
          {
            message,
            orderID: orderData.orderPrescriptionDetails[0].order_id,
            hashID: orderHashId,
            prescriptionID: orderData.orderPrescriptionDetails[0].prescription_id,
            customerID: myAccountData.customer.id,
            createdAt: orderData.createdAt
          },
          fileToSend,
          [
            http.MIDDLEWARES.CUSTOMER_ID,
          ]
        )
        .then((response:any) => {
          setRefreshData((current:boolean)=>!current)
        })
        .catch((error) => {
          setStickyNotificationDetails((currentDetails:any) => ({
            show: true,
            title: "",
            content: translator.translate(language, "my_account", `stickyNotifications/error/${error.response.data}`),
            level : "error",
            showInfoIcon:false,
            refresh: !currentDetails.refresh
          }))
        });
    };

  if(orderPrescriptionDetails?.length > 0){
    return (
      <>
        <div className="prescription-details">
          {/* RENDERING MESSAGES ROWS */}
          {orderPrescriptionDetails.map((message: any, index: number) => (
              <div className="prescription-details-message" key={index}>
                <div className="prescription-details-author">
                  <span>
                    {message.first_name} {message.middle_name} {message.last_name}{" "}
                  </span>
                  <span> {message.created_at} </span>
                </div>
    
                <div className="prescription-details-content">
                  <p>{message.content}</p>
                  {/* the following for attachments: */}
                  {message.display_name && (
                    <a onClick={() => getPhoto(message.base_path, message.name)}>
                      {message.display_name}
                    </a>
                  )}
                </div>
              </div>
          ))}
          {/* END OF RENDERING MESSAGES ROWS */}

          {
            isShowAnswerBlock && <MyAccountOrderPrescriptionDetailsAnswer onSubmit={onSubmit} isLoading={isLoading}/>
          }

        </div>
      </>
    );
  } else {
    return <></>
  }

  
};

export default MyAccountOrderPrescriptionDetails;
