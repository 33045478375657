import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { clientConfig } from "./config";
import * as BugsnagManager from './utils/BugsnagManager'
import {MainTracker} from './PixelTrackers/MainTracker'
import {CustomErrorBoundary} from './components/CustomErrorBoundary/CustomErrorBoundary'
import * as OneSignal from './utils/OneSignal'

const createApp = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));

  window.clientLogger && window.clientLogger.setMetaData({version:clientConfig.VERSION})

  if (clientConfig.BUGSNAG_API_KEY) {

    BugsnagManager.start()
    
    root.render(
      <CustomErrorBoundary>
        <App />
        </CustomErrorBoundary>
    );

  } else {
    root.render(<App />);
  }
  MainTracker.init()
  OneSignal.init()
};

// create react app only after scripts framework is loaded
if (window.eventsTracking.loaded) {
  createApp();
} else {
  window.eventsTracking.onLoad(createApp);
}