import { useContext } from "react";
import { ShippingDataContext } from "../Shipping";
import ToggleRadioButton from "../../../components/ToggleRadioButton/ToggleRadioButton";

type Props = {
    isToggleEnabled: boolean,
    updateToggle: () => void,
    precautionLevel: string
}

const TogglePrecaution:React.FC<Props> = ({isToggleEnabled,updateToggle,precautionLevel}) => {

    const selfPickupPharmacy = useContext(ShippingDataContext).selfPickupPharmacy;
  
    return (
      <div className="toggle-precaution-container">
        <ToggleRadioButton
          id={precautionLevel === "error" ? "toggle-precaution-error" : "toggle-precaution"}
          isToggleEnabled={isToggleEnabled}
          updateToggle={updateToggle}
        />
        <span className="toggle-precaution-text" style={{ color: `${precautionLevel === "error" ? "var(--primary-danger)" : "var(--radiobutton-text-color)"}` }}>
          Ich werde das Paket selbst abholen, meine persönlichen Daten sind korrekt, <b> und ich werde dem 
            {selfPickupPharmacy ? " Apotheker "  : " Kurier "}
            meinen Ausweis zeigen.
            </b>
        </span>
      </div>
    )
  }
  
  export default TogglePrecaution  