import React from 'react';
import './OtherOrders.css';
import * as translator from "../../../../utils/translator"

interface OtherOrdersProps {
    otherCountry:string;
    otherOrdersCount:number;
    country:any;
}

const OtherOrders: React.FC<OtherOrdersProps> = ({country, otherCountry,otherOrdersCount}) => {
    
  return (
    <div className="account-other-orders">
        <div className="other-orders-text">
            <p className="other-orders-title">{translator.translate(country, "my_account", 'MyOrders/otherCountriesTitle')}</p>
            <p className="other-orders-description">{translator.translate(country, "my_account", 'MyOrders/otherCountriesSubTitle')}</p>
        </div>
        <div className="other-orders-links">
            <a 
              href={`/${otherCountry!}/account/my-orders`}
              className="other-country-orders">

              {`${otherOrdersCount} ${translator.translate(country, "my_account", 'MyOrders/otherCountriesOrder')}`}
            </a>

        </div>
    </div>
  )
}

export default OtherOrders