import React, { useEffect } from "react";
import './PhotoDiagnose.css'
import { clientConfig } from "../../../../config";
import { useDropzone } from "react-dropzone";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import cameraLogo from '../../../../icons/camera-logo.png'
import xLogo from '../../../../icons/X.png'
import activeLogo from '../../../../icons/Active.svg'
import Significance from "../Significance/Significance";
import * as translator from "../../../../utils/translator";

interface PhotoDiagnoseProps {
  language?:string,
  setFilesCallback:any,
  files:any,
  category?:string,
  imageUrl?:string,
  necessaryFileCount?:[number, number]
}

const PhotoDiagnose:React.FC<PhotoDiagnoseProps> = ({ language, category, setFilesCallback, files, imageUrl, necessaryFileCount=[2,4] }) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    noClick: true,
    noKeyboard: true,

    onDrop: (accepted_files) => {
      if (!accepted_files || accepted_files.length === 0) return;

      let new_files = files.map((item:any)=> item);

      accepted_files.forEach((curr_accepted_file) => {
        const reader = new FileReader();
        reader.onabort = () => {};
        reader.onerror = () => {};
        reader.onload = () => {

            new_files.push({
            local_image: URL.createObjectURL(curr_accepted_file),
            binary_data: reader.result,
            filename: curr_accepted_file.name,
            type: curr_accepted_file.type,
          })

          setFilesCallback(new_files);
        };
        reader.readAsArrayBuffer(curr_accepted_file);
      });
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file:any)=>URL.revokeObjectURL(file.preview))
    },
    [files]
  );

  const deleteFile = (index:number)=>{
    let new_files = files.filter((file:any,fileIndex:number)=> fileIndex !== index)
    setFilesCallback(new_files)
  }


  return (
    <div className="questionnaire-photoDiagnose-container">
      {category === "hair_loss" ? 
       <div className="questionnaire-photoDiagnose-image-container">
        <img 
          src={clientConfig.IMAGES_URL + translator.translate(language,"default","PhotoDiagnose/hairloss_image")}    
        />
        <p>{translator.translate(language,"default","PhotoDiagnose/hairloss_image_text")}</p>
       </div>
      :
      null
      }

      <div className="questionnaire-photoDiganose-draganddrop-container"
        {...getRootProps()}
      >
        <div className="questionnaire-photoDiganose-draganddrop">
          <input {...getInputProps()} />
          <img className="questionnaire-photoDiagnose-cameralogo" src={imageUrl ?? cameraLogo}/>
          <p className="questionnaire-photoDiagnose-title">{translator.translate(language,"default","PhotoDiagnose/drag_and_drop_text")}</p>
          <SubmitButton 
            id={"photo_diagnose_submit"}
            isEnabledState={files.length < 4}
            onSubmit={open}
            language={language}
            placeholder={translator.translate(language,"default","PhotoDiagnose/drag_and_drop_button_text")}
            margin={"15px 0px"}
            height={'44px'}
            fontsize={"14"}/>
          <p className="questionnaire-photoDiagnose-subtext">{translator.translate(language,"default","PhotoDiagnose/drag_and_drop_subtext")}</p>
        </div>

        {files.length && files.length > necessaryFileCount[1] ? <Significance parentID={null} dangerSignificancesRefsDispatch={null} fontWeight={null} level="danger" text={`You can upload up to ${necessaryFileCount[1]} photos`}/> : null}

        {files.map((file:any,index:number)=> 
            <div className="questionnaire-photoDiagnose-uploaded-file-container" key={index}>
              <img className="questionnaire-photoDiagnose-uploaded-image" src={file.local_image}/>

              <span className="questionnaire-photoDiagnose-uploaded-filename">{file.filename}</span>
              <div className="questionnaire-photoDiagnose-uploaded-logos">
                <img className="questionnaire-photoDiagnose-uploaded-cancel" src={xLogo} onClick={()=>deleteFile(index)}/>
                <img className="questionnaire-photoDiagnose-uploaded-activelogo" src={activeLogo}/>
              </div>
            </div> 

          )}

        {files.length && files.length < necessaryFileCount[0] ? <Significance parentID={null} dangerSignificancesRefsDispatch={null} fontWeight={null} level="warning" text={`Please upload at least ${necessaryFileCount[0]} photos`}/> : null}
      </div>
    </div>
  );
}


export default PhotoDiagnose;