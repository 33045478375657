import React from 'react';
// @ts-ignore
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import "./DatePickerInput.css"
import { useParams } from 'react-router-dom';


type DateInputProps = {
    onChange: Function;
    id?: string;
    errorMode: boolean;
    value?: string;
    isBirthdayInput?:boolean;
};

const DatePickerInput:React.FC<DateInputProps> = ({onChange,id,errorMode = false,value,isBirthdayInput=true}) => {
  const {language} = useParams();

  /* Helpers */
  function getOptionsRange(startRange: number, finishRange: number) {
    let options: number[] = [];
    for (let i = startRange; i <= finishRange; i++) {
      options.push(i);
    }
    return options;
  }

  function getMonthsOptionsByLanguage(languageId: string) {
    const options: string[] = [];
    const currentYear = new Date().getFullYear();
    const monthList = Array.from({ length: 12 }, (_, i) => i);
    const formatter = new Intl.DateTimeFormat(languageId, { month: 'long' });
    monthList.forEach((monthNumber) => {
      options.push(formatter.format(new Date(currentYear, monthNumber, 1)));
    });
    return options;
  }

  function getMaxDate(){
    // Limit the pick to 18 years old
    if(isBirthdayInput){
      const maxDate = new Date();
      maxDate.setFullYear(currentYear - 18);
      return maxDate
    } else {
      // don't limit
      return null
    }
  } 

  function getMonthIndex(date: Date): number {
    const monthIndex: number = date.getMonth();
    return monthIndex;
  }

  function getYear (date: Date): number {
    const year: number = date.getFullYear();
    return year;
  }
  
  /* End of Helpers */


  const initialDate = new Date();
  // Get the current year
  const currentYear = initialDate.getFullYear();
  // Generate the range of years from 1930 to the current year
  const years = getOptionsRange(1930, currentYear);
  // Generate the range of months by language
  const months = getMonthsOptionsByLanguage(language!);
  

  
  return (
    <DatePicker
        className={`${errorMode ? "date-picker-error" : "date-picker-input"} notranslate`}
        id={id}
        selected={value ? new Date(value) : null}  //the selected value
        onChange={(date: Date) => {
          // If the initial value is not set, clicking on a day in the first time takes the previous day for some reason, fix it by adding 1 day, 
          let adjustedDate = date;
          if (!value) {
            adjustedDate = new Date(date);
            adjustedDate.setDate(adjustedDate.getDate() + 1);
          }
          
          onChange(adjustedDate?.toISOString().split("T")[0]);
        }}
        
        placeholderText="Please select date"
        dateFormat="dd/MM/yyyy"
        maxDate={getMaxDate()}
       // @ts-ignore
        renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (

          // Header container 
          <div
            className='date-picker-header notranslate'
          >
          
            {/* Left Arrow  */}
            <span className={"date-picker-arrow"} onClick={() => {
              if(prevMonthButtonDisabled){
                return 
              }
              decreaseMonth()
            }}>
              {'<'}
            </span>

            {/* Year select  */}
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >

              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>


            {/* Month select */}
            <select
              value={months[getMonthIndex(date)]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))} 
            >

              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            {/* Right Arrow */}
            <span className={"date-picker-arrow"} onClick={() => {
              if(nextMonthButtonDisabled){
                return 
              }
               increaseMonth()
            }} >
              {'>'}
            </span>
            
          </div>
        )}

    />
  );
};

export default DatePickerInput;
