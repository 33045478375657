import React, { useContext, useMemo } from 'react'
import ProductCard from './ProductCard'
import { Product, ReturingCustomersLayoutContext, groupedProductsType } from '../ReturningCustomersLayout';
import NoPreferenceProductCard from './NoPreferenceProductCard';
import { CatalogSettingContext } from '../../ChooseTreatmentRouter';
import { sorted } from '../../../../utils/helpers';

const ProductSelection = () => {


  const context = useContext(ReturingCustomersLayoutContext);
  const routerContext = useContext(CatalogSettingContext) as any;

  const noPrefMedicineEnabled = routerContext?.isNoPrefereceTreatmentEnabled; //context?.noPreferenceMedicationFlow;
  const initialProduct = context?.initialProduct;

  const groupedProducts = context?.groupedProducts as groupedProductsType;

  const defaultCatalogID = context?.defaultCatalogID;
  let defaultProduct: any;

  if (groupedProducts && defaultCatalogID) {
    defaultProduct = groupedProducts[Number(defaultCatalogID)]
  }
  
  const productCatalogIDs = useMemo(() => {
    
    let productCatalogIDs = Object.keys(groupedProducts || {}).map((key) => parseInt(key))
    //Sort by order:
    productCatalogIDs = sorted(productCatalogIDs,(a, b) => (groupedProducts[a][0].treatments_order - groupedProducts[b][0].treatments_order));

    if (!context?.allowPrescriptionOnlyFlow) {

      //Sort by in stock:
      productCatalogIDs = sorted(productCatalogIDs, (a, b) => {
        const aInStock =  Number(groupedProducts[a].some((product:Product) => product.in_stock));
        const bInStock  =  Number(groupedProducts[b].some((product:Product) => product.in_stock));
        return bInStock - aInStock
      })
    }

    return productCatalogIDs

  },[groupedProducts])
  

  return (
    <div id='products-selection' className='flex flex-col gap-5 md:pr-5'>
      {
        noPrefMedicineEnabled && initialProduct && <div className='mb-5 flex flex-col gap-7'>
          <NoPreferenceProductCard key={'no-pref'} title={"Aucune préférence de traitement"} /> 
          <p className='text-xs text-[#98a0af] font-bold px-2'>Options de traitment</p>
        </div>
      }
      {
        defaultProduct && <div className='flex flex-col gap-8 justify-between'>
          <ProductCard key={defaultCatalogID} productCatalogID={Number(defaultCatalogID)} title={defaultProduct[0].title} />
          <p className='text-xs text-[#98a0af] font-bold px-2'>Weitere Behandlungen</p>
          </div>
      }

      {
        productCatalogIDs.filter((productCatalogID) => {
          return productCatalogID !== Number(defaultCatalogID)
        }).map((productCatalogID) => {
          return <ProductCard key={productCatalogID} productCatalogID={productCatalogID} title={groupedProducts[productCatalogID][0].title} />
        })
      }
    </div>
  )
}

export default ProductSelection
