import "./OptionCard.css"
import RadioSelect from "../../../../components/RadioSelect/RadioSelect";
import { RiInformationLine } from "react-icons/ri";



const OptionCard = ({isOptionSelected, onClick, data, disabled = false, icon, badgeText, showRadioSelected=true, showDescription=true}) => {
  return (
    <div
      className={(isOptionSelected ? "option-card-selected" : "option-card") + (disabled ? " disabled" : "")}
      onClick={!disabled ? onClick : () => {}}
    >

      {/* Header  */}
      <div className="option-card-header">
        {showRadioSelected && <RadioSelect backgroundColor={"#11ddac"} borderColor={"#c6f4e9"} selected={isOptionSelected}/>}
        <p className={"option-card-text" + (disabled ? " disabled" : " ")}>  {data.value} </p>

        { icon ?  
          <div>
            <img  src={icon} alt="" className="payment-icon" /> 
        </div>
          : 
          null      
        }
      </div>
      
      {/* Description  */}
      {
        data.description && showDescription ? <p className="option-card-description">{data.description}</p> : null
      }

      {/* Bold Description  */}
      {
        data.bold_description ? <p style={{fontWeight:600}} className="option-card-description"> {data.bold_description} </p> : null
      }


      {/* Badge  */}
      { badgeText ?
        <span className="option-card-badge">
          <RiInformationLine size={14} id="info-icon"/>
          {badgeText}
        </span>
        :
        null
      }
     




      
    </div>
  );
};

export default OptionCard;
