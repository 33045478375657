import React, { ReactNode } from 'react';
import { useNavigate } from "react-router-dom"
import * as translator from "../../../../utils/translator"
import Significance from '../../../Questionnaire/components/Significance/Significance';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';

interface MyAccountOrderDetailRowProps {
  title: string;
  detailData: string | string[] | { [key: string]: string } | any; //value is either a string, an array of strings,or an object of strings 
  language: string;
  rowID?:string
  additionalData?: any
  children?: ReactNode
}

const MyAccountOrderDetailRow: React.FC<MyAccountOrderDetailRowProps> = ({ title, detailData, language, rowID='', additionalData, children }) => {
  const navigate = useNavigate();

  const renderValueByType = (significanceText:string) => {
    if (typeof detailData === 'string') {
      return <p className="account-order-detail-row-value">{detailData}</p>;
    } else if (Array.isArray(detailData)) {
      return (
        <ul>
          {detailData.map((item, index) => (
            <li key={index}>
              <p className="account-order-detail-value-fieldvalue">{item}</p>
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <ul id="object-detail">
          {Object.keys(detailData).map((key) => {
            if (translator.translate(language, "my_account", `MyOrders/details/${key}`)) {
              return (
                <li key={key}>
                  <p className="account-order-detail-value-field">
                    {translator.translate(language, "my_account", `MyOrders/details/${key}`)}
                  </p>
                  <p className="account-order-detail-value-fieldvalue">{detailData[key]}</p>
                </li>
              );
            } else if(key == 'wire_banner') {
              return(
                <Significance key={key}
                id={"account-significance"}
                dangerSignificancesRefsDispatch={null}
                parentID={null}
                showIcon 
                level={"danger"} 
                fontWeight={400}
                fontSize = {'13px'}
                text={significanceText}/>
                )
              }else if(key === "isShowPaymentButton" && detailData[key] === true){
                return(
                  <SubmitButton 
                    key={key}
                    id="detail-row-pay-now"
                    placeholder={translator.translate(language, "my_account", `MyOrders/details/payOnline`)}
                    onSubmit={()=>{navigate(`/payment/${additionalData.country}/${language}/${additionalData.hashID}`)}}
                  />
                  )
                } else {
                  return (
                    <li key={key}>
                  <p className="account-order-detail-value-fieldvalue"><strong>{detailData[key]}</strong></p>
                </li>
              );
            }
          })}
        </ul>
      );
    }
  };

  let significanceText = translator.translate(language, "my_account", `MyOrders/details/paymentSignificance/${additionalData?.isOnlineVD}`);
  if(significanceText){
    significanceText = significanceText.replace(':amount', `<strong>${additionalData?.total}</strong>`);
    significanceText = significanceText.replace(':hash_id', `<strong>${additionalData?.hashID}</strong>`);  
  }
  
return (
  <div> 
    <div className="account-order-detail-row" id={rowID}>

      <div id="title-value-container"> 
      <p className="account-order-detail-row-title">{title}</p>
      {renderValueByType(significanceText)}
      </div>
      
      {children}
    </div>
  </div>
);
};

export default MyAccountOrderDetailRow;
