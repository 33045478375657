import React from "react";
import { useParams, useOutletContext } from 'react-router-dom';
import InfoCell from "../../../../components/InfoCell/InfoCell";
import EditableInfoCard from "../EditableInfoCard/EditableInfoCard";
import "./MyAccountEditSubscriptionPanel.css";
import * as translator from '../../../../utils/translator';
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { RiInformationLine } from "react-icons/ri";
import { MainTracker } from '../../../../PixelTrackers/MainTracker';
import Significance from "../../../Questionnaire/components/Significance/Significance";

const MyAccountEditSubscriptionPanel = () => {
  const {myAccountData,setCurrentGlobalModalName} = useOutletContext<any>();
  const {order_hash_id} = useParams<{  order_hash_id: string | undefined }>(); //the hashID from url
  const language = myAccountData.locale.language_id
  if(order_hash_id){
    const subscriptionData = myAccountData.mySubscriptions.subscriptionData[order_hash_id]
    const discountValue = (subscriptionData.regularPrice - subscriptionData.subscriptionPrice).toFixed(2) + ' ' + subscriptionData.currencySign
    const nextPayment:any = new Date(subscriptionData.rawNextBilling);
    const nextDelivery:any = new Date(subscriptionData.rawNextDelivery); 
    const deliverySkipped:any = new Date(subscriptionData.rawDeliverySkipped);  

    const today:any = new Date();

    const prePayment = nextPayment > today ? true : false //allow skipping only if next payment is after today

    let timeDifference
    if(deliverySkipped && prePayment){
      timeDifference = deliverySkipped - today; // in miliseconds
    }else{
      timeDifference = nextDelivery - today
    }
    const daysUntilNextSkip = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); //convert to days

    return <>
      <div className="edit-subscription-panel">
        {
          subscriptionData.isQuestionnaireExpired &&
          <Significance dangerSignificancesRefsDispatch={null} parentID={null} showIcon level={"danger"} fontWeight={400} fontSize={'16px'} text={`Ihre medizinischen Daten müssen aktualisiert werden, damit die nächste Bestellung Ihres Abonnements erstellt werden kann. Bitte {klicken Sie hier:onclick="window.location.href = window.location.pathname + '#renew_questionnaire_banner'; location.reload()"}, um sie zu überprüfen und gegebenenfalls zu ändern`}/>
        }
        <div className="info-cells-row">
          <InfoCell title={translator.translate(language, "my_account", 'SubscriptionOrders/infoCells/dosage')} value={subscriptionData.infoCells.dosage} />
          <InfoCell title={translator.translate(language, "my_account", 'SubscriptionOrders/infoCells/pillCount')} value={subscriptionData.infoCells.pillQuantity} />
          <InfoCell title={translator.translate(language, "my_account", 'SubscriptionOrders/infoCells/plan')} value={subscriptionData.infoCells.plan} />
        </div>
          { ((!subscriptionData.deliverySkipped || (today > deliverySkipped)) && prePayment) ? (
          <div className="skip-subscription-container">
            <div className="skip-subscription-text-icon-container">
              <RiInformationLine className="skip-subscription-info-icon"/>
              <p className="skip-subscription-text">Ich habe im Moment genug Medikamente und möchte meine nächste Lieferung überspringen. Mein Behandlungsplan mit einmaligem Rabatt von {discountValue} soll aktiv bleiben</p>
            </div>
            <SubmitButton 
              loading={false}
              onSubmit={()=>{
                setCurrentGlobalModalName({type:"SKIP_SUBSCRIPTION", data:{hashID:order_hash_id, from:'mySubscriptions'}})
                MainTracker.track('pv', 'View Pop Up', {'Pop Up Name': 'Skip my upcoming order from my account'})
              }
            }
              id={"subscription-delivery-address-submit"}
              fontsize={"14px"}
              padding={"0px 30px"}
              height={"45px"}
              placeholder="Nächste Lieferung überspringen" 
              isEnabledState />
          </div>
          ):(
            <div className="skip-subscription-container skip-disabled">
            <div className="skip-subscription-text-icon-container skip-disabled">
              <RiInformationLine className="skip-subscription-info-icon skip-disabled"/>
            <div className="skip-subscription-text-container">
              {
                (!prePayment && !subscriptionData.rawPausedAt) ? 
                  (
                    <>
                      <p className="skip-subscription-title skip-disabled">Sie können Ihre nächste Lieferung erst wieder in {daysUntilNextSkip} Tagen überspringen.</p>
                      <p className="skip-subscription-text skip-disabled">Das nächste Abrechnungsdatum ist vergangen.</p>
                    </>
                  )
                :
                  (!prePayment && subscriptionData.rawPausedAt) ? 
                      (
                        <>
                          <p className="skip-subscription-title skip-disabled">Die Option ‚Überspringen’ ist vorübergehend nicht verfügbar, Sie können sich für weitere Informationen an den Kundenservice wenden.</p>
                        </> 
                      )
                    :
                      (
                        <>
                          <p className="skip-subscription-title skip-disabled">Sie können Ihre nächste Lieferung erst wieder in {daysUntilNextSkip} Tagen überspringen.</p>
                          <p className="skip-subscription-text skip-disabled">Sie haben Ihre Bestellung, vorgesehen für den {subscriptionData.deliverySkipped}, übersprungen.</p>
                        </>
                      )
              }
            </div>
            </div>
            <SubmitButton 
              loading={false}
              id={"subscription-skip-submit-disabled"}
              fontsize={"14px"}
              padding={"0px 30px"}
              height={"45px"}
              placeholder="Nächste Lieferung überspringen" 
              isEnabledState />
          </div>
          )}

          <EditableInfoCard editSection={"delivery-address"} title={translator.translate(language, "my_account", 'SubscriptionOrders/infoCards/address')} value={subscriptionData.address} language={language}/>
          <EditableInfoCard editSection={"treatment-questionnaire"} title={translator.translate(language, "my_account", 'SubscriptionOrders/infoCards/treatmentQuestionnaire')} value={subscriptionData.catalog_title} language={language}/>

          <span onClick={()=> setCurrentGlobalModalName("CANCEL_SUBSCRIPTION")} className="cancel-subscription">{translator.translate(language, "my_account", 'SubscriptionOrders/cancel')}</span>
        </div>
    </>
  }else{
    return(<>400</>)
  }
};

export default MyAccountEditSubscriptionPanel;
