import React from "react";
import "./ReorderProductCard.css";
import { clientConfig } from "../../../../config";

interface ReorderProductCardProps {
  productImgSrc: string;
  productTitle: string;
  productQuantity: string;
  productPrice: number;
}

const ReorderProductCard:React.FC<ReorderProductCardProps> = ({productImgSrc,productTitle,productQuantity,productPrice}) => {
  return (
    <div className="reorder-product-card">
      <div className="img-container">
        <img
          src={`${clientConfig.IMAGES_URL}${productImgSrc}`}
          alt="product-img"
        />
      </div>
      <div className="texts-container">
        <h3 className="reorder-product-card-title"> {productTitle}</h3>
        <span className="reorder-product-card-quantity">{productQuantity}</span>
        <p className="total-price-row">
          <span className="total-vat">Total Incl. VAT</span>
          <span>{productPrice.toFixed(2) + " €"}</span>
        </p>
      </div>
    </div>
  );
};

export default ReorderProductCard;
