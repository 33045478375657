import { useState } from "react";
import {
  CHECKBOX_OPTION,
  CHECKBOX,
  getNode,
  validateQuestion,
} from "../components/helpers";


type ID = string | number | null;

interface AnswersGroup {
  recipe: any;
  children: Array<any>
}

interface Question {
  question_id: ID,
  question: string | null
  question_type: string
  answerGroup: AnswersGroup
  answer: any
  answer_id: any
  specification: any
  auto_processing: any
  hide_from_doctor: any
  show_to_pharmacy: any
  is_answer_blocking: any
  specification_data: Array<any>
}

interface QuestionsObject {
  [key: string | number]: any;
}

export const useQuestionnaire = () => {
  // ---------------------------------------------- questionnaire data  -----------------------------------------
  const [questionnaireTree, setQuestionnaireTree] = useState<any>({});
  const [flattenedQuestionnaire, setFlattenedQuestionnaire] = useState<Array<any>>([]);
  const [answersSelectedMap, setAnswersSelectedMap] = useState<any>({});
  const [revealedAnswerGroups, setRevealedAnswerGroups] = useState<any>({});
  const [questionnaireValidationObject, setQuestionnaireValidationObject] =
    useState<any>({});
  const [questionsRef, setQuestionsRef] = useState<QuestionsObject>({});
  // -------------------------------------------------------------------------------------------------------------

  // utility functions for questionnaire ------------------------------
  const handleRef = (questionID: string | number, questionRef: any) => {
    setQuestionsRef((oldVal) => ({ ...oldVal, [questionID]: questionRef }));
  };

  const onReveal = (answerGroup: AnswersGroup) => {
    setRevealedAnswerGroups((oldVal: any) => {
      return { ...oldVal, [answerGroup.recipe.id]: true };
    });
  };

  const onHide = (answerGroup: AnswersGroup) => {
    setRevealedAnswerGroups((oldVal: any) => {
      return { ...oldVal, [answerGroup.recipe.id]: false };
    });
  };

  //helper function to recursivley set all sibling answers and their subquestions when an answer is clicked
  // set the form to false for each answer/answerGroup that is a sibling of "selectedAnswerID" and is not "startingNodeID"

  const recursivelyShutDown = (node: AnswersGroup, selectedAnswerID: ID, startingNodeID: ID) => {
    if (node?.recipe.id === selectedAnswerID) {
      return;
    }

    if (node?.recipe.id !== startingNodeID) {
      setAnswersSelectedMap((oldVal: any) => ({
        ...oldVal,
        [node?.recipe.id]: false,
      }));
    }

    node?.children.forEach((child) => {
      recursivelyShutDown(child, selectedAnswerID, startingNodeID);
    });
  };


  //wrapper function for "recusivelyShutDown"
  //given an answer, start shutting down starting from the parent of that answer

  const shutDownSiblingsDescendants = (selectedAnswerNode: AnswersGroup) => {
    let rootQuestionNode: AnswersGroup =
      questionnaireTree[selectedAnswerNode.recipe.recipe_question_id];
    let parentID = selectedAnswerNode.recipe.parent_id;
    let parentNode = getNode(rootQuestionNode, parentID);

    if ([CHECKBOX_OPTION, CHECKBOX].includes(selectedAnswerNode.recipe.type)) {
      if (answersSelectedMap[selectedAnswerNode.recipe.id]) {
        recursivelyShutDown(selectedAnswerNode, null, parentID);
      }
    } else {
      recursivelyShutDown(parentNode, selectedAnswerNode.recipe.id, parentID);
    }
  };

  const validateQuestionnaire = (questionnaire: any, revealedAnswerGroups: any, questionnaireValidationObject: any) => {
    let isValid = true;

    let validationObjectCpy = JSON.parse(
      JSON.stringify(questionnaireValidationObject)
    );

    let firstInValidQuestionID: ID = 0;

    questionnaire.forEach((question: Question) => {
      if (
        !["title","photo_diagnose"].includes(question.question_type) &&
        revealedAnswerGroups[question.answerGroup.recipe.id]
        
      ) {
        
        let isAnswerValid = validateQuestion(question) && questionnaireValidationObject[question.answerGroup.recipe.id];

        //keep track of the first invalid question for scrolling...
        if (!firstInValidQuestionID && !isAnswerValid) {
          isValid = false;
          firstInValidQuestionID = question.question_id;
        }

        validationObjectCpy[question.answerGroup.recipe.id] = isAnswerValid;
      }
    });

    setQuestionnaireValidationObject((oldVal : any) => {
      return validationObjectCpy;
    });


    //scroll with a small delay for animation
    setTimeout(() => {
      if (firstInValidQuestionID) {
        questionsRef[firstInValidQuestionID]?.current?.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 150);

    return isValid;
  };
  return {
    questionnaireValidationObject,
    setQuestionnaireValidationObject,
    questionnaireTree,
    setQuestionnaireTree,
    flattenedQuestionnaire,
    setFlattenedQuestionnaire,
    answersSelectedMap,
    setAnswersSelectedMap,
    revealedAnswerGroups,
    setRevealedAnswerGroups,
    questionsRef,
    setQuestionsRef,
    handleRef,
    onReveal,
    onHide,
    recursivelyShutDown,
    shutDownSiblingsDescendants,
    validateQuestionnaire,
  };
};
