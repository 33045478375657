import React from "react";
import "../../../../core.css";

import ShippingForm from "../ShippingForm/ShippingForm";

interface VCAddressProps {
  allowPrescriptionOnly: boolean;
  updatePrescriptionOnly: boolean;
  pageState: any;
  selected: boolean;
  onClick: any;
  params: any;
  updateDeliveryMethodID: any;
  language: string;
}

const VideoConsultationAddressShipping: React.FC<VCAddressProps> = ({
  allowPrescriptionOnly = true,
  updatePrescriptionOnly,
  pageState,
  selected = false,
  onClick,
  params,
  updateDeliveryMethodID,
  language = "fr",
}) => {
  if (pageState.serviceOptions) {
    return (
      <div className="delivery-panel-outer-container">
        <div className={"single-content"}>
          <div className="delivery-options ">
            <p className="delivery-options-title">
              {"L'adresse de facturation"}
            </p>
          </div>
          {/* <p className="delivery-options-title">Shipping Address</p> */}

          <ShippingForm language={language} selected />
        </div>
      </div>
    );
  } else {
    return <React.Fragment/>;
  }
};

export default VideoConsultationAddressShipping;
