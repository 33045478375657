import React from 'react';
import InnerHTMLRenderer from '../../../../components/InnerHTMLRenderer/InnerHTMLRenderer';
import "./MyAccountOrderPrescriptionQuestion.css";

interface MyAccountOrderPrescriptionQuestionProps {
    questionHtmlContent:string;
    questionType:string
    answerText:string;
}


const MyAccountOrderPrescriptionQuestion:React.FC<MyAccountOrderPrescriptionQuestionProps> = ({
    questionHtmlContent,
    questionType,
    answerText,
}) => {
    if(questionHtmlContent){
        return (
            <div className={questionType === "title" ? "prescription-row-title-container" : "prescription-row-container"}>
               <InnerHTMLRenderer className={"prescription-question"} htmlContent={questionHtmlContent}/>
               {
                answerText &&
                <div className="prescription-answer-container">
                <p>{answerText}</p>
               </div>
               }
            </div>
          );
    } else {
        return <></>
    }

  


}

export default MyAccountOrderPrescriptionQuestion