type Props = {
	title: string;
	content: string;
	step: number | string;
	imgSrc: string;
	imgAlt: string;
};
export const StartConsultationCard: React.FC<Props> = ({ title, content, step, imgSrc, imgAlt }) => {
	return (
		<div
			id="start-consultation-card"
			className="w-full h-fit lg:h-[340px] relative pt-[10px] rounded-[38px_7.5px] bg-[#F7F7F7] md:pt-[25px] pr-[18px] pb-0 pl-[18px] justify-center"
		>
			<div
				id="blue-circle"
				className="left-0 lg:mx-auto lg:right-0 lg:left-0 w-[35px] h-[35px] md:h-[50px] md:w-[50px] justify-center items-center flex top-[-10px] md:top-[-20px] rounded-[50%] bg-[#0D2C54] absolute"
			>
				<div id="circle-text" className="text-[21px] text-white font-semibold justify-center items-center flex">
					{step}
				</div>
			</div>
			<div className="flex flex-row-reverse justify-end">
				<div id="step-block" className="flex flex-col h-full lg:justify-center lg:items-center lg:text-center">
					<div id="step-title" className="lg:mt-3 text-[14px] md:text-[22px] mb-[10px] text-[#0D2C54] font-poppins font-bold" dangerouslySetInnerHTML={{ __html: title }} />
					<div id="step-text" className="mb-[10px] text-[10px] lg:text-[11px] md:text-[14px] text-[#2B2B2B] font-medium font-poppins" dangerouslySetInnerHTML={{ __html: content }} />
				</div>
				<div id="start-consultation-card-photo" className="static w-fit items-end flex mr-[19px] lg:absolute lg:bottom-0 lg:inset-x-0 lg:mx-auto">
					<img src={imgSrc} alt={imgAlt} className="object-cover w-full h-full max-lg:h-[110px] max-lg:w-[110px]" />
				</div>
			</div>
		</div>
	);
};

export default StartConsultationCard;
