/* Hubspot TRACKER   */

export const identify = (email) => {
  window._hsq = window._hsq || [];
  window._hsq.push([
    "identify",{email},
  ]);

  window._hsq.push(["setPath", window.location.href]);
  window._hsq.push(["trackPageView"]);
};


export const track = (eventType,eventName, props) => {

  if (eventType === 'pv'){
    window._hsq = window._hsq || [];
    window._hsq.push(["setPath", window.location.href]);
    window._hsq.push(["trackPageView"]);
  }
  
};
