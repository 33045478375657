import Significance from "../Significance/Significance";
import "./EmojiBlock.css";

export default function EmojiBlock({discount_value,discount_measure}) {

   const getDiscountValueString = () =>{
      if(discount_measure === "percent"){
        return String(Math.floor(discount_value)) + "%"
      } else {
        return Math.floor(discount_value);
      }
   }

  return (
    <div className="emojiblock-container">
      <p className="emojiblock-title">Gratis Online-Diagnose per Fragebogen</p>
      <div className="emojiblock-discount-notification">
        <Significance
         level={"success"}
         showIcon={false}
         fontWeight={600}
         text={`🎁   Ihr ${getDiscountValueString()}-Euro-Rabatt wird im nächsten Schritt verrechnet`}/>

      </div>
      <p className="emojiblock-subTitle">
        So funktioniert der Prozess - Einfach. Schnell. Diskret:
      </p>
      <ul>
        <li>
          <span className="icon">&#128203;</span>{" "}
          <p>Füllen Sie den medizinischen Fragebogen aus</p>
        </li>
        <li>
          <span className="icon">&#128138;</span>{" "}
          <p>Danach können Sie Ihr bevorzugtes Medikament wählen</p>
        </li>
        <li>
          <span className="icon">&#127891;</span>{" "}
          <p>Der Arzt wird Ihren Fragebogen auswerten</p>
        </li>
        <li>
          <span className="icon">&#128666;</span>{" "}
          <p>
            Die Apotheke erhält das Rezept digital übermittelt und versendet das
            Medikament
          </p>
        </li>
      </ul>
      <span className="only-doc">Nur der Arzt kann in diese Daten einsehen.</span>
    </div>
  );
}
