import React from "react";
import RadioSelect from "../../../../components/RadioSelect/RadioSelect";
import * as translator from "../../../../utils/translator";
import DrugSelectionSidePanel from "../DrugSelectionSidePanel/DrugSelectionSidePanel";

const DefaultProductCard = (props) => {

  return (
    <>
      <div
        style={{ width: props.width }}
        className={
          props.selectedDrugID == props.index
            ? "single-item-container-selected"
            : "single-item-container"
        }
      >
        <li
          style={{ width: props.width, height: props.height }}
          className={
            props.selectedDrugID == props.index
              ? "default-product product-item-selected"
              : "default-product product-item" +
                (props.isDisabled ? " out-of-stock" : "")
          }
          onClick={
            props.isDisabled
              ? () => {}
              : () => {
                  if (props.index !== props.selectedDrugID) {
                    props.setSelectedDrugID(props.index);
                    props.changeDrugCallback(props.drugName);
                  }
                }
          }
        >
          <RadioSelect selected={props.index === props.selectedDrugID} />
          <div className="product-name-and-price">
            <span className="product-item-text">
              <span>{props.drugName}</span>
            </span>

            <span className="product-item-from-price">
              {props.showPrice ? (
                <>
                  <div className="from-text">
                    {translator.translate(
                      props.language,
                      "default",
                      "DrugInfoPanel/from"
                    )}{" "}
                  </div>

                  <div className="from-price">
                    €
                    {props.minPrices[props.drugName]
                      ? props.minPrices[props.drugName]
                      : 0}
                  </div>
                </>
              ) : null}
            </span>

          </div>
        </li>
        <div
          className={
            props.selectedDrugID == props.index
              ? "test-panel-selected"
              : "test-panel"
          }
        >
          <DrugSelectionSidePanel
            currencySign={props.currencySign}
            navigationCallback={props.navigationCallback}
            selectedDosage={props.selectedDosage}
            currentSelectedProductItemIndex={
              props.currentSelectedProductItemIndex
            }
            changeCurrentlySelectedProductItemIndex={
              props.changeCurrentlySelectedProductItemIndex
            }
            productItemSubscriptionPlans={props.productItemSubscriptionPlans}
            pricesLogistics={props.pricesLogistics}
            language={props.language}
            drugFullNames={props.drugFullNames}
            hideOnResize={props.hideOnResize}
            products={props.products}
            listOfPackets={props.listOfPackets}
            selectedDrug={props.selectedDrug}
            defaultDosages={props.defaultDosages}
            listOfDosages={props.listOfDosages}
            changeDosage={props.changeDosage}
            changePacketsList={props.changePacketsList}
          />
        </div>

        {Object.keys(props.products).length > 1 ? (
          <p className="orderWindow-container-title treatment-options">
            Weitere Behandlungen
          </p>
        ) : null}
      </div>
    </>
  );
};

export default DefaultProductCard;
