import React from 'react'
import { useOutletContext} from "react-router-dom";
import { useEffect } from "react";
import MyAccountContentHeader from '../MyAccountContentHeader/MyAccountContentHeader'
import MyAccountOrderCard from '../MyAccountOrderCard/MyAccountOrderCard'
import * as translator from '../../../../utils/translator';

const defaultImage = require('../../../../images/product_box.jpg')

const MyAccountMyOrders = () => {
  const {setActiveMenuLinkId ,myAccountData,country,language} = useOutletContext<any>();
  const ordersAmount = Object.keys(myAccountData.myOrders.ordersData).length;

  useEffect(()=>{
    setActiveMenuLinkId("my-orders");
  },[])
  
  return (
    <>
      <div className="account-content">
          <MyAccountContentHeader
            title={translator.translate(language, "my_account", 'MyOrders/title')}
            description={translator.translate(language, "my_account", 'MyOrders/subTitle')}
            showPrintOptionInDescription={ordersAmount > 0 ? true : false}
            showOtherOrders
            showSubscriptionDisclaimer = {ordersAmount > 0 && country == 'de' ? true : false}
            otherCountry = {myAccountData.myOrders.otherCountryCounter}
            />

        {Object.values(myAccountData.myOrders.ordersData).map((order:any, index:number) => {
            
            const treatmentFee:number = Number(order.prices['treatmentFee']?.split(' ')[0])
            const treatmentFeeDiscount:number = Number(order.prices['treatmentFeeDiscount']?.split(' ')[0]);
            const currencySymbol:string = String(order.prices['treatmentFee']?.split(' ')[1])

            return(
              <MyAccountOrderCard 
              key={index}
              productImgSrc={order.image || defaultImage}
              orderTitle={order.orderType == 'prescription_only' ? `${translator.translate(language, "my_account", 'MyOrders/CardPrescriptionFor')} - ${order.title}` : order.title}
              orderDate={order.orderDate}
              orderStatus={order.status}
              orderHashId={order.hashID}              
              orderPriceTotal={order.prices['total']}
              orderTreatmentFee={order.prices['treatmentFeeDiscount'] ? (treatmentFee + treatmentFeeDiscount).toFixed(2) + ' ' + currencySymbol : order.prices['treatmentFee']}
              country={country}
              orderApplicationNotes={order.applicationNotes}
              orderRejectReason={order.rejectReason}
              isAvailableForReorder={order.isAvailableForReorder}
              mention={order.mention}
              catalogID={order.catalogID}
              notificationsAmount={order.notifications}
              paymentMethod={order.paymentMethod}
              isOtcOrder={order.orderType === 'otc'}
              orderShipping={order.orderShipping}
              recipeCopy={order.recipeCopy}
              />
            )
        })}

      </div>
    </>
  )
}

export default MyAccountMyOrders