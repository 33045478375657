import './PhotoSelection.css'


export default function PhotoSelection({photoSrc,placeholder,id,pickedPhotoId,onChangeValue,questionId, answer, errorMode}) {

  //in order to change border color dynamiclly
  const getContainerClassName = () =>{
    if(errorMode){
      return 'questionnaire-photoSelection-error';
    }

    else if(id === pickedPhotoId){
      return 'questionnaire-photoSelection-selected';
    }

    else return 'questionnaire-photoSelection';
  }


  return (
    <div onClick={()=>onChangeValue(answer ,id)} className={getContainerClassName()}>
        <img className='questionnaire-photoSelection-image' src={photoSrc} alt={`photoSelection-${placeholder}`} />   

        <div className='questionnaire-photoSelection-input-label-container'>
            <div
             className={id === pickedPhotoId  ? 'questionnaire-photoSelection-input-selected':'questionnaire-photoSelection-input'}
             placeholder={'this is a test'}
            />
            <span id={`photo_${questionId}_${id}`} className='questionnaire-photoSelection-placeholder'>{placeholder}</span>
        </div> 
        
    </div>
  )
}
