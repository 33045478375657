import * as http from "../../utils/http";

export const getDefaultProductItemByRule = (product, defaultQuantityRule) => {
  let defaultProductItem;

  let inStockFilteredProductGroup = product.filter(
    (product) => product.in_stock
  );

  switch (defaultQuantityRule) {
    case "min_available":
      defaultProductItem = inStockFilteredProductGroup[0];
      break;

    case "max_available":
      defaultProductItem =
        inStockFilteredProductGroup[inStockFilteredProductGroup.length - 1];
      break;

    case "second_from_min_available":
      defaultProductItem = inStockFilteredProductGroup[1];
      break;

    case "second_from_max_available":
      defaultProductItem =
        inStockFilteredProductGroup[inStockFilteredProductGroup.length - 2];
      break;

    default:
      defaultProductItem = inStockFilteredProductGroup[0];
  }

  return defaultProductItem;
};

//this function is responsible for choosing the default product item (when user chooses no prefference)
export const getDefaultProductItem = (products) => {
  let defaultProductName = Object.keys(products)[0];

  let productNamesWithDosages = Object.keys(products[defaultProductName]);
  let defaultProduct = products[defaultProductName][productNamesWithDosages[0]];
  let defaultQuantityRule = defaultProduct[0].default_quantity;

  //iterate the products hirarchey to find a product name (with dosage) that is marked as default dosage
  //get the default_quantity_rule, (which can be max, min, etc..)

  for (let productNameWithDosage of productNamesWithDosages) {
    if (
      products[defaultProductName][productNameWithDosage][0]
        .is_default_dosage === 1
    ) {
      defaultProduct = products[defaultProductName][productNameWithDosage];
      defaultQuantityRule = defaultProduct[0].default_quantity;
      break;
    }
  }

  //according to the rule, get the item from an array of sorted product items (sorted by quantity)
  return getDefaultProductItemByRule(defaultProduct, defaultQuantityRule);
};

export const updatePrescriptionOnlyDBCart = async (
  cartID,
  prescriptionOnly
) => {
  try {
    await http.postToServer("cart/update_prescription_only", {
      prescriptionOnly,
    },
    [http.MIDDLEWARES.CART_ORDER]);
  } catch (err) {
    throw new Error("error updating cart");
  }
};

export const updateSubscriptionDBCart = async (cartID, subscriptionPlanID) => {
  try {
    await http.postToServer("cart/update_subscription_data", {
      subscriptionPlanID,
    },[http.MIDDLEWARES.CART_ORDER]);
  } catch (err) {
    throw new Error("error updating cart");
  }
};


//basically returns the unions of two arrays (no duplicates)
export const mergeArrays = (arr1, arr2, compareFn) => {
  const newArr = arr1;

  for (let i = 0; i < arr2.length; i++) {
    const item = arr2[i];
    if (!newArr.find((arrItem) => arrItem.value === compareFn(item))) {
      newArr.push(item);
    }
  }
  return newArr;

};

