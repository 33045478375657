import { clientConfig } from "../config";

let initialized = false
export const init = () => {
  if (initialized){
    return
  }
  initialized = true

  let windowAsAny = window as any;

  const uuid = windowAsAny.eventsTracking.getData("uuid");

  windowAsAny.OneSignalDeferred = windowAsAny.OneSignalDeferred || [];
  windowAsAny.OneSignalDeferred.push(async (OneSignal: any) => {
    try {
      await OneSignal.init({
        appId: clientConfig.ONESIGNAL_APP_ID,
        notifyButton: {
          enable: true,
        },
        serviceWorkerParam: { scope: "/" },
        serviceWorkerPath: "/OneSignalSDKWorker.js",
      });
      if (uuid) {
        await OneSignal.login(uuid);
      }

      const createTags = (permission: any) => {
        if (permission) {
          const tags: any = {
            currentUrl: windowAsAny.location.href,
            currentTitle: document.title,
          };
          const params = new URLSearchParams(window.location.search);
          for (const [key, value] of params.entries() as any) {
            tags[key] = value;
          }
          OneSignal.User.addTags(tags);
        }
      };
      OneSignal.Notifications.addEventListener("permissionChange", createTags);
    } catch (err:any) {
      // mute one signal not enabled for current funnel domain
      if (!err.message.includes("This web push config can only be used")){
        window.clientLogger.warn("error loading one signal", err);
      }
    }
  });

  let oneSignalScript = document.createElement("script")
  oneSignalScript.setAttribute("src","https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js")  
  document.body.append(oneSignalScript)

};
