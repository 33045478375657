import React, { useState } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import "./ResetPasswordPage.css";
import HeaderController from '../../components/Headers/HeaderController';
import Footer from '../../components/Footer/Footer';
import "./ResetPasswordPage.css";
import useForm from '../../customHooks/useForm';
import InputField from '../../components/CredentialsInput/InputField';
import * as translator from "../../utils/translator";
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import * as http from "../../utils/http"
import StickyNotification from '../../components/StickyNotification/StickyNotification';

const ResetPasswordPage = () => {

  const { country } = useParams();
  const language = translator.getLanguageByCountry(country);
  const { token } = useParams();
  const navigate = useNavigate()
  const [stickyNotificationDetails, setStickyNotificationDetails] = useState({ // global state for sticky handling
    show: false,
    title: "",
    content: "",
    level : "",
    showInfoIcon:true,
    refresh:false,
  });
  const resetPasswordFormAttributes = useForm(
    {
      password: "",
      repeat_password: ""
    }, language
  )

  const onSubmit = () => {

    if(resetPasswordFormAttributes.validateForm()){
      http
      .postToServer(
      `update-password`,
      {
        token:token,
        newPassword: resetPasswordFormAttributes.form.password
      },
      []
      )
      .then((response) => {
        navigate(`/${country}/login`);
      })
      .catch(error => {
        setStickyNotificationDetails({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/error/error`), 
          level: "error",
          showInfoIcon: false,
          refresh: false
        });
      });
    } else {
      window.clientLogger.error('forgot password form not validated, passwords dont match')
    }
  }

  return (

    
    <div className="reset-password-page">
      <StickyNotification
      showNotification={stickyNotificationDetails.show}
      title={stickyNotificationDetails.title}
      onClick={() => setStickyNotificationDetails((currentDetails:any) => { return {...currentDetails, show:false}})}  
      content={stickyNotificationDetails.content}
      level={stickyNotificationDetails.level}
      showInfoIcon={stickyNotificationDetails.showInfoIcon}
      refresh={stickyNotificationDetails.refresh}
      />
      <HeaderController
        stage="LoginPage"
        showSteps={false}
      />
      <div className="reset-password-content-container">
        <div className="reset-password-container">
        <form onSubmit={onSubmit} className="reset-password">
          <p className="reset-password-title">{
            translator.translate(
              language, 
              "authentication",
              "ResetPassword/title"
            )}
          </p>
          <InputField
            value={resetPasswordFormAttributes.form.password}
            errorMsg={resetPasswordFormAttributes.errorMessagePerField.password}
            onChange={(value: string) => {
              resetPasswordFormAttributes.updateForm("password", value);
            }}
            type="password"
            placeholder={translator.translate(
              language, 
              "default",
              "LoginQuestion/password"
            )}
            language={language}
            mandatory
            onBlur={() => resetPasswordFormAttributes.onFieldBlur("password")}
            touched={resetPasswordFormAttributes.touched.password}
          />
          
          <p className="registration-password-info">{translator.translate(
            language,
            "default",
            "RegistrationForm/password_info"
          )}

          </p> 

          <InputField
            value={resetPasswordFormAttributes.form.repeat_password}
            errorMsg={resetPasswordFormAttributes.errorMessagePerField.repeat_password}
            onChange={(value: string) => {
              resetPasswordFormAttributes.updateForm("repeat_password", value);
            }}
            type="password"
            placeholder={translator.translate(
              language,
              "default",
              "RegistrationForm/repeat_password"
            )}
            language={language}
            mandatory
            onBlur={() => resetPasswordFormAttributes.onFieldBlur("repeat_password")}
            touched={resetPasswordFormAttributes.touched.repeat_password}
          />
        </form>

        <SubmitButton
          id={"reset-password-form-submit"}
          language={language} 
          loading={false}
          width={"100%"}
          onSubmit={onSubmit}
          placeholder={translator.translate(
            language, 
            "authentication",
            "ResetPassword/submit_button_text"
          )}
        />

        </div>
      </div>
      <Footer page="CheckoutRegister" />
    </div>
  )
}

export default ResetPasswordPage;