import React, { useState } from 'react'
import HeaderController from '../../components/Headers/HeaderController'
import {useParams,useNavigate,Link} from "react-router-dom";
import "./RegistrationPage.css";
import Footer from '../../components/Footer/Footer';
import StickyNotification from '../../components/StickyNotification/StickyNotification';
import RegistrationForm from '../../components/RegistrationForm/RegistrationForm';
import * as translator from "../../utils/translator";

const RegistrationPage = () => {

  const {country} = useParams();
  const language = translator.getLanguageByCountry(country);
  const navigate = useNavigate();
  const [stickyNotificationDetails, setStickyNotificationDetails] = useState({ // global state for sticky handling
    show: false,
    title: "",
    content: "",
    level : "",
    showInfoIcon:true,
    refresh:false,
  });
  
  if(language){

  
  return (
    <>
    <div className="registration-page">
       <HeaderController
            language={language} 
            stage="RegistrationPage"
            showSteps={false}
        />  

        <StickyNotification
            showNotification={stickyNotificationDetails.show}
            title={stickyNotificationDetails.title}
            onClick={() => setStickyNotificationDetails((currentDetails:any) => { return {...currentDetails, show:false}})}  
            content={stickyNotificationDetails.content}
            level={stickyNotificationDetails.level}
            showInfoIcon={stickyNotificationDetails.showInfoIcon}
            refresh={stickyNotificationDetails.refresh}
        />

        <div className="registration-content-container">
          <div className="registration-content">
            <div className="registration-form-container">
               
              <p className="registration-form-container-title">
                {translator.translate(language, "authentication", 'RegistrationPage/registration_form_title')}
              </p>

              <span className="already-a-customer">
                 {translator.translate(language, "authentication", 'RegistrationPage/already_a_customer')}
                <Link to={`/${country}/login`} className="login-here">
                  {translator.translate(language, "authentication", 'RegistrationPage/login_here')}
                  </Link>
                </span>

               <RegistrationForm
                 languageProp={language}
                 navigationCallback={()=> {
                  navigate(`/${country}/account/personal-data`);
                }}
                 setStickyNotificationDetails={setStickyNotificationDetails}
                 submitButtonPlaceholder={
                  translator.translate(
                    language,
                    "authentication",
                    "RegistrationPage/submit_button_text"
                  )
                 }

               />
            
            </div>
          </div>
        </div>
        <Footer page="RegistrationPage" language={language} />  
    </div>
    </>
  )
  } else {
    return <></>
  }
}

export default RegistrationPage